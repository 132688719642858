import { useState } from 'react';
import { DialogLayout } from '../DialogLayout';
import { RichTextEditor } from '../RichTextEditor';
import { ArrowUturnLeftIcon, ClockIcon } from '@heroicons/react/24/outline';
import { Button } from '../Button';
import { useMutation, useQuery } from '@redwoodjs/web';
import { Spinner } from '../Spinner';
import { GET_DOCUMENT_QUERY } from 'src/graphql/queries/getDocumentQuery';
import { ListItem } from './ListItem';
import { RESTORE_DOCUMENT_MUTATION } from 'src/graphql/mutations';
import { toast } from '@redwoodjs/web/dist/toast';
import {
  GetDocumentQuery,
  GetDocumentQueryVariables,
  RestoreDocumentMutation,
  RestoreDocumentMutationVariables,
} from 'types/graphql';
import { documentPresentationalProperties } from 'src/lib/document';

export type DocumentHistoryDialogProps = {
  onClose: () => void;
  onRestore: (markup: string) => void;
  documentId: string;
};

export const DocumentHistoryDialog = ({
  onClose,
  onRestore,
  documentId,
}: DocumentHistoryDialogProps) => {
  const [selectedDocumentVersion, setSelectedDocumentVersion] = useState('');

  const { data, loading, error } = useQuery<GetDocumentQuery, GetDocumentQueryVariables>(
    GET_DOCUMENT_QUERY,
    {
      variables: { id: documentId },
    }
  );

  const [restoreDocument, { loading: restoreLoading }] = useMutation<
    RestoreDocumentMutation,
    RestoreDocumentMutationVariables
  >(RESTORE_DOCUMENT_MUTATION, {
    onCompleted: ({ restoreDocument }) => {
      onRestore(restoreDocument.markup);
      onClose();
      toast.success('Document restored.');
    },
    refetchQueries: [
      {
        query: GET_DOCUMENT_QUERY,
        variables: {
          id: documentId,
        },
      },
    ],
  });

  const documentHistory = data?.document?.history ?? [];
  const documentMarkup = data?.document?.markup;

  // Find the selected history record's markup or use the current document markup
  const documentContent = selectedDocumentVersion
    ? documentHistory?.find((doc) => doc.id === selectedDocumentVersion)?.markup
    : documentMarkup;

  const handleRestoreDocument = async () => {
    await restoreDocument({
      variables: { input: { id: selectedDocumentVersion } },
    });
  };

  if (loading) return <Spinner />;
  if (error) return <p>Error loading document history</p>;

  const documentTitle = data?.document?.__typename
    ? documentPresentationalProperties[
        data.document.__typename as keyof typeof documentPresentationalProperties
      ]?.title
    : undefined;

  return (
    <DialogLayout
      title={documentTitle}
      onClose={onClose}
      Icon={ClockIcon}
      className="relative flex h-full w-full flex-col  rounded-lg bg-white"
    >
      <div className="flex min-h-0 overflow-hidden md:space-x-4">
        <div className="flex  flex-1 flex-col  overflow-auto   p-4">
          <RichTextEditor disableMinWidth editable={false} content={documentContent} hideMenuBar />
        </div>
        <div className="flex min-w-56 overflow-auto p-4 pt-8">
          <ul className="flex flex-col space-y-2 ">
            {documentHistory?.map((content) => (
              <ListItem
                onClick={(id) => setSelectedDocumentVersion(id)}
                key={content.id}
                content={content}
                selected={selectedDocumentVersion == content.id}
              />
            ))}
          </ul>
        </div>
      </div>

      <div className="self-end py-8 pr-12">
        <Button
          onClick={handleRestoreDocument}
          size="large"
          text="Restore"
          LeftIcon={ArrowUturnLeftIcon}
          loading={restoreLoading}
          disabled={!selectedDocumentVersion}
        />
      </div>
    </DialogLayout>
  );
};
