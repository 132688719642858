import { FC } from 'react';
import { Checkbox, EntityStatusPill } from 'src/components';
import { navigate, routes } from '@redwoodjs/router';
import { classNames } from 'src/lib';
import { formatRelativeDateTime } from 'src/lib/dateTime';
import { CAMPAIGN_STATUS, Company } from '../../../types/graphql';
import { LocationTag } from 'src/components/LocationTag';
import {
  ArchiveBoxIcon,
  ArchiveBoxXMarkIcon,
  ArrowDownTrayIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/24/outline';
import { useDialog } from 'src/hooks';
import { UserIcon } from 'src/components';
import { CompanyExportDialog } from 'src/components/ExportDialogs';
import { notNullish } from 'src/lib/guards';
import { canSelectCompany } from './util';
import { DropdownButton } from 'src/components/DropdownButton';

type Props = {
  company: Pick<
    Company,
    'name' | 'status' | 'campaignStatus' | 'id' | 'location' | 'createdAt' | 'myPermission'
  > & { owner: Pick<Company['owner'], 'id' | 'name' | 'email'> };
  selected?: boolean;
  onToggleSelected?: (id: string) => void;
  hideCheckbox?: boolean;
  hideDropdownButton?: boolean;
  onArchiveCompany?: (company: Pick<Company, 'id'>) => void;
  onUnArchiveCompany?: (company: Pick<Company, 'id'>) => void;
  onDuplicateCompany?: (company: Pick<Company, 'id'>) => void;
};

export const CompanyTableRow: FC<Props> = ({
  company,
  selected,
  onToggleSelected,
  hideCheckbox,
  hideDropdownButton,
  onArchiveCompany,
  onUnArchiveCompany,
  onDuplicateCompany,
}) => {
  const { show } = useDialog();

  const onClick = () => {
    navigate(routes.company({ companyId: company.id }));
  };

  const isOwner = company.myPermission === 'OWNER';
  const archiveButton =
    company.status === 'ACTIVE'
      ? {
          Icon: ArchiveBoxIcon,
          text: 'Archive',
          onClick: () => onArchiveCompany?.(company),
        }
      : {
          Icon: ArchiveBoxXMarkIcon,
          text: 'Restore',
          onClick: () => onUnArchiveCompany?.(company),
        };

  const dropdownOptions = [
    isOwner ? archiveButton : null,
    {
      Icon: DocumentDuplicateIcon,
      text: 'Create a copy',
      onClick: () => onDuplicateCompany?.(company),
    },
    {
      Icon: ArrowDownTrayIcon,
      text: 'Export company details',
      onClick: () => show(<CompanyExportDialog id={company.id} />),
    },
  ].filter(notNullish);

  return (
    <li className="relative flex items-center">
      {!hideCheckbox && (
        <div className="mx-2 flex h-6 w-6">
          {canSelectCompany(company) && (
            <Checkbox
              value={!!selected}
              onChange={onToggleSelected ? () => onToggleSelected(company.id) : undefined}
            />
          )}
        </div>
      )}

      <div
        onClick={onClick}
        className={classNames(
          'flex flex-grow items-center border-b border-text-light py-4 pl-3',
          company.campaignStatus !== 'PENDING' && 'cursor-pointer hover:bg-gray-50'
        )}
      >
        <div className="flex flex-1 flex-col gap-y-1">
          <div className="text-text-dark">{company.name}</div>
        </div>
        <div className="w-48">
          {company.location && <LocationTag location={company.location} />}
        </div>
        <div className="flex w-32 justify-center">
          <UserIcon user={company.owner} />
        </div>
        <div className="flex-1 pl-8 text-sm text-text-medium">
          {formatRelativeDateTime(new Date(company.createdAt))}
        </div>
        <div className="flex h-4 flex-1 justify-center">
          <div>
            <EntityStatusPill
              campaignStatus={company.campaignStatus as CAMPAIGN_STATUS}
              status={company.status}
            />
          </div>
        </div>
        {!hideDropdownButton && (
          <div className="px-6">
            {dropdownOptions && <DropdownButton options={dropdownOptions} />}
          </div>
        )}
      </div>
    </li>
  );
};
