import { useEffect, useState } from 'react';

import { useForm } from '@redwoodjs/forms';
import { navigate, routes } from '@redwoodjs/router';
import { MetaTags } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/toast';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { useAuth } from 'src/auth';
import { TextField } from 'src/components';
import { Button } from 'src/components/Button';

import { Form } from 'src/components/Form/Form';
import Logo from '../../assets/logo.svg';

const schema = z.object({
  email: z.string({ coerce: true }).min(1, { message: 'Email is required' }).email(),
});

type ForgotPasswordForm = z.infer<typeof schema>;

const ForgotPasswordPage = () => {
  const formMethods = useForm<ForgotPasswordForm>({
    resolver: zodResolver(schema),
  });

  const [isLoading, setIsLoading] = useState(false);

  const { isAuthenticated, forgotPassword, loading } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(routes.Home());
    }
  }, [isAuthenticated]);

  const onSubmit = async (data: { email: string }) => {
    setIsLoading(true);
    try {
      await forgotPassword(data.email);

      toast.success(
        'Password reset email, if applicable, has been sent to the provided email address. Please check your inbox and spam folder.'
      );
      navigate(routes.login());
    } catch (error) {
      console.error(error);
      toast.error(
        'Sorry, we encountered an issue processing your request. Please check your details and try again, or contact support if the problem persists.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex h-full w-full flex-grow items-center justify-center">
      <MetaTags title="Forgot Password" />
      <Form<ForgotPasswordForm>
        className="flex w-full max-w-lg flex-col gap-y-3 rounded-3xl border border-text-light p-12"
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <div className="w-48 self-center">
          <Logo />
        </div>
        <div className="flex flex-col gap-y-2 pt-4">
          <h3 className="text-2xl text-text-dark">Forgot Password</h3>
          <p className="text-text-medium">Enter your email below to reset your password.</p>
        </div>
        <TextField disabled={loading} schema={schema} name="email" label="Email" />
        <div className="flex flex-col gap-y-3 pt-4">
          <Button
            disabled={isLoading}
            type="submit"
            text="Reset"
            size="large"
            loading={isLoading}
          />
          <Button
            onClick={() => navigate(routes.login())}
            text="Back"
            size="large"
            variant="outline"
          />
        </div>
      </Form>
    </div>
  );
};

export default ForgotPasswordPage;
