import { navigate, routes } from '@redwoodjs/router';
import { MetaTags, useMutation, useQuery } from '@redwoodjs/web';
import {
  CreateTemplateDocMutation,
  CreateTemplateDocMutationVariables,
  CreateTemplateDocumentInput,
  GetDocumentQuery,
  GetDocumentQueryVariables,
} from '../../../types/graphql';
import { DocumentEditor, Moonwalk, TemplateForm } from '../../components';
import { CREATE_TEMPLATE_DOC_MUTATION } from '../../graphql/mutations';
import { GET_DOCUMENT_QUERY } from '../../graphql/queries/getDocumentQuery';
import { usePageClasses } from '../../hooks';

type TemplateTypes = 'ClientProposal';

const CreateDocumentPage = ({ templateType }: { templateType: TemplateTypes }) => {
  usePageClasses('bg-pageGray');
  const [
    createTemplateDoc,
    { loading: mutationLoading, error: mutationError, data: mutationData },
  ] = useMutation<CreateTemplateDocMutation, CreateTemplateDocMutationVariables>(
    CREATE_TEMPLATE_DOC_MUTATION,
    {
      onCompleted: (data) => {
        navigate(routes.document({ documentId: data.createTemplateDocument.id }));
      },
    }
  );

  const { data, loading: documentLoading } = useQuery<GetDocumentQuery, GetDocumentQueryVariables>(
    GET_DOCUMENT_QUERY,
    {
      variables: {
        id: mutationData?.createTemplateDocument.id ?? '',
      },
      skip: !mutationData?.createTemplateDocument.id,
    }
  );

  const onSubmit = (input: CreateTemplateDocumentInput) => {
    createTemplateDoc({
      variables: {
        input,
      },
    });
  };

  return (
    <div className="flex flex-grow overflow-hidden">
      <MetaTags title="CreateDocument" description="CreateDocument page" />
      <div className="flex basis-5/12 flex-col">
        <TemplateForm
          templateType={templateType}
          onSubmit={onSubmit}
          mutationLoading={mutationLoading}
          mutationError={mutationError}
          document={data?.document}
          documentLoading={documentLoading}
          operation="create"
        />
      </div>
      <div className="flex basis-7/12 flex-col overflow-hidden bg-white">
        {data?.document ? (
          <DocumentEditor id={data.document.id} />
        ) : mutationLoading ? (
          <Moonwalk text="Crafting your document..." />
        ) : (
          <p className="mx-auto my-auto text-center text-3xl font-bold text-text-light">
            Your document will appear here
          </p>
        )}
      </div>
    </div>
  );
};

export default CreateDocumentPage;
