import { Reorder } from 'framer-motion';
import { FC } from 'react';
import { IconButton } from '../IconButton';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { classNames } from 'src/lib';
import { Controller } from 'react-hook-form';
import { z } from 'zod';

export type StructuredProps = {
  structuredItems: { key: string; visible: boolean }[];
  label: string;
  onChange: (value: { key: string; visible: boolean }[]) => void;
};

const structuredItemSchema = z.object({
  key: z.string(),
  visible: z
    .union([z.boolean(), z.literal('true'), z.literal('false')])
    .transform((value) => (typeof value === 'string' ? value === 'true' : value)),
});

const structuredItemsSchema = z.array(structuredItemSchema);

export const Structured: FC<StructuredProps> = ({ structuredItems, label, onChange }) => {
  const toggleVisibility = (key: string) => {
    const newValue = structuredItems.map((item) =>
      item.key === key ? { ...item, visible: !item.visible } : item
    );
    onChange(newValue);
  };

  const handleReorder = (newOrder: { key: string; visible: boolean }[]) => {
    onChange(newOrder);
  };

  return (
    <div className="flex flex-col gap-2">
      <div className={classNames('text-lg font-semibold text-text-dark')}>{label}</div>

      <Reorder.Group
        axis="y"
        values={structuredItems}
        onReorder={handleReorder}
        className="flex flex-col gap-2 rounded-lg bg-white "
      >
        {structuredItems?.map((item) => (
          <Reorder.Item
            key={item.key}
            value={item}
            className={`flex h-14 max-w-xs cursor-pointer justify-between rounded-xl border px-3 py-4`}
            whileDrag={{
              scale: 1.02,
              boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
              background: 'white',
              rotate: 2,
            }}
          >
            <div
              className={classNames(
                'flex-grow font-medium text-text-medium ',
                !item.visible && 'text-text-light'
              )}
            >
              {item.key}
            </div>
            <div className="flex-shrink-0">
              <IconButton
                size="small"
                className={`${!item.visible && 'text-text-light'} `}
                Icon={item.visible ? EyeIcon : EyeSlashIcon}
                tooltipText={item.visible === true ? 'Hide' : 'Show'}
                onClick={() => toggleVisibility(item.key)}
              />
            </div>
          </Reorder.Item>
        ))}
      </Reorder.Group>
    </div>
  );
};

export const StructuredField = ({ name, label }: { name: string; label: string }) => (
  <Controller
    name={name}
    render={({ field }) => {
      const transformedValue = structuredItemsSchema.parse(field.value ?? []);

      return (
        <Structured
          structuredItems={transformedValue}
          onChange={(newValue) => {
            field.onChange(newValue);
          }}
          label={label}
        />
      );
    }}
  />
);
