import { useQuery } from '@redwoodjs/web';
import {
  GetJobs,
  GetJobsVariables,
  GetCandidates,
  GetCandidatesVariables,
  GetChatSessionsQuery,
  GetChatSessionsQueryVariables,
  GetCompanies,
  GetCompaniesVariables,
} from '../../types/graphql';
import {
  GET_JOBS_QUERY,
  GET_CANDIDATES_QUERY,
  GET_CHAT_SESSIONS_QUERY,
  GET_COMPANIES_QUERY,
} from '../graphql/queries';

export function usePrimeCache() {
  useQuery<GetJobs, GetJobsVariables>(GET_JOBS_QUERY, {
    variables: {
      input: {
        searchTerm: '',
        connection: {
          page: 1,
          take: 10,
        },
        statuses: ['ACTIVE'],
        accessLevel: 'OWNER',
      },
    },
  });
  useQuery<GetCandidates, GetCandidatesVariables>(GET_CANDIDATES_QUERY, {
    variables: {
      input: {
        searchTerm: '',
        connection: {
          page: 1,
          take: 10,
        },
        statuses: ['ACTIVE'],
        accessLevel: 'OWNER',
      },
    },
  });
  useQuery<GetChatSessionsQuery, GetChatSessionsQueryVariables>(GET_CHAT_SESSIONS_QUERY);
  useQuery<GetCompanies, GetCompaniesVariables>(GET_COMPANIES_QUERY, {
    variables: {
      input: {
        searchTerm: '',
        connection: {
          page: 1,
          take: 10,
        },
        statuses: ['ACTIVE'],
        accessLevel: 'OWNER',
      },
    },
  });
}
