export const SUGGESTION_PROMPTS = [
  {
    title: '🤔  Get simple explanations of an industry term or role',
    description: `In plain English, please can you provide a clear, simple, and thorough explanation of what the following means: [TERM/ROLE] related to the [SPECIFY INDUSTRY] industry. Could you please provide:
- A clear and concise definition.
- An overview of key responsibilities or concepts associated with the term/role.
- Examples of how it is typically applied or manifests in real-work scenarios within the industry.
- Any relevant insights or trends associated with it that are currently impacting the industry.
I’m aiming to gain a clear and thorough understanding to enhance my knowledge when speaking with candidates and clients.`,
  },
  {
    title: '🪄  Create social media posts that engage your talent network',
    description: `Please can you provide me with 10 ideas for social media captions that offer practical tips and advice that would be valuable to prospective candidates in my network. My candidates are professionals in the [INDUSTRY] industry, ranging from [JOB TITLES/LEVEL OF EXPERIENCE]. Please make sure to:
- Speak directly to the audience.
- Use simple and clear language that’s inclusive and accessible to a diverse audience, regardless of background.
- Show personality and authenticity.
- Use a tone that resonates with my target audience.
- Avoid jargon.
- Follow best-practice.
Structure the captions so that they are easy and clear to follow.
Conclude with best-practice industry and career related hashtags to increase visibility.
Use emoji’s to add context and clarity - not to replace words or distract from the message.`,
  },
  {
    title: '🤝  Strengthen client relationship with these key tactics',
    description: `I specialise in the [SPECIFY INDUSTRY] industry, operating mainly in [SPECIFY LOCATION]. My target clients
are [SPECIFY TARGET CLIENTS]. I aim to offer more than just transactional recruitment services; I want to be seen as a trusted expert in my field and add longer-term value to my clients beyond specific placements. Could you provide a list of strategies and ideas on how I can achieve this? Please include clear and practical examples, such as specific content ideas or initiatives, that I can implement to demonstrate value to my clients and strengthen my position as an industry leader.`,
  },
  {
    title: '🥊 Handle Objections like a Pro',
    description: `I'm seeking expert advice and practical tips on effectively addressing the following objection: [INSERT SPECIFIC OBJECTION HERE, e.g., a candidate is considering accepting a counter-offer after my client made them an offer, a client is requesting to lower our recruitment fees, etc.]. Could you provide a step-by-step guide on how to navigate this objection, including:

Initial response strategies: How should I first respond to the objection to show understanding and maintain a positive rapport with them.
Persuasive arguments: What key points can I use to persuasively address the concerns behind the objection?
Follow-up actions: After the initial conversation, what steps should I take to ensure the objection is effectively resolved and the relationship is strengthened?
Prevention tips: Are there any strategies or practices I can implement to minimise the likelihood of this objection arising in the future?
I'm looking for insights that combine industry best practices with psychological tactics to turn objections into opportunities. The goal is to enhance my negotiation skills, improve candidate/client satisfaction, and ultimately, increase successful placements and retain business.`,
  },
];
