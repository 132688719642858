import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
dayjs.extend(relativeTime);
dayjs.extend(utc);

export function formatDate(date: Date, format = 'DD-MM-YYYY') {
  return dayjs(date).format(format);
}

export function formatDateTime(date: Date) {
  return dayjs(date).format('DD-MM-YYYY HH:mm');
}

export function formatRelativeDateTime(date: Date) {
  return dayjs(date).fromNow();
}

export function difference(date1: Date, date2: Date, unit: dayjs.OpUnitType = 'days') {
  return dayjs(date1).diff(dayjs(date2), unit);
}

/**
 * Serializes as a UTC Date
 */
export function serializeDate(date: Date) {
  return dayjs(date).utc().format('YYYY-MM-DD');
}
