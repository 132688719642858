export const GET_JOB_QUERY = gql`
  query GetJob($id: String!) {
    job(id: $id) {
      id
      campaignStatus
      title
      salary
      company {
        name
      }
    }
  }
`;
