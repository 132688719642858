import { useEffect, useState } from 'react';
import { useForm } from '@redwoodjs/forms';
import { navigate, routes } from '@redwoodjs/router';
import { MetaTags } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/toast';
import { useAuth } from 'src/auth';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from 'src/components';
import Logo from '../../assets/logo.svg';
import { Spinner } from '../../components/Spinner';
import { Button } from '../../components/Button';
import { passwordRegex } from '../../lib';
import { Form } from 'src/components/Form/Form';

const schema = z.object({
  password: z
    .string({ coerce: true })
    .min(1, { message: 'Password is required' })
    .regex(passwordRegex, {
      message:
        'Password must contain at least 8 characters, one uppercase, one lowercase and one number',
    }),
});

const ResetPasswordPage = ({
  resetToken,
  initial,
}: {
  resetToken: string;
  initial?: 'true';
  username: string;
}) => {
  const isInitialPassword = initial === 'true';

  const { isAuthenticated, reauthenticate, validateResetToken, resetPassword, loading } = useAuth();
  const formMethods = useForm({
    resolver: zodResolver(schema),
  });

  const [validateTokenLoading, setValidateTokenLoading] = useState(true);
  const [username, setUsername] = useState('');

  useEffect(() => {
    if (isAuthenticated) {
      if (isInitialPassword) {
        navigate(routes.newUserPersona());
        return;
      }
      navigate(routes.Home());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const validateToken = async () => {
      setValidateTokenLoading(true);
      const response = await validateResetToken(resetToken);
      if (response.error) {
        toast.error('Invalid reset link');
        navigate(routes.login());
      } else {
        setUsername(response.email);
      }
      setValidateTokenLoading(false);
    };
    validateToken();
  }, []);

  const onSubmit = async (data: Record<string, string>) => {
    const response = await resetPassword({
      resetToken,
      password: data.password,
    });

    if (response.error) {
      toast.error('Something went wrong, please try again');
    } else {
      toast.success('Password changed!');
      await reauthenticate();
      if (isInitialPassword) {
        navigate(routes.newUserPersona());
        return;
      }
      navigate(routes.Home());
    }
  };

  if (validateTokenLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex h-full w-full flex-grow flex-col items-center justify-center">
      <MetaTags title="Reset Password" />
      <Form
        onSubmit={onSubmit}
        className="flex w-full max-w-lg flex-col gap-y-3 rounded-3xl border border-text-light p-12"
        formMethods={formMethods}
      >
        <div className="w-48 self-center">
          <Logo />
        </div>
        {isInitialPassword ? (
          <>
            <h3 className="pt-4 text-xl text-text-dark">Welcome to AdScribe</h3>
            <p className="text-text-medium">
              Your account has been created with the username <b>{username}</b>.
            </p>
            <p className="pt-2 text-text-medium">Set a password to get started:</p>
          </>
        ) : (
          <h3 className="pt-3 text-2xl text-text-dark">Enter Your New Password</h3>
        )}
        <TextField
          disabled={loading}
          type="password"
          name="password"
          label="Password"
          schema={schema}
        />
        <div className="flex flex-col gap-y-3 pt-4">
          <Button loading={loading} type="submit" text="Submit" size="large" />
        </div>
      </Form>
    </div>
  );
};

export default ResetPasswordPage;
