import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { Label } from '../Label';
import { IconButton } from '../IconButton/IconButton';
import { FieldError } from '../FieldError';
import { ReadOnlyField } from '../ReadOnlyField';
import { useReadOnlyForm } from 'src/hooks';
import { textInputClasses } from '../TextField/TextField';
import { classNames } from 'src/lib';
import dayjs from 'dayjs';
import { Spinner } from 'src/components/Spinner';
import { formatDate } from 'src/lib/dateTime';

const DATE_FORMAT_UI = 'MM-yyyy';

export type DatePickerFieldProps = {
  name: string;
  label: string;
  minDate?: Date;
  maxDate?: Date;
  loading?: boolean;
  startDateName?: string;
  setPlaceholderPresentIfNull?: boolean;
  required?: boolean;
};

/**
 * A date picker. Dates are always saved as UTC. AKA if a user picks 09-2024, that is 09-2024 in UTC time.
 */
export const DatePickerField: FC<DatePickerFieldProps> = ({
  name,
  label,
  minDate,
  maxDate,
  loading,
  setPlaceholderPresentIfNull,
  required,
}) => {
  const { control, watch } = useFormContext();
  const { readOnly } = useReadOnlyForm();
  const watchedValue = watch(name);

  if (readOnly) {
    return <ReadOnlyField name={name} label={label} value={watchedValue} />;
  }

  const shouldSetPlaceholderToPresent = setPlaceholderPresentIfNull;
  const isPresent = setPlaceholderPresentIfNull && watchedValue === null;
  const isDisabled = loading;

  return (
    <div className="flex flex-col">
      {label && <Label name={name} label={label} required={required} />}
      <div className="pt-1">
        <div className="relative">
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                // User always selects local date (dayjs doesn't support UTC)
                selected={utcToLocalDate(value)}
                onChange={(date) => {
                  // User picks are always saved as UTC
                  onChange(localToUtcDate(date));
                }}
                dateFormat={DATE_FORMAT_UI}
                showMonthYearPicker
                wrapperClassName="flex w-full"
                showPopperArrow={false}
                disabled={isDisabled}
                className={classNames(
                  isPresent && 'placeholder:text-text-veryDark',
                  textInputClasses,
                  'pl-3'
                )}
                calendarClassName={`z-10 mt-1 rounded-lg bg-white shadow-lg p-4 w-64 h-72 custom-calendar`}
                minDate={localToUtcDate(minDate)}
                maxDate={localToUtcDate(maxDate)}
                renderCustomHeader={({ date, decreaseYear, increaseYear }) => (
                  <div>
                    <div className="flex items-center justify-between px-2">
                      <IconButton
                        Icon={ChevronLeftIcon}
                        onClick={decreaseYear}
                        tooltipText="Previous Year"
                        size="small"
                        className="text-text-medium hover:text-text-dark focus:outline-none"
                      />
                      <span className="text-lg font-medium text-text-veryDark">
                        {formatDate(date, 'YYYY')}
                      </span>
                      <IconButton
                        Icon={ChevronRightIcon}
                        onClick={increaseYear}
                        tooltipText="Next Year"
                        size="small"
                        className="hover:text-veryDark text-text-medium focus:outline-none"
                      />
                    </div>
                  </div>
                )}
                placeholderText={shouldSetPlaceholderToPresent ? 'Present' : 'mm-yyyy'}
              />
            )}
          />
          {loading && <Spinner className="absolute right-2 top-2 h-6 w-6" />}
        </div>
        <FieldError name={name} />
      </div>
    </div>
  );
};

/**
 * DayJS Date selection is in local time by default.
 * When a user selects a date, they do not consider timezone - e.g if I graduated in Sep 2023, I don't care about the timezone.
 * We want to save these dates as UTC, even if they've selected a local date (without doing a timezone conversion, just translation)
 */
function localToUtcDate(date?: Date | null) {
  if (!date) {
    return null;
  }

  const localStringDate = dayjs(date).format('YYYY-MM-DD');
  const utcDate = dayjs(localStringDate, { utc: true, format: 'YYYY-MM-DD' }).toDate();
  return utcDate;
}

function utcToLocalDate(date?: Date | null) {
  if (!date) {
    return null;
  }

  const utcStringDate = dayjs(date, { utc: true }).format('YYYY-MM-DD');
  return dayjs(utcStringDate).toDate();
}
