import { useEffect, useState } from 'react';

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(debouncedValue)) {
      const timer = setTimeout(() => setDebouncedValue(value), delay || 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [value, delay]);

  return debouncedValue;
}
