import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';

type FadeInProps = {
  visible?: boolean;
  children: ReactNode;
  className?: string;
  hasExitAnimation?: boolean;
  delay?: number;
};

export const FadeIn = ({
  visible,
  children,
  className,
  hasExitAnimation = true,
  delay,
}: FadeInProps) => {
  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={hasExitAnimation ? { opacity: 0 } : undefined}
          transition={{ duration: 0.4, delay: delay ?? 0 }}
          className={className}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
