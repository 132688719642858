import Lottie from 'lottie-react';
import { FC } from 'react';
import moonWalkAnimation from './moonwalk.json';

/**
 * Moonwalk is a loading animation that is used when a user is creating a campaign.
 * showEmailInfo is used to let a user know they'll receive an email if loading is taking a while
 */
export const Moonwalk: FC<{ text?: string; showEmailInfo?: boolean }> = ({
  text,
  showEmailInfo,
}) => {
  return (
    <div className="flex h-full w-full items-center justify-center text-center">
      <div className="flex flex-col items-center">
        <Lottie className="h-[450px] w-[450px]" animationData={moonWalkAnimation} loop={true} />
        <p className="pb-4 text-lg text-text-dark">{text ?? 'Crafting your Campaign...'}</p>
        {showEmailInfo && (
          <p className="pb-4 pt-2 text-lg text-text-dark">
            We{"'"}ll also send you an email when it{"'"}s ready.
          </p>
        )}
      </div>
    </div>
  );
};
