import { FC } from 'react';
import { classNames } from '../../lib';
import { track } from '../../services';
import { Tooltip } from '../Tooltip';
import type { TooltipPosition } from '../Tooltip/Tooltip';

export const iconButtonColors: Record<Required<IconButtonProps>['variant'], string> = {
  primary: 'text-text-dark',
  generate: 'text-generate-medium',
};

type IconButtonProps = {
  Icon: FC<React.ComponentProps<'svg'>>;
  onClick?: () => void;
  tooltipText: string;
  tooltipMinWidth?: string;
  tooltipPosition?: TooltipPosition;
  size?: 'medium' | 'large' | 'small' | 'xs';
  styles?: string;
  type?: 'submit' | 'button';
  disabled?: boolean;
  tabIndex?: number;
  className?: string;
  variant?: 'primary' | 'generate';
};

export const IconButton: FC<IconButtonProps> = ({
  Icon,
  onClick,
  size = 'medium',
  tooltipText,
  tooltipMinWidth,
  tooltipPosition = 'top',
  styles,
  type = 'button',
  disabled,
  tabIndex,
  className,
  variant = 'primary',
}) => {
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    track('Button Click', { buttonName: tooltipText });
    onClick && onClick();
  };

  return (
    <Tooltip innerBody={tooltipText} position={tooltipPosition} minWidth={tooltipMinWidth}>
      <button
        tabIndex={tabIndex}
        type={type}
        className={classNames(
          iconButtonDimensions[size],
          'flex items-center justify-center rounded-lg',
          styles,
          disabled && 'cursor-default opacity-50',
          !disabled && 'hover:bg-gray-100',
          iconButtonColors[variant] || iconButtonColors.primary
        )}
        onClick={onClick ? handleClick : undefined}
        disabled={disabled}
      >
        <Icon
          className={classNames(
            size === 'small' ? 'h-5 w-5' : size === 'xs' ? 'h-4 w-4' : 'h-6 w-6',
            className
          )}
          strokeWidth={size === 'xs' ? 1 : 2}
        />
      </button>
    </Tooltip>
  );
};

const iconButtonDimensions: Record<Exclude<IconButtonProps['size'], undefined>, string> = {
  large: 'p-2',
  medium: 'p-1',
  small: 'p-1',
  xs: 'p-1',
};
