import { FC, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import SwiperCore from 'swiper';

type CarouselProps<T extends { id: string }> = {
  items: T[];
  ItemComponent: FC<{ item: T; index: number; onClickItem: (ix: number) => void }>;
  onSelect: (ix: number) => void;
};

export const Carousel = <T extends { id: string }>({
  items,
  ItemComponent,
  onSelect,
}: CarouselProps<T>) => {
  const swiperRef = useRef<SwiperCore | null>(null);

  const onSlideChange = (swiper: SwiperCore) => {
    const activeIndex = swiper.activeIndex;
    onSelect(activeIndex);
  };

  const onClickItem = (index: number) => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(index, 300);
    }
    onSelect && onSelect(index);
  };

  return (
    <div className="relative mx-auto max-w-3xl">
      <Swiper
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        slidesPerView={3}
        initialSlide={1}
        centeredSlides={true}
        spaceBetween={30}
        navigation={{
          prevEl: '.swiper-button-prev-custom',
          nextEl: '.swiper-button-next-custom',
        }}
        onSlideChange={onSlideChange}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {items.map((item, index) => (
          <SwiperSlide key={item.id}>
            <ItemComponent index={index} item={item} onClickItem={onClickItem} />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="swiper-button-prev-custom absolute left-[-40px] top-1/2 z-10 -translate-y-1/2 transform cursor-pointer">
        <ChevronLeftIcon className="h-6 w-6 text-generate-medium" />
      </div>

      <div className="swiper-button-next-custom absolute right-[-40px] top-1/2 z-10 -translate-y-1/2 transform cursor-pointer">
        <ChevronRightIcon className="h-6 w-6 text-generate-medium" />
      </div>
    </div>
  );
};
