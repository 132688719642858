import { DOCUMENT_FIELDS_FRAGMENT } from '../fragments';

// TODO: FIX FRAGMENT INTERPOLATION https://github.com/redwoodjs/redwood/issues/7491
export const GET_DOCUMENTS_QUERY = gql`
  ${DOCUMENT_FIELDS_FRAGMENT}
  query GetDocuments($input: DocumentsInput!) {
    documents(input: $input) {
      nodes {
        ...DocumentFields
      }
      pageInfo {
        count
        isLast
        page
      }
    }
  }
`;
