import { useLazyQuery } from '@apollo/client';
import { useState } from 'react';
import { GET_FILE_UPLOAD_DESTINATION_QUERY } from 'src/graphql/queries';
import { uploadFileToAzure } from 'src/services';

/**
 * Custom hook for handling the process of uploading files to Azure Blob Storage.
 * It fetches the file upload destination (a pre-signed URL) and manages the upload status.
 *
 * @returns {object} An object containing functions and state related to the file upload process.
 */
export function useFileUpload() {
  const [getFileUploadDestination, { loading: getSasLoading, error }] = useLazyQuery(
    GET_FILE_UPLOAD_DESTINATION_QUERY,
    { fetchPolicy: 'network-only' }
  );

  const [uploadLoading, setUploadLoading] = useState(false);

  /**
   * Function to upload a file using the fetched pre-signed URL.
   *
   * @param {File} file - The file object to be uploaded.
   * @param {string} fileRecord - The pre-signed URL obtained from the backend.
   * @returns {Promise<object>} A promise that resolves with the URL and blob name of the uploaded file.
   */
  const uploadFile = async (file: File, fileRecord: string) => {
    setUploadLoading(true);
    try {
      const { url, blobName } = await uploadFileToAzure(fileRecord, file);
      return {
        url,
        blobName,
      };
    } catch (error) {
      console.error('Upload failed:', error);
      throw error;
    } finally {
      setUploadLoading(false);
    }
  };

  return {
    getFileUploadDestination, // Function to trigger the fetching of the upload URL.
    uploadFile, // Function to perform the upload operation.
    getSasLoading, // Indicates whether the URL fetching is in progress.
    uploadLoading, // Indicates whether the file upload is in progress.
    error, // Contains error information, if any during the fetch operation.
  };
}
