import { useQuery } from '@redwoodjs/web';
import { FC } from 'react';
import {
  GET_BRANDED_DOCUMENT_CONFIG_QUERY,
  GET_BRAND_ASSETS_QUERY,
  GET_CANDIDATE_AND_DOCUMENTS_QUERY,
} from 'src/graphql/queries';
import {
  Candidate,
  GetBrandAssets,
  GetBrandAssetsVariables,
  GetBrandedDocumentConfig,
  GetBrandedDocumentConfigVariables,
  GetCandidateAndDocuments,
  GetCandidateAndDocumentsVariables,
} from 'types/graphql';
import { EntityExportDialog } from './EntityExportDialog';
import { ExportSubmitParams } from './types';
import { handleExport } from './lib/handleExport';
import { DEFAULT_BRANDING_CONFIG, DEFAULT_BRAND_ASSETS } from '../PdfDocument/pdf';

type Props = {
  id: string;
};

export const CandidateExportDialog: FC<Props> = ({ id }) => {
  const { data } = useQuery<GetCandidateAndDocuments, GetCandidateAndDocumentsVariables>(
    GET_CANDIDATE_AND_DOCUMENTS_QUERY,
    {
      variables: {
        candidateId: id,
      },
    }
  );

  const { data: configData } = useQuery<
    GetBrandedDocumentConfig,
    GetBrandedDocumentConfigVariables
  >(GET_BRANDED_DOCUMENT_CONFIG_QUERY);

  const { data: brandAssetsData } = useQuery<GetBrandAssets, GetBrandAssetsVariables>(
    GET_BRAND_ASSETS_QUERY
  );

  const candidate = data?.candidate as Candidate;

  const handleSubmit = async ({
    exportFormat,
    includeDocuments,
    onComplete,
  }: ExportSubmitParams) => {
    if (!candidate) return;

    try {
      await handleExport(
        candidate,
        'Candidate',
        exportFormat,
        includeDocuments,
        configData?.getBrandedDocumentConfig ?? DEFAULT_BRANDING_CONFIG,
        brandAssetsData?.getBrandAssets ?? DEFAULT_BRAND_ASSETS
      );
    } catch (error) {
      console.error(error);
    } finally {
      onComplete();
    }
  };

  return <EntityExportDialog entityName="Candidate" onSubmit={handleSubmit} />;
};
