import { classNames } from '../../lib';
import { usePageClasses, usePrimeCache } from '../../hooks';
import { ReloadErrorBoundary } from '../../components';
import { useQuery } from '@redwoodjs/web';
import { GetStatus } from 'types/graphql';
import { GET_STATUS_QUERY } from '../../graphql/queries';
import { useAuth } from '../../auth';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { Banner } from 'src/components/Banner';

type HeaderLayoutProps = {
  children?: React.ReactNode;
};

export const HeaderLayout = ({ children }: HeaderLayoutProps) => {
  const { pageClasses } = usePageClasses();
  const { currentUser } = useAuth();
  usePrimeCache();
  const { data } = useQuery<GetStatus>(GET_STATUS_QUERY, {
    pollInterval: 60000,
    skip: !currentUser,
    keepPreviousData: true,
  });

  const statusIncident = data?.statusIncident;
  return (
    <ReloadErrorBoundary>
      <div className={classNames('flex h-full w-full', pageClasses)}>
        <Sidebar />
        <div className="flex flex-grow flex-col overflow-auto">
          {!!statusIncident && (
            <Banner>
              <p>{statusIncident.message}</p>
            </Banner>
          )}
          {children}
        </div>
      </div>
    </ReloadErrorBoundary>
  );
};
