import { PERMISSION_FIELDS_FRAGMENT } from '../fragments';

export const REVOKE_ACCESS_JOB_MUTATION = gql`
  ${PERMISSION_FIELDS_FRAGMENT}
  mutation RevokeAccessJobMutation($input: RevokeAccess!) {
    revokeAccessJob(input: $input) {
      id
      permissions {
        ...PermissionFields
      }
      myPermission
    }
  }
`;

export const REVOKE_ACCESS_CANDIDATE_MUTATION = gql`
  ${PERMISSION_FIELDS_FRAGMENT}
  mutation RevokeAccessCandidateMutation($input: RevokeAccess!) {
    revokeAccessCandidate(input: $input) {
      id
      permissions {
        ...PermissionFields
      }
      myPermission
    }
  }
`;

export const REVOKE_ACCESS_COMPANY_MUTATION = gql`
  ${PERMISSION_FIELDS_FRAGMENT}
  mutation RevokeAccessCompanyMutation($input: RevokeAccess!) {
    revokeAccessCompany(input: $input) {
      id
      permissions {
        ...PermissionFields
      }
      myPermission
    }
  }
`;

export const REVOKE_ACCESS_DOCUMENT_MUTATION = gql`
  ${PERMISSION_FIELDS_FRAGMENT}
  mutation RevokeAccessDocumentMutation($input: RevokeAccess!) {
    revokeAccessDocument(input: $input) {
      ... on DocumentBase {
        id
        permissions {
          ...PermissionFields
        }
        myPermission
      }
      ... on General {
        id
        permissions {
          ...PermissionFields
        }
        myPermission
      }
      ... on JobAdvert {
        id
        permissions {
          ...PermissionFields
        }
        myPermission
      }
      ... on JobSnapshot {
        id
        permissions {
          ...PermissionFields
        }
        myPermission
      }
      ... on JobInterviewQuestions {
        id
        permissions {
          ...PermissionFields
        }
        myPermission
      }
      ... on JobBooleanSearch {
        id
        permissions {
          ...PermissionFields
        }
        myPermission
      }
      ... on JobReferenceCheck {
        id
        permissions {
          ...PermissionFields
        }
        myPermission
      }
      ... on JobInterviewPreparation {
        id
        permissions {
          ...PermissionFields
        }
        myPermission
      }
      ... on JobSocialPost {
        id
        permissions {
          ...PermissionFields
        }
        myPermission
      }
      ... on JobInMail {
        id
        permissions {
          ...PermissionFields
        }
        myPermission
      }
      ... on JobEmail {
        id
        permissions {
          ...PermissionFields
        }
        myPermission
      }
      ... on CandidateSnapshot {
        id
        permissions {
          ...PermissionFields
        }
        myPermission
      }
      ... on CandidateIntroduction {
        id
        permissions {
          ...PermissionFields
        }
        myPermission
      }
      ... on CandidateCv {
        id
        permissions {
          ...PermissionFields
        }
        myPermission
      }
      ... on CompanySnapshot {
        id
        permissions {
          ...PermissionFields
        }
        myPermission
      }
      ... on CompanyIntroduction {
        id
        permissions {
          ...PermissionFields
        }
        myPermission
      }
      ... on ClientProposal {
        id
        permissions {
          ...PermissionFields
        }
        myPermission
      }
      ... on BlogPost {
        id
        permissions {
          ...PermissionFields
        }
        myPermission
      }
      ... on BlogPostIdeas {
        id
        permissions {
          ...PermissionFields
        }
        myPermission
      }
      ... on TestimonialRequest {
        id
        permissions {
          ...PermissionFields
        }
        myPermission
      }
      ... on MarketingStrategy {
        id
        permissions {
          ...PermissionFields
        }
        myPermission
      }

      ... on ClientOutreach {
        id
        permissions {
          ...PermissionFields
        }
        myPermission
      }
    }
  }
`;
