import { MetaTags, useQuery } from '@redwoodjs/web';
import { FC, useState } from 'react';
import { NetworkStatus } from '@apollo/client';
import {
  Checkbox,
  CreateDialog,
  CreateTemplateDialog,
  ReloadErrorBoundary,
  SearchInput,
} from '../../components';
import {
  useDebounce,
  useDialog,
  usePageClasses,
  useQueryParamSyncedState,
  useTrackPageView,
} from '../../hooks';
import { GetDocuments, GetDocumentsVariables } from 'types/graphql';
import { Spinner } from '../../components/Spinner';
import { PageTitle } from '../../components/PageTitle';
import { FolderIcon, PlusIcon } from '@heroicons/react/24/outline';
import { GET_DOCUMENTS_QUERY } from '../../graphql/queries';
import { Button } from '../../components/Button';
import { DocumentsTable } from './DocumentsTable';
import { useLocation } from '@redwoodjs/router';

const DocsPage = () => {
  usePageClasses('bg-white');
  useTrackPageView();
  const { show, close } = useDialog();

  const { search } = useLocation();

  const { value: page, setValue: setPage } = useQueryParamSyncedState('page', 1);
  const [searchTerm, setSearchTerm] = useState<string | null>('');
  const { value: mineOnly, setValue: setMineOnly } = useQueryParamSyncedState('mineOnly', true);

  const debouncedFilterValues = useDebounce({ mineOnly, searchTerm });

  const {
    data: result,
    previousData,
    networkStatus,
    error,
  } = useQuery<GetDocuments, GetDocumentsVariables>(GET_DOCUMENTS_QUERY, {
    variables: {
      input: {
        includeDocumentTypes: [
          'ClientProposal',
          'General',
          'BlogPost',
          'BlogPostIdeas',
          'TestimonialRequest',
          'MarketingStrategy',
        ],
        isActive: true,
        accessLevel: debouncedFilterValues.mineOnly ? 'OWNER' : 'READ',
        connection: {
          page,
          take: 10,
        },
        searchTerm: debouncedFilterValues.searchTerm,
      },
    },
  });

  const handleSearchInputChange = (newValue: string | null) => {
    setSearchTerm(newValue);
  };

  const handleMineOnlyChange = (newValue: boolean) => {
    setMineOnly(newValue);
  };

  const handleSetPage = (newPage: number) => {
    setPage(newPage);
  };

  const loading = networkStatus === NetworkStatus.loading && !result;
  const paginationPending = networkStatus === NetworkStatus.setVariables && !result;

  if (error) {
    throw error;
  }

  const data = result ?? previousData;
  const documents = data?.documents?.nodes ?? [];

  const hasSetFilterValues = debouncedFilterValues.searchTerm !== '';

  const noResults = hasSetFilterValues && data && data?.documents?.nodes.length === 0;
  const isEmpty = !hasSetFilterValues && data && data?.documents?.nodes.length === 0;

  return (
    <ReloadErrorBoundary>
      <MetaTags title="Docs" description="Docs" />
      <div className="flex h-full w-full flex-grow flex-col items-center">
        <div className="flex w-full max-w-6xl flex-shrink-0 flex-grow flex-col">
          <div className="flex  flex-row justify-between  pb-4 pt-12">
            <PageTitle Icon={FolderIcon} text="Docs" size="lg" />
            <Button
              text="New Template Doc"
              LeftIcon={PlusIcon}
              onClick={() => show(<CreateTemplateDialog onClose={close} />)}
            />
          </div>
          <div className="flex max-w-[600px] gap-x-4 py-4">
            <SearchInput
              placeholder="Search CoScribe Docs"
              value={searchTerm ?? ''}
              onChange={handleSearchInputChange}
            />
            <div className="w-[200px] pt-3">
              <Checkbox onChange={handleMineOnlyChange} value={mineOnly} label="Mine only" />
            </div>
          </div>

          {loading ? (
            <Spinner fillContainer />
          ) : isEmpty ? (
            <Empty />
          ) : (
            <>
              {noResults ? (
                <NoResults />
              ) : (
                <DocumentsTable
                  paginationLoading={paginationPending}
                  onSetPage={handleSetPage}
                  pageInfo={data?.documents?.pageInfo}
                  pageLimit={10}
                  documents={documents}
                />
              )}
            </>
          )}
        </div>
      </div>
    </ReloadErrorBoundary>
  );
};

export const Empty: FC = () => {
  const { show, close } = useDialog();
  return (
    <div className="flex flex-grow flex-col">
      <div className="flex flex-grow flex-col items-center justify-center gap-y-2">
        <p className="font-medium text-text-medium">You haven&apos;t created any documents yet.</p>
        <p className="text-text-medium">Click the button below to create with AdScribe.</p>
        <div className="pb-6 pt-4">
          <Button
            onClick={() => show(<CreateDialog onClose={close} />)}
            variant="outline"
            size="medium"
            LeftIcon={PlusIcon}
            text="Create with AdScribe"
          />
        </div>
      </div>
    </div>
  );
};

export const NoResults: FC = () => {
  const { show, close } = useDialog();
  return (
    <div className="flex flex-grow flex-col">
      <div className="flex flex-grow flex-col items-center justify-center gap-y-2">
        <p className="font-medium text-text-medium">
          No Documents found matching the search criteria above.
        </p>
        <p className="text-text-medium">Click the button below to create with AdScribe.</p>
        <div className="pb-6 pt-4">
          <Button
            onClick={() => show(<CreateDialog onClose={close} />)}
            variant="outline"
            size="medium"
            LeftIcon={PlusIcon}
            text="Create with AdScribe"
          />
        </div>
      </div>
    </div>
  );
};

export default DocsPage;
