import { DOCUMENT_FIELDS_FRAGMENT } from '../fragments';

export const UPDATE_STRUCTURED_DOCUMENT_MUTATION = gql`
  ${DOCUMENT_FIELDS_FRAGMENT}
  mutation UpdateStructuredDocument($id: String!, $input: UpdateStructuredDocumentMarkupInput!) {
    updateStructuredDocument(input: { id: $id, input: $input }) {
      ...DocumentFields
    }
  }
`;
