export const GET_JOBS_QUERY = gql`
  query GetJobs($input: JobsInput!) {
    jobs(input: $input) {
      nodes {
        id
        status
        campaignStatus
        title
        salary
        contractType
        mustHaves
        roleAndResponsibilities
        company {
          id
          name
          location
        }
        owner {
          id
          name
          email
        }
        myPermission
        createdAt
        updatedAt
      }

      pageInfo {
        count
        isLast
        page
      }
    }
  }
`;
