import { useLocation, useParams } from '@redwoodjs/router';
import { useEffect } from 'react';
import { track } from '../services';

export function useTrackPageView() {
  const { pathname, search, hash } = useLocation();
  const params = useParams();
  /**
   * Use href to track page view on every route change
   */
  const href = window.location.href;

  useEffect(() => {
    track('Page View', {
      page: pathname,
      search,
      hash,
      params,
    });
  }, [href]);
}
