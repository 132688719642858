export const SET_DEFAULT_USER_PERMISSION_MUTATION = gql`
  mutation SetDefaultUserPermissionMutation($input: SetDefaultUserPermissionInput!) {
    setDefaultUserPermission(input: $input) {
      id
      defaultJobPermissions
      defaultCompanyPermissions
      defaultCandidatePermissions
    }
  }
`;
