import { EditorContent, useEditor, EditorEvents, Editor } from '@tiptap/react';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import StarterKit from '@tiptap/starter-kit';
import React, { useEffect, useImperativeHandle } from 'react';
import { classNames } from '../../lib';
import { RichTextMenuBar } from '../RichTextMenuBar';
import { ACCESS_LEVEL } from 'types/graphql';

const textBodyWidth = 'max-w-[70ch] min-w-[40ch]';

type Props = {
  // Initial content
  content?: string;
  editable?: boolean;
  /**
   * Optional top bar to add buttons etc
   */
  topBar?: React.ReactNode | null;
  autofocus?: boolean;
  placeholder?: string;
  extraButtons?: React.ReactNode;
  /**
   * Use this to trigger a re-render when switching selected content. This is not a controlled component.
   */
  contentId?: string;
  onChange?: (e: EditorEvents['update']) => void;
  disableMinWidth?: boolean;
  disableMaxWidth?: boolean;
  hideMenuBar?: boolean;
  onHandleSnippetClick?: (snippet: string) => void;
  myPermission?: ACCESS_LEVEL | null;
};

export type RichTextEditorRef = {
  setContent: (content: string) => void;
  getContent: () => string;
  getEditor: () => Editor | null;
};

/**
 * THIS IS NOT A CONTROLLED COMPONENT BY DEFAULT
 * TO UPDATE THE CONTENT OF THE EDITOR, USE THE REF e.g `ref.current.setContent(markup)`
 *
 * IN READONLY MODE: THIS CONVERTS TO A CONTROLLED COMPONENT
 */

export const RichTextEditor = React.forwardRef<RichTextEditorRef, Props>(
  (
    {
      onChange,
      content,
      topBar = null,
      editable = true,
      autofocus = true,
      extraButtons,
      placeholder,
      disableMinWidth,
      hideMenuBar,
      onHandleSnippetClick,
      myPermission,
    },
    ref
  ) => {
    const editor = useEditor({
      editable,
      content: content ?? '',
      extensions: [
        StarterKit.configure({
          heading: { levels: [1, 2] },
        }),
        Placeholder.configure({
          placeholder: placeholder ?? '',
          showOnlyWhenEditable: false,
        }),
        Link.configure({
          openOnClick: true,
          HTMLAttributes: {
            target: '_blank',
            rel: 'noopener noreferrer',
            class: 'font-medium underline underline-offset-4 cursor-pointer',
          },
          protocols: ['https'],
          linkOnPaste: true,
          autolink: true,
        }),
      ],
      autofocus,
      onUpdate: onChange,
      editorProps: {
        attributes: {
          class: 'prose focus:outline-none flex-grow',
        },
      },
    });

    /**
     * Expose a method to set the content of the editor as the editor is not a controlled component
     */
    useImperativeHandle<RichTextEditorRef, RichTextEditorRef>(
      ref,
      () => ({
        setContent: (content: string) => {
          editor?.commands?.setContent(content);
        },
        getContent: () => {
          return editor?.getHTML() ?? '';
        },
        getEditor: () => {
          return editor;
        },
      }),
      [editor]
    );

    /**
     * If ReadOnly mode, make this a controlled component.
     */
    useEffect(() => {
      if (content && !editable) {
        editor?.commands?.setContent(content);
      }
    }, [content, editable, editor]);

    useEffect(() => {
      if (!editor) {
        return;
      }

      editor?.setEditable?.(editable);
      // Do not include editor on dependency array
    }, [editable]);

    if (!editor) {
      return null;
    }

    return (
      <div className="flex min-h-0 w-full flex-1 flex-col items-center">
        <div className="flex min-h-0 w-full flex-1 flex-col items-center overflow-auto ">
          <EditorContent
            spellCheck={true}
            className={classNames('mx-32 flex flex-1 flex-col pb-8 pt-6', textBodyWidth)}
            placeholder={placeholder}
            editor={editor}
          />
        </div>
        <div className={classNames('mx-32 flex w-full', textBodyWidth)}>
          {!hideMenuBar && editor && (
            <RichTextMenuBar
              editor={editor}
              extraButtons={extraButtons}
              editable={editable}
              hasBorderTop
              hasSnippetButton
              onHandleSnippetClick={onHandleSnippetClick}
              myPermission={myPermission}
            />
          )}
        </div>
      </div>
    );
  }
);

// Eslint complains if you don't set display name
RichTextEditor.displayName = 'RichTextEditor';
