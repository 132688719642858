import { gql } from '@apollo/client';
import { DOCUMENT_FIELDS_FRAGMENT, PERMISSION_FIELDS_FRAGMENT } from '../fragments';

export const GET_COMPANY_AND_DOCUMENTS_QUERY = gql`
  ${DOCUMENT_FIELDS_FRAGMENT}
  ${PERMISSION_FIELDS_FRAGMENT}

  query GetCompanyAndDocuments($companyId: String!) {
    company(id: $companyId) {
      id
      status
      campaignStatus
      name
      location
      overview
      culture
      benefits
      website
      notes
      createdAt
      updatedAt
      documents {
        ...DocumentFields
      }
      permissions {
        ...PermissionFields
      }
      myPermission
    }
  }
`;
