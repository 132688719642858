import { FC, PropsWithChildren } from 'react';
import { classNames } from '../../lib';

export type Props = {
  size: 'small' | 'medium' | 'large';
  onClick?: () => void;
  LeftIcon?: React.FC<React.ComponentProps<'svg'>>;
  RightIcon?: React.FC<React.ComponentProps<'svg'>>;
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
};

export const Link: FC<PropsWithChildren<Props>> = ({
  children,
  size,
  LeftIcon,
  RightIcon,
  disabled,
  onClick,
  className,
  iconClassName,
}) => {
  const handleClick = () => {
    if (disabled) return;
    if (onClick) {
      onClick();
    }
  };

  return (
    <span
      onClick={handleClick}
      className={classNames(
        'font-semibold hover:cursor-pointer hover:underline',
        getTextClasses(size),
        (LeftIcon || RightIcon) && 'flex items-center space-x-1',
        disabled ? 'text-text-medium' : 'text-text-veryDark',
        disabled && 'hover:cursor-not-allowed hover:no-underline'
      )}
    >
      {LeftIcon && (
        <LeftIcon strokeWidth={2} className={classNames(getIconClasses(size), iconClassName)} />
      )}
      <a className={className}>{children}</a>
      {RightIcon && (
        <RightIcon strokeWidth={2} className={classNames(getIconClasses(size), iconClassName)} />
      )}
    </span>
  );
};

function getTextClasses(size: Props['size']) {
  switch (size) {
    case 'small':
      return 'text-xs';
    case 'medium':
      return 'text-sm';
    case 'large':
      return 'text-base';
  }
}

function getIconClasses(size: Props['size']) {
  switch (size) {
    case 'small':
      return 'h-3 w-3';
    case 'medium':
      return 'h-4 w-4';
    case 'large':
      return 'h-5 w-5';
  }
}
