import { DocumentBrandingConfig } from 'types/graphql';
import { Styles } from '@react-pdf/renderer';

export enum PDF_NODE_TYPE {
  // To use when we just want to render children
  FRAGMENT = 'fragment',
  PARAGRAPH = 'paragraph',
  TABLE = 'table',
  TABLE_BODY = 'tableBody',
  TABLE_CELL = 'tableCell',
  TABLE_HEADER = 'tableHeader',
  TABLE_ROW = 'tableRow',
  LIST_ITEM = 'listItem',
  UNORDERED_LIST = 'unorderedList',
  ORDERED_LIST = 'orderedList',
  // Marks
  LINK = 'link',
  BOLD = 'bold',
  ITALIC = 'italic',
  BLOCKQUOTE = 'blockquote',
  STRIKE = 'strike',
  HARD_BREAK = 'hardBreak',
  CODE = 'code',
  TEXT = 'text',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
}

export type PdfNode =
  | ParagraphNode
  | TableNode
  | TableBodyNode
  | TableCellNode
  | TableHeaderNode
  | TableRowNode
  | ListItemNode
  | UnorderedListNode
  | OrderedListNode
  | LinkNode
  | BoldNode
  | ItalicNode
  | BlockquoteNode
  | StrikeNode
  | HardBreakNode
  | CodeNode
  | TextNode
  | H1Node
  | H2Node
  | H3Node
  | H4Node
  | H5Node
  | H6Node
  | FragmentNode;

export type PdfNodeChildren = string | null | PdfNode[];

export type PdfNodeBase = {
  type: PDF_NODE_TYPE;
  context: {
    index?: number;
    brandedDocumentConfig?: DocumentBrandingConfig;
    [key: string]: unknown;
  };
  children: PdfNodeChildren;
  style?: Styles;
};

export type TextNode = PdfNodeBase & {
  type: PDF_NODE_TYPE.TEXT;
};

export type ParagraphNode = PdfNodeBase & {
  type: PDF_NODE_TYPE.PARAGRAPH;
};

export type TableNode = PdfNodeBase & {
  type: PDF_NODE_TYPE.TABLE;
};

export type TableBodyNode = PdfNodeBase & {
  type: PDF_NODE_TYPE.TABLE_BODY;
};

export type TableCellNode = PdfNodeBase & {
  type: PDF_NODE_TYPE.TABLE_CELL;
};

export type TableHeaderNode = PdfNodeBase & {
  type: PDF_NODE_TYPE.TABLE_HEADER;
};

export type TableRowNode = PdfNodeBase & {
  type: PDF_NODE_TYPE.TABLE_ROW;
};

export type ListItemNode = PdfNodeBase & {
  type: PDF_NODE_TYPE.LIST_ITEM;
};

export type UnorderedListNode = PdfNodeBase & {
  type: PDF_NODE_TYPE.UNORDERED_LIST;
};

export type OrderedListNode = PdfNodeBase & {
  type: PDF_NODE_TYPE.ORDERED_LIST;
};

// Marks
export type LinkNode = PdfNodeBase & {
  type: PDF_NODE_TYPE.LINK;
  attrs: {
    href: string;
  };
};

export type BoldNode = PdfNodeBase & {
  type: PDF_NODE_TYPE.BOLD;
};

export type ItalicNode = PdfNodeBase & {
  type: PDF_NODE_TYPE.ITALIC;
};

export type BlockquoteNode = PdfNodeBase & {
  type: PDF_NODE_TYPE.BLOCKQUOTE;
};

export type StrikeNode = PdfNodeBase & {
  type: PDF_NODE_TYPE.STRIKE;
};

export type HardBreakNode = PdfNodeBase & {
  type: PDF_NODE_TYPE.HARD_BREAK;
};

export type CodeNode = PdfNodeBase & {
  type: PDF_NODE_TYPE.CODE;
};

export type H1Node = PdfNodeBase & {
  type: PDF_NODE_TYPE.H1;
};

export type H2Node = PdfNodeBase & {
  type: PDF_NODE_TYPE.H2;
};

export type H3Node = PdfNodeBase & {
  type: PDF_NODE_TYPE.H3;
};

export type H4Node = PdfNodeBase & {
  type: PDF_NODE_TYPE.H4;
};

export type H5Node = PdfNodeBase & {
  type: PDF_NODE_TYPE.H5;
};

export type H6Node = PdfNodeBase & {
  type: PDF_NODE_TYPE.H6;
};

export type FragmentNode = PdfNodeBase & {
  type: PDF_NODE_TYPE.FRAGMENT;
};
