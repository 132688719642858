import { FC } from 'react';
import { Document } from '../../../types/graphql';
import { classNames } from '../../lib';
import { documentPresentationalProperties } from '../../lib/document';

type Props = {
  onClick: () => void;
  selected?: boolean;
  type: NonNullable<Document['__typename']>;
  display?: 'card' | 'list';
};

export const DocumentListItem: FC<Props> = ({ onClick, selected, type, display = 'card' }) => {
  const handleClick = () => {
    if (!selected) {
      onClick();
    }
  };

  const { Icon, title, color } = documentPresentationalProperties[type];

  return (
    <div
      className={classNames(
        'relative flex min-w-[9ch] gap-y-1 rounded-2xl bg-white px-2 py-3 hover:cursor-pointer hover:bg-gray-100 sm:px-3 sm:py-5',
        display === 'list'
          ? 'max-w-[300px] flex-row items-center gap-x-2'
          : 'flex-col items-center',
        selected && 'shadow-md'
      )}
      style={{
        backgroundColor: selected ? color : undefined,
      }}
      onClick={handleClick}
    >
      {Icon && (
        <Icon
          className={classNames('h-6 w-6')}
          style={{ color: selected ? '#FFFFFF' : color }}
          strokeWidth={selected ? 2 : 1.5}
        />
      )}
      <p
        className={classNames(
          'flex flex-wrap pl-1 text-sm',
          selected ? 'font-medium text-white' : 'font-medium text-text-dark',
          display === 'card' && 'text-center'
        )}
      >
        {title}
      </p>
    </div>
  );
};
