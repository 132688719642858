import { CandidateFormValues } from '../../lib/formSchemas';
import dayjs from 'dayjs';

const CV_DETAILS_PROPERTIES: Array<keyof CandidateFormValues> = [
  'profileSummary',
  'experience',
  'certifications',
  'education',
  'skills',
  'languages',
  'achievements',
  'interests',
];

export function hasCVDetails(candidate: CandidateFormValues): boolean {
  const hasCvDetails = CV_DETAILS_PROPERTIES.some((key) => {
    const value = candidate[key];
    return Array.isArray(value) ? value.length > 0 : !!value;
  });

  return hasCvDetails;
}

/**
 * Hides CV details if they are not present.
 */
export function setCvDetailsToUndefined(candidate: CandidateFormValues): CandidateFormValues {
  return {
    ...candidate,
    profileSummary: undefined,
    experience: undefined,
    certifications: undefined,
    education: undefined,
    skills: undefined,
    languages: undefined,
    achievements: undefined,
    interests: undefined,
  };
}

/**
 * Convert a start, end date pair int human readable date range
 * @returns Human-readable date range. e.g Dec 2023 - March 2024 OR March 2024 OR Dec 2023 - Present
 */
export function formatStartEndDateSnippet(startDate?: Date | null, endDate?: Date | null): string {
  const startDateString = startDate ? dayjs(startDate).format('MMM YYYY') : '';
  const isEndDatePresent = startDate && !endDate;

  const endDateString = isEndDatePresent
    ? 'Present'
    : endDate
    ? dayjs(endDate).format('MMM YYYY')
    : '';
  const separator = !!startDateString && !!endDateString ? ' - ' : '';

  return `${startDateString}${separator}${endDateString}`;
}
