import { FC } from 'react';
import { useMutation } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/dist/toast';
import { navigate, routes, useParams } from '@redwoodjs/router';
import {
  ArrowDownOnSquareIcon,
  ArrowPathIcon,
  CheckIcon,
  DocumentDuplicateIcon,
  DocumentPlusIcon,
} from '@heroicons/react/24/outline';
import DOMPurify from 'dompurify';
import { motion } from 'framer-motion';
import {
  OVERRIDE_DOCUMENT_WITH_CHAT_RESPONSE_MUTATION,
  SAVE_CHAT_RESPONSE_AS_DOCUMENT,
} from 'src/graphql/mutations';
import { GET_CHAT_SESSION_QUERY } from 'src/graphql/queries';
import {
  ChatMessage as TChatMessage,
  ChatRequest as TChatRequest,
  ChatResponse as TChatResponse,
  OverrideDocumentWithChatResponseMutation,
  OverrideDocumentWithChatResponseMutationVariables,
  SaveChatResponseAsDocument,
  SaveChatResponseAsDocumentVariables,
} from 'types/graphql';
import { useDialog } from 'src/hooks';
import { useAuth } from 'src/auth';
import { copyToClipboard } from 'src/lib/clipboard';
import { Link, UserIcon } from 'src/components';
import { IconButton } from 'src/components/IconButton';
import * as ALogo from 'src/assets/alogo.svg';
import { classNames, convertMarkdownToHtml } from 'src/lib';
import { CreatePromptDialog } from 'src/components/PromptsDialog';
import { Button } from 'src/components/Button';

type Props = {
  chatSessionId?: string;
  message: TChatMessage;
  documentId?: string;
  onRegenerate?: (responseId: string) => void;
  isLast?: boolean;
};

export const ChatMessage: FC<Props> = ({
  message,
  documentId,
  chatSessionId,
  onRegenerate,
  isLast,
}) => {
  if (!message.__typename) {
    return null;
  }

  switch (message.__typename) {
    case 'ChatRequest':
      return (
        <ChatRequest message={message} documentId={documentId} chatSessionId={chatSessionId} />
      );
    case 'ChatResponse':
      return (
        <ChatResponse
          isLast={isLast}
          onRegenerate={onRegenerate}
          message={message}
          documentId={documentId}
          chatSessionId={chatSessionId}
        />
      );
  }
};

const ChatResponse: FC<{
  message: TChatResponse;
  documentId?: string;
  chatSessionId?: string;
  onRegenerate?: (responseId: string) => void;
  isLast?: boolean;
}> = ({ message, documentId, onRegenerate, chatSessionId, isLast }) => {
  const { redirectUrl, documentType } = useParams();

  const [mutate, { loading }] = useMutation<
    OverrideDocumentWithChatResponseMutation,
    OverrideDocumentWithChatResponseMutationVariables
  >(OVERRIDE_DOCUMENT_WITH_CHAT_RESPONSE_MUTATION, {
    refetchQueries: [
      {
        query: GET_CHAT_SESSION_QUERY,
        variables: { id: chatSessionId },
      },
    ],
    onCompleted: (data) => {
      toast.success('Document updated with chat response');
      if (!data?.overrideDocumentWithChatResponse?.document?.id) {
        return;
      }

      navigate(`${redirectUrl}${documentType ? `?documentType=${documentType}` : ''}`);
    },
  });

  const [saveResponseAsDocument, { loading: saveResponseLoading }] = useMutation<
    SaveChatResponseAsDocument,
    SaveChatResponseAsDocumentVariables
  >(SAVE_CHAT_RESPONSE_AS_DOCUMENT, {
    onCompleted: (data) => {
      toast.success('Chat response saved as document');
      if (!data?.saveResponseAsDocument?.document?.id) {
        return;
      }
      navigate(
        routes.document({
          documentId: data.saveResponseAsDocument.document.id,
        })
      );
    },
  });

  const nodes = new DOMParser().parseFromString(message.text, 'text/html').body.childNodes;
  // Check if this is HTML or markdown. If it's markdown, it will only have one text node when parsed as HTML
  const isMarkdown = nodes.length === 1 && nodes[0].nodeName === '#text';
  // If markdown, convert to html else render as HTML
  const innerHtml = isMarkdown ? convertMarkdownToHtml(message.text) : message.text;

  const onCopy = async () => {
    await copyToClipboard(message.text);
  };

  const onOverride = () => {
    if (!documentId) {
      return;
    }
    mutate({
      variables: {
        documentId,
        chatResponseId: message.id,
      },
    });
  };

  const onSave = () => {
    saveResponseAsDocument({
      variables: {
        responseId: message.id,
      },
    });
  };

  return (
    <div className="flex flex-col border-b border-text-light bg-chatResponse px-6 py-10 text-text-dark">
      <div className="flex gap-x-6">
        <div className="box-border flex h-8 w-8 items-start justify-center self-start rounded bg-pageGray p-1">
          {/* @ts-expect-error svg doesn't work as a react component due to style formatting*/}
          <ALogo class="h-6 w-6 rounded" />
        </div>

        <div className="prose" dangerouslySetInnerHTML={{ __html: innerHtml }} />
      </div>
      <div className="flex flex-row items-center justify-end gap-x-6 pt-4">
        {message.document?.id ? (
          <Link
            onClick={() => {
              // Not sure why ternary is not narrowing the type
              navigate(routes.document({ documentId: message.document?.id ?? '' }));
            }}
            size="medium"
            LeftIcon={CheckIcon}
          >
            Saved as AdScribe Doc
          </Link>
        ) : (
          <>
            {documentId && (
              <Button
                text={loading ? 'Loading...' : 'Save Changes'}
                variant="outline"
                size="medium"
                loading={loading}
                LeftIcon={ArrowDownOnSquareIcon}
                onClick={onOverride}
              />
            )}
            <IconButton
              tooltipText={loading ? 'Loading...' : 'Save as new AdScribe doc'}
              disabled={saveResponseLoading}
              size="medium"
              Icon={DocumentPlusIcon}
              onClick={onSave}
            />
          </>
        )}
        {isLast && (
          <IconButton
            Icon={ArrowPathIcon}
            tooltipText="Regenerate"
            onClick={() => onRegenerate && onRegenerate(message.id)}
            disabled={saveResponseLoading}
          />
        )}
        <IconButton Icon={DocumentDuplicateIcon} tooltipText="Copy" onClick={onCopy} />
      </div>
    </div>
  );
};

export const ChatRequest: FC<{
  message: TChatRequest;
  chatSessionId?: string;
  documentId?: string;
  hideSavePrompt?: boolean;
}> = ({ message, chatSessionId, documentId, hideSavePrompt }) => {
  const { currentUser } = useAuth();
  const { show, close } = useDialog();
  const innerHtml = DOMPurify.sanitize(message.text, {
    USE_PROFILES: { html: true },
  });

  return (
    <div className="flex flex-col border-b border-text-light px-6 py-10 text-text-dark">
      <div className="flex gap-x-6">
        <div className="h-8 w-8">
          <UserIcon user={currentUser} className="rounded" />
        </div>
        <div
          //TODO: Find a better solution for presenting documents and chat consistently
          className={classNames('prose', !documentId && 'whitespace-pre-wrap')}
          dangerouslySetInnerHTML={{ __html: innerHtml }}
        />
      </div>
      <div className="flex flex-row justify-end gap-x-6 pt-4">
        {!hideSavePrompt && (
          <IconButton
            Icon={ArrowDownOnSquareIcon}
            tooltipText="Save Prompt"
            onClick={() => show(<CreatePromptDialog text={message.text} />)}
          />
        )}
      </div>
    </div>
  );
};

export const ChatResponseLoading: FC = () => {
  return (
    <div className="flex flex-col border-b border-text-light bg-chatResponse px-6 py-10 text-text-dark">
      <div className="flex gap-x-6">
        <div className="box-border flex h-8 w-8 items-start justify-center self-start rounded bg-pageGray p-1">
          {/* @ts-expect-error svg doesn't work as a react component due to style formatting*/}
          <ALogo class="h-6 w-6 rounded" />
        </div>
        <ChatLoadingAnim />
      </div>
    </div>
  );
};

const ChatLoadingAnim = () => {
  return (
    <motion.div className="mt-2 flex flex-row gap-x-2 rounded-b-2xl rounded-tr-2xl bg-generate-medium px-8 py-4">
      <motion.div
        className="h-2 w-2 rounded-full bg-white"
        animate={{ opacity: [1, 0, 1] }}
        transition={{ delay: 0, repeat: Infinity, duration: 1.2 }}
      />
      <motion.div
        className="h-2 w-2 rounded-full bg-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: [1, 0, 1] }}
        transition={{ delay: 0.3, repeat: Infinity, duration: 1.2 }}
      />
      <motion.div
        className="h-2 w-2 rounded-full bg-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: [1, 0, 1] }}
        transition={{ delay: 0.6, repeat: Infinity, duration: 1.2 }}
      />
    </motion.div>
  );
};
