export const SET_DOCUMENT_TEMPLATE_MUTATION = gql`
  mutation SetDocumentTemplateMutation($input: SetDocumentTemplateInput!) {
    setDocumentTemplate(input: $input) {
      id
      title
      description
      config
      configSchema
    }
  }
`;
