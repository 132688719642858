export const EXTRACT_CANDIDATE_URL_MUTATION = gql`
  mutation ExtractCandidateUrlMutation($url: String!) {
    extractCandidateUrl(url: $url) {
      __typename
      name
      refId
      profileSummary
      jobTitle
      desiredSalary
      currentSalary
      availability
      overview
      motivationsForMoving
    }
  }
`;
