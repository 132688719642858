export const EXTRACT_COMPANY_URL_MUTATION = gql`
  mutation ExtractCompanyUrlMutation($url: String!) {
    extractCompanyUrl(url: $url) {
      __typename
      name
      location
      website
      overview
      culture
      benefits
      notes
    }
  }
`;
