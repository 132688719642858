import { FadeIn } from 'src/components/FadeIn';
import { Tooltip } from '../Tooltip';
import { UserIcon } from '../UserIcon';
import { PermissionFields, PermissionFields_UserPermission_ } from 'types/graphql';
import { USER_PERMISSION_LABELS } from 'src/lib';
import { useAuth } from 'src/auth';
import { sortUserPermissions } from 'src/lib/sortUserPermissions';

export type PermissionsData = {
  permissionsData: PermissionFields[];
  onClick?: () => void;
};

export const OtherPermissions = ({ permissionsData = [], onClick }: PermissionsData) => {
  const { currentUser } = useAuth();

  const userPermissions = permissionsData?.filter((i) => i.__typename === 'UserPermission') ?? [];

  const sortedPermissions = sortUserPermissions(
    userPermissions,
    currentUser
  ) as PermissionFields_UserPermission_[];

  return (
    <FadeIn visible={sortedPermissions?.length > 1} className="flex items-center justify-center ">
      {sortedPermissions.slice(0, 3)?.map((perm, index) => {
        const userInfo = perm.user.name ?? perm.user.email;
        const permissionLabel = USER_PERMISSION_LABELS[perm.permission];
        const tooltipText = (
          <>
            <div>{userInfo}</div>
            <div>{permissionLabel}</div>
          </>
        );
        const marginLeftClass = index > 0 ? '-ml-1' : '';
        const zIndex = sortedPermissions.length - index; // First icon has the highest z-index

        return (
          <Tooltip key={perm.id} innerBody={tooltipText}>
            <div className={`relative ${marginLeftClass}`} style={{ zIndex }}>
              <UserIcon
                user={perm.user}
                className="cursor-pointer"
                size="medium"
                hasShadow
                onClick={onClick}
              />
            </div>
          </Tooltip>
        );
      })}
    </FadeIn>
  );
};
