import {
  BuildingOfficeIcon,
  ChevronDownIcon,
  DocumentTextIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { NetworkStatus } from '@apollo/client';
import { navigate, routes } from '@redwoodjs/router';
import { MetaTags, useMutation, useQuery } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/dist/toast';
import { FC, useState } from 'react';
import {
  Company,
  DuplicateCandidateMutationVariables,
  DuplicateCompanyMutation,
  GetCompanies,
  GetCompaniesVariables,
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables,
} from 'types/graphql';
import { Checkbox, Pagination, SearchInput } from '../../components';
import { Button } from '../../components/Button';
import { DropdownButton } from '../../components/DropdownButton';
import { PageTitle } from '../../components/PageTitle';
import { GET_COMPANIES_QUERY } from '../../graphql/queries';
import {
  useDebounce,
  usePageClasses,
  useQueryParamSyncedState,
  useQueryParamSyncedStateObject,
  useTrackPageView,
} from '../../hooks';
import { useCreateSpecEmail } from '../../hooks/useCreateSpecEmail';
import { CompanyTableRow } from './CompanyTableRow';
import { Spinner } from '../../components/Spinner';
import { DUPLICATE_COMPANY_MUTATION, UPDATE_COMPANY_MUTATION } from '../../graphql/mutations';
import { canSelectCompany } from './util';

type FilterValuesType = {
  mineOnly: boolean;
  includeArchived: boolean;
};

const PAGE_LIMIT = 10;

const CompaniesPage = () => {
  useTrackPageView();
  usePageClasses('bg-white');

  const { value: filterValues, setValue: setFilterValues } =
    useQueryParamSyncedStateObject<FilterValuesType>({
      mineOnly: true,
      includeArchived: false,
    });

  const { value: page, setValue: setPage } = useQueryParamSyncedState('page', 1);
  const [searchTerm, setSearchTerm] = useState<string | null>('');
  const [selected, setSelected] = useState<string[]>([]);

  const debouncedFilterValues = useDebounce({ ...filterValues, searchTerm });

  const queryVariables: GetCompaniesVariables = {
    input: {
      connection: { page, take: PAGE_LIMIT },
      searchTerm: debouncedFilterValues.searchTerm,
      accessLevel: debouncedFilterValues.mineOnly ? 'OWNER' : 'READ',
      statuses: debouncedFilterValues.includeArchived ? ['ACTIVE', 'ARCHIVED'] : ['ACTIVE'],
    },
  };

  const {
    networkStatus,
    error,
    data: queryData,
    previousData,
  } = useQuery<GetCompanies, GetCompaniesVariables>(GET_COMPANIES_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: queryVariables,
  });

  const [updateCompany] = useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(
    UPDATE_COMPANY_MUTATION,
    {
      optimisticResponse: ({ input: { status }, id }) => {
        return {
          __typename: 'Mutation',
          updateJob: {
            __typename: 'Company',
            id,
            status,
          },
        } as unknown as UpdateCompanyMutation;
      },
      onCompleted: ({ updateCompany: { status } }) => {
        if (status === 'ARCHIVED') {
          toast.success('Company Archived');
        } else {
          toast.success('Company restored');
        }
      },
      update: (cache, { data }) => {
        if (debouncedFilterValues.includeArchived === false) {
          cache.updateQuery<GetCompanies, GetCompaniesVariables>(
            {
              query: GET_COMPANIES_QUERY,
              variables: queryVariables,
            },
            (cacheData) => {
              return {
                __typename: 'Query',
                companies: {
                  __typename: 'CompaniesConnection',
                  nodes:
                    cacheData?.companies?.nodes.filter((j) => j.id !== data?.updateCompany?.id) ??
                    [],
                  pageInfo: {
                    // Make typescript happy
                    isLast: cacheData?.companies?.pageInfo?.isLast ?? true,
                    ...cacheData?.companies?.pageInfo,
                    __typename: 'PageInfo',
                    count: (cacheData?.companies?.pageInfo?.count ?? 1) - 1,
                  },
                },
              };
            }
          );
        }
      },
      refetchQueries: [GET_COMPANIES_QUERY],
    }
  );

  const [duplicateCompany] = useMutation<
    DuplicateCompanyMutation,
    DuplicateCandidateMutationVariables
  >(DUPLICATE_COMPANY_MUTATION, {
    onCompleted: (data) => {
      toast.success('Company created!');
      navigate(routes.company({ companyId: data.duplicateCompany.id }));
    },
  });

  const { createSpecEmail, loading: specEmailLoading } = useCreateSpecEmail({
    companyIds: selected,
  });

  const data = queryData ?? previousData;
  const loading = networkStatus === NetworkStatus.loading && !queryData;
  const paginationPending = networkStatus === NetworkStatus.setVariables && !queryData;
  const pageInfo = data?.companies.pageInfo;

  const handleSearchInputChange = (newValue: string | null) => {
    setSearchTerm(newValue);
  };

  const handleIncludeArchivedChange = (newValue: boolean) => {
    setFilterValues({ ...filterValues, includeArchived: newValue });
  };

  const handleMineOnlyChange = (newValue: boolean) => {
    setFilterValues({ ...filterValues, mineOnly: newValue });
  };

  const handleSetPage = (newPage: number) => {
    setPage(newPage);
  };

  const onToggleSelected = (id: string) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((s) => s !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const onExportSpecEmail = () => {
    toast.promise(createSpecEmail(), {
      loading: 'Generating Combined Spec Email...',
      success: 'Success',
      error: 'Something went wrong, please try again.',
    });
  };

  const onArchiveCompany = (company: Pick<Company, 'id'>) => {
    updateCompany({
      variables: {
        id: company.id,
        input: {
          status: 'ARCHIVED',
        },
      },
    });
  };

  const onUnArchiveCompany = (company: Pick<Company, 'id'>) => {
    updateCompany({
      variables: {
        id: company.id,
        input: {
          status: 'ACTIVE',
        },
      },
    });
  };

  const onDuplicateCompany = (company: Pick<Company, 'id'>) => {
    duplicateCompany({
      variables: {
        id: company.id,
      },
    });
  };

  if (error) {
    throw error;
  }

  const hasSetFilterValues = debouncedFilterValues.searchTerm !== '';
  const isEmpty = !hasSetFilterValues && data?.companies?.nodes.length === 0;
  const noResults = hasSetFilterValues && data?.companies?.nodes.length === 0;

  const selectableCompanies = data?.companies?.nodes.filter(canSelectCompany) ?? [];

  const onSelectAll = () => {
    if (selected.length === selectableCompanies.length) {
      setSelected([]);
    } else {
      setSelected(selectableCompanies.map((j) => j.id) ?? []);
    }
  };

  return (
    <div className="flex h-full w-full flex-col items-center px-16">
      <div className="flex w-full max-w-6xl flex-grow flex-col">
        <MetaTags title="Companies" description="Companies page" />
        <div className="flex flex-row items-center justify-between pb-4 pt-12">
          <PageTitle text="Companies" Icon={BuildingOfficeIcon} size="lg" />
          <Button
            text="New Company"
            LeftIcon={PlusIcon}
            onClick={() => navigate(routes.newCompany())}
          />
        </div>
        <div className="flex w-full flex-row items-center gap-x-4 pb-4 pt-6">
          <div className="mx-2">
            <div className="w-8">
              {!!data?.companies?.nodes.length && (
                <Checkbox
                  onChange={onSelectAll}
                  value={selected.length === data?.companies?.nodes.length}
                />
              )}
            </div>
          </div>
          <div className="max-w-[600px] flex-grow">
            <SearchInput
              placeholder="Search by name and industry"
              value={searchTerm ?? ''}
              onChange={handleSearchInputChange}
            />
          </div>
          <Checkbox
            label="Mine only"
            value={filterValues.mineOnly}
            onChange={handleMineOnlyChange}
          />
          <Checkbox
            label="Include archived"
            value={filterValues.includeArchived}
            onChange={handleIncludeArchivedChange}
          />
          {!!selected.length && (
            <div className="flex flex-1 items-center justify-end">
              <p className="px-2 text-sm text-text-medium">{selected.length} selected</p>
              <DropdownButton
                buttonText="Batch Actions"
                Icon={ChevronDownIcon}
                options={[
                  {
                    Icon: DocumentTextIcon,
                    onClick: () => onExportSpecEmail(),
                    text: 'Combined Spec Email',
                    disabled: specEmailLoading,
                  },
                ]}
              />
            </div>
          )}
        </div>
        {loading ? (
          <Spinner />
        ) : isEmpty ? (
          <Empty />
        ) : (
          <>
            {noResults ? (
              <NoResults />
            ) : (
              <div className="flex-grow">
                <div className="relative">
                  {data?.companies.nodes.map((company) => (
                    <CompanyTableRow
                      company={company}
                      key={company.id}
                      onToggleSelected={onToggleSelected}
                      selected={selected.includes(company.id)}
                      onArchiveCompany={onArchiveCompany}
                      onUnArchiveCompany={onUnArchiveCompany}
                      onDuplicateCompany={onDuplicateCompany}
                    />
                  ))}
                </div>
                {pageInfo && (
                  <Pagination
                    loading={paginationPending}
                    count={pageInfo.count}
                    limit={PAGE_LIMIT}
                    isLast={pageInfo.isLast}
                    page={pageInfo.page}
                    thisPageCount={data?.companies.nodes.length}
                    onSetPage={handleSetPage}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export const Empty: FC = () => (
  <div className="flex flex-grow flex-col">
    <div className="flex flex-grow flex-col items-center justify-center gap-y-2">
      <p className="font-medium text-text-medium">You haven&apos;t created any Companies yet.</p>
      <p className="text-text-medium">
        Click the button below to create a Company and auto-generate a Company Introduction and
        Summary.
      </p>
      <div className="pb-6 pt-4">
        <Button
          onClick={() => navigate(routes.newCompany())}
          variant="outline"
          size="medium"
          LeftIcon={PlusIcon}
          text="Create a Company"
        />
      </div>
    </div>
  </div>
);

export const NoResults: FC = () => (
  <div className="flex flex-grow flex-col">
    <div className="flex flex-grow flex-col items-center justify-center gap-y-2">
      <p className="font-medium text-text-medium">
        No Companies found with the search criteria above.
      </p>
      <p className="text-text-medium">
        Click the button below to create a Company and auto-generate a Company Introduction and
        Summary.
      </p>
      <div className="pb-6 pt-4">
        <Button
          onClick={() => navigate(routes.newCompany())}
          variant="outline"
          size="medium"
          LeftIcon={PlusIcon}
          text="Create a Company"
        />
      </div>
    </div>
  </div>
);

export default CompaniesPage;
