import showdown from 'showdown';

const converter = new showdown.Converter({
  simpleLineBreaks: true,
  requireSpaceBeforeHeadingText: true,
});

export function convertMarkdownToHtml(markdown: string): string {
  return converter.makeHtml(markdown);
}
export function convertHtmlToMarkdown(html: string): string {
  return converter.makeMarkdown(html);
}
