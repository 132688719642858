import { FC, ReactNode } from 'react';
import { classNames } from 'src/lib';

interface SectionListItemProps {
  children: ReactNode;
  selected?: boolean;
  onClick?: () => void;
}

export const SectionListItem: FC<SectionListItemProps> = ({ children, selected, onClick }) => (
  <div
    onClick={onClick}
    className={classNames(
      'flex cursor-pointer items-center gap-x-[10px] rounded-md p-3 text-left text-sm transition-all duration-100 ease-in-out',
      selected ? 'bg-gray-100/60 font-bold text-text-dark' : 'text-text-medium hover:bg-gray-100/60'
    )}
  >
    <div className="flex items-center gap-x-2">{children}</div>
  </div>
);
