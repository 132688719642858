import { useState, PropsWithChildren } from 'react';
import { FormEditStateContext, FormEditStateProviderProps } from 'src/hooks/useReadOnlyForm';

export const FormEditStateProvider = ({
  readOnly = false,
  children,
}: PropsWithChildren<FormEditStateProviderProps>) => {
  const [state, setState] = useState(readOnly);

  const setReadOnly = (readOnly: boolean) => {
    setState(readOnly);
  };

  return (
    <FormEditStateContext.Provider value={{ readOnly: state, setReadOnly }}>
      {children}
    </FormEditStateContext.Provider>
  );
};
