import { z } from 'zod';

export const inviteFormSchema = z.object({
  email: z
    .string()
    .email({ message: 'Invalid email address' })
    .min(1, { message: 'Email is required' }),
  permission: z.enum(['READ', 'WRITE']).default('READ'),
});

export type InviteFormValues = z.infer<typeof inviteFormSchema>;
