import { useForm } from '@redwoodjs/forms';
import { useMutation } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/dist/toast';
import { FC } from 'react';
import { CREATE_PROMPT_MUTATION } from '../../graphql/mutations';
import { GET_PROMPTS_QUERY } from '../../graphql/queries';
import { useDialog } from '../../hooks';
import { Button } from '../Button';
import { DialogLayout } from '../DialogLayout';
import { TextAreaField } from '../TextArea';
import { TextField } from '../TextField';
import { PromptsDialog } from './PromptsDialog';
import { FormError } from '../FormError';
import { Form } from '../Form/Form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PromptFormValues, promptFormSchema } from './promptFormSchema';

type FormValues = {
  description: string;
  prompt: string;
};

export const CreatePromptDialog: FC<{ text?: string }> = ({ text }) => {
  const { close, show } = useDialog();
  const [createPrompt, { loading, error }] = useMutation(CREATE_PROMPT_MUTATION);

  const formMethods = useForm<PromptFormValues>({
    resolver: zodResolver(promptFormSchema),
    defaultValues: { prompt: text ?? '' },
  });

  const handleSubmit = async (values: PromptFormValues) => {
    await createPrompt({
      variables: {
        input: {
          description: values.description,
          prompt: values.prompt,
        },
      },
      refetchQueries: [{ query: GET_PROMPTS_QUERY }],
    });
    toast.success('Prompt created');
    show(<PromptsDialog />);
  };
  return (
    <DialogLayout onClose={close} title="Create a Prompt" className="w-full max-w-[720px]">
      <Form<FormValues>
        onSubmit={handleSubmit}
        className="flex flex-col gap-y-3 pt-6"
        config={{ defaultValues: { prompt: text ?? '' } }}
        formMethods={formMethods}
      >
        <FormError error={error} />
        <TextField name="description" label="Description" schema={promptFormSchema} />
        <TextAreaField
          name="prompt"
          label="Prompt"
          className="min-h-[300px]"
          expandable={false}
          schema={promptFormSchema}
        />
        <div className="py-2" />

        <Button text={loading ? 'Loading...' : 'Create'} loading={loading} type="submit" />
      </Form>
    </DialogLayout>
  );
};
