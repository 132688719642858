import { DOCUMENT_FIELDS_FRAGMENT } from '../fragments';

export const RESTORE_DOCUMENT_MUTATION = gql`
  ${DOCUMENT_FIELDS_FRAGMENT}
  mutation RestoreDocumentMutation($input: RestoreDocumentInput!) {
    restoreDocument(input: $input) {
      ...DocumentFields
    }
  }
`;
