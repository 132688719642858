import { USER_ROLE } from '../../types/graphql';
import { User } from './auth';

export function hasRole(user: User, role: USER_ROLE): boolean {
  if (!user) return false;
  return (user.roles as string[]).includes(role);
}

export function parseRole(role: USER_ROLE): 'Member' | 'Admin' {
  switch (role) {
    case 'USER':
      return 'Member';
    case 'ADMIN':
      return 'Admin';
  }
}
