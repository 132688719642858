import { Link, Styles, Text, View } from '@react-pdf/renderer';
import { FC } from 'react';
import {
  BlockquoteNode as TBlockquoteNode,
  BoldNode as TBoldNode,
  CodeNode as TCodeNode,
  H1Node as TH1Node,
  H2Node as TH2Node,
  H3Node as TH3Node,
  H4Node as TH4Node,
  H5Node as TH5Node,
  H6Node as TH6Node,
  TableBodyNode as TTableBodyNode,
  HardBreakNode as THardBreakNode,
  ItalicNode as TItalicNode,
  LinkNode as TLinkNode,
  ListItemNode as TListItemNode,
  OrderedListNode as TOrderedListNode,
  PDF_NODE_TYPE,
  ParagraphNode as TParagraphNode,
  StrikeNode as TStrikeNode,
  PdfNode as TPdfNode,
  TableCellNode as TTableCellNode,
  TableHeaderNode as TTableHeaderNode,
  TableNode as TTableNode,
  TableRowNode as TTableRowNode,
  TextNode as TTextNode,
  UnorderedListNode as TUnorderedListNode,
} from './types';
import { DocumentBrandingConfig } from '../../../../types/graphql';
import colors from '../../../assets/colors';

type PdfNodeProps<T extends TPdfNode> = {
  node: T;
  documentConfig?: DocumentBrandingConfig;
};

export const PdfNode: FC<PdfNodeProps<TPdfNode>> = ({ node, documentConfig }) => {
  switch (node.type) {
    case PDF_NODE_TYPE.PARAGRAPH:
      return <ParagraphPdfNode node={node} documentConfig={documentConfig} />;
    case PDF_NODE_TYPE.H1:
      return <H1Node node={node} documentConfig={documentConfig} />;
    case PDF_NODE_TYPE.H2:
      return <H2Node node={node} documentConfig={documentConfig} />;
    case PDF_NODE_TYPE.H3:
      return <H3Node node={node} documentConfig={documentConfig} />;
    case PDF_NODE_TYPE.H4:
      return <H4Node node={node} documentConfig={documentConfig} />;
    case PDF_NODE_TYPE.H5:
      return <H5Node node={node} documentConfig={documentConfig} />;
    case PDF_NODE_TYPE.H6:
      return <H6Node node={node} documentConfig={documentConfig} />;
    case PDF_NODE_TYPE.TABLE:
      return <TableNode node={node} documentConfig={documentConfig} />;
    case PDF_NODE_TYPE.TABLE_BODY:
      return <TableBodyNode node={node} documentConfig={documentConfig} />;
    case PDF_NODE_TYPE.TABLE_ROW:
      return <TableRowNode node={node} documentConfig={documentConfig} />;
    case PDF_NODE_TYPE.TABLE_HEADER:
      return <TableHeaderNode node={node} documentConfig={documentConfig} />;
    case PDF_NODE_TYPE.TABLE_CELL:
      return <TableCellPdfNode node={node} documentConfig={documentConfig} />;
    case PDF_NODE_TYPE.LIST_ITEM:
      return <ListItemNode node={node} documentConfig={documentConfig} />;
    case PDF_NODE_TYPE.UNORDERED_LIST:
      return <UnorderedListNode node={node} documentConfig={documentConfig} />;
    case PDF_NODE_TYPE.ORDERED_LIST:
      return <OrderedListNode node={node} documentConfig={documentConfig} />;
    case PDF_NODE_TYPE.LINK:
      return <LinkNode node={node} documentConfig={documentConfig} />;
    case PDF_NODE_TYPE.BOLD:
      return <BoldNode node={node} documentConfig={documentConfig} />;
    case PDF_NODE_TYPE.ITALIC:
      return <ItalicNode node={node} documentConfig={documentConfig} />;
    case PDF_NODE_TYPE.BLOCKQUOTE:
      return <BlockquoteNode node={node} documentConfig={documentConfig} />;
    case PDF_NODE_TYPE.STRIKE:
      return <StrikeNode node={node} documentConfig={documentConfig} />;
    case PDF_NODE_TYPE.HARD_BREAK:
      return <HardBreakNode node={node} documentConfig={documentConfig} />;
    case PDF_NODE_TYPE.CODE:
      return <CodeNode node={node} documentConfig={documentConfig} />;
    case PDF_NODE_TYPE.TEXT:
      return <TextNode node={node} documentConfig={documentConfig} />;
    case PDF_NODE_TYPE.FRAGMENT:
      return (
        <>
          <ChildNodes childNodes={node.children} documentConfig={documentConfig} />
        </>
      );
    case undefined:
    case null: {
      console.error('PdfNode: node type is undefined or null', node);
      return null;
    }
  }
};

type PdfBodyStyles = Record<PDF_NODE_TYPE, Styles['string']>;

const pdfNodeStyles: (config?: DocumentBrandingConfig) => PdfBodyStyles = (config) => ({
  [PDF_NODE_TYPE.TEXT]: {},
  [PDF_NODE_TYPE.PARAGRAPH]: {
    fontSize: 12,
    lineHeight: 1.5,
    paddingBottom: 8,
  },
  [PDF_NODE_TYPE.H1]: {
    fontFamily: config?.fontConfig?.header ?? 'sans-serif',
    fontWeight: 800,
    fontSize: 24,
    paddingVertical: 10,
    lineHeight: 1.2,
  },
  [PDF_NODE_TYPE.H2]: {
    fontFamily: config?.fontConfig?.header ?? 'sans-serif',
    fontWeight: 'bold',
    fontSize: 14,
    paddingVertical: 10,
  },
  [PDF_NODE_TYPE.H3]: {
    fontFamily: config?.fontConfig?.header ?? 'sans-serif',
    fontWeight: 'bold',
    fontSize: 14,
    paddingVertical: 10,
  },
  [PDF_NODE_TYPE.H4]: {
    fontFamily: config?.fontConfig?.header ?? 'sans-serif',
    fontWeight: 'bold',
    fontSize: 14,
    paddingVertical: 10,
  },
  [PDF_NODE_TYPE.H5]: {
    fontFamily: config?.fontConfig?.header ?? 'sans-serif',
    fontWeight: 'bold',
    fontSize: 14,
    paddingVertical: 10,
  },
  [PDF_NODE_TYPE.H6]: {
    fontFamily: config?.fontConfig?.header ?? 'sans-serif',
    fontWeight: 'bold',
    fontSize: 14,
    paddingVertical: 10,
  },
  [PDF_NODE_TYPE.LIST_ITEM]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingBottom: 8,
    fontSize: 12,
    flexWrap: 'nowrap',
  },
  [PDF_NODE_TYPE.UNORDERED_LIST]: {
    marginBottom: 10,
    paddingLeft: 8,
  },
  [PDF_NODE_TYPE.ORDERED_LIST]: {
    marginBottom: 10,
    paddingLeft: 8,
  },
  [PDF_NODE_TYPE.LINK]: {
    color: colors.generate.medium,
    textDecoration: 'underline',
    fontWeight: 500,
    fontSize: 12,
  },
  [PDF_NODE_TYPE.BOLD]: {
    fontWeight: 'bold',
    fontFamily: 'serif',
  },
  [PDF_NODE_TYPE.ITALIC]: {
    fontFamily: 'serif',
    fontStyle: 'italic',
  },
  [PDF_NODE_TYPE.BLOCKQUOTE]: {
    fontStyle: 'italic',
  },
  [PDF_NODE_TYPE.STRIKE]: {
    textDecoration: 'line-through',
  },
  [PDF_NODE_TYPE.HARD_BREAK]: {
    marginBottom: 5,
    fontSize: 12,
  },
  [PDF_NODE_TYPE.CODE]: {
    fontFamily: 'mono',
  },
  [PDF_NODE_TYPE.TABLE]: {
    borderStyle: config?.showTableBorders ? 'solid' : undefined,
    borderTopWidth: config?.showTableBorders ? 1 : 0,
    borderRightWidth: config?.showTableBorders ? 1 : 0,
    borderColor: config?.showTableBorders ? colors.text.light : 'transparent',
    borderCollapse: 'collapse',
  },
  [PDF_NODE_TYPE.TABLE_BODY]: {},
  [PDF_NODE_TYPE.TABLE_HEADER]: {
    borderStyle: config?.showTableBorders ? 'solid' : undefined,
    borderBottomWidth: config?.showTableBorders ? 1 : 0,
    borderLeftWidth: config?.showTableBorders ? 1 : 0,
    borderColor: config?.showTableBorders ? colors.text.light : 'transparent',
    paddingHorizontal: config?.showTableBorders ? 4 : 0,
    paddingTop: config?.showTableBorders ? 4 : 0,
    backgroundColor: '#E8E8E8',
    fontWeight: 'bold',
    flex: 1,
    borderCollapse: 'collapse',
  },
  [PDF_NODE_TYPE.TABLE_ROW]: {
    flexDirection: 'row',
    borderCollapse: 'collapse',
  },
  [PDF_NODE_TYPE.TABLE_CELL]: {
    flex: 1,
    borderCollapse: 'collapse',
    borderBottomWidth: config?.showTableBorders ? 1 : 0,
    borderLeftWidth: config?.showTableBorders ? 1 : 0,
    borderStyle: config?.showTableBorders ? 'solid' : undefined,
    borderColor: config?.showTableBorders ? colors.text.light : 'transparent',
    paddingHorizontal: config?.showTableBorders ? 4 : 0,
    paddingTop: config?.showTableBorders ? 4 : 0,
  },
  [PDF_NODE_TYPE.FRAGMENT]: {},
});

const TextNode: FC<PdfNodeProps<TTextNode>> = ({ node }) => {
  if (typeof node.children !== 'string') {
    console.error('TextNode: children is not a string', node.children);
    return null;
  }

  return <Text>{node.children}</Text>;
};

const ParagraphPdfNode: FC<PdfNodeProps<TParagraphNode>> = ({ node, documentConfig }) => {
  const style = pdfNodeStyles(documentConfig)[node.type];
  return (
    <Text style={style}>
      <ChildNodes childNodes={node.children} documentConfig={documentConfig} />
    </Text>
  );
};

const H1Node: FC<PdfNodeProps<TH1Node>> = ({ node, documentConfig }) => {
  const style = pdfNodeStyles(documentConfig)[node.type];
  return (
    <Text style={style}>
      <ChildNodes childNodes={node.children} documentConfig={documentConfig} />
    </Text>
  );
};

const H2Node: FC<PdfNodeProps<TH2Node>> = ({ node, documentConfig }) => {
  const style = pdfNodeStyles(documentConfig)[node.type];
  return (
    <Text style={style}>
      <ChildNodes childNodes={node.children} documentConfig={documentConfig} />
    </Text>
  );
};

const H3Node: FC<PdfNodeProps<TH3Node>> = ({ node, documentConfig }) => {
  const style = pdfNodeStyles(documentConfig)[node.type];
  return (
    <Text style={style}>
      <ChildNodes childNodes={node.children} documentConfig={documentConfig} />
    </Text>
  );
};

const H4Node: FC<PdfNodeProps<TH4Node>> = ({ node, documentConfig }) => {
  const style = pdfNodeStyles(documentConfig)[node.type];
  return (
    <Text style={style}>
      <ChildNodes childNodes={node.children} documentConfig={documentConfig} />
    </Text>
  );
};

const H5Node: FC<PdfNodeProps<TH5Node>> = ({ node, documentConfig }) => {
  const style = pdfNodeStyles(documentConfig)[node.type];
  return (
    <Text style={style}>
      <ChildNodes childNodes={node.children} documentConfig={documentConfig} />
    </Text>
  );
};

const H6Node: FC<PdfNodeProps<TH6Node>> = ({ node, documentConfig }) => {
  const style = pdfNodeStyles(documentConfig)[node.type];
  return (
    <Text style={style}>
      <ChildNodes childNodes={node.children} documentConfig={documentConfig} />
    </Text>
  );
};

const BoldNode: FC<PdfNodeProps<TBoldNode>> = ({ node, documentConfig }) => {
  const style = pdfNodeStyles(documentConfig)[node.type];
  return (
    <Text style={style}>
      <ChildNodes childNodes={node.children} documentConfig={documentConfig} />
    </Text>
  );
};

const ItalicNode: FC<PdfNodeProps<TItalicNode>> = ({ node, documentConfig }) => {
  const style = pdfNodeStyles(documentConfig)[node.type];
  return (
    <Text style={style}>
      <ChildNodes childNodes={node.children} documentConfig={documentConfig} />
    </Text>
  );
};

const StrikeNode: FC<PdfNodeProps<TStrikeNode>> = ({ node, documentConfig }) => {
  const style = pdfNodeStyles(documentConfig)[node.type];
  return (
    <Text style={style}>
      <ChildNodes childNodes={node.children} documentConfig={documentConfig} />
    </Text>
  );
};

const LinkNode: FC<PdfNodeProps<TLinkNode>> = ({ node, documentConfig }) => {
  const style = pdfNodeStyles(documentConfig)[node.type];
  return (
    <Link href={node.attrs?.href ?? node.children} style={style}>
      <ChildNodes childNodes={node.children} documentConfig={documentConfig} />
    </Link>
  );
};

const BlockquoteNode: FC<PdfNodeProps<TBlockquoteNode>> = ({ node, documentConfig }) => {
  const style = pdfNodeStyles(documentConfig)[node.type];
  return (
    <Text style={style}>
      <ChildNodes childNodes={node.children} documentConfig={documentConfig} />
    </Text>
  );
};

const HardBreakNode: FC<PdfNodeProps<THardBreakNode>> = ({ node, documentConfig }) => {
  const style = pdfNodeStyles(documentConfig)[node.type];
  return (
    <Text style={style}>
      <ChildNodes childNodes={node.children} documentConfig={documentConfig} />
    </Text>
  );
};

const CodeNode: FC<PdfNodeProps<TCodeNode>> = ({ node, documentConfig }) => {
  const style = pdfNodeStyles(documentConfig)[node.type];
  return (
    <Text style={style}>
      <ChildNodes childNodes={node.children} documentConfig={documentConfig} />
    </Text>
  );
};

const UnorderedListNode: FC<PdfNodeProps<TUnorderedListNode>> = ({ node, documentConfig }) => {
  const style = pdfNodeStyles(documentConfig)[node.type];
  return (
    <View style={style}>
      <ChildNodes childNodes={node.children} documentConfig={documentConfig} />
    </View>
  );
};

const OrderedListNode: FC<PdfNodeProps<TOrderedListNode>> = ({ node, documentConfig }) => {
  const style = pdfNodeStyles(documentConfig)[node.type];
  return (
    <View style={style}>
      <ChildNodes childNodes={node.children} documentConfig={documentConfig} />
    </View>
  );
};

const ListItemNode: FC<PdfNodeProps<TListItemNode>> = ({ node, documentConfig }) => {
  const style = pdfNodeStyles(documentConfig)[node.type];
  const isOrderedList = node.context.parent === PDF_NODE_TYPE.ORDERED_LIST;
  const prefix = isOrderedList ? `${(node.context.index ?? 0) + 1}.` : '\u2022';
  return (
    <View wrap={false} style={[style, { flexDirection: 'row' }]}>
      <Text>{prefix}</Text>
      <View style={{ marginLeft: 8 }}>
        <ChildNodes childNodes={node.children} documentConfig={documentConfig} />
      </View>
    </View>
  );
};

const TableNode: FC<PdfNodeProps<TTableNode>> = ({ node, documentConfig }) => {
  const style = pdfNodeStyles(documentConfig)[node.type];
  return (
    <View style={style}>
      <ChildNodes childNodes={node.children} documentConfig={documentConfig} />
    </View>
  );
};

const TableBodyNode: FC<PdfNodeProps<TTableBodyNode>> = ({ node, documentConfig }) => {
  const style = pdfNodeStyles(documentConfig)[node.type];
  return (
    <View style={style}>
      <ChildNodes childNodes={node.children} documentConfig={documentConfig} />
    </View>
  );
};

const TableRowNode: FC<PdfNodeProps<TTableRowNode>> = ({ node, documentConfig }) => {
  const style = pdfNodeStyles(documentConfig)[node.type];
  return (
    <View style={style}>
      <ChildNodes childNodes={node.children} documentConfig={documentConfig} />
    </View>
  );
};

const TableHeaderNode: FC<PdfNodeProps<TTableHeaderNode>> = ({ node, documentConfig }) => {
  const style = pdfNodeStyles(documentConfig)[node.type];
  return (
    <View style={style}>
      <ChildNodes childNodes={node.children} documentConfig={documentConfig} />
    </View>
  );
};

const TableCellPdfNode: FC<PdfNodeProps<TTableCellNode>> = ({ node, documentConfig }) => {
  const style = pdfNodeStyles(documentConfig)[node.type];
  return (
    <View style={style}>
      <ChildNodes childNodes={node.children} documentConfig={documentConfig} />
    </View>
  );
};

/**
/**
 * The ChildNodes component is responsible for rendering the children of a PDF node.
 * It handles different types of children, including strings, arrays of nodes, and single nodes.
 *
 * @param param0 - An object containing childNodes and documentConfig.
 * @returns A React element representing the rendered children, or null if there are no children.
 */
const ChildNodes: FC<{
  childNodes?: TPdfNode[] | string | TPdfNode | null;
  documentConfig?: DocumentBrandingConfig;
}> = ({ childNodes, documentConfig }) => {
  if (!childNodes) return null;

  if (typeof childNodes === 'string') {
    return <>{childNodes}</>;
  } else if (Array.isArray(childNodes)) {
    return (
      <>
        {childNodes.map((child, index) => (
          <PdfNode node={child} key={index} documentConfig={documentConfig} />
        ))}
      </>
    );
  }

  return <PdfNode node={childNodes} documentConfig={documentConfig} />;
};
