import { PropsWithChildren } from 'react';
import { hasRequiredAccess } from 'src/lib/accessControl';
import { ACCESS_LEVEL } from 'types/graphql';

type AccessControlProps = {
  myPermission?: ACCESS_LEVEL | null;
  requiredPermission?: ACCESS_LEVEL | null;
};

export const AccessControl = ({
  myPermission,
  requiredPermission,
  children,
}: PropsWithChildren<AccessControlProps>) => {
  return hasRequiredAccess(myPermission, requiredPermission) ? <>{children}</> : null;
};
