import { useMutation, useQuery } from '@redwoodjs/web';
import {
  ACCESS_LEVEL,
  AddAccessJobMutation,
  AddAccessJobMutationVariables,
  GetJobAndDocuments,
  GetJobAndDocumentsVariables,
  RevokeAccessJobMutation,
  RevokeAccessJobMutationVariables,
  SetAccessJobMutation,
  SetAccessJobMutationVariables,
} from 'types/graphql';
import { GET_JOB_AND_DOCUMENTS } from 'src/graphql/queries/getJobAndDocumentsQuery';
import {
  ADD_ACCESS_JOB_MUTATION,
  REVOKE_ACCESS_JOB_MUTATION,
  SET_ACCESS_JOB_MUTATION,
} from 'src/graphql/mutations';
import { toast } from '@redwoodjs/web/dist/toast';
import { ShareDialog } from './ShareDialog';
import { InviteFormValues } from './inviteFormSchema';
import { getErrorMessage, isBadUserInputError } from '../../lib/errors';

export type ShareJobDialogProps = {
  jobId: string;
  onClose: () => void;
  title: string;
  description: string;
  isOwner: boolean;
};

export const ShareJobDialog = ({
  jobId,
  onClose,
  title,
  description,
  isOwner,
}: ShareJobDialogProps) => {
  const { data: jobData, previousData: jobPreviousData } = useQuery<
    GetJobAndDocuments,
    GetJobAndDocumentsVariables
  >(GET_JOB_AND_DOCUMENTS, {
    variables: { jobId },
    fetchPolicy: 'cache-only',
    returnPartialData: true,
  });

  const jobPermissionData = (jobData ?? jobPreviousData)?.job?.permissions ?? [];

  const [addJobAccess] = useMutation<AddAccessJobMutation, AddAccessJobMutationVariables>(
    ADD_ACCESS_JOB_MUTATION
  );

  const [setJobAccess] = useMutation<SetAccessJobMutation, SetAccessJobMutationVariables>(
    SET_ACCESS_JOB_MUTATION
  );

  const [revokeJobAccess] = useMutation<RevokeAccessJobMutation, RevokeAccessJobMutationVariables>(
    REVOKE_ACCESS_JOB_MUTATION
  );

  const handleAddJobAccess = async (values: InviteFormValues) => {
    const userAlreadyExists = jobPermissionData.some(
      (permission) =>
        permission.__typename === 'UserPermission' && permission.user.email === values.email
    );

    if (userAlreadyExists) {
      toast.error(`User with email ${values.email} already has access.`);
      return;
    }

    await addJobAccess({
      variables: {
        input: {
          id: jobId,
          email: values.email,
          permission: values.permission,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        addAccessJob: {
          __typename: 'Job',
          id: jobId,
          permissions: [
            ...jobPermissionData,
            {
              __typename: 'UserPermission',
              id: 'temp-id',
              user: {
                __typename: 'User',
                id: 'temp-user-id',
                name: 'temp-name',
                email: values.email,
              },
              permission: values.permission,
            },
          ],
        },
      },
      onCompleted: () => {
        toast.success(`An invite has been sent to ${values.email}`);
      },
      onError: (error) => {
        if (isBadUserInputError(error)) {
          toast.error(getErrorMessage(error));
        }
      },
    });
  };

  const handleSetJobAccessToUser = async (userEmail: string, newRole: string, userName: string) => {
    const permissionRecord = jobPermissionData.find((p) =>
      p.__typename === 'UserPermission'
        ? p.user.email === userEmail
        : p.organisation.name === userName
    );

    if (!permissionRecord) {
      toast.error('User Permission not found');
      return;
    }

    const permissionId = permissionRecord.id;

    const isRevokingAccess = newRole === 'Remove' || newRole === 'NONE';

    if (isRevokingAccess) {
      await revokeJobAccess({
        variables: {
          input: {
            user: {
              entityId: jobId,
              permissionId,
            },
          },
        },
        onCompleted: () => {
          toast.success(`Access for ${userName} has been revoked.`);
        },
        optimisticResponse: {
          __typename: 'Mutation',
          revokeAccessJob: {
            __typename: 'Job',
            id: jobId,
            permissions: [...jobPermissionData.filter((p) => p.id !== permissionId)],
          },
        },
      });
    } else {
      await setJobAccess({
        variables: {
          input: {
            user: {
              entityId: jobId,
              permissionId,
              permission: newRole as ACCESS_LEVEL,
            },
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          setAccessJob: {
            __typename: 'Job',
            id: jobId,
            permissions: jobPermissionData.map((permission) =>
              permission.id === permissionId
                ? {
                    ...permission,
                    __typename: 'UserPermission',
                    id: 'new',
                    user: {
                      __typename: 'User',
                      id: 'new',
                      email: userEmail,
                    },
                    permission: newRole as ACCESS_LEVEL,
                  }
                : permission
            ),
          },
        },
      });
    }
  };

  const handleSetJobAccessToOrganisation = async (newRole: ACCESS_LEVEL | null) => {
    const permissionRecord = jobPermissionData.find(
      (p) => p.__typename === 'OrganisationPermission'
    );

    if (!permissionRecord) {
      toast.error('Organisation Permission not found');
      return;
    }

    const permissionId = permissionRecord.id;

    await setJobAccess({
      variables: {
        input: {
          organisation: {
            entityId: jobId,
            permissionId: permissionId,
            permission: newRole,
          },
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        setAccessJob: {
          __typename: 'Job',
          id: jobId,
          permissions: jobPermissionData.map((permission) =>
            permission.id === permissionId
              ? {
                  ...permission,
                  __typename: 'OrganisationPermission',
                  id: permissionId,
                  organisationId:
                    'organisation' in permissionRecord ? permissionRecord?.organisationId : '',
                  orgPermission: newRole,
                  organisation: {
                    __typename: 'Organisation',
                    id: 'organisation' in permissionRecord ? permissionRecord?.organisationId : '',
                    name:
                      'organisation' in permissionRecord ? permissionRecord?.organisation.name : '',
                  },
                  permission: newRole,
                }
              : permission
          ),
        },
      },
      onCompleted: () => {
        toast.success(`Organisation's permission updated successfully.`);
      },
    });
  };

  return (
    <ShareDialog
      title={title}
      description={description}
      onClose={onClose}
      isOwner={isOwner}
      permissionsData={jobPermissionData}
      onAddAccess={handleAddJobAccess}
      onSetAccessToUser={handleSetJobAccessToUser}
      onSetAccessToOrganisation={handleSetJobAccessToOrganisation}
    />
  );
};
