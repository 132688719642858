import { convertHtmlToPlainText } from 'src/lib';
import { z } from 'zod';

export const SNIPPET_SCHEMA_CONSTRAINTS = {
  text: {
    minLength: 1,
    maxLength: 1500,
    requiredMessage: 'Snippet is required',
    maxLengthMessage: "Can't be longer than 1500 characters",
  },
};

/**
 * Adjustment of the `text` field validation in `snippetFormSchema`:
 *
 * The `text` field is designed to accept HTML content, which inherently includes HTML tags alongside the user-generated text. These tags contribute to the overall character count but are not visible to the user. Direct validation against a character limit that includes these tags could lead to early limit errors, confusing users as they haven't visibly reached the maximum allowed characters.
 *
 * To mitigate this, an `htmlMarkupBuffer` of 2000 characters is added to the maximum length constraint in the schema. This buffer accounts for the additional characters from HTML tags, ensuring users can utilize the full range of allowed characters for their visible text.
 *
 * On the frontend, when displaying the maximum character limit to the user, we subtract this buffer to present the original maximum length (e.g., 4000 characters). This subtraction is handled as follows:
 *
 * ```
 * const maxLengthResult = findMaxLengthOfFieldSchema(fieldSchema);
 * const maxLengthOfFieldSchema = maxLengthResult !== undefined ? maxLengthResult - 2000 : undefined;
 * ```
 *
 * This approach ensures the displayed character limit aligns with user expectations, focusing solely on the count of visible characters. The backend schema, however, accommodates the additional length from HTML markup, ensuring a seamless user experience without sacrificing the integrity of data validation.
 *
 * This methodology strikes a balance between user-friendly interface practices and robust backend validation mechanisms, addressing the unique challenges of accepting and processing HTML content.
 */

const htmlMarkupBuffer = 4500;

export const snippetFormSchema = z.object({
  name: z.string().min(1, 'Description is required'),
  text: z
    .string()
    .min(SNIPPET_SCHEMA_CONSTRAINTS.text.minLength, SNIPPET_SCHEMA_CONSTRAINTS.text.requiredMessage)

    .max(
      SNIPPET_SCHEMA_CONSTRAINTS.text.maxLength + htmlMarkupBuffer,
      SNIPPET_SCHEMA_CONSTRAINTS.text.maxLengthMessage
    )
    .refine((html) => {
      const plainText = convertHtmlToPlainText(html);

      return (
        plainText.length >= SNIPPET_SCHEMA_CONSTRAINTS.text.minLength &&
        plainText.length <= SNIPPET_SCHEMA_CONSTRAINTS.text.maxLength
      );
    }),
});

export type SnippetValues = z.infer<typeof snippetFormSchema>;
