import { FC } from 'react';

export const CandidateCvIllustration: FC<React.ComponentProps<'svg'>> = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="595.28px"
    height="595.28px"
    viewBox="0 0 595.28 595.28"
    enableBackground="new 0 0 595.28 595.28"
    xmlSpace="preserve"
    {...props}
  >
    <path
      id="color_x5F_2"
      fill="#000000"
      d="M234.14,262.331h120.09v14.041H234.14V262.331z M234.14,309.831h120.09v-14.086H234.14
	V309.831z M353.685,345.53c0.112-3.635,0.121-7.304,0.437-10.927c0.009-0.101-0.045-0.19-0.143-0.272H234.14v19.322h120.09v-7.431
	C353.906,346.134,353.672,345.929,353.685,345.53z M198.162,230.165c0.046-3.885,0.093-7.77,0.143-11.654
	c0.001-0.066,0.025-0.129,0.058-0.189c-0.141-0.146-0.237-0.336-0.253-0.583c-0.166-2.651-0.611-5.257-1.654-7.441
	c-0.091,0.015-0.183,0.028-0.273,0.042c-0.517,0.078-0.737-0.709-0.218-0.789c0.03-0.005,0.061-0.01,0.091-0.014
	c-0.549-0.957-1.23-1.816-2.086-2.533c-2.83-1.172-6.553-2.299-9.251-2.989c-2.038-0.268-4.1-0.351-6.067-0.128
	c0.54,1.2,0.813,2.467,0.543,3.617c-0.002,0.95-0.015,1.898-0.021,2.848c0.025,0.064,0.042,0.134,0.038,0.215
	c-0.019,0.419-0.032,0.838-0.049,1.257c-0.029,3.032-0.07,6.063-0.128,9.095c-0.003,0.168-0.096,0.32-0.241,0.454
	c-0.098,2.776-0.191,5.553-0.284,8.329c-0.018,0.546-1.13,0.993-1.969,0.992v0.18h23.889l0.01-0.861
	C199.585,230.396,198.157,230.499,198.162,230.165z M160.485,230.085c-0.016-3.618-0.041-7.236-0.06-10.854
	c-0.063-0.036-0.104-0.081-0.104-0.14c-0.018-3.715-0.031-7.429-0.033-11.143c0-0.096,0.04-0.184,0.102-0.269
	c-1.952-1.547-3.616-3.345-4.779-5.653c-6.3,1.69-13.769,2-19.665,4.938c-0.766,0.403-1.511,0.839-2.229,1.308
	c-0.244,0.166-0.493,0.323-0.729,0.503c-6.544,4.969-6.084,13.329-6.574,20.743c-0.03,0.448-0.381,0.788-0.829,0.988
	c-0.006,0.122-0.013,0.243-0.019,0.366h37.07v-0.889C161.778,230.321,160.486,230.405,160.485,230.085z M488.975,442.45
	l-6.514-9.441C484.567,436.198,486.733,439.353,488.975,442.45z M503.292,463.93c-12.582-12.753-22.767-27.954-32.012-43.235
	c-0.886-1.465-4.33-5.44-4.076-7.205c0.371-2.582,0.724-5.168,1.067-7.754c1.599-12.016,3.067-24.064,4.046-36.148
	c0.472-5.816,0.94-11.684,0.818-17.523c-0.022-1.063,0.239-7.322-1.244-7.936c-2.187,0.498-4.367,0.818-6.348,1.039
	c-8.496,0.943-20.476,1.683-26.537-5.859c-1.938-2.412-2.411-5.191-2.448-8.098l-12.912,0.078c-0.547,4.439-2.427,8.279-7.401,9.832
	c-4.646,1.451-9.958-1.308-13.714-3.957c-0.125,0.025-0.249,0.043-0.37,0.055c-0.019,0.164-0.085,0.346-0.219,0.549
	c-3.963,5.994-8.72,11.16-13.803,16.052v0.511h-0.542c-2.839,2.71-5.768,5.346-8.719,7.992
	c-8.393,7.528-16.332,15.779-19.596,26.885c-7.84,26.676,2.271,54.543,2.415,81.512c0.001,0.088-0.015,0.172-0.038,0.252
	c0.268-0.027,0.518-0.021,0.715,0.025l3.209-0.061c1.076-0.057,2.313-0.068,3.453-0.065l5.478-0.103
	c3.43-0.18,6.859-0.345,10.293-0.453c2.896-0.082,5.793-0.177,8.688-0.277c0.06-1.311,0.003-2.631-0.044-3.938
	c-0.422-11.643-1.391-23.274-2.298-34.887c-0.655-8.379-1.831-16.472,0.822-24.633c2.267-6.975,5.793-13.486,10.071-19.432
	c7.924-11.013,17.685-20.987,28.746-28.883c0.314-1.117,2.958-1.457,2.897-0.055c-0.626,14.473-0.292,28.97,1.049,43.396
	c0.519,5.571,1.138,11.131,1.86,16.68c0.399,3.072,0.752,5.114,2.471,7.693c5.28,7.921,11.715,15.128,17.878,22.359
	c8.76,10.277,17.6,20.486,26.301,30.814c6.413-4.801,13.851-8.35,19.477-14.027c-0.056-0.379,0.273-0.761,0.736-1.02
	c0.008-0.016,0.017-0.035,0.023-0.05C503.412,464.022,503.345,463.983,503.292,463.93z M377.453,340.891
	c0.246-0.177,0.525-0.378,0.835-0.603c-0.207,0.082-0.496,0.137-0.803,0.158C377.474,340.594,377.469,340.745,377.453,340.891z
	 M366.617,233.958c8.082,5.535,16.431,10.705,24.978,15.491c8.114,4.544,16.304,8.989,25.643,10.391
	c6.967,1.046,13.807,0.818,20.514-0.284c0.394-0.178,0.873-0.265,1.282-0.223c8.611-1.563,17-4.541,25.146-8.148
	c1.528-0.678,3.127,1.039,1.318,1.84c-8.146,3.607-16.762,6.811-25.603,8.541c-0.123,15.4-0.165,30.803-0.183,46.203
	c0.307,0.053,0.481,0.181,0.371,0.401c-0.112,0.226-0.249,0.433-0.372,0.651c-0.005,4.336-0.018,8.672-0.019,13.009
	c-0.001,2.501-0.431,6.063-0.267,9.362c0.138,2.75,0.688,5.318,2.258,6.928c3.319,3.401,7.692,5.326,12.442,5.563
	c2.208,0.11,5.01,0.203,8.007,0.104c3.305-0.108,6.845-0.452,10.09-1.265c0.343-0.086,0.682-0.176,1.017-0.272
	c4.917-1.415,8.985-4.015,10.221-8.675c0.062-0.231,0.247-0.43,0.491-0.591c-0.595-8.828-0.315-17.78-0.39-26.62
	c-0.124-14.592-0.215-29.184-0.427-43.773c-0.148-10.178-0.391-19.987-1.93-30.048c-0.636-4.153-0.901-10.202-3.589-13.669
	c-2.275-2.936-5.684-4.744-9.075-6.115c-7.203-2.911-14.797-4.443-22.291-6.362c-0.139,0.012-0.277,0.026-0.418,0.05
	c-1.381,2.699-3.356,4.822-5.645,6.656c-0.002,4.338-0.019,8.676-0.037,13.014c6.509-1.693,12.971-3.895,19.433-6.008
	c1.753-0.572,2.189,1.188,0.469,1.75c-17.601,5.754-36.47,12.861-55.119,7.391c-13.132-3.853-23.823-12.293-34.18-21.01
	c-2.808-2.248-4.781-3.865-4.781-3.865s-1.213,0.338-2.901,0.928c-6.005,2.807-10.938,8.028-12.746,14.448
	c-0.019,0.065-0.059,0.126-0.107,0.187c0.033,0.039,0.068,0.075,0.097,0.121C353.86,225.907,361.119,230.194,366.617,233.958z
	 M400.142,303.19c-0.002-0.203,0.124-0.383,0.319-0.539c-0.279-10.365-0.578-20.731-0.814-31.099
	c-2.839,7.96-6.857,15.501-11.406,22.614c-5.207,7.887-11.488,16.46-19.937,21.21c5.279,5.55,9.407,15.849,9.253,23.784
	C390.835,332.304,400.265,318.319,400.142,303.19z M417.886,229.02c-0.091-5.058-0.162-10.116-0.172-15.175
	c0-0.002,0.001-0.004,0.001-0.005c-0.417-1.564,0.088-3.349,0.913-4.94c-5.133-0.719-10.97,1.074-13.636,5.881
	c-1.886,3.398-2.449,7.035-2.669,10.868c-0.009,0.146-0.058,0.28-0.131,0.403c0.914,0.328,1.835,0.642,2.771,0.916
	C409.319,228.245,413.623,228.873,417.886,229.02z M404.215,335.089c2.58,1.885,5.709,3.893,8.999,4.112
	c5.007,0.335,7.292-3.646,7.689-7.897c0.053-0.565,0.075-1.135,0.063-1.701c-0.149-7.068-0.364-14.135-0.5-21.205
	c-0.292-15.325-0.598-30.651-1.272-45.965c-6.002-0.779-11.523-2.433-16.901-4.707c0.018,1.111,0.038,2.223,0.057,3.334
	c0.142,0.078,0.214,0.197,0.179,0.365c-0.05,0.236-0.108,0.472-0.16,0.707c0.441,24.277,1.413,48.541,1.663,72.82
	c0,0.012,0,0.023,0,0.035C404.096,335.017,404.158,335.048,404.215,335.089z"
    ></path>
    <path
      id="color_x5F_1"
      d="M190.69,173.507c0.796,1.896,1.719,4.344,1.266,6.436c-0.5,2.31-2.738,3.144-4.909,3.027
	c-1.963-0.106-0.053-1.792,1.104-1.73c2.282,0.123,0.079-5.912-0.359-6.955C187.385,173.315,190.242,172.44,190.69,173.507z
	 M182.025,169.714c-0.473,0.051-1.628,0.4-1.628,1.053c0,0.82,0,1.642,0,2.463c0,0.676,0.728,0.799,1.283,0.74
	c0.473-0.051,1.628-0.4,1.628-1.053c0-0.82,0-1.642,0-2.463C183.308,169.778,182.581,169.655,182.025,169.714z M193.52,169.714
	c-0.473,0.051-1.628,0.4-1.628,1.053c0,0.82,0,1.642,0,2.463c0,0.676,0.728,0.799,1.283,0.74c0.473-0.051,1.628-0.4,1.628-1.053
	c0-0.82,0-1.642,0-2.463C194.802,169.778,194.075,169.655,193.52,169.714z M183.208,164.606c-1.231,0-2.463,0-3.694,0
	c-0.642,0-1.514,0.487-1.593,1.184c-0.087,0.764,0.767,0.973,1.345,0.973c1.231,0,2.463,0,3.694,0c0.642,0,1.514-0.487,1.593-1.184
	C184.64,164.815,183.786,164.606,183.208,164.606z M179.091,180.301c-0.738-0.737-3.483,0.353-2.688,1.146
	c0.274,0.273,0.548,0.546,0.821,0.82c0.737,0.737,3.483-0.351,2.688-1.146C179.639,180.848,179.365,180.574,179.091,180.301z
	 M123.474,229.727c0.512-7.741,0.185-17.522,7.581-22.313c7.237-4.688,15.788-5.098,23.877-7.368
	c0.141-0.479,0.729-0.829,1.351-0.929c0.937-1.959,2.136-3.787,3.259-5.643c0.323-0.535,0.958-0.816,1.58-0.85
	c0.621-1.77,0.76-3.674,0.789-5.562c-1.013,0.39-2.153,0.65-2.034-0.044c-3.21-3.635-6.497-7.814-7.131-12.787
	c-0.521-4.086,0.158-8.021,1.151-11.942c0.141-0.786,0.345-1.556,0.609-2.3c0.019-0.068,0.037-0.137,0.056-0.205
	c0.014-0.051,0.045-0.101,0.084-0.148c1.554-4.044,4.847-7.312,9.011-8.532c9.373-2.749,18.182-7.321,28.206-6.006
	c6.063,0.796,14.784,8.011,9.313,14.574c-0.938,1.126-2.148,2.026-3.492,2.63c0.164,8.266,2.019,19.985-3.302,26.886
	c-1.955,2.536-4.716,4.378-8.059,4.138c-2.827-0.201-6.876-1.463-7.851,2.289c-0.546,2.104-0.796,4.223-0.88,6.38
	c4.911-0.759,10.597-0.184,15.237,1.47c6.214,2.215,7.828,7.903,8.213,14.046c0.002,0.026-0.005,0.05-0.006,0.074
	c0.102,0.033,0.166,0.081,0.165,0.149c-0.049,3.885-0.096,7.77-0.143,11.654c-0.003,0.251-0.262,0.462-0.622,0.623
	c-0.853,0.385-2.28,0.488-2.276,0.154c0.046-3.885,0.093-7.77,0.143-11.654c0.001-0.066,0.025-0.129,0.058-0.189
	c-0.141-0.146-0.237-0.336-0.253-0.583c-0.166-2.651-0.611-5.257-1.654-7.441c-0.125-0.261-0.258-0.514-0.4-0.761
	c-0.549-0.957-1.23-1.816-2.086-2.533c-1.005-0.842-2.241-1.496-3.766-1.895c-1.732-0.453-3.597-0.846-5.485-1.095
	c-2.038-0.268-4.1-0.351-6.067-0.128c0.54,1.2,0.813,2.467,0.543,3.617c-0.002,0.95-0.015,1.898-0.021,2.848
	c0.025,0.064,0.042,0.134,0.038,0.215c-0.019,0.419-0.032,0.838-0.049,1.257c-0.029,3.032-0.07,6.063-0.128,9.095
	c-0.003,0.168-0.096,0.32-0.241,0.454c-0.098,2.776-0.191,5.553-0.284,8.329c-0.018,0.546-1.13,0.993-1.969,0.992
	c-0.519,0-0.934-0.171-0.92-0.598c0.183-5.475,0.371-10.949,0.596-16.422c-1.178,1.452-2.757,2.492-4.81,2.484
	c-0.599-0.002-1.138-0.117-1.648-0.283c-0.106,4.549-0.253,9.098-0.398,13.646c-0.03,0.948-2.935,1.557-2.896,0.367
	c0.167-5.223,0.347-10.446,0.45-15.672c0.002-0.09,0.037-0.176,0.086-0.26c-0.698-0.82-1.265-1.795-1.723-2.805
	c-0.034-0.074-0.035-0.15-0.021-0.225c-0.038-0.02-0.078-0.032-0.116-0.055c-0.633-0.381-1.254-0.767-1.865-1.158
	c0.049,6.531,0.08,13.063,0.108,19.594c0.001,0.276-0.32,0.507-0.747,0.676c-0.858,0.338-2.149,0.422-2.151,0.102
	c-0.016-3.618-0.041-7.236-0.06-10.854c-0.063-0.036-0.104-0.081-0.104-0.14c-0.018-3.715-0.031-7.429-0.033-11.143
	c0-0.096,0.04-0.184,0.102-0.269c-1.952-1.547-3.616-3.345-4.779-5.653c-6.3,1.69-13.769,2-19.665,4.938
	c-0.773,0.386-1.516,0.822-2.229,1.308c-0.244,0.166-0.493,0.323-0.729,0.503c-6.544,4.969-6.084,13.329-6.574,20.743
	c-0.03,0.448-0.381,0.788-0.829,0.988C124.69,230.907,123.406,230.747,123.474,229.727z M176.014,210.27
	c-0.223-0.022-0.435-0.091-0.614-0.231c-1.76-1.387-2.74-3.203-3.207-5.375c-0.005-0.025-0.002-0.05-0.006-0.076
	c-1.43,1.846-2.788,3.746-4.357,5.475c0.154,0.068,0.277,0.167,0.343,0.313c0.707,1.557,1.82,3.532,3.585,4.088
	c1.733,0.545,2.854-2.311,3.334-3.509C175.21,210.661,175.578,210.427,176.014,210.27z M175.175,204.233
	c0.257,1.053,0.642,2.016,1.246,2.855c0.081-0.926-0.392-1.986-0.885-2.84C175.405,204.255,175.285,204.249,175.175,204.233z
	 M194.938,166.763c-0.845,0-1.69,0-2.535,0c-0.578,0-1.432-0.209-1.345-0.973c0.08-0.696,0.952-1.184,1.593-1.184
	c0.73,0,1.46,0,2.189,0c-0.023-0.55-0.043-1.064-0.055-1.527c-0.116,0.01-0.223,0.026-0.351,0.027
	c-0.038,0.002-0.076,0.01-0.114,0.011c-5.485,0.218-10.151-2.473-15.304-2.758c-1.861,0.243-3.368,0.371-4.44,0.438
	c-0.192,0.051-0.389,0.119-0.583,0.18c0.98,4.701-0.354,10.938-4.093,14.061c-0.316,0.264-0.933,0.393-1.499,0.391
	c-0.057,0.02-0.113,0.039-0.172,0.059c-0.084,0.065-0.142,0.107-0.142,0.107s-0.022-0.02-0.054-0.048
	c-0.724,0.203-1.495,0.265-1.587-0.032c-0.285-0.911-0.802-1.676-1.407-2.164c-0.28-0.169-0.567-0.33-0.859-0.48
	c-0.966-0.298-1.989,0.156-2.624,1.824c-1.087,2.861,0.783,5.002,2.608,6.936c0.87,0.922,0.965,1.938,0.6,2.877
	c0.045,0.098,0.076,0.204,0.076,0.332c-0.002,3.026-0.034,6.07-1.321,8.87c-0.071,0.154-0.201,0.286-0.36,0.399
	c2.789,3.586,6.651,8.976,11.504,8.917c0.012-3.128,0.015-8.124,2.539-9.997c2.596-1.928,4.996-1.926,8.113-1.508
	C195.869,192.935,195.374,176.337,194.938,166.763z M158.267,200.702c1.634,3.491,4.594,5.692,7.813,7.684
	c1.313-1.546,2.5-3.192,3.746-4.795c-3.548-1.359-6.328-4.635-8.82-7.767c-0.89,1.445-1.754,2.907-2.409,4.474
	C158.532,200.453,158.414,200.587,158.267,200.702z M407.301,181.483c-4.391,0.234-1.572-7.376-0.74-9.357
	c0.415-0.988-2.488-0.653-2.904,0.336c-0.988,2.353-1.797,4.896-1.59,7.476c0.213,2.659,2.568,3.368,4.863,3.245
	C408.405,183.104,409.301,181.376,407.301,181.483z M414.315,167.382c-0.474,0.051-1.629,0.4-1.629,1.053c0,1.004,0,2.008,0,3.012
	c0,0.676,0.728,0.799,1.283,0.74c0.474-0.051,1.629-0.4,1.629-1.053c0-1.004,0-2.008,0-3.012
	C415.599,167.446,414.871,167.323,414.315,167.382z M400.262,167.382c-0.474,0.051-1.629,0.4-1.629,1.053c0,1.004,0,2.008,0,3.012
	c0,0.676,0.729,0.799,1.283,0.74c0.474-0.051,1.629-0.4,1.629-1.053c0-1.004,0-2.008,0-3.012
	C401.545,167.446,400.816,167.323,400.262,167.382z M418.623,162.149c0.088-0.764-0.767-0.973-1.345-0.973c-1.506,0-3.012,0-4.518,0
	c-0.642,0-1.514,0.487-1.593,1.184c-0.088,0.764,0.767,0.973,1.345,0.973c1.506,0,3.012,0,4.518,0
	C417.672,163.333,418.544,162.846,418.623,162.149z M420.045,180.024c-0.655-0.144-1.565,0.065-2.049,0.549
	c-0.335,0.334-0.67,0.669-1.004,1.004c-0.428,0.427-0.342,0.921,0.283,1.057c0.655,0.144,1.565-0.065,2.049-0.549
	c0.335-0.334,0.67-0.669,1.004-1.004C420.756,180.654,420.67,180.16,420.045,180.024z M231.14,292.745h126.09v20.086H231.14V292.745
	z M234.14,309.831h120.09v-14.086H234.14V309.831z M231.14,259.331h126.09v20.086H231.14V259.331z M354.229,276.372v-14.041H234.14
	v14.041v0.045h120.09V276.372z M510.66,483.063c-5.289,4.828-12.494,10.969-20.025,10.904c-5.938-0.05-11.92-0.287-17.811-1.068
	c-3.109-0.412-9.508-0.273-10.439-4.205c-1.248-5.264,13.832-7.176,18.301-8.17c-9.426-11.188-19.035-22.219-28.483-33.389
	c-5.764-6.813-12.562-13.75-16.861-21.646c-1.521-2.792-1.58-5.877-1.965-9c-0.704-5.711-1.339-11.433-1.799-17.17
	c-1.034-12.905-1.361-25.828-0.891-38.766c-13.077,9.71-25.282,22.782-32.406,37.332c-4.794,9.791-5.347,18.348-4.501,29.08
	c0.705,8.947,1.396,17.896,1.96,26.854c0.329,5.225,1.512,11.424,0.603,16.667c-0.174,1.007-1.584,1.465-2.507,1.198
	c-0.073,0.002-0.168,0.004-0.3,0.009c-0.591,0.021-1.182,0.041-1.771,0.062c-2.69,0.089-5.381,0.172-8.071,0.25
	c-5.86,0.184-11.709,0.588-17.566,0.832c-0.369,0.016-0.813,0.061-1.284,0.107c0.279,0.16,0.201,0.449-0.688,0.81
	c-7.62,3.089-16.984,3.517-23.787,8.378c-6.977,4.986-1.004,8.311,4.617,9.096c0.265,0.037,0.53,0.061,0.795,0.094h21.818
	c6.057-0.535,12.862-0.952,18.527-3.331c0.185-0.077,0.387-0.129,0.588-0.16l-1.565-15.353l2.984-0.305l2.257,22.148H81.236
	l-2.104-393.449H388.14v117.832c3.61,2.435,7.362,4.582,11.312,6.264c0.404-5.384,1.738-11.087,6.274-14.521
	c4.091-3.096,8.906-4.369,13.956-3.613c-0.072-2.381-0.279-4.828-0.999-7.056c-1.576-4.882-4.819-4.428-9.276-4.047
	c-4.468,0.382-8.266-0.706-10.688-4.813c-5.577-9.461-3.708-21.885-3.6-32.414c-1.8-0.516-3.415-1.5-4.482-3.204
	c-5.208-8.312,3.136-15.651,10.663-17.642c11.568-3.058,21.525,2.867,32.302,5.76c5.031,1.35,9.126,2.467,11.604,7.371
	c0.483,0.955,1.077,2.105,1.615,3.324c0.208,0.027,0.356,0.096,0.393,0.225c1.615,5.778,3.38,12.172,2.077,18.199
	c-1.274,5.896-4.666,10.602-8.597,15.039c0.054,0.904-1.339,1.188-2.467,1.051c0.078,2.336,0.354,4.65,1.239,6.829
	c0.63-0.192,1.33-0.142,1.606,0.315c1.502,2.488,3.113,4.934,4.315,7.586c0.742-0.042,1.439,0.277,1.329,0.994
	c7.689,2.016,15.574,3.459,22.97,6.448c7.955,3.215,11.891,6.931,13.491,15.481c1.985,10.611,2.619,20.725,2.798,31.47
	c0.25,15.093,0.346,30.188,0.471,45.283c0.082,9.792-0.263,19.722,0.421,29.497c0.024,0.355-0.208,0.66-0.548,0.89
	c-1.502,5.374-6.287,8.298-11.549,9.937c1.104,1.357,1.092,3.941,1.171,5.424c0.264,4.92,0.015,9.873-0.313,14.783
	c-0.855,12.793-2.346,25.547-4,38.259c-0.465,3.571-0.941,7.143-1.45,10.708c-0.235,1.654,2.771,5.362,3.798,7.049
	c2.74,4.503,5.557,8.998,8.484,13.43c2.106,3.189,4.272,6.344,6.514,9.441c5.19,7.173,10.781,14.04,16.969,20.318
	c0.816-0.096,1.546,0.037,1.117,0.541c-0.332,0.391-0.68,0.762-1.027,1.134c3.475,2.184,9.268,4.491,10.004,8.764
	C516.706,477.093,513.259,480.691,510.66,483.063z M388.14,334.372v15.865c4.198-4.125,8.144-8.457,11.373-13.342
	c0.325-0.492,1.027-0.682,1.628-0.611c-0.068-6.898-0.195-13.797-0.354-20.694c-2.6,7.294-7.299,13.796-13.305,18.782H388.14z
	 M234.14,334.331v19.322v0.178h120.09v-0.178v-7.431c-0.323-0.089-0.558-0.294-0.545-0.692c0.112-3.635,0.121-7.304,0.437-10.927
	c0.009-0.101-0.045-0.19-0.143-0.272H234.14z M357.229,342.483c1.883-1.515,3.739-3.062,5.528-4.679
	c2.869-2.593,8.901-6.983,8.927-11.274c0.001-0.078,0.021-0.152,0.05-0.226c-1.445-3.483-3.331-6.71-5.437-9.165
	c-0.113,0.205-0.335,0.404-0.708,0.572c-7.372,3.314-14.14,8.305-20.275,13.619h11.915v2.643v0.754V342.483z M347.628,219.673
	c2.486-7.992,9.525-13.719,17.293-16.36c-1.229-1.035-2.463-2.057-3.698-3.069c-0.688,0.078-1.313-0.099-1.24-0.668
	c0.014-0.107,0.009-0.221,0.019-0.33c-0.122-0.099-0.242-0.199-0.364-0.298c-0.628-0.506-0.246-1.085,0.391-1.437
	c-0.072-1.333-0.332-2.681-0.763-3.885c-0.211-0.59-0.51-1.512-1.057-1.934c-0.358,0.375-0.659,0.787-0.941,1.23
	c-0.056,0.088-0.143,0.167-0.245,0.241c0.092,0.084,0.185,0.169,0.275,0.253c0.999,0.93-1.738,2.109-2.66,1.254
	c-3.865-3.594-7.945-7.069-10.21-11.947c0,0,0,0,0-0.001c-1.13-0.342-2.33-0.877-2.339,0.601c-0.008,1.23,0.482,2.494,1.024,3.581
	c1.174,2.353,2.965,4.997,5.396,6.181c1.334,0.648-1.07,2.012-2.162,1.622c-0.539,0.368-1.232,0.499-1.869,0.433
	c-2.859-0.303-3.43,2.553-3.177,4.877c0.066,0.614-0.9,1.083-1.735,1.188c-0.228,0.166-0.506,0.289-0.828,0.331
	c-2.766,0.356-3.307,2.542-2.324,4.362c0.289,0.056,0.426,0.19,0.265,0.426c0.325,0.465,0.75,0.894,1.27,1.24
	c1.418,0.947-1.071,2.509-2.215,1.746c-0.266-0.178-0.52-0.376-0.763-0.588C333.771,213.33,344.029,218.063,347.628,219.673z
	 M436.792,309.282c0.002-2.162,0.001-4.324,0.005-6.486c0.022-13.572,0.075-27.145,0.183-40.718
	c-4.896,0.767-9.852,1.048-14.828,0.657c0.653,15.276,0.738,30.572,1.085,45.855c0.402,0.004,0.737,0.115,0.853,0.393
	c1.152,2.768,3.325,5.074,6.003,6.441C432.77,313.995,435.266,311.89,436.792,309.282z M402.293,257.726
	c0.018,1.111,0.038,2.223,0.057,3.334c0.142,0.078,0.214,0.197,0.179,0.365c-0.05,0.236-0.108,0.472-0.16,0.707
	c0.441,24.277,1.413,48.541,1.663,72.82c0,0.012,0,0.023,0,0.035c0.064,0.029,0.127,0.061,0.184,0.102
	c2.58,1.885,5.709,3.893,8.999,4.112c5.007,0.335,7.292-3.646,7.689-7.897c0.053-0.565,0.075-1.135,0.063-1.701
	c-0.149-7.068-0.364-14.135-0.5-21.205c-0.292-15.325-0.598-30.651-1.272-45.965C413.192,261.653,407.671,260,402.293,257.726z
	 M388.14,290.077c5.056-8.542,9.175-17.7,11.326-27.406c-0.037-2.083-0.077-4.166-0.106-6.25c-2.105-0.982-4.198-2.05-6.299-3.195
	c-1.649-0.898-3.284-1.828-4.921-2.754V290.077z M377.557,339.161c13.278-6.857,22.708-20.842,22.585-35.971
	c-0.002-0.203,0.124-0.383,0.319-0.539c-0.279-10.365-0.578-20.731-0.814-31.099c-2.839,7.96-6.857,15.501-11.406,22.614
	c-5.207,7.887-11.488,16.46-19.937,21.21C373.583,320.927,377.711,331.226,377.557,339.161z M423.798,329.581
	c4.138,0,8.275,0,12.414,0c0.145,0,0.261,0.011,0.364,0.027c0.055-1.834,0.204-3.7,0.204-5.541c0.001-3.837,0.006-7.674,0.009-11.51
	c-1.905,1.836-4.201,3.304-6.575,4.41c-2.288,1.066-4.972-1.256-6.856-3.688c0.115,4.297,0.251,8.592,0.441,12.885
	C423.85,327.312,423.856,328.458,423.798,329.581z M462.133,343.787c3.305-0.108,6.845-0.452,10.09-1.265
	c0.343-0.086,0.682-0.176,1.017-0.272c4.917-1.415,8.985-4.015,10.221-8.675c0.062-0.231,0.247-0.43,0.491-0.591
	c-0.595-8.828-0.315-17.78-0.39-26.62c-0.124-14.592-0.215-29.184-0.427-43.773c-0.148-10.178-0.391-19.987-1.93-30.048
	c-0.636-4.153-0.901-10.202-3.589-13.669c-2.275-2.936-5.684-4.744-9.075-6.115c-7.203-2.911-14.797-4.443-22.291-6.362
	c-0.116-0.03-0.232-0.061-0.348-0.091c-0.023,0.047-0.046,0.094-0.07,0.141c-1.381,2.699-3.356,4.822-5.645,6.656
	c-0.002,4.338-0.019,8.676-0.037,13.014c6.509-1.693,12.971-3.895,19.433-6.008c1.753-0.572,2.189,1.188,0.469,1.75
	c-17.601,5.754-36.47,12.861-55.119,7.391c-13.132-3.853-23.823-12.293-34.18-21.01c-1.608-1.355-3.212-2.713-4.813-4.065
	c-0.978,0.314-1.937,0.691-2.87,1.128c-6.005,2.807-10.938,8.028-12.746,14.448c-0.019,0.065-0.059,0.126-0.107,0.187
	c0.033,0.039,0.068,0.075,0.097,0.121c3.547,5.85,10.806,10.137,16.304,13.9c8.082,5.535,16.431,10.705,24.978,15.491
	c8.114,4.544,16.304,8.989,25.643,10.391c6.967,1.046,13.807,0.818,20.514-0.284c0.394-0.178,0.873-0.265,1.282-0.223
	c8.611-1.563,17-4.541,25.146-8.148c1.528-0.678,3.127,1.039,1.318,1.84c-8.146,3.607-16.762,6.811-25.603,8.541
	c-0.123,15.4-0.165,30.803-0.183,46.203c0.307,0.053,0.481,0.181,0.371,0.401c-0.112,0.226-0.249,0.433-0.372,0.651
	c-0.005,4.336-0.018,8.672-0.019,13.009c-0.001,2.501-0.431,6.063-0.267,9.362c0.138,2.75,0.688,5.318,2.258,6.928
	c3.319,3.401,7.692,5.326,12.442,5.563C456.334,343.793,459.136,343.886,462.133,343.787z M402.192,226.052
	c0.914,0.328,1.835,0.642,2.771,0.916c4.355,1.277,8.659,1.905,12.922,2.052c-0.091-5.058-0.162-10.116-0.172-15.175
	c0-0.002,0.001-0.004,0.001-0.005c-0.417-1.564,0.088-3.349,0.913-4.94c-5.133-0.719-10.97,1.074-13.636,5.881
	c-1.886,3.398-2.449,7.035-2.669,10.868C402.314,225.795,402.266,225.929,402.192,226.052z M425.686,209.044
	c-0.008,0.002-0.016,0.003-0.023,0.004c0.01,0.086,0.01,0.179-0.013,0.283c-0.589,2.747-1.789,5.039-4.02,6.796
	c-0.269,0.211-0.626,0.35-0.992,0.394c0.001,0.029,0.001,0.059,0.001,0.089c0.639-0.126,1.279-0.053,1.45,0.374
	c0.77,1.923,2.178,4.96,4.602,5.209c2.175,0.223,3.78-3.037,4.503-4.63c0.097-0.214,0.331-0.393,0.624-0.533
	c-2.178-2.323-3.989-4.94-5.948-7.444C425.725,209.4,425.676,209.218,425.686,209.044z M437.285,215.208
	c-1.048,0.695-2.128,1.364-3.223,2.023c-0.574,1.255-1.277,2.448-2.139,3.468c0.114,0.151,0.188,0.338,0.188,0.575
	c0,1.666,0,3.333,0,5c0,1.365-2.943,1.766-2.943,0.195c0-1.174,0-2.348,0-3.521c-0.818,0.421-1.725,0.71-2.734,0.818
	c-2.593,0.278-4.453-1.195-5.766-3.146c0.033,2.811,0.076,5.621,0.129,8.43c5.54-0.087,11.016-0.943,16.455-2.217
	C437.27,222.958,437.281,219.083,437.285,215.208z M422.812,209.294c-0.456-0.014-0.921-0.053-1.394-0.12
	c-0.648,1.197-1.147,2.569-0.865,3.757c0.035,0.039,0.06,0.083,0.06,0.137c0,0.03,0.001,0.061,0.001,0.092
	c0.059,0.178,0.143,0.35,0.243,0.516c0.965-1.17,1.524-2.572,1.857-4.125C422.733,209.46,422.769,209.375,422.812,209.294z
	 M439.271,198.407c-3.048,3.797-6.559,7.842-10.882,9.76c0.045,0.039,0.091,0.077,0.128,0.125c1.726,2.205,3.35,4.49,5.197,6.594
	c3.995-2.463,7.617-5.128,9.641-9.43c-0.03-0.054-0.049-0.109-0.049-0.168c-0.224-0.095-0.401-0.25-0.497-0.479
	C441.867,202.556,440.561,200.48,439.271,198.407z M422.617,207.854c0.092-0.006,0.177-0.006,0.241,0.008
	c6.396,1.363,11.315-6.087,14.813-10.55c-0.395-0.046-0.733-0.207-0.885-0.536c-1.551-3.372-1.515-7.149-1.517-10.789
	c0-0.053,0.013-0.103,0.024-0.152c-0.396-1.078-0.235-2.213,0.848-3.361c2.098-2.223,4.735-5.15,3.11-8.482
	c-0.621-1.274-1.608-2.421-2.95-2.976c-0.1-0.041-0.193-0.071-0.288-0.104c-1.145,0.558-2.26,1.294-3.15,1.949
	c-0.082,0.214-0.158,0.438-0.229,0.667c-0.104,0.334-0.804,0.578-1.485,0.692c-0.11,0.096-0.175,0.153-0.175,0.153
	s-0.06-0.044-0.144-0.108c-0.543,0.058-1.001,0.02-1.042-0.139c-0.364,0.019-0.7-0.046-0.92-0.229
	c-4.501-3.762-6.006-11.432-4.745-17.031c-0.041-0.006-0.082-0.013-0.115-0.025c-0.714-0.269-1.405-0.469-2.088-0.639
	c-1.242-0.1-2.793-0.253-4.613-0.496c-0.131-0.018-0.279-0.017-0.418-0.027c-5.227,0.193-9.912,2.438-15.566,3.125
	c-1.352,0.258-2.51,0.38-3.322,0.238c-0.013,0.711-0.028,1.423-0.047,2.135c1.088,0,2.175,0,3.263,0
	c0.578,0,1.433,0.209,1.345,0.973c-0.079,0.696-0.951,1.184-1.593,1.184c-1.025,0-2.05,0-3.075,0
	c-0.116,3.96-0.23,7.922,0.028,11.875c0.348,5.307,0.866,12.345,4.823,16.393c5.041,5.158,11.11-0.397,16.26,3.226
	C422.662,197.401,422.578,203.797,422.617,207.854z M377.724,491.321h9.345l-0.249-2.443
	C383.96,490.04,380.873,490.793,377.724,491.321z M362.885,473.081c-1.139,0.023-2.248-0.104-2.976-0.668
	c-0.104-0.114-0.14-0.247-0.106-0.398c0.016-0.039,0.03-0.077,0.045-0.115c-0.584-0.042-1.084-0.336-1.087-0.967
	c-0.134-25.282-9.618-52.271-3.551-77.436c0.065-0.271,0.14-0.543,0.209-0.814c0.326-1.277,0.68-2.553,1.091-3.82
	c4.947-15.26,17.3-25.061,28.63-35.741V336.19c-2.132,1.547-4.397,2.915-6.774,4.067c-0.022,0.011-0.052,0.021-0.077,0.03
	c-0.207,0.082-0.496,0.137-0.803,0.158c-0.012,0.147-0.017,0.299-0.032,0.444c-0.006,0.056-0.006,0.115-0.013,0.171
	c-0.097,0.829-2.932,1.062-2.898,0.777c0.401-3.428-0.172-7.525-1.39-11.555c-1.911,2.933-5.273,5.627-7.255,7.417
	c-2.797,2.527-5.688,4.957-8.668,7.27v11.86H231.14v-25.5h110.743c6.713-5.967,14.21-11.638,22.424-15.33
	c0.085-0.038,0.17-0.067,0.256-0.092c0.074-0.105,0.232-0.227,0.522-0.37c0.118-0.058,0.231-0.126,0.349-0.187
	c0.107-0.055,0.212-0.113,0.318-0.17c8.471-4.464,14.686-13.153,19.826-20.941c0.396-0.619,0.788-1.242,1.176-1.869h-1.614v-43.604
	c-6.746-3.899-13.372-8.023-19.794-12.421c-5.948-4.072-13.825-8.627-17.675-14.977c-0.125-0.206-0.16-0.406-0.142-0.595
	c-0.626,0.293-1.469,0.488-1.826,0.329c-4.535-2.023-18.061-8.007-12.18-14.161c-0.047-0.084-0.104-0.164-0.146-0.251
	c-0.404,0.273-0.918,0.488-1.306,0.488c-34,0-68,0-102,0c-1.958,0,0.07-1.617,1.135-1.617c33.93,0,67.859,0,101.789,0
	c-0.166-0.764-0.155-1.568,0.142-2.383c0.828-2.275,2.96-3.385,5.204-3.801c0.038-1.586,0.479-3.168,1.756-4.223
	c1.283-1.061,2.834-1.512,4.437-1.586c-2.566-2.039-4.706-5.533-5.237-8.457c-0.814-4.482,3.469-5.214,6.927-4.2
	c0.446,0.131,0.621,0.34,0.619,0.571c0.21,0.098,0.383,0.246,0.482,0.461c1.798,3.875,4.947,6.863,8.077,9.73
	c0.784-0.928,1.755-1.666,3.022-1.838c1.234-0.166,2.352,0.305,2.986,1.398c1.175,2.019,1.682,4.645,1.558,7.031
	c7.193,5.889,14.383,12.662,22.171,18.337V103.872H82.147l2.073,387.449h254.102c-4.107-1.643-5.88-4.758-1.152-8.646
	C344.24,476.857,354.623,476.358,362.885,473.081z M374.515,470.767c3.43-0.18,6.859-0.345,10.293-0.453
	c2.896-0.082,5.793-0.177,8.688-0.277c0.06-1.311,0.003-2.631-0.044-3.938c-0.422-11.643-1.391-23.274-2.298-34.887
	c-0.655-8.379-1.831-16.472,0.822-24.633c2.267-6.975,5.793-13.486,10.071-19.432c7.924-11.013,17.685-20.987,28.746-28.883
	c0.314-1.117,2.958-1.457,2.897-0.055c-0.626,14.473-0.292,28.97,1.049,43.396c0.519,5.571,1.138,11.131,1.86,16.68
	c0.399,3.072,0.752,5.114,2.471,7.693c5.28,7.921,11.715,15.128,17.878,22.359c8.76,10.277,17.6,20.486,26.301,30.814
	c6.413-4.801,13.851-8.35,19.477-14.027c-0.056-0.379,0.273-0.761,0.736-1.02c0.021-0.012,0.041-0.024,0.062-0.035
	c-0.013-0.006-0.026-0.01-0.038-0.015c-0.074-0.034-0.142-0.073-0.194-0.127c-12.582-12.753-22.767-27.954-32.012-43.235
	c-0.886-1.465-4.33-5.44-4.076-7.205c0.371-2.582,0.724-5.168,1.067-7.754c1.599-12.016,3.067-24.064,4.046-36.148
	c0.472-5.816,0.94-11.684,0.818-17.523c-0.022-1.063,0.239-7.322-1.244-7.936c-2.187,0.498-4.367,0.818-6.348,1.039
	c-8.496,0.943-20.476,1.683-26.537-5.859c-1.938-2.412-2.411-5.191-2.448-8.098c-0.003-0.221-0.002-0.441,0-0.662
	c-0.424,0.329-1.049,0.615-1.491,0.615c-3.801,0-7.603,0-11.404,0c-0.005,0.042-0.011,0.083-0.017,0.125
	c-0.547,4.439-2.427,8.279-7.401,9.832c-4.646,1.451-9.958-1.308-13.714-3.957c-0.125,0.025-0.249,0.043-0.37,0.055
	c-0.019,0.164-0.085,0.346-0.219,0.549c-3.963,5.994-8.72,11.16-13.803,16.052v0.511h-0.542c-2.839,2.71-5.768,5.346-8.719,7.992
	c-8.393,7.528-16.332,15.779-19.596,26.885c-7.84,26.676,2.271,54.543,2.415,81.512c0.001,0.088-0.015,0.172-0.038,0.252
	c0.268-0.027,0.518-0.021,0.715,0.025c0.318,0.073,0.5,0.244,0.383,0.537c0.14-0.35,1.323-0.519,2.826-0.598
	c1.076-0.057,2.313-0.068,3.453-0.065c1.536,0.003,2.896,0.03,3.427,0.001C373.147,470.833,373.831,470.802,374.515,470.767z
	 M512.694,472.438c-1.876-2.607-5.693-4.331-8.557-6.104c-5.943,5.479-13.476,9.043-19.809,14.095
	c0.074,0.089,0.149,0.177,0.225,0.266c0.814,0.969-1.232,1.965-2.292,1.518c-0.014,0.007-0.03,0.012-0.045,0.018
	c-0.283,0.256-0.57,0.506-0.847,0.771c-0.498,0.477-2.494,0.693-2.57,0.143c-1.955,0.444-3.929,0.821-5.867,1.319
	c-2.977,0.765-12.255,4.035-3.989,5.562c7.818,1.443,16.01,2.021,23.961,1.81c2.156-0.059,4.34-1.733,6.14-2.863
	c3.096-1.945,6.178-4.023,8.885-6.494C510.078,480.514,515.15,475.854,512.694,472.438z M212.14,233.872h-95v-99h95V233.872z
	 M209.14,137.872h-89v93h5.426h37.07h13.903h23.889h8.712V137.872z M117.14,376.872h87v81h-87V376.872z M120.14,454.872h81v-75h-81
	V454.872z M230.072,141.181c40,0,80,0,120,0c1.064,0,3.093-1.617,1.135-1.617c-40,0-80,0-120,0
	C230.142,139.563,228.114,141.181,230.072,141.181z M230.072,163.181c40,0,80,0,120,0c1.064,0,3.093-1.617,1.135-1.617
	c-40,0-80,0-120,0C230.142,161.563,228.114,163.181,230.072,163.181z M217.207,262.563c-34.667,0-69.333,0-104,0
	c-1.065,0-3.093,1.617-1.135,1.617c34.667,0,69.333,0,104,0C217.137,264.181,219.165,262.563,217.207,262.563z M217.207,284.563
	c-34.667,0-69.333,0-104,0c-1.065,0-3.093,1.617-1.135,1.617c34.667,0,69.333,0,104,0
	C217.137,286.181,219.165,284.563,217.207,284.563z M217.207,308.563c-34.667,0-69.333,0-104,0c-1.065,0-3.093,1.617-1.135,1.617
	c34.667,0,69.333,0,104,0C217.137,310.181,219.165,308.563,217.207,308.563z M217.207,330.563c-34.667,0-69.333,0-104,0
	c-1.065,0-3.093,1.617-1.135,1.617c34.667,0,69.333,0,104,0C217.137,332.181,219.165,330.563,217.207,330.563z M217.207,354.563
	c-34.667,0-69.333,0-104,0c-1.065,0-3.093,1.617-1.135,1.617c34.667,0,69.333,0,104,0
	C217.137,356.181,219.165,354.563,217.207,354.563z M341.207,376.563c-34.667,0-69.333,0-104,0c-1.065,0-3.093,1.617-1.135,1.617
	c34.667,0,69.334,0,104,0C341.137,378.181,343.165,376.563,341.207,376.563z M341.207,396.173c-34.667,0-69.333,0-104,0
	c-1.065,0-3.093,1.617-1.135,1.617c34.667,0,69.334,0,104,0C341.137,397.79,343.165,396.173,341.207,396.173z M341.207,417.563
	c-34.667,0-69.333,0-104,0c-1.065,0-3.093,1.617-1.135,1.617c34.667,0,69.334,0,104,0
	C341.137,419.181,343.165,417.563,341.207,417.563z M341.207,437.173c-34.667,0-69.333,0-104,0c-1.065,0-3.093,1.617-1.135,1.617
	c34.667,0,69.334,0,104,0C341.137,438.79,343.165,437.173,341.207,437.173z M341.207,458.563c-34.667,0-69.333,0-104,0
	c-1.065,0-3.093,1.617-1.135,1.617c34.667,0,69.334,0,104,0C341.137,460.181,343.165,458.563,341.207,458.563z M230.072,185.181
	c35.333,0,70.667,0,106,0c1.064,0,3.093-1.617,1.135-1.617c-35.334,0-70.667,0-106,0
	C230.142,183.563,228.114,185.181,230.072,185.181z M351.207,227.563c-40,0-80,0-120,0c-1.065,0-3.093,1.617-1.135,1.617
	c40,0,80,0,120,0C351.137,229.181,353.165,227.563,351.207,227.563z"
      fill="#000000"
    ></path>
  </svg>
);
