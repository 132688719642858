import { FC } from 'react';
import { classNames } from '../../lib';

type Props = {
  Icon: FC<React.ComponentProps<'svg'>>;
  text?: string | null;
  variant?: 'medium' | 'light';
};

export const DetailSnippet: FC<Props> = ({ Icon, text, variant = 'medium' }) => {
  if (!text) return null;
  return (
    <div
      className={classNames(
        'flex items-center space-x-1 text-xs',
        variant === 'medium' ? 'text-text-medium' : 'text-text-light'
      )}
      title={text}
    >
      <Icon className="h-4 w-4" />
      <p className="max-w-[100px] truncate">{text}</p>
    </div>
  );
};
