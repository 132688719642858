export const UPSERT_LOGO_IMAGE_MUTATION = gql`
  mutation UpsertLogoImage($input: UpsertImageInput!) {
    upsertLogoImage(input: $input) {
      id
      image {
        id
        src
      }
      alignment
    }
  }
`;
