import { DialogPanel, DialogTitle } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { FC, PropsWithChildren } from 'react';
import { classNames } from '../../lib';
import { IconButton } from '../IconButton/IconButton';

type DialogLayoutProps = {
  title?: string;
  Icon?: FC<React.ComponentProps<'svg'>>;
  iconClassName?: string;
  /**
   * Optional button bar sitting between title and close button
   */
  buttons?: React.ReactNode;
  onClose?: () => void;
  className?: string;
  titleClassName?: string;
};

export const DialogLayout: FC<PropsWithChildren<DialogLayoutProps>> = ({
  children,
  Icon,
  iconClassName,
  title,
  onClose,
  buttons,
  className,
  titleClassName,
}) => {
  return (
    <DialogPanel
      className={classNames(
        'mx-auto flex max-h-[85vh] min-w-[500px] max-w-5xl flex-col rounded-3xl bg-white px-8 py-6 shadow-lg',
        className
      )}
    >
      <div tabIndex={0} className="flex ">
        {title && (
          <div className="items-between flex items-center gap-x-2">
            {Icon && <Icon className={classNames('h-6 w-6', iconClassName ?? 'text-text-dark')} />}
            <DialogTitle
              className={classNames('text-xl font-medium text-text-dark', titleClassName)}
            >
              {title}
            </DialogTitle>
          </div>
        )}
        {/* This is a hack to disable autofocus, Dialogs + Focus management need some work */}
        <div className="flex flex-grow items-center justify-end gap-x-3">
          {buttons && buttons}
          <IconButton
            tabIndex={-1}
            size="medium"
            onClick={onClose ? onClose : undefined}
            tooltipText="close"
            Icon={XMarkIcon}
          />
        </div>
      </div>

      {children}
    </DialogPanel>
  );
};
