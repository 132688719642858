import { BoltIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from '@redwoodjs/forms';
import { useQuery } from '@redwoodjs/web';
import { FC, useEffect } from 'react';
import { ClientProposal, GetUserAgency, GetUserAgencyVariables } from '../../../types/graphql';
import { GET_USER_AGENCY_QUERY } from '../../graphql/queries';
import { clientProposalFormSchema, ClientProposalFormValues } from '../../lib/formSchemas';
import { PageTitle } from '../PageTitle';
import { TextAreaField } from '../TextArea';
import { TextField } from '../TextField';
import { BaseForm } from './BaseForm';
import { BaseFormProps } from './types';

export const ClientProposalForm: FC<BaseFormProps> = ({
  onSubmit,
  operation,
  document,
  documentLoading,
  mutationError,
  mutationLoading,
}) => {
  const {
    data: userAgencyData,
    loading: userAgencyLoading,
    error: userAgencyError,
  } = useQuery<GetUserAgency, GetUserAgencyVariables>(GET_USER_AGENCY_QUERY, {
    skip: operation === 'update',
  });
  const formMethods = useForm<ClientProposalFormValues>({
    resolver: zodResolver(clientProposalFormSchema),
  });

  if (document && document?.__typename !== 'ClientProposal') {
    throw new Error('Document is not a ClientProposal');
  }

  const handleSubmit: SubmitHandler<ClientProposalFormValues> = (data) => {
    onSubmit({
      inputData: {
        clientProposal: {
          clientCompanyName: data.clientCompanyName,
          clientCompanyUSPs: data.clientCompanyAbout,
          yourCompanyName: data?.name,
          yourCompanyUSPs: data?.about,
        },
      },
    });
  };

  useEffect(() => {
    if (document?.__typename === 'ClientProposal') {
      const documentData = document as ClientProposal;
      formMethods.reset({
        about: documentData.input.yourCompanyUSPs,
        name: documentData.input.yourCompanyName,
        clientCompanyAbout: documentData.input.clientCompanyUSPs,
        clientCompanyName: documentData.input.clientCompanyName,
      });
    }
  }, [document, formMethods]);

  useEffect(() => {
    if (operation === 'create' && !!userAgencyData) {
      formMethods.reset({
        name: userAgencyData?.userAgency?.name,
        about: userAgencyData?.userAgency?.about,
      });
    }
  }, [operation, userAgencyData, formMethods]);

  return (
    <BaseForm<ClientProposalFormValues>
      formProps={{ onSubmit: handleSubmit, formMethods }}
      operation={operation}
      documentLoading={documentLoading}
      mutationError={mutationError}
      mutationLoading={mutationLoading}
      document={document}
    >
      <PageTitle Icon={BoltIcon} text="Client Proposal" />
      <TextField name="name" label="Your Company Name" schema={clientProposalFormSchema} />
      <TextField
        name="clientCompanyName"
        label="Client Company Name"
        schema={clientProposalFormSchema}
      />
      <TextAreaField
        name="about"
        label="Your Company USPs"
        schema={clientProposalFormSchema}
        className="min-h-[200px]"
      />
      <TextAreaField
        name="clientCompanyAbout"
        label="Client Company USPs"
        schema={clientProposalFormSchema}
        className="min-h-[200px]"
      />
    </BaseForm>
  );
};
