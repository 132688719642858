import { createContext, useContext } from 'react';

export type FormEditStateProviderProps = {
  readOnly: boolean;
  setReadOnly?: (readOnly: boolean) => void;
};

export const FormEditStateContext = createContext<FormEditStateProviderProps>({
  readOnly: false,
  setReadOnly: (_readOnly: boolean) => undefined,
});

export const useReadOnlyForm = () => useContext(FormEditStateContext);
