import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  Transition,
} from '@headlessui/react';
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/24/outline';
import useExpandDirection from 'src/hooks/useExpandDirection';

type DropdownItem<T> = {
  label: string;
  value: T;
};

type DropdownSelectProps<T> = {
  items: DropdownItem<T>[];
  value: T | undefined;
  onChange: (value: T) => void;
  disabled?: boolean;
  emptyText?: string;
};

export const DropdownSelect = <T,>({
  items,
  value,
  onChange,
  disabled,
  emptyText = 'Select',
}: DropdownSelectProps<T>) => {
  const { anchorRef, expandDirection } = useExpandDirection({
    autoAdjust: true,
    defaultDirection: 'right',
  });

  return (
    <div className="relative">
      <Listbox value={value} disabled={disabled} onChange={onChange}>
        <ListboxButton
          disabled={disabled}
          className="-z-1 flex flex-row items-center gap-x-3 text-text-dark"
          ref={anchorRef}
        >
          {!disabled && <ChevronDownIcon className="h-5 w-5 text-text-dark" />}
          {items.find((item) => item.value === value)?.label || emptyText}
        </ListboxButton>
        <Transition
          enter="transition ease-out duration-100"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <ListboxOptions
            anchor="bottom start"
            className={`${
              expandDirection === 'left' ? 'right-0 origin-top-right' : 'left-0 origin-top-left'
            } absolute top-1 mt-2 w-56 divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
          >
            {items.map((item) => (
              <ListboxOption
                className={`flex items-center gap-x-2 px-4 py-2 text-text-medium ${
                  value === item.value
                    ? 'text-text-veryDark'
                    : 'hover:cursor-pointer hover:bg-gray-100'
                }`}
                key={item.label}
                value={item.value}
              >
                {({ selected }) => (
                  <>
                    {item.label}
                    {selected && <CheckIcon className="h-5 w-5 text-text-dark" strokeWidth={2} />}
                  </>
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </Transition>
      </Listbox>
    </div>
  );
};
