import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from '@redwoodjs/web//toast';
import { extractTextFromFile } from 'src/lib/fileParsing';

const supportedFileTypes = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/csv',
];

/**
 *
 * @param onTextExtracted function to call when text is extracted from the file
 * @description Hook uses useDropzone hook with file parsing and upload logic
 * @returns {getRootProps, getInputProps, isDragActive, onUploadFile}
 */
export function useTextFromFile(onTextExtracted: (text: string) => void) {
  const onUploadFile = async (file: File) => {
    try {
      const text = await extractTextFromFile(file);
      if (text.length > 40000) {
        toast.error('File too large, must be less than 40,000 characters');
        return;
      }

      if (!text || text.length === 0) {
        toast.error('Unable to parse file, please try a different file or file format');
        return;
      }

      onTextExtracted(text);
    } catch (e) {
      console.error(e);
      toast.error('Error during file processing');
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      if (!file) return;

      if (!supportedFileTypes.includes(file.type)) {
        toast.error('Only PDF, DOCX and CSV files are supported');
        return;
      }

      if (file.size > 10485760) {
        toast.error('File size must be less than 2MB');
        return;
      }

      onUploadFile(file);
    },
    [onTextExtracted]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  return { getRootProps, getInputProps, isDragActive, onUploadFile };
}
