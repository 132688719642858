import Worker from 'src/worker';
import { EntityData, EntityName } from '../types';
import { blobToBase64 } from 'src/lib/blobToBase64';
import { saveAs } from 'file-saver';
import { createPdfFromHtmlReactPdf } from 'src/components/PdfDocument/pdf';
import { DocumentBrandingConfig, GetBrandAssets } from 'types/graphql';

export const handleExport = async (
  entityData: EntityData | undefined | null,
  entityName: EntityName,
  exportFormat: string,
  includeDocuments: boolean,
  config: DocumentBrandingConfig,
  brandAssetsConfig: GetBrandAssets['getBrandAssets']
) => {
  if (!entityData) throw new Error('No data available for export');
  const workerInstance = new Worker();

  try {
    if (entityData?.documents && includeDocuments) {
      const pdfItemsWithCanvases = await Promise.all(
        entityData.documents.map(async (document) => {
          const pdfBlob = await createPdfFromHtmlReactPdf(
            document.markup,
            config,
            brandAssetsConfig
          );
          const pdfBase64 = await blobToBase64(pdfBlob);
          return {
            ...document,
            pdf: pdfBase64,
          };
        })
      );

      const stringifiedData = JSON.stringify({
        entityData: { ...entityData, documents: pdfItemsWithCanvases },
        entityName,
        exportFormat,
      });

      const formattedData = await workerInstance.formatEntityDocuments(stringifiedData);
      if (formattedData) {
        saveAs(formattedData);
      }
    } else {
      const stringifiedData = JSON.stringify({
        entityData,
        entityName,
        exportFormat,
      });

      const results = await workerInstance.formatEntity(stringifiedData);

      if (results?.data) {
        saveAs(new Blob([results.data], { type: 'text/plain' }), `${results.fileName}`);
      }
    }
  } catch (error) {
    console.error('Error in handleExport:', error);
    throw error;
  }
};
