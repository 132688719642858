import { FC, PropsWithChildren } from 'react';
import { classNames } from 'src/lib';

export const SettingsItem = ({
  label,
  value,
  LeftIcon,
  Icon,
  labelClassName,
  className,
  children,
}: PropsWithChildren<{
  label: string;
  value?: string;
  LeftIcon?: FC<React.ComponentProps<'svg'>>;
  Icon?: FC<React.ComponentProps<'svg'>>;
  labelClassName?: string;
  className?: string;
}>) => (
  <div className={classNames('flex items-center justify-between', className)}>
    <div className={classNames('w-[250px] font-medium text-text-medium', labelClassName)}>
      {label}
    </div>
    <div className="flex-grow font-normal text-text-dark">
      <div className="flex gap-10">
        <div className="flex items-center gap-x-3">
          {LeftIcon && <LeftIcon className="h-5 w-5 cursor-pointer " />}
          {value}
        </div>
        {Icon && <Icon className="h-5 w-5 cursor-pointer " />}
      </div>
    </div>

    {children}
  </div>
);
