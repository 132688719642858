import { FC } from 'react';
import { motion } from 'framer-motion';

export type ProgressBarProps = {
  totalSteps: number;
  currentStep: number;
};

export const ProgressBar: FC<ProgressBarProps> = ({ totalSteps, currentStep }) => {
  const progressWidth = (currentStep / totalSteps) * 100;

  return (
    <div className="progress-bar-container relative h-[11px] w-full max-w-[747px] overflow-hidden rounded-[32px] bg-generate-light">
      <motion.div
        className="progress-bar-fill h-[11px] rounded-[32px] bg-generate-medium"
        animate={{ width: `${progressWidth}%` }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
      />
    </div>
  );
};
