import { useMutation } from '@redwoodjs/web';
import { DebouncedSelect } from './DebouncedSelect';
import { UPDATE_USER_MUTATION } from '../../../graphql/mutations';
import { UpdateUser, UpdateUserVariables } from '../../../../types/graphql';
import { LANGUAGE_CODES } from '../../../lib/iso';

type Props = {
  initialValue?: string;
  label: string;
};

export const LanguageSelect = ({ initialValue, label }: Props) => {
  const [updateUser, { loading }] = useMutation<UpdateUser, UpdateUserVariables>(
    UPDATE_USER_MUTATION
  );

  const onChange = (value: string) => {
    updateUser({
      variables: {
        input: {
          language: value,
        },
      },
    });
  };

  return (
    <DebouncedSelect
      options={LANGUAGE_CODES.map(({ name, code }) => ({ label: name, value: code }))}
      loading={loading}
      label={label}
      initialValue={initialValue}
      onChange={onChange}
    />
  );
};
