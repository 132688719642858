export const UPDATE_CV_CONFIG_MUTATION = gql`
  mutation UpdateCvConfig($input: UpdateCvConfigInput!) {
    updateCvConfig(input: $input) {
      id
      CvTemplate {
        id
      }
      accentColor
      headerType
      logoAlignment
      font
      fontColor
      capitaliseHeading
      boldHeading
      showBorder
      borderColor
      marginSize
      fontScale
      headingFont
      headingColor
    }
  }
`;
