export const GET_COMPANY_QUERY = gql`
  query GetCompany($id: String!) {
    company(id: $id) {
      id
      name
      status
      campaignStatus
      location
      overview
      culture
      benefits
    }
  }
`;
