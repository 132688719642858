import { useQuery } from '@redwoodjs/web';
import { FC } from 'react';
import {
  GET_BRANDED_DOCUMENT_CONFIG_QUERY,
  GET_BRAND_ASSETS_QUERY,
  GET_COMPANY_AND_DOCUMENTS_QUERY,
} from 'src/graphql/queries';
import {
  Company,
  GetBrandAssets,
  GetBrandAssetsVariables,
  GetBrandedDocumentConfig,
  GetBrandedDocumentConfigVariables,
  GetCompanyAndDocuments,
  GetCompanyAndDocumentsVariables,
} from 'types/graphql';
import { ExportSubmitParams } from './types';
import { handleExport } from './lib/handleExport';
import { EntityExportDialog } from './EntityExportDialog';
import { DEFAULT_BRANDING_CONFIG, DEFAULT_BRAND_ASSETS } from '../PdfDocument/pdf';

type Props = {
  id: string;
};

export const CompanyExportDialog: FC<Props> = ({ id }) => {
  const { data } = useQuery<GetCompanyAndDocuments, GetCompanyAndDocumentsVariables>(
    GET_COMPANY_AND_DOCUMENTS_QUERY,
    {
      variables: {
        companyId: id,
      },
    }
  );

  const { data: configData } = useQuery<
    GetBrandedDocumentConfig,
    GetBrandedDocumentConfigVariables
  >(GET_BRANDED_DOCUMENT_CONFIG_QUERY);

  const { data: brandAssetsData } = useQuery<GetBrandAssets, GetBrandAssetsVariables>(
    GET_BRAND_ASSETS_QUERY
  );

  const company = data?.company as Company;

  const handleSubmit = async ({
    exportFormat,
    includeDocuments,
    onComplete,
  }: ExportSubmitParams) => {
    if (!company) return;

    try {
      await handleExport(
        company,
        'Company',
        exportFormat,
        includeDocuments,
        configData?.getBrandedDocumentConfig ?? DEFAULT_BRANDING_CONFIG,
        brandAssetsData?.getBrandAssets ?? DEFAULT_BRAND_ASSETS
      );
    } catch (error) {
      console.error(error);
    } finally {
      onComplete();
    }
  };

  return <EntityExportDialog entityName="Company" onSubmit={handleSubmit} />;
};
