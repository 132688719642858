import { AnimatePresence, motion } from 'framer-motion';
import { PropsWithChildren } from 'react';

type StaggeredFadeInProps = {
  visible?: boolean;
  className?: string;
};

/**
 * `StaggeredFadeIn` is a component that animates its children with a staggered fade-in effect.
 * Each child will fade in one after the other, creating a sequence of animations.
 *
 * Note: This component manipulates children by wrapping each child with a `motion.div` to control
 * the animation of individual children.
 */

export const StaggeredFadeIn = ({
  visible,
  children,
  className,
}: PropsWithChildren<StaggeredFadeInProps>) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { x: -30, y: 50, opacity: 0 },
    show: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: { duration: 0.6 },
    },
  };

  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="show"
          className={className}
        >
          {React.Children.map(children, (child, index) => (
            <motion.div key={index} variants={itemVariants}>
              {child}
            </motion.div>
          ))}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
