export const GET_CANDIDATES_QUERY = gql`
  query GetCandidates($input: CandidatesInput!) {
    candidates(input: $input) {
      nodes {
        id
        campaignStatus
        status
        name
        jobTitle
        desiredSalary
        availability
        createdAt
        updatedAt
        myPermission
        owner {
          id
          name
          email
        }
      }
      pageInfo {
        count
        isLast
        page
      }
    }
  }
`;
