const tagColors = [
  'bg-tags-1',
  'bg-tags-2',
  'bg-tags-3',
  'bg-tags-4',
  'bg-tags-5',
  'bg-tags-6',
  'bg-tags-7',
  'bg-tags-8',
  'bg-tags-9',
  'bg-tags-10',
];

const tagColorMap = new Map<string, string>();

export const getTagColor = (tag: string) => {
  if (!tagColorMap.has(tag)) {
    tagColorMap.set(tag, tagColors[tagColorMap.size % tagColors.length]);
  }
  return tagColorMap.get(tag);
};
