import { FC } from 'react';
import { Candidate, Company, Job } from '../../../types/graphql';
import { StatusPill } from '../StatusPill';

type Props = Pick<Partial<Job | Company | Candidate>, 'campaignStatus' | 'status'>;

/**
 *  Used to display the status of a job, company or candidate.
 */
export const EntityStatusPill: FC<Props> = ({ status, campaignStatus }) => {
  const isProcessing = campaignStatus === 'PENDING';
  const isArchived = status === 'ARCHIVED';

  if (!(isProcessing || isArchived)) {
    return null;
  }

  /**
   * This is a hack as we don't actually present the active state. Just added it to make TS happy.
   */
  const color = isProcessing ? 'orange' : isArchived ? 'grey' : 'green';
  const text = isProcessing ? 'Processing' : isArchived ? 'Archived' : 'Active';

  return <StatusPill text={text} color={color} />;
};
