import { FC, useRef } from 'react';
import { classNames } from 'src/lib';
import { IconButton } from '../IconButton';
import { UrlScrapePopover } from '../UrlScrapePopover';
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';

type FileUploadProps = {
  onFileUpload?: (file: File) => Promise<void>;
};

const FileUpload: FC<FileUploadProps> = ({ onFileUpload }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onFileUploadClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) onFileUpload?.(e.target.files[0]);
          e.target.value = '';
        }}
        className="hidden"
        accept=".pdf,.docx,.csv"
      />
      <IconButton
        Icon={ArrowUpTrayIcon}
        tooltipText="Import data from a file"
        variant="generate"
        onClick={onFileUploadClick}
      />
    </div>
  );
};

type SmartDataUploaderProps = {
  onFileUpload?: (file: File) => Promise<void>;
  onUrlScrape?: (url: string) => void;
  popoverClassName?: string;
  className?: string;
};

export const SmartDataUploader: FC<SmartDataUploaderProps> = ({
  onFileUpload,
  onUrlScrape,
  popoverClassName,
  className,
}) => {
  const mainClassNames = classNames('flex items-center gap-x-4', className);

  return (
    <div className={mainClassNames}>
      <UrlScrapePopover onSubmit={(data) => onUrlScrape?.(data.url)} className={popoverClassName} />
      <FileUpload onFileUpload={onFileUpload} />
    </div>
  );
};
