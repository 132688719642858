export const GET_BRAND_ASSETS_QUERY = gql`
  query GetBrandAssets {
    getBrandAssets {
      id
      brandColor
      logo {
        id
        src
      }
      logoSize
      logoAlignment
      letterhead {
        id
        src
      }
      letterheadLeftMargin
      letterheadRightMargin
      letterheadTopMargin
      letterheadBottomMargin
      leftFooter
      middleFooter
      rightFooter
      footerFont
    }
  }
`;
