export const UPDATE_BRANDED_DOCUMENT_CONFIG_MUTATION = gql`
  mutation UpdateBrandedDocumentConfig($input: UpdateBrandedDocumentConfigInput!) {
    updateBrandedDocumentConfig(input: $input) {
      id
      headerStyle
      logoConfig {
        id
        image {
          id
          src
        }
        alignment
        size
      }
      fontConfig {
        header
        body
      }
      marginsConfig {
        horizontal
      }
      footerConfig {
        content
        alignment
      }
      showTableBorders
    }
  }
`;
