import { FC } from 'react';
import { DefaultPermissions } from '../SettingsComponents';
import { USER_ORGANISATION_DEFAULT_ROLE_DROPDOWN_OPTIONS } from 'src/lib/sharedConstants/userRoleDropdownOptions';
import { useMutation, useQuery } from '@redwoodjs/web';
import {
  GetMeQuery,
  GetMeQueryVariables,
  GetOrganisationQuery,
  GetOrganisationQueryVariables,
  SetDefaultUserPermissionInput,
  SetDefaultUserPermissionMutation,
  SetDefaultUserPermissionMutationVariables,
} from 'types/graphql';
import { GET_ME_QUERY, GET_ORGANISATION_QUERY } from 'src/graphql/queries';
import { useAuth } from 'src/auth';
import { hasRole } from 'src/lib/user';
import { SET_DEFAULT_USER_PERMISSION_MUTATION } from 'src/graphql/mutations';
import { toast } from '@redwoodjs/web/dist/toast';
import { usePageClasses } from '../../../hooks';

const PrivacyPage: FC = () => {
  usePageClasses('bg-white');
  const { currentUser } = useAuth();

  const isAdmin = currentUser ? hasRole(currentUser, 'ADMIN') : false;

  const { data: meData } = useQuery<GetMeQuery, GetMeQueryVariables>(GET_ME_QUERY, {
    variables: {
      isAdmin,
    },
    fetchPolicy: 'cache-first',
  });

  const { data: organisationData } = useQuery<GetOrganisationQuery, GetOrganisationQueryVariables>(
    GET_ORGANISATION_QUERY,
    {
      variables: {
        isAdmin,
      },
    }
  );

  const [setDefaultUserPermission] = useMutation<
    SetDefaultUserPermissionMutation,
    SetDefaultUserPermissionMutationVariables
  >(SET_DEFAULT_USER_PERMISSION_MUTATION, {
    onCompleted: () => {
      toast.success('Default permission has changed successfully');
    },
    refetchQueries: [GET_ME_QUERY],
  });

  const handleDefaultPermissionChange = async (input: SetDefaultUserPermissionInput) => {
    await setDefaultUserPermission({ variables: { input } });
  };

  const jobsPermission =
    meData?.me?.defaultJobPermissions ?? organisationData?.organisation?.defaultJobPermissions;

  const candidatesPermission =
    meData?.me?.defaultCandidatePermissions ??
    organisationData?.organisation?.defaultCandidatePermissions;

  const companiesPermission =
    meData?.me?.defaultCompanyPermissions ??
    organisationData?.organisation?.defaultCompanyPermissions;

  return (
    <div className="p-6">
      <h3 className="mb-4 text-xl font-bold text-text-dark">Privacy</h3>
      <p className="text-sm font-normal text-text-medium">
        Control what your team-members can do with jobs, companies and candidates you create
      </p>
      <DefaultPermissions
        permissionOptions={USER_ORGANISATION_DEFAULT_ROLE_DROPDOWN_OPTIONS}
        jobsPermission={jobsPermission}
        candidatesPermission={candidatesPermission}
        companiesPermission={companiesPermission}
        onDefaultPermissionChange={handleDefaultPermissionChange}
      />
    </div>
  );
};

export default PrivacyPage;
