import { FC, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@redwoodjs/web';
import { Spinner } from 'src/components/Spinner';
import {
  GET_BRANDED_DOCUMENT_CONFIG_QUERY,
  GET_BRAND_ASSETS_QUERY,
  GET_CV_CONFIG_QUERY,
  GENERATE_CV_PDF_QUERY,
} from 'src/graphql/queries';
import { PdfViewer } from 'src/components/PdfViewer';
import { CvTemplates } from './CvTemplates';
import { BrandAssets } from './BrandAssets';
import { Link, Tabs } from 'src/components';
import { navigate, routes } from '@redwoodjs/router';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import {
  GetBrandedDocumentConfig,
  GetBrandAssets,
  GetCvConfig,
  GetBrandedDocumentConfigVariables,
  GetBrandAssetsVariables,
  GetCvConfigVariables,
  GenerateCvPdfQuery,
  GenerateCvPdfQueryVariables,
  UpdateBrandedDocumentConfig,
  UpdateBrandedDocumentConfigVariables,
} from 'types/graphql';
import { useLoadPdfUrl, usePageClasses } from 'src/hooks';
import {
  DEFAULT_BRANDING_CONFIG,
  convertHtmlToReactPdfContent,
} from 'src/components/PdfDocument/pdf';
import { DUMMY_PDF_PREVIEW_DATA } from './constants';
import { notNullish } from 'src/lib/guards';
import { BrandedPreview } from '../../../components/PdfDocument/BrandedPreview';
import { serializeDate } from '../../../lib/dateTime';
import { UPDATE_BRANDED_DOCUMENT_CONFIG_MUTATION } from 'src/graphql/mutations';
import { InsetTabs } from 'src/components/InsetTabs';

export const DUMMY_PDF_DATA = {
  name: 'John Doe',
  jobTitle: 'Senior Legal Counsel',
  profileSummary:
    '<p>Experienced legal professional with over 10 years of expertise in corporate law, contract negotiation, and compliance. Adept at providing strategic legal advice and ensuring regulatory adherence.</p>',
  location: 'New York, NY',
  email: 'john.doe@example.com',
  phone: '+1 555-123-4567',
  experience: [
    {
      startDate: serializeDate(new Date('2015-01-01')),
      endDate: serializeDate(new Date('2023-01-01')),
      title: 'Senior Legal Counsel',
      company: 'ABC Corporation',
      location: 'New York, NY',
      description:
        '<p>Provided legal counsel on corporate matters, including mergers and acquisitions, contract negotiations, and compliance issues.</p><ul><li>Led a team of junior lawyers in high-stakes negotiations.</li><li>Ensured compliance with federal and state regulations.</li><li>Drafted and reviewed complex legal documents.</li></ul>',
    },
    {
      startDate: serializeDate(new Date('2010-01-01')),
      endDate: serializeDate(new Date('2014-12-31')),
      title: 'Legal Associate',
      company: 'XYZ Law Firm',
      location: 'New York, NY',
      description:
        '<p>Assisted senior solicitors in litigation and corporate law cases.</p><ul><li>Conducted legal research and prepared case briefs.</li><li>Represented clients in court under supervision.</li><li>Drafted legal documents and contracts.</li></ul>',
    },
  ],
  education: [
    {
      startDate: serializeDate(new Date('2006-09-01')),
      endDate: serializeDate(new Date('2009-06-01')),
      title: 'Juris Doctor (JD)',
      institution: 'Harvard Law School',
      location: 'Cambridge, MA',
      description:
        '<p>Graduated with honours, focusing on corporate law and international business transactions.</p><ul><li>Member of the Harvard Law Review.</li><li>Completed internships with top law firms.</li></ul>',
    },
    {
      startDate: serializeDate(new Date('2002-09-01')),
      endDate: serializeDate(new Date('2006-06-01')),
      title: 'Bachelor of Arts in Political Science',
      institution: 'University of California, Berkeley',
      location: 'Berkeley, CA',
      description:
        '<p>Graduated summa cum laude with a major in Political Science and a minor in Economics.</p><ul><li>President of the Debate Club.</li><li>Completed a thesis on international trade law.</li></ul>',
    },
  ],
  skills: [
    { title: 'Corporate Law' },
    { title: 'Contract Negotiation' },
    { title: 'Compliance' },
    { title: 'Litigation' },
    { title: 'Legal Research' },
  ],
  achievements: [
    {
      description:
        '<p>Recognised as a top legal counsel by the New York Bar Association in 2020.</p>',
    },
    {
      description: '<p>Successfully negotiated a $500 million merger deal for ABC Corporation.</p>',
    },
  ],
  certifications: [
    {
      title: 'Certified Compliance & Ethics Professional (CCEP)',
      description: '<p>Compliance Certification Board (CCB)</p>',
    },
  ],
  interests: [{ description: 'Reading' }, { description: 'Travelling' }, { description: 'Chess' }],
  languages: [{ language: 'English' }, { language: 'Spanish' }],
};

export const BrandedDocumentSettings: FC = () => {
  usePageClasses('bg-pageGray');
  const [selectedTab, setSelectedTab] = useState<'Assets' | 'Documents' | 'CV'>('Assets');
  const [templateId, setTemplateId] = useState<string | null>(null);

  const {
    data: configData,
    loading: configLoading,
    previousData: configPreviousData,
  } = useQuery<GetBrandedDocumentConfig, GetBrandedDocumentConfigVariables>(
    GET_BRANDED_DOCUMENT_CONFIG_QUERY
  );
  const {
    data: brandAssetsData,
    loading: brandAssetsLoading,
    previousData: brandAssetsPreviousData,
  } = useQuery<GetBrandAssets, GetBrandAssetsVariables>(GET_BRAND_ASSETS_QUERY);
  const {
    data: cvConfigData,
    loading: cvConfigLoading,
    previousData: cvConfigPreviousData,
  } = useQuery<GetCvConfig, GetCvConfigVariables>(GET_CV_CONFIG_QUERY);

  const [updateConfig] = useMutation<
    UpdateBrandedDocumentConfig,
    UpdateBrandedDocumentConfigVariables
  >(UPDATE_BRANDED_DOCUMENT_CONFIG_MUTATION);

  const configDataResult = configData || configPreviousData;
  const brandAssetsResult = brandAssetsData || brandAssetsPreviousData;
  const cvConfigDataResult = cvConfigData || cvConfigPreviousData;

  const {
    data: pdfData,
    loading: pdfLoading,
    refetch,
  } = useQuery<GenerateCvPdfQuery, GenerateCvPdfQueryVariables>(GENERATE_CV_PDF_QUERY, {
    variables: {
      input: DUMMY_PDF_DATA,
      templateId,
    },
    skip: !templateId,
  });

  const PDFContent = [convertHtmlToReactPdfContent(DUMMY_PDF_PREVIEW_DATA)]
    .filter(notNullish)
    .flat();

  useEffect(() => {
    refetch();
  }, [cvConfigDataResult, templateId, brandAssetsResult]);

  const pdfBlobUrl = useMemo(
    () => `data:application/pdf;base64,${pdfData?.generateCvPdf?.pdfContent}`,
    [pdfData]
  );

  const doc = useMemo(
    () => (
      <BrandedPreview
        content={PDFContent}
        config={configDataResult?.getBrandedDocumentConfig || DEFAULT_BRANDING_CONFIG}
        brandAssets={brandAssetsResult?.getBrandAssets}
      />
    ),
    [configDataResult, brandAssetsResult, cvConfigDataResult]
  );

  // Load the branded document as a Blob URL
  const { dataUrl: brandedPdfBlobUrl, loading: brandedPdfLoading } = useLoadPdfUrl(doc);

  const handleHeaderTypeChange = (tab: 'LOGO' | 'LETTERHEAD') => {
    updateConfig({
      variables: {
        input: {
          headerStyle: tab,
        },
      },
    });
  };

  const handleTabChange = (tab: 'Assets' | 'Documents' | 'CV') => {
    setSelectedTab(tab);
  };

  if (configLoading || brandAssetsLoading || cvConfigLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex h-screen overflow-hidden ">
      <div className="flex basis-5/12 flex-col gap-y-3 overflow-auto bg-pageGray px-12 py-5">
        <Link
          size="medium"
          onClick={() => navigate(routes.settingsOrganisation())}
          LeftIcon={ArrowLeftIcon}
          className="text-sm font-normal text-text-veryDark"
        >
          Back to settings
        </Link>

        <div className="flex flex-col justify-center gap-y-1">
          <h1 className="text-2xl font-bold text-text-dark">Branding</h1>
          <p className="text-sm font-normal text-text-medium">
            Customise AdScribe documents to match your company brand. Changes will be saved
            automatically.
          </p>
        </div>

        <div className="flex justify-between border-b border-text-light">
          <Tabs
            options={['Assets', 'Documents', 'CV']}
            selected={selectedTab}
            setSelected={handleTabChange}
          />
        </div>

        <div className="flex max-w-2xl flex-col justify-center gap-y-1 pt-5">
          {selectedTab === 'Assets' && (
            <BrandAssets brandAssets={brandAssetsResult?.getBrandAssets} />
          )}
          {selectedTab === 'Documents' && <div>Documents component will go here</div>}
          {selectedTab === 'CV' && (
            <CvTemplates
              cvConfigData={cvConfigDataResult?.getCvConfig}
              brandAssetsData={brandAssetsResult?.getBrandAssets}
              onTemplateSelect={setTemplateId}
              selectedTemplateId={templateId ?? ''}
            />
          )}
        </div>
      </div>
      <div className="flex basis-7/12 flex-col items-center bg-white">
        <div className="flex flex-1 flex-col items-center justify-center p-4">
          {selectedTab === 'CV' && pdfBlobUrl ? (
            <PdfViewer fileUrl={pdfBlobUrl} loading={pdfLoading} />
          ) : selectedTab === 'Assets' && brandedPdfBlobUrl ? (
            <>
              <InsetTabs
                tabs={[
                  { label: 'Logo', value: 'LOGO' },
                  { label: 'Letterhead', value: 'LETTERHEAD' },
                ]}
                onTabChange={handleHeaderTypeChange}
                selectedTab={configData?.getBrandedDocumentConfig?.headerStyle ?? 'LOGO'}
              />
              <PdfViewer fileUrl={brandedPdfBlobUrl} loading={pdfLoading} />
            </>
          ) : (
            <p className="text-sm font-normal text-text-medium">
              Select a template to preview the CV PDF.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
