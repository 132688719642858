import { z } from 'zod';

export const promptFormSchema = z.object({
  description: z
    .string()
    .min(1, 'Description is required')
    .max(100, 'Description must be less than 100 characters'),
  prompt: z
    .string()
    .min(1, 'Prompt is required')
    .max(2000, 'Prompt must be less than 2000 characters'),
});

export type PromptFormValues = z.infer<typeof promptFormSchema>;
