import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ZodObject, ZodSchema } from 'zod';
import { toast } from '@redwoodjs/web/dist/toast';

import { classNames } from 'src/lib';
import { useCharCount, useDebounce } from 'src/hooks';
import { findMaxLengthOfFieldSchema } from 'src/lib/zod';
import { DialogLayout } from '../../DialogLayout';
import { TextArea } from '../TextArea';

export type ExpandedTextAreaProps = {
  onClose: () => void;
  initialContent: string;
  onUpdateContent: (content: string) => void;
  name: string;
  label?: string;
  schema?: ZodSchema;
  charCount: number;
  autoResize?: boolean;
};

export const ExpandedTextArea: FC<ExpandedTextAreaProps> = ({
  onClose,
  initialContent,
  onUpdateContent,
  name,
  schema,
  charCount,
  label,
  autoResize,
}) => {
  const expandedContentRef = useRef<HTMLTextAreaElement | null>(null);
  const [inputValue, setInputValue] = useState(initialContent);
  const debouncedInputValue = useDebounce(inputValue, 300);

  const resizeTextarea = () => {
    const textarea = expandedContentRef.current;
    if (!textarea) return;

    textarea.style.height = 'auto';

    if (textarea.scrollHeight > textarea.clientHeight) {
      textarea.style.height = `${textarea.scrollHeight + 8}px`;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fieldSchema = (schema as ZodObject<any, any>)?.shape?.[name];

  const maxLengthOfFieldSchema = findMaxLengthOfFieldSchema(fieldSchema);

  const [currentCharCount, updateCharCount] = useCharCount(charCount, maxLengthOfFieldSchema);

  const isCharCountLimitReached =
    maxLengthOfFieldSchema && currentCharCount >= maxLengthOfFieldSchema;

  const handleSaveInput = () => {
    const updatedContent = expandedContentRef.current?.value || '';
    onUpdateContent(updatedContent);
    onClose();
  };

  useEffect(() => {
    onUpdateContent(debouncedInputValue);
  }, [debouncedInputValue, onUpdateContent]);

  useLayoutEffect(() => {
    resizeTextarea();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
    autoResize && resizeTextarea();
  };

  const handleExpandedTextareaInput = () => {
    const textarea = expandedContentRef.current;
    if (textarea) {
      textarea.value = updateCharCount(textarea.value);
      autoResize && resizeTextarea();
    }
  };

  const handleExpandedTextareaPaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const pastedText = event.clipboardData.getData('text/plain');
    const currentContent = expandedContentRef.current?.value || '';
    const combinedLength = currentContent.length + pastedText.length;

    if (maxLengthOfFieldSchema && combinedLength > maxLengthOfFieldSchema) {
      toast.error('Pasted text has been trimmed as it exceeds the field character limit.');
    }

    if (autoResize) {
      resizeTextarea();
    }
  };

  return (
    <DialogLayout className="flex-grow overflow-auto" title={label} onClose={handleSaveInput}>
      <div className="flex h-full flex-col">
        <p className="pb-3 text-text-medium">
          This is an expanded view of the text field. Your edits will be auto-saved.
        </p>
        <div className="flex flex-col gap-1">
          <div className="relative flex justify-between">
            <label htmlFor={name} className="pb-1 text-sm font-medium text-text-dark">
              {label}
            </label>
          </div>
          <div className="relative flex flex-col">
            <TextArea
              ref={expandedContentRef}
              defaultValue={initialContent}
              value={inputValue}
              onChange={handleInputChange}
              onInput={handleExpandedTextareaInput}
              onPaste={handleExpandedTextareaPaste}
              autoFocus
            />
            {maxLengthOfFieldSchema && (
              <div
                className={classNames(
                  'mt-1 self-end text-text-light',
                  Boolean(isCharCountLimitReached) && 'text-text-medium/90'
                )}
              >
                {currentCharCount}/{maxLengthOfFieldSchema}
              </div>
            )}
          </div>
        </div>
      </div>
    </DialogLayout>
  );
};
