import { Dialog } from '@headlessui/react';
import React, { FC, PropsWithChildren, ReactElement, useRef } from 'react';

export type TDialogContext = {
  show: (dialog: ReactElement) => void;
  close: () => void;
  onClose?: () => void;
  setOnClose?: (onClose: () => void) => void;
};

export const DialogContext = React.createContext<TDialogContext>({
  show: (dialog) => undefined,
  close: () => undefined,
  onClose: () => undefined,
  setOnClose: (onClose) => undefined,
});

export const DialogProvider: FC<PropsWithChildren> = ({ children }) => {
  const [dialog, setDialog] = React.useState<ReactElement | null>(null);
  const [onClose, setOnClose] = React.useState<() => void>(() => undefined);
  const initialFocusRef = useRef(null);

  const show: TDialogContext['show'] = (dialog) => {
    setDialog(dialog);
  };

  const close: TDialogContext['close'] = () => {
    setDialog(null);
    onClose && onClose();
  };

  return (
    <DialogContext.Provider value={{ show, close, onClose, setOnClose }}>
      {children}

      <Dialog
        open={!!dialog}
        onClose={close}
        className="relative z-50"
        initialFocus={initialFocusRef}
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-gray-400/80" aria-hidden="true" />

        {/* Full-screen scrollable container */}
        <div className="fixed inset-0 overflow-y-auto">
          {/* Container to center the panel */}
          <div className="flex min-h-full items-center justify-center p-4">
            {/* Invisible button to receive initial focus */}
            <button
              ref={initialFocusRef}
              className="absolute h-0 w-0 opacity-0"
              aria-hidden="true"
            />
            {/* The actual dialog panel  */}
            {dialog}
          </div>
        </div>
      </Dialog>
    </DialogContext.Provider>
  );
};
