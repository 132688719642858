import { useState, useCallback, useRef, useLayoutEffect } from 'react';

type ExpandDirection = 'left' | 'right';
type UseExpandDirectionOptions = {
  defaultDirection?: ExpandDirection;
  autoAdjust?: boolean;
  onDirectionChange?: ((direction: ExpandDirection) => void) | null;
};

function useExpandDirection({
  defaultDirection = 'right',
  autoAdjust = true,
  onDirectionChange = null,
}: UseExpandDirectionOptions): {
  expandDirection: ExpandDirection;
  changeDirection: (direction: ExpandDirection) => void;
  anchorRef: React.RefObject<HTMLButtonElement>;
} {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [expandDirection, setExpandDirection] = useState<ExpandDirection>(defaultDirection);

  const validateDirection = useCallback(
    (direction: ExpandDirection): ExpandDirection => {
      return ['left', 'right'].includes(direction) ? direction : defaultDirection;
    },
    [defaultDirection]
  );

  const changeDirection = useCallback(
    (direction: ExpandDirection) => {
      const validDirection = validateDirection(direction);
      setExpandDirection(validDirection);
      onDirectionChange?.(validDirection);
    },
    [validateDirection, onDirectionChange]
  );

  useLayoutEffect(() => {
    if (autoAdjust && anchorRef.current) {
      const { left, right, width } = anchorRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;

      if (right + width / 2 > viewportWidth) {
        setExpandDirection('left');
      } else if (left - width / 2 < 0) {
        setExpandDirection('right');
      }
    }
  }, [anchorRef.current, autoAdjust]);

  return { expandDirection, changeDirection, anchorRef };
}

export default useExpandDirection;
