import { MODEL } from '../../../types/graphql';

export const MODEL_SELECT_OPTIONS = [
  'GPT_4',
  'GPT_4o',
  'GPT_4o_MINI',
  'GPT_3_5_TURBO_16k',
  'GPT_3_5_TURBO',
  'CLAUDE_3_OPUS',
  'CLAUDE_3_SONNET',
  'CLAUDE_3_HAIKU',
].map((option) => ({
  value: option as MODEL,
  label: option,
}));
