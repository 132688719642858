import { difference } from 'src/lib/dateTime';
import { GetChatSessionsQuery } from 'types/graphql';

type Session = GetChatSessionsQuery['chatSessions'][number];

export const groupChatSessionsByDate = (sessions: Session[]): Record<string, Session[]> => {
  const now = new Date();
  return sessions.reduce((acc: Record<string, Session[]>, session: Session) => {
    const createdAtDate = new Date(session.createdAt);
    const daysAgo = difference(now, createdAtDate, 'days');

    let group: string;
    if (daysAgo === 0) {
      group = 'Today';
    } else if (daysAgo === 1) {
      group = 'Yesterday';
    } else if (daysAgo <= 7) {
      group = 'Last 7 Days';
    } else if (daysAgo <= 30) {
      group = 'Last 30 Days';
    } else {
      group = 'Older';
    }

    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(session);
    return acc;
  }, {});
};
