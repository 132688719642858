import { FC } from 'react';
import { classNames, getTagColor } from '../../lib';

type Props = {
  location: string;
};

/**
 *
 * @returns Auto-colored tag for location
 */
export const LocationTag: FC<Props> = ({ location: location }) => {
  return (
    <div
      className={classNames(
        'w-fit rounded px-2 py-1 text-sm font-medium text-text-dark',
        getTagColor(location)
      )}
      title={location}
    >
      <p className="max-w-[200px] truncate"> {location}</p>
    </div>
  );
};
