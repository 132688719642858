import { DOCUMENT_FIELDS_FRAGMENT } from '../fragments';

// TODO: FIX FRAGMENT INTERPOLATION https://github.com/redwoodjs/redwood/issues/7491
export const REGENERATE_DOCUMENT_MUTATION = gql`
  ${DOCUMENT_FIELDS_FRAGMENT}
  mutation RegenerateDocument($input: RegenerateDocumentInput!) {
    regenerateDocument(input: $input) {
      ...DocumentFields
    }
  }
`;
