import { DOCUMENT_FIELDS_FRAGMENT } from '../fragments';

// TODO: FIX FRAGMENT INTERPOLATION https://github.com/redwoodjs/redwood/issues/7491
export const UPDATE_JOB_MUTATION = gql`
  ${DOCUMENT_FIELDS_FRAGMENT}
  mutation UpdateJobMutation($id: String!, $input: UpdateJobInput!) {
    updateJob(id: $id, input: $input) {
      id
      title
      status
      campaignStatus
      salary
      contractType
      roleAndResponsibilities
      mustHaves
      company {
        id
        name
        location
        overview
        culture
        benefits
      }
      documents {
        ...DocumentFields
      }
    }
  }
`;
