import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { FC } from 'react';
import { PageInfo } from '../../../types/graphql';
import { IconButton } from '../../components/IconButton';

type Props = Omit<PageInfo, '__typename'> & {
  loading?: boolean;
  limit: number;
  onSetPage: (page: number) => void;
  /**
   * Number of results on the current page in case the last page is not full
   */
  thisPageCount: number;
};

export const Pagination: FC<Props> = ({
  count,
  isLast,
  page = 1,
  limit,
  onSetPage,
  thisPageCount,
  loading,
}) => {
  const pageCount = Math.ceil(count / limit);
  const isFirst = page === 1;

  // Type guard
  if (!page) {
    return null;
  }

  return (
    <div className="flex flex-row items-center px-6 py-6">
      <div className="flex flex-grow text-text-medium">
        Showing <b className="font-medium">&nbsp;{(page - 1) * limit + 1}&nbsp;</b> to
        <b className="font-medium">&nbsp;{(page - 1) * limit + thisPageCount}&nbsp;</b>
        of
        <b className="font-medium">&nbsp;{count}&nbsp;</b>
        results
      </div>
      <div className="flex flex-row items-center gap-x-3">
        {!isFirst && (
          <>
            <div className="h-7 w-7">
              <IconButton
                disabled={loading}
                onClick={() => onSetPage(1)}
                size="medium"
                tooltipText="First"
                Icon={ChevronDoubleLeftIcon}
              />
            </div>
            <div className="h-7 w-7">
              <IconButton
                disabled={loading}
                onClick={() => onSetPage(page - 1)}
                size="medium"
                tooltipText="Previous"
                Icon={ChevronLeftIcon}
              />
            </div>
          </>
        )}
      </div>
      {!(isFirst && isLast) && (
        <div className="flex-0 flex px-3 text-text-medium">
          Page <b className="font-medium">&nbsp;{page}</b>
        </div>
      )}
      <>
        <div className="h-7 w-7">
          {!isLast && (
            <IconButton
              disabled={loading}
              onClick={() => onSetPage(page + 1)}
              size="medium"
              tooltipText="Next"
              Icon={ChevronRightIcon}
            />
          )}
        </div>
        {/* spacer, not sure why the right-side layout of pagination doesn't render as the left does */}
        <div className="pl-3" />
        <div className="h-7 w-7">
          {!isLast && (
            <IconButton
              disabled={loading}
              onClick={() => onSetPage(pageCount)}
              size="medium"
              tooltipText="Last"
              Icon={ChevronDoubleRightIcon}
            />
          )}
        </div>
      </>
    </div>
  );
};
