import { FC } from 'react';
import { CheckCircleIcon, LightBulbIcon } from '@heroicons/react/24/outline';
import { Transition } from '@headlessui/react';
import { motion } from 'framer-motion';

type TipsProps = {
  tips: string[];
  animateEntry?: boolean;
};

/**
 * Tailwind doesn't allow string interpolation so have to pre-define the colors
 */
const textTipColors = [
  'text-tips-1',
  'text-tips-2',
  'text-tips-3',
  'text-tips-4',
  'text-tips-5',
  'text-tips-6',
  'text-tips-7',
  'text-tips-8',
  'text-tips-9',
  'text-tips-10',
];

export const FormTipsCard: FC<TipsProps> = ({ tips, animateEntry }) => {
  return (
    <motion.div
      animate={animateEntry ? { opacity: 1 } : undefined}
      transition={animateEntry ? { delay: 0.5 } : undefined}
      initial={animateEntry ? { opacity: 0 } : undefined}
      className="mt-3 flex h-full flex-1 justify-center"
    >
      <div className="mx-6 flex w-[250px] flex-col rounded-2xl bg-gray-50 px-6 pt-16 text-xs 2xl:w-[350px] 2xl:text-lg">
        <LightBulbIcon className="mx-auto h-10 w-10 text-text-medium 2xl:h-14 2xl:w-14" />
        <p className="pt-3 text-center text-sm text-text-medium 2xl:text-lg">Suggestions</p>
        <Transition
          /**
           * Key needed to force a re-render when tips change and reapply the transition
           */
          key={tips[0]}
          appear={true}
          show={true}
          enter="transition-opacity duration-300 ease-in-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300 ease-in-out"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="flex flex-col gap-y-4 pt-6">
            {tips.length ? (
              <>
                {tips.map((tip, index) => (
                  <div key={index} className={`flex items-center gap-x-2 ${textTipColors[index]}`}>
                    <div className="h-5 w-5 2xl:h-7 2xl:w-7">
                      <CheckCircleIcon className="h-5 w-5 2xl:h-7 2xl:w-7" strokeWidth="2" />
                    </div>
                    <p className="text-text-medium">{tip}</p>
                  </div>
                ))}
              </>
            ) : (
              <div>
                <p className="text-center text-xs font-medium text-text-medium 2xl:text-lg">
                  Not sure what to write? 🤔
                </p>
                <p className="px-4 pt-4 text-center text-xs text-text-medium 2xl:text-lg">
                  We{"'"}ll give you some tips as you fill in the form.
                </p>
              </div>
            )}
          </div>
        </Transition>
      </div>
    </motion.div>
  );
};
