import { navigate, routes } from '@redwoodjs/router';
import { FC } from 'react';
import { Connection, Document } from '../../../types/graphql';
import { DeleteDocumentDialog, Pagination, UserIcon } from '../../components';
import { classNames, getDocumentTagColor } from '../../lib';
import {
  canDeleteDocument,
  documentPresentationalProperties,
  DocumentType,
} from 'src/lib/document';
import { formatRelativeDateTime } from 'src/lib/dateTime';
import { DropdownButton } from 'src/components/DropdownButton';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useDialog } from 'src/hooks';

type Props = {
  paginationLoading: boolean;
  documents: Array<
    Pick<Document, 'id' | '__typename' | 'title' | 'createdAt'> & {
      owner: Pick<Document['owner'], 'id' | 'name' | 'email'>;
    }
  >;
  pageInfo?: Pick<Connection['pageInfo'], 'isLast' | 'page' | 'count'>;
  pageLimit: number;
  onSetPage: (page: number) => void;
};

export const DocumentsTable: FC<Props> = ({
  paginationLoading,
  documents,
  pageInfo,
  onSetPage,
  pageLimit,
}) => {
  return (
    <div className="flex flex-grow flex-col">
      <ul className="flex flex-grow flex-col">
        {documents.map((document) => (
          <DocumentTableRow key={document.id} document={document} />
        ))}
      </ul>
      {pageInfo && (
        <Pagination
          {...pageInfo}
          loading={paginationLoading}
          limit={pageLimit}
          onSetPage={onSetPage}
          thisPageCount={documents.length}
        />
      )}
    </div>
  );
};

export const DocumentTableRow: FC<{
  document: Pick<Document, 'id' | '__typename' | 'title' | 'createdAt'> & {
    owner: Pick<Document['owner'], 'id' | 'name' | 'email'>;
  };
  hideDropdown?: boolean;
}> = ({ document, hideDropdown }) => {
  const { show, close } = useDialog();
  const { id, title, __typename, createdAt } = document;
  if (!__typename) return null;
  const canDelete = canDeleteDocument(__typename);

  return (
    <li
      onClick={() => navigate(routes.document({ documentId: id }))}
      className="hover-pointer relative flex items-center gap-x-3 border-b border-text-light p-3 hover:cursor-pointer hover:bg-gray-50"
    >
      <div className="line-clamp-2 flex flex-1 text-text-dark">{title ?? 'Untitled'}</div>
      <div className="flex-1">
        <DocumentTag documentType={__typename} />
      </div>
      <div className="flex w-32 justify-center">
        <UserIcon user={document.owner} />
      </div>
      <div className="flex-1 text-sm text-text-medium">
        {formatRelativeDateTime(new Date(createdAt))}
      </div>
      <div className="flex flex-1 justify-end text-sm text-text-medium">
        {!hideDropdown && canDelete ? (
          <DropdownButton
            options={[
              {
                Icon: TrashIcon,
                text: 'Delete',
                onClick: () => show(<DeleteDocumentDialog onDismiss={close} document={document} />),
              },
            ]}
          />
        ) : null}
      </div>
    </li>
  );
};

export const DocumentTag: FC<{ documentType: DocumentType }> = ({ documentType }) => {
  const color = getDocumentTagColor(documentType);
  const { title } = documentPresentationalProperties[documentType];
  return (
    <div
      className={classNames(
        `w-fit self-start justify-self-end rounded px-2 py-1 text-sm font-medium text-text-dark`,
        color
      )}
      title={title}
    >
      <p className="max-w-[200px] truncate"> {title}</p>
    </div>
  );
};
