import { FC, useState } from 'react';
import { useDialog } from 'src/hooks';
import { DialogLayout } from '../DialogLayout';
import { classNames } from 'src/lib';
import { useQuery } from '@redwoodjs/web';
import {
  DocumentBrandingConfig,
  GetBrandAssets,
  GetBrandAssetsVariables,
  GetBrandedDocumentConfig,
  GetBrandedDocumentConfigVariables,
  GetDocumentQuery,
  GetDocumentQueryVariables,
} from 'types/graphql';
import { GET_DOCUMENT_QUERY } from 'src/graphql/queries/getDocumentQuery';
import { saveAs } from 'file-saver';
import ActionButtons from './ActionButtons';
import { toast } from '@redwoodjs/web/dist/toast';
import { convertMarkupToBase64Text } from 'src/lib/convertMarkupToBase64Text';
import { DropdownSelect } from '../DropdownSelect';
import {
  DEFAULT_BRANDING_CONFIG,
  DEFAULT_BRAND_ASSETS,
  createPdfFromHtmlReactPdf,
} from '../PdfDocument/pdf';
import { GET_BRANDED_DOCUMENT_CONFIG_QUERY, GET_BRAND_ASSETS_QUERY } from 'src/graphql/queries';

type DocumentDropDownItem = {
  label: string;
  value: string;
};

const documentDropdownItems: Array<DocumentDropDownItem> = [
  { label: 'PDF', value: 'pdf' },
  { label: 'Text', value: 'txt' },
];

type Props = {
  id: string;
  className?: string;
};

export const DocumentExportDialog: FC<Props> = ({ className, id }) => {
  const dialogLayoutClassNames = classNames(
    'overflow-visible pt-4 px-6 pb-8 w-full min-w-0 max-w-[348px]',
    className
  );

  const { data } = useGetDocument(id);
  const { data: configData } = useQuery<
    GetBrandedDocumentConfig,
    GetBrandedDocumentConfigVariables
  >(GET_BRANDED_DOCUMENT_CONFIG_QUERY);

  const { data: brandAssetsData } = useQuery<GetBrandAssets, GetBrandAssetsVariables>(
    GET_BRAND_ASSETS_QUERY
  );

  const { close } = useDialog();
  const [exportFormat, setExportFormat] = useState<string>('pdf');
  const [loading, setLoading] = useState<boolean>(false);

  const exportHandler = async () => {
    if (!data?.document?.markup) {
      console.error('No markup available for export');
      return;
    }
    setLoading(true);

    try {
      toast.loading('Exporting...');
      await handleExport(
        data.document.markup,
        data.document.title || data.document.__typename || 'Document',
        exportFormat,
        configData?.getBrandedDocumentConfig ?? DEFAULT_BRANDING_CONFIG,
        brandAssetsData?.getBrandAssets ?? DEFAULT_BRAND_ASSETS
      );
    } catch (e) {
      console.error('Export failed:', e);
    } finally {
      close();
      setLoading(false);
      toast.dismiss();
    }
  };

  return (
    <DialogLayout className={dialogLayoutClassNames} title="Export Document" onClose={close}>
      <div className="flex justify-between py-4">
        <p className="text-base text-text-medium">Export format</p>
        <DropdownSelect
          items={documentDropdownItems}
          onChange={setExportFormat}
          value={exportFormat}
        />
      </div>
      <ActionButtons onCancel={close} onExport={exportHandler} loading={loading} />
    </DialogLayout>
  );
};

const useGetDocument = (id: string) => {
  return useQuery<GetDocumentQuery, GetDocumentQueryVariables>(GET_DOCUMENT_QUERY, {
    variables: { id },
    skip: !id,
    returnPartialData: true,
    fetchPolicy: 'cache-first',
  });
};

const handleExport = async (
  markup: string,
  title: string,
  format: string,
  config: DocumentBrandingConfig,
  brandAssetsConfig: GetBrandAssets['getBrandAssets']
) => {
  const fileName = title || 'document';
  switch (format) {
    case 'pdf': {
      const pdfBlob = await createPdfFromHtmlReactPdf(markup, config, brandAssetsConfig);
      saveAs(pdfBlob, `${fileName}.pdf`);
      break;
    }
    case 'docx':
      console.log('DOCX export not yet implemented');
      break;
    case 'txt': {
      const textMarkup = convertMarkupToBase64Text(markup);
      saveAs(textMarkup, `${fileName}.txt`);

      break;
    }
    default:
      throw new Error('Unsupported export format');
  }
};
