import z from 'zod';

export const blogPostIdeasFormSchema = z.object({
  industry: z.string().trim().min(1, {
    message: 'Required',
  }),
  clients: z.string().trim().min(1, {
    message: 'Required',
  }),
  candidates: z.string().trim().min(1, {
    message: 'Required',
  }),
});

export type BlogPostIdeasFormValues = z.infer<typeof blogPostIdeasFormSchema>;
