import { DOCUMENT_FIELDS_FRAGMENT } from '../fragments';

export const SAVE_CHAT_RESPONSE_AS_DOCUMENT = gql`
  ${DOCUMENT_FIELDS_FRAGMENT}
  mutation SaveChatResponseAsDocument($responseId: String!) {
    saveResponseAsDocument(responseId: $responseId) {
      id
      text
      createdAt
      document {
        ...DocumentFields
      }
    }
  }
`;
