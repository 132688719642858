import {
  Editor,
  useEditor as useTiptapEditor,
  JSONContent,
  AnyExtension,
  EditorEvents,
} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Heading from '@tiptap/extension-heading';
import Placeholder from '@tiptap/extension-placeholder';
import Link from '@tiptap/extension-link';
import CharacterCount from '@tiptap/extension-character-count';

/**
 * Custom hook for initializing a Tiptap editor instance with predefined extensions.
 *
 * This hook abstracts the common setup for Tiptap editor instances across the application,
 * allowing for a consistent configuration while also providing flexibility through options.
 *
 * @param {UseCustomEditorOptions} options - Configuration options for the editor.
 * @param {string | JSONContent} [options.content=''] - Initial content for the editor, can be a string or JSONContent format.
 * @param {boolean} [options.editable=true] - Flag to set if the editor is editable.
 * @param {string} [options.placeholder=''] - Placeholder text for the editor.
 * @param {number} [options.maxLength] - Maximum length of content allowed in the editor.
 * @param {(html: string) => void} [options.onUpdate] - Callback function that is called with the current HTML content whenever it is updated.
 * @param {boolean} [options.autofocus=false] - Flag to set if the editor should autofocus on initialization.
 *
 * @returns {Editor | null} An instance of the Tiptap Editor if successful, or null if the editor could not be initialized.
 *
 * The `extensions` configuration includes:
 * - `StarterKit`: A set of standard extensions for basic text editing capabilities.
 * - `Heading`: Configured for levels 1 and 2 headings.
 * - `Placeholder`: Shows a placeholder text when the editor is empty and editable.
 * - `Link`: Enables hyperlink functionality, with options to open links on click and to autolink URLs pasted into the editor.
 * - `CharacterCount`: Optionally added to limit the maximum character count of the content.
 *
 * This setup ensures that the editor is ready-to-use with a standard set of features out of the box,
 * while also being easily customizable for different parts of the application.
 */

type UseEditorProps = {
  content?: string | JSONContent;
  editable?: boolean;
  placeholder?: string;
  maxLength?: number;
  onUpdate?: (html: string) => void;
  autofocus?: boolean;
};

export const useEditor = ({
  content = '',
  editable = true,
  placeholder = '',
  maxLength,
  onUpdate,
  autofocus = false,
}: UseEditorProps): Editor | null => {
  const extensions: AnyExtension[] = [
    StarterKit,
    Heading.configure({ levels: [1, 2] }),
    Placeholder.configure({
      placeholder,
      showOnlyWhenEditable: false,
    }),
    Link.configure({
      openOnClick: true,
      HTMLAttributes: {
        target: '_blank',
        rel: 'noopener noreferrer',
        class: 'font-medium underline underline-offset-4 cursor-pointer',
      },
      protocols: ['https'],
      linkOnPaste: true,
      autolink: true,
    }),
    maxLength ? CharacterCount.configure({ limit: maxLength }) : null,
  ].filter((extension): extension is AnyExtension => extension !== null);

  const handleUpdate = ({ editor }: EditorEvents['update']) => {
    const outputContent = editor?.getHTML();
    onUpdate?.(outputContent);
  };

  const editor = useTiptapEditor({
    content,
    extensions,
    editable,
    onUpdate: handleUpdate,
    autofocus,
    editorProps: {
      attributes: {
        class: 'prose focus:outline-none flex-grow',
      },
    },
  });

  return editor;
};
