import { DOCUMENT_FIELDS_FRAGMENT, PERMISSION_FIELDS_FRAGMENT } from '../fragments';

export const GET_CANDIDATE_AND_DOCUMENTS_QUERY = gql`
  ${DOCUMENT_FIELDS_FRAGMENT}
  ${PERMISSION_FIELDS_FRAGMENT}

  query GetCandidateAndDocuments($candidateId: String!) {
    candidate(id: $candidateId) {
      id
      campaignStatus
      status
      name
      refId
      jobTitle
      desiredJobTitle
      currentSalary
      desiredSalary
      location
      availability
      overview
      notes
      motivationsForMoving
      rightToWork
      experience {
        id
        title
        company
        startDate
        endDate
        location
        description
      }
      education {
        id
        title
        institution
        startDate
        endDate
        location
        description
      }
      skills {
        id
        title
        description
      }
      achievements {
        id
        description
      }
      profileSummary
      languages {
        id
        language
        level
      }
      interests {
        id
        description
      }
      certifications {
        id
        title
        description
      }
      createdAt
      updatedAt
      documents {
        ...DocumentFields
      }
      permissions {
        ...PermissionFields
      }
      myPermission
    }
  }
`;
