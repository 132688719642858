import { FC } from 'react';
import SpinnerSvg from '../../assets/spinner.svg';
import { classNames } from '../../lib';
type Props = {
  className?: string;
  fillContainer?: boolean;
};
export const Spinner: FC<Props> = ({ className, fillContainer = true }) => {
  return (
    <div
      className={classNames(
        fillContainer ? 'flex h-full w-full flex-grow items-center justify-center' : ''
      )}
    >
      <SpinnerSvg
        className={classNames('animate-spin text-text-veryDark', className ?? 'h-8 w-8')}
      />
    </div>
  );
};
