/**
 * This module defines functionality for parsing XML content and rendering it
 * based on a given configuration using Handlebars. It includes type definitions
 * and validation schemas for structured parsing and rendering.
 */

/**
 * Zod schema for validating individual parsed items. Each item may contain a key
 * and content, both of which are nullable strings.
 */

import { HelperOptions } from 'handlebars';
import { z } from 'zod';

/**
 * Zod schema for validating individual parsed items. Each item may contain a key
 * and content, both of which are nullable strings.
 */
const parsedItemSchema = z.object({
  key: z.string().nullable(),
  content: z.string().nullable(),
});

/**
 * Zod schema for a single configuration item. Each config item includes a key
 * and a visibility indicator, which can be a boolean or the string 'false'/'true'.
 * This allows for flexible configuration, accommodating boolean values directly
 * from JSON and string representations from environments where boolean conversion
 * may not be straightforward. Additionally, string representations of 'true' and 'false'
 * are automatically transformed into their corresponding boolean values, ensuring
 * that the 'visible' property is consistently a boolean after validation. This
 * transformation simplifies further processing and use of the configuration items
 * by standardizing the data type of the visibility indicator.
 */
const configItemSchema = z.object({
  key: z.string(),
  visible: z.union([z.boolean(), z.literal('false'), z.literal('true')]).transform((value) => {
    if (typeof value === 'string') {
      return value === 'true';
    }
    return value;
  }),
});

/**
 * Zod schema for an array of configuration items, enabling batch validation
 * of configuration arrays.
 */
const configArraySchema = z.array(configItemSchema);

type ParsedItem = z.infer<typeof parsedItemSchema>;

/**
 * Parses an XML string, automatically wrapping it in a root <xml> tag to ensure
 * valid XML structure, and extracts structured items defined by <structuredItem> tags.
 * Each item's key and content are extracted and validated against the parsedItemSchema.
 *
 * @param {string} xmlString - The XML content string to be parsed.
 * @return {ParsedItem[]} An array of parsed items, each validated and containing a key and content.
 */
export function parseSimpleXML(xmlString: string): ParsedItem[] {
  const wrappedXmlString = `<xml>${xmlString}</xml>`;
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(wrappedXmlString, 'text/xml');
  const items = xmlDoc.getElementsByTagName('structuredItem');

  return Array.from(items).map((item) => {
    const parsedItem = parsedItemSchema.parse({
      key: item.getAttribute('key'),
      content: item.textContent,
    });

    return parsedItem;
  });
}

/**
 * A Handlebars helper function to work with structured XML content.
 * This function parses XML to extract content, then filters and orders these items
 * based on a provided configuration array. It then combines the content of the filtered
 * and ordered items into a single string, returned as a safe string for use in templates.
 *
 * The configuration array (`config`) specifies which items to include based on their
 * visibility and the order in which the items should appear. Items with visibility
 * not explicitly set to 'false' and truthy are included.
 *
 * This implementation first filters the configuration items based on visibility.
 * It then utilizes a Map for efficient key-based lookup of parsed items,
 * ensuring the operation is optimized for performance. The visible configuration items
 * are mapped to their corresponding content, which is aggregated and ordered according
 * to the `config` array. Finally, content is filtered to exclude empty strings, and the
 * resulting non-empty content strings are joined together.
 *
 * @param {void} this - The current context of the template
 * @param {unknown} config - The configuration specifying which keys to include
 *                                and their order based on visibility.
 * @param {HelperOptions} options - Handlebars helper options, used here to provide the XML string.
 * @param {typeof Handlebars} handlebarinjection - The Handlebars instance used for creating safe strings.
 * @return {Handlebars.SafeString} A safe string combining the content of filtered and ordered XML items.
 */
export function structuredHelper(
  this: void,
  config: unknown,
  options: HelperOptions,
  handlebarinjection: typeof Handlebars
): Handlebars.SafeString {
  // Validate the config array using the Zod schema. This remains unchanged.
  const configValidationResult = configArraySchema.safeParse(config);
  if (!configValidationResult.success) {
    throw new Error(`Invalid configuration: ${configValidationResult.error.message}`);
  }

  // If the validation is successful, proceed with the rest of the function
  const validConfig = configValidationResult.data;

  const xmlString = options.fn(this);
  const parsedItems = parseSimpleXML(xmlString);

  const itemsMap = new Map(parsedItems.map((item) => [item.key, item.content]));

  // Filter based on visibility.
  const visibleConfigItems = validConfig.filter((configItem) => configItem.visible);

  // Ordered and nonempty config items
  const orderedAndFilteredContent = visibleConfigItems
    .map((configItem) => itemsMap.get(configItem.key) || '')
    .filter((content) => content);

  return new handlebarinjection.SafeString(orderedAndFilteredContent.join(''));
}
