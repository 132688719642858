import { convert, HtmlToTextOptions } from 'html-to-text';

/**
 * Converts HTML markup to base64 encoded plain text.
 * @param htmlMarkup HTML markup to be converted.
 * @returns Base64 encoded plain text.
 */
export function convertMarkupToBase64Text(htmlMarkup: string) {
  const options: HtmlToTextOptions = {
    selectors: [
      { selector: 'ul', options: { itemPrefix: '- ' } },
      { selector: 'ol', options: { itemPrefix: '1. ' } },
      { selector: 'h1', options: { uppercase: false } },
      { selector: 'h2', options: { uppercase: false } },
      { selector: 'h3', options: { uppercase: false } },
      { selector: 'h4', options: { uppercase: false } },
      { selector: 'h5', options: { uppercase: false } },
      { selector: 'h6', options: { uppercase: false } },
    ],
  };

  const formattedText = convert(htmlMarkup, options);

  // Function to handle Unicode characters in base64 encoding
  function toBase64(str: string) {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode(parseInt(p1, 16));
      })
    );
  }

  const base64Text = toBase64(formattedText);
  return `data:text/plain;base64,${base64Text}`;
}
