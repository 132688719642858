import { useState, useEffect } from 'react';
import { pdf } from '@react-pdf/renderer';

/**
 * Custom hook for generating a URL for a PDF file from a React PDF component.
 * This hook converts a given React PDF component into a Blob, generates a URL for it,
 * and returns the URL along with the loading state.
 *
 * @param {JSX.Element} reactPdfComponent - The React PDF component to convert into a PDF Blob and generate a URL for.
 * @returns {object} An object containing the loading state and the generated URL.
 * @returns {boolean} loading - Indicates whether the PDF generation is in progress.
 * @returns {string | null} dataUrl - The generated URL for the PDF Blob, or null if not yet generated.
 */
export function useLoadPdfUrl(reactPdfComponent: JSX.Element): {
  loading: boolean;
  dataUrl: null | string;
} {
  const [dataUrl, setDataUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const generatePdfBlob = async () => {
      const asPdf = pdf(reactPdfComponent); // Convert the React PDF document to a PDF object.
      const blob = await asPdf.toBlob(); // Convert the PDF object to a Blob.
      const blobUrl = URL.createObjectURL(blob); // Create a URL for the Blob.

      setDataUrl(blobUrl);
      setLoading(false);
    };

    // Generate the PDF Blob and URL whenever the React PDF component changes.
    generatePdfBlob();
  }, [reactPdfComponent]);

  return { loading, dataUrl };
}
