import { FC, Fragment, useLayoutEffect, useRef, useState } from 'react';
import { useQuery } from '@redwoodjs/web';
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { DocumentDuplicateIcon, PlusCircleIcon, ScissorsIcon } from '@heroicons/react/24/outline';

import { GetSnippetsQuery, GetSnippetsQueryVariables, Snippet } from '../../../types/graphql';
import { GET_SNIPPETS_QUERY } from '../../graphql/queries';
import { classNames, convertHtmlToPlainText } from '../../lib';
import { useDebounce, useDialog } from '../../hooks';
import { Spinner } from '../Spinner';
import { Tooltip } from '../Tooltip';
import { CreateSnippetDialog } from '../SnippetDialogs';
import { SearchInput } from '../SearchInput';

export const SnippetsDropdown: FC<{
  hideCreate?: boolean;
  onClickSnippet: (htmlContent: string) => void;
}> = ({ hideCreate, onClickSnippet }) => {
  const [_, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const { data, loading, error } = useQuery<GetSnippetsQuery, GetSnippetsQueryVariables>(
    GET_SNIPPETS_QUERY,
    {
      variables: { searchInput: debouncedSearchTerm },
    }
  );

  const [expandDirection, setExpandDirection] = useState<'left' | 'right'>('left');
  const anchorRef = useRef<HTMLButtonElement>(null);

  if (error) {
    throw error;
  }

  // This effect is used to determine if the dropdown menu should expand to the left or right
  useLayoutEffect(() => {
    if (anchorRef.current) {
      const { left } = anchorRef.current.getBoundingClientRect();
      if (left < 200) {
        setExpandDirection('right');
      }
    }
  }, [anchorRef]);

  return (
    <Popover className="relative inline-block text-left">
      <Tooltip innerBody="Snippet">
        <PopoverButton
          onMouseOver={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
          ref={anchorRef}
          className="flex items-center justify-center rounded-lg p-1 text-text-dark hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-medium focus:ring-offset-2 focus:ring-offset-gray-100"
        >
          <ScissorsIcon className={classNames('h-5 w-5')} strokeWidth={2} />
        </PopoverButton>
      </Tooltip>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <PopoverPanel
          className={classNames(
            expandDirection === 'left' ? 'right-0 origin-top-right' : 'left-0 origin-top-left',
            'z-15 absolute bottom-full mt-2 max-h-[400px] w-72 divide-y divide-gray-100 overflow-y-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
          )}
        >
          <div className="p-2">
            <SearchInput
              autofocus
              placeholder="Search snippets..."
              value={searchTerm}
              onChange={setSearchTerm}
              size="small"
            />
          </div>
          <div className="py-1">
            {loading ? (
              <Spinner className="h-4 w-4" />
            ) : (
              <>
                {data?.snippets.map((snippet) => (
                  <MenuItem
                    key={snippet.name}
                    onClick={() => onClickSnippet(snippet.text)}
                    {...snippet}
                  />
                ))}
                {!hideCreate && <CreateSnippetItem />}
              </>
            )}
          </div>
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};

const MenuItem: FC<
  Pick<Snippet, 'name' | 'text'> & {
    onClick: () => void;
  }
> = ({ text, onClick, name }) => {
  return (
    <div>
      <a
        href="#"
        onClick={onClick}
        className="group flex items-center gap-x-2 px-4 py-2 text-sm text-text-dark hover:bg-gray-100 hover:text-text-veryDark"
      >
        <DocumentDuplicateIcon
          className="h-5 w-5 shrink-0 text-text-dark group-hover:text-gray-500"
          aria-hidden="true"
        />
        <div className="flex flex-col gap-x-1">
          <p className="text-sm text-text-dark">{name}</p>
          <p className="line-clamp-1 whitespace-pre-wrap text-xs text-text-medium">
            {convertHtmlToPlainText(text)}
          </p>
        </div>
      </a>
    </div>
  );
};

const CreateSnippetItem: FC = () => {
  const { show, close } = useDialog();
  const onClick = () => {
    show(<CreateSnippetDialog onClose={close} />);
  };
  return (
    <div>
      <a
        href="#"
        onClick={onClick}
        className="group flex items-center justify-center gap-x-2 px-4 py-1 text-sm text-text-dark hover:bg-gray-100 hover:text-text-veryDark"
      >
        <div className="flex items-center gap-x-1 text-ellipsis">
          <PlusCircleIcon className="h-4 w-4 text-text-medium" />
          <p className="text-sm text-text-medium">Create a new snippet</p>
        </div>
      </a>
    </div>
  );
};
