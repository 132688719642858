import { ComponentProps, FC } from 'react';

export const BottomMarginIcon: FC<ComponentProps<'svg'>> = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4999 7.5H7.49992C6.57944 7.5 5.83325 8.24619 5.83325 9.16667V10.8333C5.83325 11.7538 6.57944 12.5 7.49992 12.5H12.4999C13.4204 12.5 14.1666 11.7538 14.1666 10.8333V9.16667C14.1666 8.24619 13.4204 7.5 12.4999 7.5Z"
        stroke="#6B7280"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3334 16.667H1.66675"
        stroke="#6B7280"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
