import { useEffect, useState } from 'react';
import { useForm } from '@redwoodjs/forms';
import { navigate, routes, useParams } from '@redwoodjs/router';
import { MetaTags, useMutation, useQuery } from '@redwoodjs/web';
import { CircleStackIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { z } from 'zod';

import {
  CreateChatSessionMutation,
  CreateChatSessionMutationVariables,
  GetChatSessionQuery,
  GetChatSessionQueryVariables,
  GetDocumentQuery,
  GetDocumentQueryVariables,
  RegenerateChatResponseMutation,
  RegenerateChatResponseMutationVariables,
  SendChatMessageMutation,
  SendChatMessageMutationVariables,
} from '../../../types/graphql';
import {
  CREATE_CHAT_SESSION_MUTATION,
  REGENERATE_CHAT_RESPONSE_MUTATION,
  SEND_CHAT_MESSAGE_MUTATION,
} from '../../graphql/mutations';
import { GET_CHAT_SESSION_QUERY } from '../../graphql/queries';
import { GET_DOCUMENT_QUERY } from '../../graphql/queries/getDocumentQuery';

import { useBoolean, useDialog, usePageClasses } from '../../hooks';
import { documentPresentationalProperties } from '../../lib/document';
import { useSelectedPromptStore } from '../../services/store';
import { Link, PromptsDialog, TextAreaField } from '../../components';
import { IconButton } from '../../components/IconButton';
import { Spinner } from '../../components/Spinner';
import { ChatMessage, ChatRequest, ChatResponseLoading } from './ChatMessage';
import { ChatSidebar } from './ChatSidebar';
import { SUGGESTION_PROMPTS } from './suggestionPrompts';
import { StaggeredFadeIn } from 'src/components/StaggeredFadeIn';
import { AdscribeLogo } from 'src/assets/AdscribeLogo';
import { Form } from 'src/components/Form';
import { classNames } from 'src/lib';

const chatSchema = z.object({
  text: z.string().min(1, { message: 'Required' }),
});

type ChatValues = z.infer<typeof chatSchema>;

const ChatPage = () => {
  usePageClasses('bg-pageGray');
  const { id, documentId, redirectUrl, documentType } = useParams();

  const {
    value: isSuggestionPromptsVisible,
    setTrue: showSuggestedPrompts,
    setFalse: hideSugesstionPrompts,
  } = useBoolean(true);
  const { value: isChatSidebarExpanded, toggle: toggleChatSidebar } = useBoolean(true);

  const { show } = useDialog();

  const selectedPrompt = useSelectedPromptStore((state) => state.selectedPrompt);
  const formMethods = useForm<ChatValues>({
    resetOptions: { keepValues: false },
    reValidateMode: 'onSubmit',
  });
  const text = formMethods.watch('text');
  // Used to show a loading state for the message that is being sent
  const [pendingMessage, setPendingMessage] = useState<string | null>(null);

  const [createChatSession] = useMutation<
    CreateChatSessionMutation,
    CreateChatSessionMutationVariables
  >(CREATE_CHAT_SESSION_MUTATION, {
    refetchQueries: ['GetChatSessionsQuery'],
    update: (cache, { data }) => {
      const newChatSession = data?.createChatSession;

      if (newChatSession) {
        cache.writeQuery<GetChatSessionQuery, GetChatSessionQueryVariables>({
          query: GET_CHAT_SESSION_QUERY,
          variables: { id: data.createChatSession.id },
          data: { chatSession: newChatSession, __typename: 'Query' },
        });
      }
    },
  });

  const [sendChatMessage] = useMutation<SendChatMessageMutation, SendChatMessageMutationVariables>(
    SEND_CHAT_MESSAGE_MUTATION,
    {
      refetchQueries: ['GetChatSessionQuery'],
    }
  );

  const {
    data,
    loading: chatSessionLoading,
    error,
  } = useQuery<GetChatSessionQuery, GetChatSessionQueryVariables>(GET_CHAT_SESSION_QUERY, {
    variables: { id: id ?? '' },
    skip: !id,
  });

  const [regenerateChatResponse, { loading: regenerateLoading }] = useMutation<
    RegenerateChatResponseMutation,
    RegenerateChatResponseMutationVariables
  >(REGENERATE_CHAT_RESPONSE_MUTATION, {
    update: (cache, { data }) => {
      const newChatSession = data?.regenerateChatResponse;
      if (newChatSession) {
        cache.writeQuery<GetChatSessionQuery, GetChatSessionQueryVariables>({
          query: GET_CHAT_SESSION_QUERY,
          variables: { id: data.regenerateChatResponse.id },
          data: { chatSession: newChatSession, __typename: 'Query' },
        });
      }
    },
    optimisticResponse: ({ responseId }) => {
      const chatSession = data?.chatSession;
      if (!chatSession) {
        throw new Error('Chat session not found');
      }

      return {
        __typename: 'Mutation',
        regenerateChatResponse: {
          ...chatSession,
          messages: chatSession.messages.filter((i) => i.id !== responseId),
        },
      };
    },
  });

  const {
    data: documentData,
    loading: documentLoading,
    error: documentError,
  } = useQuery<GetDocumentQuery, GetDocumentQueryVariables>(GET_DOCUMENT_QUERY, {
    skip: !documentId,
    variables: { id: documentId ?? '' },
  });

  useEffect(() => {
    if (selectedPrompt) {
      formMethods.setValue('text', selectedPrompt.prompt);
      useSelectedPromptStore.setState({ selectedPrompt: null });
    }
  }, [selectedPrompt]);

  const onSubmit = async (data: ChatValues) => {
    hideSugesstionPrompts();
    setPendingMessage(data.text);
    formMethods.resetField('text');
    if (!id) {
      const { data: response } = await createChatSession({
        variables: {
          input: { message: data.text, documentId: documentId ?? undefined },
        },
      });
      if (!response?.createChatSession) {
        console.error('Error creating chat session');
        return;
      }

      navigate(
        routes.chat(
          documentId
            ? {
                id: response.createChatSession.id,
                documentId,
                redirectUrl,
                documentType,
              }
            : { id: response.createChatSession.id }
        )
      );
    } else {
      await sendChatMessage({
        variables: { sessionId: id, text: data.text },
      });
    }
    formMethods.reset();
    setPendingMessage(null);
  };

  if (error) {
    throw error;
  }

  const handleSelectSuggestionPrompt = (prompt: string) => {
    hideSugesstionPrompts();
    formMethods.setValue('text', prompt);
  };

  const IS_EDITING_DOCUMENT = !!documentId;
  const document = documentData?.document;
  const chatSession = data?.chatSession;
  const messages = chatSession?.messages ?? [];
  const showSpinner = !chatSession && chatSessionLoading;
  const showNewChatTextBackdrop = !id && !pendingMessage && !documentId;
  const showPendingMessageLoader = !!pendingMessage || regenerateLoading;

  const chatLength = messages?.length;
  const title = document?.__typename
    ? documentPresentationalProperties[document.__typename].title
    : '';
  return (
    <div className="flex h-full min-h-0 flex-grow overflow-hidden">
      <MetaTags title="Chat" description="Chat page" />
      <ChatSidebar
        document={document ?? undefined}
        onSelectSession={(id) => navigate(routes.chat({ id }))}
        selectedSessionId={id}
        IS_EDITING_DOCUMENT={IS_EDITING_DOCUMENT}
        onShowSuggestedPrompts={showSuggestedPrompts}
        isChatSidebarExpanded={isChatSidebarExpanded}
        onToggleChatSidebar={toggleChatSidebar}
      />
      <div
        className={classNames(
          'flex h-full min-h-0 flex-grow flex-col overflow-hidden',
          !isChatSidebarExpanded && 'mr-[300px]'
        )}
      >
        {IS_EDITING_DOCUMENT && document && (
          <p className="bg-blue-200 py-2 text-center text-text-dark">
            You are editing a{' '}
            <Link
              size="medium"
              onClick={() => navigate(routes.document({ documentId: document?.id }))}
            >
              {title}
            </Link>
          </p>
        )}
        <div
          className={classNames(
            'flex min-h-0 flex-grow flex-col-reverse items-center ',
            showNewChatTextBackdrop ? 'overflow-hidden' : 'overflow-auto'
          )}
        >
          {showSpinner && <Spinner />}
          {showNewChatTextBackdrop && (
            <div className="relative flex max-w-md flex-grow flex-col gap-2 text-center">
              <AdscribeLogo className="absolute left-1/2 top-10 z-0 max-h-[70vh] w-[100vw] max-w-[1600px] -translate-x-1/2 -translate-y-1/2 transform px-4 py-2 sm:w-full md:w-[90vw] lg:w-[1100px] xl:w-[1500px]" />

              <div className="relative z-10">
                <div className="text-center text-2xl font-bold text-text-dark">
                  How can I help you today?
                </div>
                <div className="font-normal text-text-medium">
                  Whether you&apos;re looking for ideas, explanations, or to create something new,
                  get started by typing in your request ✍️
                </div>
              </div>
            </div>
          )}
          <div className="w-full max-w-3xl flex-grow">
            <div>
              {document && !id && (
                <ChatRequest
                  hideSavePrompt
                  documentId={document?.id}
                  message={{
                    text: document.markup,
                    __typename: 'ChatRequest',
                    createdAt: new Date().toISOString(),
                    id: 'document',
                  }}
                />
              )}
              {chatSession &&
                messages.map((i, ix) => (
                  <ChatMessage
                    key={i.id}
                    // @ts-expect-error TODO: fix this
                    message={
                      i as Exclude<
                        GetChatSessionQuery['chatSession'],
                        null | undefined
                      >['messages'][0]
                    }
                    documentId={documentId}
                    isLast={!pendingMessage && ix === chatLength - 1}
                    onRegenerate={(responseId) =>
                      regenerateChatResponse({
                        variables: { sessionId: id, responseId },
                      })
                    }
                  />
                ))}
              {!!pendingMessage && (
                <ChatMessage
                  message={{
                    text: pendingMessage,
                    __typename: 'ChatRequest',
                    createdAt: new Date().toISOString(),
                    id: 'pending',
                  }}
                />
              )}
              {showPendingMessageLoader && <ChatResponseLoading />}
            </div>
          </div>
        </div>
        {!id && (
          <StaggeredFadeIn
            visible={isSuggestionPromptsVisible && !IS_EDITING_DOCUMENT}
            className="grid w-full max-w-3xl grid-cols-2 gap-4 self-center pb-6"
          >
            {SUGGESTION_PROMPTS.map((prompt) => (
              <div
                key={prompt.title}
                className="flex h-20 cursor-pointer flex-col gap-2 overflow-hidden rounded-2xl bg-white px-3 py-4  hover:shadow-md"
                onClick={() => handleSelectSuggestionPrompt(prompt.description)}
                title={prompt.title}
              >
                <div className="truncate text-sm font-medium text-text-dark">{prompt.title}</div>
                <div className="truncate text-sm text-text-medium">{prompt.description}</div>
              </div>
            ))}
          </StaggeredFadeIn>
        )}

        <Form<{ text: string }>
          formMethods={formMethods}
          onSubmit={onSubmit}
          className="relative  flex w-full max-w-3xl flex-col justify-end self-center pb-4"
        >
          <div className="relative">
            <TextAreaField
              scrollHeight={200}
              placeholder="Message Adscribe..."
              name="text"
              inputClassName="pr-8"
              disabled={!!pendingMessage}
              schema={chatSchema}
              expandable={false}
              autoResize
            />
            <div className="absolute right-2 top-2">
              <IconButton
                disabled={!text || !!pendingMessage}
                size="medium"
                tooltipText="Send"
                variant="generate"
                type="submit"
                Icon={PaperAirplaneIcon}
              />
            </div>
            <Link
              onClick={() => show(<PromptsDialog />)}
              RightIcon={CircleStackIcon}
              size="medium"
              className="px-1 text-sm font-normal text-primary-medium"
            >
              Your Prompts
            </Link>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ChatPage;
