import { GetCandidateAndDocuments } from 'types/graphql';

export const hasCvDetails = (
  candidate: GetCandidateAndDocuments['candidate'] | null | undefined
): boolean => {
  return (
    (candidate?.experience?.length ?? 0) > 0 ||
    (candidate?.certifications?.length ?? 0) > 0 ||
    (candidate?.education?.length ?? 0) > 0 ||
    (candidate?.achievements?.length ?? 0) > 0 ||
    (candidate?.profileSummary?.length ?? 0) > 0
  );
};
