import { useCallback, useEffect, useState } from 'react';
import { navigate, useLocation } from '@redwoodjs/router';

/**
 * Attempts to parse a string as JSON, falling back to the original string on failure.
 *
 * @param paramValue The string value to attempt to parse as JSON.
 * @returns The parsed JSON object if successful, or the original string if parsing fails.
 */
const parseParam = (paramValue: string | null) => {
  try {
    return paramValue ? JSON.parse(paramValue) : null;
  } catch {
    return paramValue;
  }
};

/**
 * A custom hook for synchronizing a single state with a query parameter in the URL.
 *
 * @param key The query parameter key to synchronize with.
 * @param initialValue The initial value of the state if the query parameter is not present.
 * @returns An object containing the current value of the state and a function to update it.
 * @description
 * - `value`: The current value of the state, synchronized with the URL query parameter.
 * - `setValue`: A function to update the state and the corresponding URL query parameter.
 */
export const useQueryParamSyncedState = <T>(key: string, initialValue: T) => {
  const { search, pathname } = useLocation();
  const [value, setValue] = useState<T>(() => {
    const params = new URLSearchParams(search);
    const paramValue = params.get(key);
    return paramValue !== null ? parseParam(paramValue) : initialValue;
  });

  useEffect(() => {
    // Synchronization logic
    const params = new URLSearchParams(search);
    if (value !== null && value !== undefined) {
      params.set(key, JSON.stringify(value));
    } else {
      params.delete(key);
    }
    navigate(`${pathname}?${params.toString()}`, { replace: true });

    // Cleanup logic on component unmount
    return () => {
      const cleanupParams = new URLSearchParams(window.location.search);
      if (cleanupParams.has(key)) {
        cleanupParams.delete(key);
        navigate(`${pathname}?${cleanupParams.toString()}`, { replace: true });
      }
    };
  }, [value, key, pathname, search]);

  return { value, setValue };
};

/**
 * A custom hook for synchronizing an object state with multiple query parameters in the URL.
 *
 * @param initialValue The initial value of the object state, with keys corresponding to query parameter names.
 * @returns An object containing the current value of the object state and a function to update it.
 * @description
 * - `value`: The current value of the object state, with each key-value pair synchronized with a corresponding URL query parameter.
 * - `setValue`: A function to update the object state and the corresponding URL query parameters.
 */
export const useQueryParamSyncedStateObject = <T extends { [key: string]: any }>(
  initialValue: T
) => {
  const { search, pathname } = useLocation();
  const [value, setValue] = useState<T>(() => {
    const params = new URLSearchParams(search);
    return Object.keys(initialValue).reduce((acc, key) => {
      const paramValue = params.get(key);
      acc[key as keyof T] = paramValue !== null ? parseParam(paramValue) : initialValue[key];
      return acc;
    }, {} as T);
  });

  useEffect(() => {
    const params = new URLSearchParams(search);
    Object.entries(value).forEach(([key, val]) => {
      params.set(key, JSON.stringify(val));
    });
    navigate(`${pathname}?${params.toString()}`, { replace: true });
  }, [value, pathname, search]);

  return { value, setValue };
};

/**
 * A custom hook that abstracts away the manipulation of URL search parameters
 * for cleaner and more declarative query parameter updates within components.
 *
 * @returns A function that accepts an object where keys are query parameter names
 * and values are the corresponding values to set in the URL. If a value is an empty string
 * or null, it will remove the parameter from the URL.
 */
export const useSyncQueryParams = () => {
  const { pathname, search } = useLocation();

  const setQueryParams = useCallback(
    (newParams: Record<string, string | null>) => {
      const searchParams = new URLSearchParams(search);

      Object.entries(newParams).forEach(([key, value]) => {
        if (value === null || value === '') {
          searchParams.delete(key);
        } else {
          searchParams.set(key, value);
        }
      });

      navigate(`${pathname}?${searchParams.toString()}`, { replace: true });
    },
    [pathname, search]
  );

  return { setQueryParams };
};
