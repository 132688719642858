import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/react';
import { classNames } from 'src/lib';

export type Tab<T> = {
  label: string;
  value: T;
};

export type InsetTabsProps<T extends string> = {
  tabs: Array<Tab<T>>;
  onTabChange: (tabValue: T) => void;
  selectedTab?: T;
};

export function InsetTabs<T extends string>({ tabs, onTabChange, selectedTab }: InsetTabsProps<T>) {
  return (
    <TabGroup
      selectedIndex={tabs.findIndex((v) => v.value === selectedTab) ?? 0}
      onChange={(index) => {
        onTabChange(tabs[index].value);
      }}
    >
      <TabList className="flex min-w-64 items-center justify-center space-x-2 rounded-md bg-gray-200 p-2">
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            className={({ selected }) =>
              classNames(
                'flex-1 rounded-md px-4 py-2 text-center text-sm font-medium transition-colors focus:outline-none',
                selected
                  ? 'bg-white text-gray-800 shadow-md'
                  : 'bg-gray-200 font-normal text-text-dark hover:bg-gray-100 hover:font-medium'
              )
            }
          >
            {tab.label} {/* Display the label */}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {tabs.map((_, index) => (
          <TabPanel key={index} className="hidden" />
        ))}
      </TabPanels>
    </TabGroup>
  );
}
