export const AUTOFILL_CANDIDATE_MUTATION = gql`
  mutation AutofillCandidateMutation($input: AutofillCandidateNotesOrCvInput!) {
    autofillCandidateNotesOrCv(input: $input) {
      ... on CandidateNotesAutofillResult {
        name
        jobTitle
        location
        desiredJobTitle
        currentSalary
        desiredSalary
        availability
        notes
        rightToWork
      }
      ... on CandidateCvAutofillResult {
        name
        jobTitle
        location
        desiredJobTitle
        availability
        refId
        profileSummary
        experience {
          title
          company
          startDate
          endDate
          location
          description
        }
        education {
          title
          institution
          startDate
          endDate
          location
          description
        }
        languages {
          language
        }
        skills {
          title
          description
        }
        achievements {
          description
        }
        rightToWork
        certifications {
          title
          description
        }
        interests {
          description
        }
      }
    }
  }
`;
