import { CAMPAIGN_STATUS } from 'types/graphql';

/**
 * Only allow entities with a completed campaign to be selected
 */
export const canSelectCompany = ({
  campaignStatus,
}: {
  campaignStatus?: CAMPAIGN_STATUS | undefined | null | string;
}) => campaignStatus === 'COMPLETED';
