import { usePageClasses } from '../../hooks';

const HelpPage = () => {
  usePageClasses('bg-white');

  return (
    <iframe
      sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
      className="flex flex-grow flex-col"
      src={'https://adscribe-wiki.super.site/'}
    />
  );
};

export default HelpPage;
