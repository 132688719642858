import { PropsWithChildren, FC } from 'react';

export const Titlebar: FC<
  PropsWithChildren<{
    button?: React.ReactNode;
  }>
> = ({ button, children }) => {
  return (
    <div className="flex items-center justify-between px-6 py-3 text-xl font-medium text-text-veryDark">
      {children}
      <div className="flex items-center gap-x-3">{button && button}</div>
    </div>
  );
};
