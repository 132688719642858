import { DOCUMENT_FIELDS_FRAGMENT } from '../fragments';

export const SEND_CHAT_MESSAGE_MUTATION = gql`
  ${DOCUMENT_FIELDS_FRAGMENT}
  mutation SendChatMessageMutation($sessionId: String!, $text: String!) {
    sendChatMessage(sessionId: $sessionId, text: $text) {
      id
      title
      messages {
        ... on ChatRequest {
          id
          text
          createdAt
        }
        ... on ChatResponse {
          id
          text
          createdAt
          document {
            ...DocumentFields
          }
        }
      }
    }
  }
`;
