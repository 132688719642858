import { FC, useMemo, useState } from 'react';
import { useQuery } from '@redwoodjs/web';
import { navigate, routes } from '@redwoodjs/router';
import { toast } from '@redwoodjs/web/dist/toast';
import { motion, AnimatePresence } from 'framer-motion';
import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import MiniSearch from 'minisearch';

import { GetPromptsQuery, GetPromptsQueryVariables, Prompt } from '../../../types/graphql';
import { GET_PROMPTS_QUERY } from '../../graphql/queries';
import { useSelectedPromptStore } from '../../services/store';
import { useDebounce, useDialog } from '../../hooks';
import { Button } from '../Button';
import { DialogLayout } from '../DialogLayout';
import { Spinner } from '../Spinner';
import { Link } from '../Link';
import { IconButton } from '../IconButton';
import { CreatePromptDialog } from './CreatePromptDialog';
import { EditPromptDialog } from './EditPromptDialog';
import DeletePromptDialog from './DeletePromptDialog';
import { SearchInput } from '../SearchInput';

export const PromptsDialog = () => {
  const { show, close } = useDialog();
  const [searchValue, setSearchValue] = useState('');
  const searchQuery = useDebounce(searchValue);

  const { data, loading, error } = useQuery<GetPromptsQuery, GetPromptsQueryVariables>(
    GET_PROMPTS_QUERY
  );

  // Create miniSearch instance loaded with prompts
  const miniSearch = useMemo(() => {
    const miniSearch = new MiniSearch({
      fields: ['prompt', 'description'],
      storeFields: ['id', 'prompt', 'description'],
      searchOptions: {
        fuzzy: 0.2,
        prefix: true,
      },
    });
    if (data?.prompts) {
      miniSearch.removeAll();
      miniSearch.addAll(data?.prompts);
    }

    return miniSearch;
  }, [data?.prompts]);

  if (error) {
    return <div>Something went wrong</div>;
  }

  const filteredPrompts = useMemo(
    () =>
      searchQuery
        ? (miniSearch.search(
            { queries: [searchQuery] },
            {
              fields: ['prompt', 'description'],
            }
          ) as unknown as GetPromptsQuery['prompts'])
        : data?.prompts,
    [miniSearch, searchQuery]
  );

  const hasSetSearchValue = searchValue !== '';
  const isEmpty = !hasSetSearchValue && data?.prompts.length === 0;
  const noResults = hasSetSearchValue && filteredPrompts?.length === 0;
  return (
    <DialogLayout onClose={close} title="💬 Your Prompts" className="w-full">
      {loading ? (
        <Spinner />
      ) : isEmpty ? (
        <EmptyState />
      ) : noResults ? (
        <NoResults />
      ) : (
        <div className="overflow-auto px-6 pt-3">
          <div className="mb-8 flex flex-row gap-x-3">
            <SearchInput
              placeholder="Search prompts"
              value={searchValue}
              onChange={setSearchValue}
            />
            <Button
              text="Add Prompt"
              className="flex-shrink-0"
              onClick={() => show(<CreatePromptDialog />)}
            />
          </div>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
            {filteredPrompts?.map((prompt) => (
              <PromptCard key={prompt.id} prompt={prompt} />
            ))}
          </div>
        </div>
      )}
    </DialogLayout>
  );
};

type PromptCardProps = {
  prompt: Pick<Prompt, 'id' | 'prompt' | 'description'>;
};

const PromptCard: FC<PromptCardProps> = ({ prompt }) => {
  const [showMenu, setShowMenu] = useState(false);
  const { show, close } = useDialog();

  const menuItemsClasses = 'rounded-lg border border-text-light bg-white';

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    useSelectedPromptStore.setState({ selectedPrompt: prompt });
    if (window.location.pathname !== routes.chat()) {
      navigate(routes.chat());
    }
    toast.success('Prompt selected.');
    close();
  };
  return (
    <div
      onClick={handleClick}
      onMouseEnter={() => setShowMenu(true)}
      onMouseLeave={() => setShowMenu(false)}
      className="relative w-full cursor-pointer rounded-xl border border-text-light p-6 hover:bg-gray-50"
    >
      <h3 className="line-clamp-2 overflow-hidden overflow-ellipsis whitespace-pre-wrap pb-2 font-medium text-text-dark">
        {prompt.description}
      </h3>
      <p className="line-clamp-4 overflow-hidden overflow-ellipsis text-sm text-text-medium">
        {prompt.prompt}
      </p>
      {showMenu && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute right-2 top-2 flex gap-x-2"
          >
            <div className={menuItemsClasses}>
              <IconButton
                Icon={PencilIcon}
                size="small"
                tooltipText="Edit"
                onClick={() => show(<EditPromptDialog prompt={prompt} />)}
              />
            </div>

            <div className={menuItemsClasses}>
              <IconButton
                Icon={TrashIcon}
                size="small"
                tooltipText="Delete"
                onClick={() => show(<DeletePromptDialog prompt={prompt} />)}
              />
            </div>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
};

const EmptyState = () => {
  const { close, show } = useDialog();
  return (
    <div className="flex flex-grow flex-col items-center justify-center gap-y-8 self-center p-6">
      <p className="text-lg text-text-medium">
        Save your favourite prompts to supercharge your AdScribe Chat workflow. 🚀
      </p>
      <Button
        LeftIcon={PlusIcon}
        text="Add a Prompt"
        onClick={() => show(<CreatePromptDialog />)}
      />
      <p className="whitespace-pre-wrap text-center text-sm text-text-medium">
        Not sure what to add?{'\n'} Start an{' '}
        <Link
          size="small"
          onClick={() => {
            navigate(routes.chat());
            close();
          }}
        >
          💬 AdScribe Chat
        </Link>{' '}
        session to get some ideas.
      </p>
    </div>
  );
};

const NoResults = () => {
  return (
    <div className="flex flex-grow flex-col items-center justify-center gap-y-8 self-center p-6">
      <p className="text-lg text-text-medium">No prompts found with the search criteria above.</p>
    </div>
  );
};
