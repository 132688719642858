export const LANGUAGE_LEVEL_OPTIONS = [
  { label: 'Beginner', value: 1 },
  { label: 'Intermediate', value: 3 },
  { label: 'Fluent', value: 4 },
  { label: 'Native', value: 5 },
];

export const getLanguageLevelLabel = (value: number) => {
  const option = LANGUAGE_LEVEL_OPTIONS.find((opt) => opt.value === value);
  return option ? option.label : 'Unknown';
};
