import { FC } from 'react';
import { FormError, SubmitHandler, useForm } from '@redwoodjs/forms';
import { useMutation } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/dist/toast';
import { zodResolver } from '@hookform/resolvers/zod';

import { CreateSnippetMutation, CreateSnippetMutationVariables } from '../../../types/graphql';
import { Button } from '../Button';
import { DialogLayout } from '../DialogLayout';
import { Spinner } from '../Spinner';
import { TextField } from '../TextField';
import { CREATE_SNIPPET_MUTATION } from '../../graphql/mutations';
import { Form } from '../Form/Form';
import { SnippetValues, snippetFormSchema } from './snippetFormSchema';
import { RichTextField } from '../RichTextField';

type FormValues = {
  name: string;
  text: string;
};

type Props = {
  onClose: () => void;
};

export const CreateSnippetDialog: FC<Props> = ({ onClose }) => {
  const formMethods = useForm<SnippetValues>({
    resolver: zodResolver(snippetFormSchema),
  });

  const [mutate, { loading, error }] = useMutation<
    CreateSnippetMutation,
    CreateSnippetMutationVariables
  >(CREATE_SNIPPET_MUTATION, {
    onCompleted: () => {
      toast.success('Snippet created.');
      onClose();
    },
    update(cache, { data }) {
      if (!data) {
        return;
      }
      cache.modify({
        fields: {
          snippets(existingSnippets = []) {
            const newSnippetRef = cache.writeFragment({
              data: data.createSnippet,
              fragment: gql`
                fragment NewSnippet on Snippet {
                  id
                  name
                  text
                  updatedAt
                  createdAt
                }
              `,
            });
            return [...existingSnippets, newSnippetRef];
          },
        },
      });
    },
  });

  const onSubmit: SubmitHandler<FormValues> = (values) => mutate({ variables: { input: values } });

  return (
    <DialogLayout
      onClose={onClose}
      title="Create Snippet"
      className=" w-full max-w-full overflow-visible rounded-[32px] md:max-w-[802px]"
      titleClassName="text-text-veryDark font-bold text-2xl px-3"
    >
      <div className="relative px-3 py-8">
        <Form
          className="flex min-w-[600px] flex-col gap-y-4"
          onSubmit={onSubmit}
          formMethods={formMethods}
        >
          <TextField label="Description" name="name" schema={snippetFormSchema} />

          <RichTextField
            name="text"
            label="Snippet"
            loading={loading}
            disabled={false}
            placeholder="Write your snippet here"
            className="h-96"
            schema={snippetFormSchema}
            visibleButtons={['bold', 'italic', 'heading1', 'heading2', 'bulletList', 'orderedList']}
          />
          <FormError error={error} />
          <div className="mt-auto flex justify-end space-x-3">
            <Button variant="outline" size="medium" text="Cancel" onClick={onClose} />
            <Button type="submit" size="medium" text="Save" loading={loading} />
          </div>
          {loading && (
            <div className="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-white bg-opacity-80">
              <Spinner />
            </div>
          )}
        </Form>
      </div>
    </DialogLayout>
  );
};
