/**
 * Poll every 5 seconds for status updates
 */
export const GET_STATUS_QUERY = gql`
  query GetStatus {
    statusIncident {
      id
      level
      message
    }
  }
`;
