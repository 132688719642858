import { FC, useEffect, useState } from 'react';
import { compilePromptTemplate } from './handlebars';
import { ReadOnlyField } from '../../components/ReadOnlyField';
import { State } from './PromptEngineeringPage';
import { TemplateContext } from './types';

type Props = {
  prompts: State['prompts'];
  templateContext: TemplateContext;
};

export const CompiledPrompt: FC<Props> = ({ prompts, templateContext }) => {
  return (
    <div className="flex h-full flex-col gap-y-4 overflow-auto border border-gray-300 px-6 py-3">
      <Compiled label="System Prompt" value={prompts.system} templateContext={templateContext} />
      <Compiled label="User Prompt" value={prompts.user} templateContext={templateContext} />
    </div>
  );
};

type CompiledProps = {
  label: string;
  value: string;
  templateContext: TemplateContext;
};

const Compiled: FC<CompiledProps> = ({ label, value, templateContext }) => {
  const [{ compiled, compileError }, setCompiled] = useState<{
    compiled?: string;
    compileError?: string | null;
  }>({ compiled: undefined, compileError: null });

  useEffect(() => {
    try {
      const compiled = compilePromptTemplate(
        value,
        templateContext.config,
        templateContext.input,
        templateContext.userPersona,
        templateContext.user
      );

      setCompiled({ compiled, compileError: null });
    } catch (e) {
      console.error(e);
      setCompiled({ compiled: undefined, compileError: 'Error compiling template' });
    }
  }, [value, templateContext]);

  const compileResult = compileError ? compileError : compiled;

  return <ReadOnlyField name={label} label={label} value={compileResult ?? ''} />;
};
