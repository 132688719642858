import { FC } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { classNames } from 'src/lib';

export type FieldErrorProps = {
  name: string;
  className?: string;
};

export const FieldError: FC<FieldErrorProps> = ({ name, className }) => {
  return (
    <div>
      <ErrorMessage
        name={name}
        as={'div'}
        className={classNames('pl-1 pt-1 text-sm text-error-medium', className)}
      />
    </div>
  );
};
