import { useMutation, useQuery } from '@redwoodjs/web';
import { GET_COMPANY_AND_DOCUMENTS_QUERY } from 'src/graphql/queries';
import {
  ADD_ACCESS_COMPANY_MUTATION,
  REVOKE_ACCESS_COMPANY_MUTATION,
  SET_ACCESS_COMPANY_MUTATION,
} from 'src/graphql/mutations';
import { toast } from '@redwoodjs/web/dist/toast';
import {
  ACCESS_LEVEL,
  AddAccessCompanyMutation,
  AddAccessCompanyMutationVariables,
  GetCompanyAndDocuments,
  GetCompanyAndDocumentsVariables,
  OrganisationPermission,
  RevokeAccessCompanyMutation,
  RevokeAccessCompanyMutationVariables,
  SetAccessCompanyMutation,
  SetAccessCompanyMutationVariables,
  SetAccessOrganisation,
} from 'types/graphql';
import { ShareDialog } from './ShareDialog';
import { InviteFormValues } from './inviteFormSchema';
import { getErrorMessage, isBadUserInputError } from '../../lib/errors';

export type ShareCompanyDialogProps = {
  companyId: string;
  onClose: () => void;
  title: string;
  description: string;
  isOwner: boolean;
};

export const ShareCompanyDialog = ({
  companyId,
  onClose,
  title,
  description,
  isOwner,
}: ShareCompanyDialogProps) => {
  const { data: companyData, previousData: companyPreviousData } = useQuery<
    GetCompanyAndDocuments,
    GetCompanyAndDocumentsVariables
  >(GET_COMPANY_AND_DOCUMENTS_QUERY, {
    variables: { companyId },
    fetchPolicy: 'cache-only',
    returnPartialData: true,
  });

  const companyPermissions = (companyData ?? companyPreviousData)?.company?.permissions ?? [];

  const [addCompanyAccess] = useMutation<
    AddAccessCompanyMutation,
    AddAccessCompanyMutationVariables
  >(ADD_ACCESS_COMPANY_MUTATION);

  const [setCompanyAccess] = useMutation<
    SetAccessCompanyMutation,
    SetAccessCompanyMutationVariables
  >(SET_ACCESS_COMPANY_MUTATION);

  const [revokeCompanyAccess] = useMutation<
    RevokeAccessCompanyMutation,
    RevokeAccessCompanyMutationVariables
  >(REVOKE_ACCESS_COMPANY_MUTATION);

  const handleAddCompanyAccess = async (values: InviteFormValues) => {
    const userAlreadyExists = companyPermissions.some(
      (permission) =>
        permission.__typename === 'UserPermission' && permission.user.email === values.email
    );

    if (userAlreadyExists) {
      toast.error(`User with email ${values.email} already has access.`);
      return;
    }

    await addCompanyAccess({
      variables: {
        input: {
          id: companyId,
          email: values.email,
          permission: values.permission,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        addAccessCompany: {
          __typename: 'Company',
          id: companyId,
          permissions: [
            ...companyPermissions,
            {
              __typename: 'UserPermission',
              id: 'new',
              user: {
                __typename: 'User',
                id: 'new',
                email: values.email,
              },
              permission: values.permission,
            },
          ],
        },
      },
      onCompleted: () => {
        toast.success(`An invite has been sent to ${values.email}.`);
      },
      onError: (error) => {
        if (isBadUserInputError(error)) {
          toast.error(getErrorMessage(error));
        }
      },
    });
  };

  const handleSetCompanyAccessToUser = async (
    userEmail: string,
    newRole: string,
    userName: string
  ) => {
    const permissionRecord = companyPermissions.find((p) =>
      p.__typename === 'UserPermission'
        ? p.user.email === userEmail
        : p.organisation.name === userName
    );

    if (!permissionRecord) {
      toast.error('User Permission not found');
      return;
    }

    const permissionId = permissionRecord.id;
    const isRevokingAccess = newRole === 'Remove' || newRole === 'NONE';

    if (isRevokingAccess) {
      await revokeCompanyAccess({
        variables: {
          input: { user: { entityId: companyId, permissionId } },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          revokeAccessCompany: {
            __typename: 'Company',
            id: companyId,
            permissions: [...companyPermissions.filter((p) => p.id !== permissionId)],
          },
        },
        onCompleted: () => {
          toast.success(`Access for ${userName} has been revoked.`);
        },
      });
    } else {
      await setCompanyAccess({
        variables: {
          input: {
            user: {
              entityId: companyId,
              permission: newRole as ACCESS_LEVEL,
              permissionId,
            },
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          setAccessCompany: {
            __typename: 'Company',
            id: companyId,
            permissions: companyPermissions.map((permission) =>
              permission.id === permissionId
                ? {
                    ...permission,
                    __typename: 'UserPermission',
                    id: 'new',
                    user: {
                      __typename: 'User',
                      id: 'new',
                      email: userEmail,
                    },
                    permission: newRole as ACCESS_LEVEL,
                  }
                : permission
            ),
          },
        },
      });
    }
  };

  const handleSetCompanyAccessToOrganisation = async (newRole: ACCESS_LEVEL | null) => {
    const permissionRecord = companyPermissions.find(
      (p) => p.__typename === 'OrganisationPermission'
    ) as OrganisationPermission;

    if (!permissionRecord) {
      toast.error('User Permission not found');
      return;
    }

    const permissionId = permissionRecord.id;

    const setAccessinput: SetAccessOrganisation = {
      entityId: companyId,
      permission: newRole,
      permissionId,
    };

    await setCompanyAccess({
      variables: {
        input: { organisation: setAccessinput },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        setAccessCompany: {
          __typename: 'Company',
          id: companyId,
          permissions: companyPermissions.map((permission) =>
            permission.id === permissionId
              ? {
                  ...permission,
                  __typename: 'OrganisationPermission',
                  id: permissionId,
                  organisationId:
                    'organisationId' in permissionRecord ? permissionRecord?.organisationId : '',
                  orgPermission: newRole,
                  organisation: {
                    __typename: 'Organisation',
                    id:
                      'organisationId' in permissionRecord ? permissionRecord?.organisationId : '',
                    name:
                      'organisation' in permissionRecord ? permissionRecord?.organisation.name : '',
                  },
                  permission: newRole,
                }
              : permission
          ),
        },
      },
      onCompleted: () => {
        toast.success(`Permission of organisation has been updated successfully`);
      },
    });
  };

  return (
    <ShareDialog
      title={title}
      description={description}
      onClose={onClose}
      isOwner={isOwner}
      permissionsData={companyPermissions}
      onAddAccess={handleAddCompanyAccess}
      onSetAccessToUser={handleSetCompanyAccessToUser}
      onSetAccessToOrganisation={handleSetCompanyAccessToOrganisation}
    />
  );
};
