import { FC, PropsWithChildren } from 'react';

export const PageClassesContext = React.createContext({
  pageClasses: '',
  setPageClasses: (classes: string) => {
    return;
  },
});

/**
 * Used to apply classNames to the page
 */
export const PageClassesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [pageClasses, setPageClasses] = React.useState('');

  return (
    <PageClassesContext.Provider value={{ pageClasses, setPageClasses }}>
      {children}
    </PageClassesContext.Provider>
  );
};
