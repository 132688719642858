import { SparklesIcon } from '@heroicons/react/24/outline';
import { FormProps } from '@redwoodjs/forms';
import { Button } from '../Button';
import { FormError } from '../FormError';
import { Spinner } from '../Spinner';
import { BaseFormProps } from './types';
import { ApolloError } from '@apollo/client';
import { Form } from '../Form/Form';

export type Props<T extends Record<string, unknown>> = {
  formProps?: FormProps<T>;
  children: React.ReactNode;
} & Omit<BaseFormProps, 'onSubmit'>;

export function BaseForm<T extends Record<string, unknown>>({
  children,
  formProps,
  operation,
  documentLoading,
  mutationError,
  mutationLoading,
}: Props<T>) {
  if (documentLoading) {
    return <Spinner />;
  }

  return (
    <Form<T> className="flex min-h-0 flex-1 flex-col gap-y-3 px-16 pt-8" {...formProps}>
      <FormError error={mutationError as ApolloError} />
      <div className="flex flex-1 flex-col gap-y-3">{children}</div>
      <div className="flex flex-col py-4">
        <Button
          type="submit"
          disabled={mutationLoading}
          text={mutationLoading ? 'Loading' : operation === 'create' ? 'Create' : 'Update'}
          LeftIcon={SparklesIcon}
          size="mega"
        />
      </div>
    </Form>
  );
}
