import { z } from 'zod';

export const testimonialRequestFormSchema = z.object({
  recipient: z.string().trim().min(1, {
    message: 'Required',
  }),
  recipientName: z.string().trim().min(1, {
    message: 'Required',
  }),
  // Select field
  companyName: z.object({
    name: z.string().trim().min(1, {
      message: 'Required',
    }),
    id: z.string().nullish(),
  }),
  candidateName: z.string().trim().nullish(),
  duration: z.string().trim().nullish(),
  servicesProvided: z
    .string()
    .trim()
    .max(4000, {
      message: "Can't be longer than 4000 characters",
    })
    .nullish(),
});

export type TestimonialRequestFormValues = z.infer<typeof testimonialRequestFormSchema>;
