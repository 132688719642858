import { classNames } from '../../lib';
import { TEXT_AREA_CLASSES } from './util';

export type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(function TextArea(
  props: TextAreaProps,
  ref
) {
  return (
    <textarea ref={ref} {...props} className={classNames(TEXT_AREA_CLASSES, props.className)} />
  );
});
