import { z } from 'zod';

export const brandingFormSchema = z.object({
  logoAlignment: z.enum(['left', 'center', 'right']),
  footerFont: z.enum(['Roboto', 'serif', 'mono']),
  footerContent: z.string().nullish(),
  logoSize: z.enum(['64', '124', '224']),
  letterheadTopMargin: z.number(),
  letterheadBottomMargin: z.number(),
  letterheadLeftMargin: z.number(),
  letterheadRightMargin: z.number(),
  leftFooter: z.string().nullish(),
  middleFooter: z.string().nullish(),
  rightFooter: z.string().nullish(),
  logoUrl: z.string().nullish(),
  letterheadUrl: z.string().nullish(),
});

export type BrandingFormValues = z.infer<typeof brandingFormSchema>;
