import { PropsWithChildren } from 'react';
// eslint-disable-next-line no-restricted-imports
import { FormProps, Form as RedwoodForm } from '@redwoodjs/forms';
import { FieldValues } from 'react-hook-form';
import { FormEditStateProvider } from 'src/providers';

export const Form = <T extends FieldValues>({
  readOnly = false,
  ...props
}: PropsWithChildren<FormProps<T> & { readOnly?: boolean }>) => {
  return (
    <FormEditStateProvider readOnly={readOnly}>
      <RedwoodForm {...props} />
    </FormEditStateProvider>
  );
};
