import {
  ArchiveBoxIcon,
  ArchiveBoxXMarkIcon,
  ArrowDownTrayIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/24/outline';
import { BanknotesIcon, MapPinIcon, ClipboardIcon } from '@heroicons/react/24/solid';
import { FC } from 'react';
import { Checkbox, DetailSnippet, EntityStatusPill, JobTitleTag, UserIcon } from '../../components';
import { navigate, routes } from '@redwoodjs/router';
import { classNames } from 'src/lib';
import { GetJob, GetJobVariables, Job, Company } from '../../../types/graphql';
import { useQuery } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/dist/toast';
import { GET_JOB_QUERY } from '../../graphql/queries';
import { useDialog } from 'src/hooks';
import { JobExportDialog } from 'src/components/ExportDialogs/JobExportDialog';
import { notNullish } from 'src/lib/guards';
import { canSelectJob } from './util';
import { DropdownButton } from 'src/components/DropdownButton';
import { formatRelativeDateTime } from 'src/lib/dateTime';

type Props = {
  job: Pick<
    Job,
    | 'id'
    | 'myPermission'
    | 'createdAt'
    | 'campaignStatus'
    | 'salary'
    | 'roleAndResponsibilities'
    | 'contractType'
    | 'status'
    | 'title'
  > & { owner: Pick<Job['owner'], 'id' | 'name' | 'email'> } & {
    company: Pick<Company, 'name' | 'location'>;
  };
  selected?: boolean;
  onToggleSelected?: (id: string) => void;
  hideCheckbox?: boolean;
  hideDropdownButton?: boolean;
  onArchiveJob?: (job: Pick<Job, 'id'>) => void;
  onUnArchiveJob?: (job: Pick<Job, 'id'>) => void;
  onDuplicateJob?: (job: Pick<Job, 'id'>) => void;
};

export const JobTableRow: FC<Props> = ({
  job,
  selected,
  onToggleSelected,
  hideCheckbox,
  hideDropdownButton,
  onArchiveJob,
  onUnArchiveJob,
  onDuplicateJob,
}) => {
  const { show } = useDialog();
  /**
   * Poll campaign status if processing pill is shown
   */
  useQuery<GetJob, GetJobVariables>(GET_JOB_QUERY, {
    pollInterval: 5000,
    variables: { id: job.id },
    skip: job.campaignStatus !== 'PENDING',
    onCompleted: (data) => {
      if (data?.job?.campaignStatus !== 'PENDING') {
        toast.success('Your Campaign has been created!');
      }
    },
  });

  const isPending = job.campaignStatus === 'PENDING';
  const isOwner = job.myPermission === 'OWNER';

  const onClick = () => {
    if (isPending) return;
    navigate(routes.job({ jobId: job.id }));
  };

  const archiveButton =
    job.status === 'ACTIVE'
      ? {
          Icon: ArchiveBoxIcon,
          text: 'Archive',
          onClick: () => onArchiveJob?.(job),
        }
      : {
          Icon: ArchiveBoxXMarkIcon,
          text: 'Restore',
          onClick: () => onUnArchiveJob?.(job),
        };

  const dropdownOptions = [
    isOwner ? archiveButton : null,
    {
      Icon: DocumentDuplicateIcon,
      text: 'Create a copy',
      onClick: () => {
        onDuplicateJob?.(job);
      },
    },
    {
      Icon: ArrowDownTrayIcon,
      text: 'Export job details',
      onClick: () => show(<JobExportDialog id={job.id} />),
    },
  ].filter(notNullish);

  return (
    <li className="relative flex items-center">
      {!hideCheckbox && (
        <div className="mx-2 flex h-6 w-6">
          {canSelectJob(job) && (
            <Checkbox
              value={!!selected}
              onChange={() => onToggleSelected && onToggleSelected(job.id)}
            />
          )}
        </div>
      )}
      <div
        onClick={onClick}
        className={classNames(
          'flex flex-grow items-center border-b border-text-light py-4 pl-3',
          job.campaignStatus !== 'PENDING' && 'cursor-pointer hover:bg-gray-50'
        )}
      >
        <div className="flex flex-1 flex-col gap-y-1">
          <div className="text-text-dark">{job.company.name}</div>
          <DetailSnippet Icon={MapPinIcon} text={job.company?.location} />
        </div>
        <div className="flex flex-1 flex-col gap-y-1">
          <JobTitleTag jobTitle={job.title} />
          <div className="flex items-center gap-3">
            <DetailSnippet Icon={ClipboardIcon} text={job.contractType} />
            <DetailSnippet Icon={BanknotesIcon} text={job.salary} />
          </div>
        </div>
        <div className="flex w-32 justify-center">
          <UserIcon user={job.owner} />
        </div>
        <div className="flex-1 pl-8 text-sm text-text-medium">
          {formatRelativeDateTime(new Date(job.createdAt))}
        </div>
        <div className="flex h-4 flex-1 justify-center">
          <div>
            <EntityStatusPill campaignStatus={job.campaignStatus} status={job.status} />
          </div>
        </div>

        {!hideDropdownButton && (
          <div className="px-6">
            {dropdownOptions && <DropdownButton options={dropdownOptions} />}
          </div>
        )}
      </div>
    </li>
  );
};
