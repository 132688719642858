import z from 'zod';

export const marketingStrategyFormSchema = z.object({
  industry: z.string().trim().min(1, {
    message: 'Required',
  }),
  targetClients: z.string().trim().min(1, {
    message: 'Required',
  }),
  targetCandidates: z.string().trim().min(1, {
    message: 'Required',
  }),
});

export type MarketingStrategyFormValues = z.infer<typeof marketingStrategyFormSchema>;
