import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from '@redwoodjs/web//toast';

const supportedFileTypes = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/vnd.ms-excel',
];

const KB_BYTES = 1024;
const MB_BYTES = KB_BYTES * KB_BYTES;

/**
 * Hook to handle file drag and drop
 *
 * @param onUploadFile  Function to handle file upload
 * @returns  Object containing props to be spread on the root element, input element and a boolean indicating if the drag is active
 */
export function useFileDragDrop(onUploadFile: (file: File) => void) {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      if (!file) {
        toast.error('Something went wrong.');
      }

      if (!supportedFileTypes.includes(file.type)) {
        toast.error('File type not supported - must be PDF or Office File');
        return;
      }

      if (file.size > 5 * MB_BYTES) {
        toast.error('File size must be less than 5MB');
        return;
      }

      onUploadFile(file);
    },
    [onUploadFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  return { getRootProps, getInputProps, isDragActive };
}
