export const GET_CV_TEMPLATES_QUERY = gql`
  query GetCvTemplatesQuery {
    getCvTemplates {
      id
      name
      preview {
        src
      }
    }
  }
`;
