export const GET_COMPANIES_QUERY = gql`
  query GetCompanies($input: CompaniesInput!) {
    companies(input: $input) {
      nodes {
        id
        campaignStatus
        status
        name
        location
        createdAt
        myPermission
        owner {
          id
          name
          email
        }
      }
      pageInfo {
        count
        isLast
        page
      }
    }
  }
`;
