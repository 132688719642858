import { FC, useEffect, useState } from 'react';
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  Label,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../lib';
import { Spinner } from '../Spinner';
import { IconButton } from '../IconButton';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { FieldError } from '../FieldError';

export type AutocompleteProps<Option> = {
  value: Option;
  onChange: (value: Option | null) => void;
  onBlur: (query: string) => void;
  name?: string;
  label: string;
  options: Option[];
  loading?: boolean;
  filterOptions?: (options: Option[], query: string) => Option[];
  getOptionLabel: (option: Option) => string;
  getOptionKey: (option: Option) => string;
  LeftIcon?: FC<React.ComponentProps<'svg'>>;
  createIfNoneFound?: boolean;
  error?: boolean;
  required?: boolean;
  onFocus?: () => void;
  placeholder?: string;
  onQueryChange?: (query: string) => void;
  queryToOption?: (query: string) => Option;
  disabled?: boolean;
};

export function Autocomplete<Option>({
  value,
  onChange,
  onBlur,
  name,
  label,
  options,
  loading,
  disabled,
  filterOptions,
  getOptionLabel,
  getOptionKey,
  error,
  required,
  onFocus,
  placeholder,
  onQueryChange,
  queryToOption,
  LeftIcon,
}: AutocompleteProps<Option>) {
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (value) {
      setQuery(getOptionLabel(value));
    }
  }, [value]);

  useEffect(() => {
    if (onQueryChange) {
      onQueryChange(query);
    }
  }, [query]);

  const filteredOptions = filterOptions ? filterOptions(options, query) : options;

  const handleBlur = () => {
    onBlur(query);
  };

  const canClear = (query?.length > 0 ?? !!value) && !disabled;

  const onClear = () => {
    setQuery('');
    onChange(null);
  };

  return (
    <div className="relative">
      <Combobox
        as="div"
        value={value}
        disabled={disabled}
        onChange={(v: Option) => {
          onChange(v);
          setQuery(getOptionLabel(v));
        }}
        by={(a, b) => getOptionLabel(a) === getOptionLabel(b)}
      >
        <div className="flex justify-between">
          <Label
            className={classNames(
              'block text-sm font-medium text-text-dark',
              error && 'text-error-dark'
            )}
          >
            {label}
          </Label>
          {!required && <span className="text-sm text-text-medium">Optional</span>}
        </div>
        <div className="relative mt-1 flex items-center">
          {LeftIcon && (
            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              <LeftIcon className="h-4 w-4 text-text-medium" aria-hidden="true" />
            </div>
          )}
          <ComboboxInput
            autoComplete="off"
            displayValue={(v) => getOptionLabel(v as Option)}
            name={name}
            onFocus={onFocus}
            placeholder={placeholder}
            className={classNames(
              'w-full min-w-[150px] rounded-lg border bg-white py-2 pr-10 shadow-sm placeholder:text-text-medium focus:border-primary-medium focus:outline-none focus:ring-1 focus:ring-primary-medium disabled:bg-gray-100',
              error ? 'border-error-light text-error-medium' : 'border-gray-300',
              LeftIcon ? 'pl-8' : 'pl-3'
            )}
            onChange={(event) => setQuery(event.target.value)}
            onBlur={handleBlur}
          />
          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </ComboboxButton>
          {canClear && (
            <div className="absolute inset-y-0 right-0 flex items-center pr-7">
              <IconButton
                size="small"
                onClick={onClear}
                Icon={XMarkIcon}
                tooltipText="Clear"
                className="ml-2"
              />
            </div>
          )}
        </div>
        <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-xl bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {loading ? (
            <Spinner className="h-3 w-3" />
          ) : (
            filteredOptions.map((option) => (
              <ComboboxOption
                key={getOptionKey(option)}
                value={option}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-8 pr-4',
                    active ? 'bg-primary-medium text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected && 'font-semibold')}>
                      {getOptionLabel(option)}
                    </span>
                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 left-0 flex items-center pl-1.5',
                          active ? 'text-white' : 'text-primary-medium'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </ComboboxOption>
            ))
          )}
          {query?.length > 0 &&
            query !== getOptionLabel(value) &&
            !options.some((o) => getOptionLabel(o) === query) && (
              <ComboboxOption
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-8 pr-4',
                    active ? 'bg-primary-medium text-white' : 'text-gray-900'
                  )
                }
                value={queryToOption ? queryToOption(query) : query}
              >
                Create &ldquo;{query}&ldquo;
              </ComboboxOption>
            )}
          {!(query || value) && (
            <div className="py-3 text-center text-xs text-text-medium">
              Start typing to create a new entry
            </div>
          )}
        </ComboboxOptions>
      </Combobox>
      <FieldError name={name ?? ''} />
    </div>
  );
}
