import * as Sentry from '@sentry/react';
// Must be imported before apollo provider to ensure trace header works
Sentry.init({
  dsn: process.env.SENTRY_FRONTEND_DSN,
  ignoreErrors: [
    'AbortError',
    'from uncontrolled',
    "Cannot read properties of undefined (reading 'prototype')",
    'getHTML',
    'Missing field',
  ],
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [
        'https://app.adscribe.co/*',
        'https://app.*.adscribe.co/*',
        'https://api.adscribe.co/*',
        'https://api.*.adscribe.co/*',
        'http://localhost:8911/*',
        'http://localhost:8910/*',
        'http://localhost:8911',
        'http://localhost:8910',
      ],
    }),
    new CaptureConsole({ levels: ['error'] }),
    new HttpClient(),
    new Replay(),
  ],
});
import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web';
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo';
import FatalErrorPage from 'src/pages/FatalErrorPage';
import Routes from 'src/Routes';
import { CaptureConsole, HttpClient } from '@sentry/integrations';
import { toast, Toaster } from '@redwoodjs/web/toast';
import { onError } from '@apollo/client/link/error';
import { ApolloLink } from '@apollo/client';
import './index.css';
import { BrowserTracing } from '@sentry/tracing';
import { DialogProvider, PageClassesProvider } from './providers';
import { Replay } from '@sentry/react';
import { Document, DocumentVersion, Permission } from '../types/graphql';
import { AuthProvider, dbAuthClient, useAuth } from './auth';
import { AppVersionChecker } from './components/AppVersionChecker';
import { GRAPHQL_ERROR_CODES } from '../../api/src/lib/errors';

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  /**
   * Our default mutation error handling is to show a toast message.
   * If you want to handle errors differently, you can do so
   * here.
   */
  const isMutation = operation.query.definitions.some(
    (i) => 'operation' in i && i.operation === 'mutation'
  );
  if (graphQLErrors) {
    const firstError = graphQLErrors[0];
    const firstErrorCode = firstError?.extensions?.code as GRAPHQL_ERROR_CODES;
    const shouldLogOut =
      firstErrorCode === 'UNAUTHENTICATED' || firstErrorCode === 'NO_SUBSCRIPTION';
    if (shouldLogOut) {
      dbAuthClient.logout().then(() => window.location.reload());
    }
    if (isMutation) {
      if (firstErrorCode === 'NO_CREDITS') {
        toast.error(firstError.message);
      } else if (firstErrorCode === 'BAD_USER_INPUT') {
        // Noop: BAD_USER_INPUT represents validation errors. These are usually presented by <FormError/> component.
      } else {
        // Default mutation error handling behavior
        toast.error('Something went wrong. Please try again.');
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      }
    }
  } else if (networkError) {
    toast.error('Something went wrong. Please try again.');
    console.error(`[Network error]: ${networkError}`);
  }
});

const App = () => (
  <FatalErrorBoundary page={FatalErrorPage}>
    <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
      <AuthProvider>
        <RedwoodApolloProvider
          useAuth={useAuth}
          graphQLClientConfig={{
            httpLinkConfig: {
              credentials: 'include',
            },
            link: (rwLinks) => ApolloLink.from([errorLink, ...rwLinks]),
            cacheConfig: {
              possibleTypes: {
                Document: [
                  'JobAdvert',
                  'JobInMail',
                  'JobEmail',
                  'JobInterviewPreparation',
                  'JobSocialPost',
                  'JobSnapshot',
                  'JobBooleanSearch',
                  'JobReferenceCheck',
                  'JobInterviewQuestions',
                  'CandidateIntroduction',
                  'CandidateSnapshot',
                  'CandidateCv',
                  'CompanyIntroduction',
                  'CompanySnapshot',
                  'ClientProposal',
                  'BlogPost',
                  'BlogPostIdeas',
                  'TestimonialRequest',
                  'MarketingStrategy',
                  'General',
                  'ClientOutreach',
                ] as Document['__typename'][],
                DocumentVersion: [
                  'RestoreDocumentAction',
                  'EditDocumentAction',
                  'RegenerateDocumentAction',
                  'ChatDocumentAction',
                  'CreateDocumentAction',
                ] as DocumentVersion['__typename'][],
                Permission: [
                  'OrganisationPermission',
                  'UserPermission',
                ] as Permission['__typename'][],
              },
            },
          }}
        >
          <DialogProvider>
            <Toaster containerClassName="rounded-lg z-10" />
            <AppVersionChecker>
              <PageClassesProvider>
                <Routes />
              </PageClassesProvider>
            </AppVersionChecker>
          </DialogProvider>
          <Toaster />
        </RedwoodApolloProvider>
      </AuthProvider>
    </RedwoodProvider>
  </FatalErrorBoundary>
);

export default App;
