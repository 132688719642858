export const GET_BRANDED_DOCUMENT_CONFIG_QUERY = gql`
  query GetBrandedDocumentConfig {
    getBrandedDocumentConfig {
      id
      headerStyle
      logoConfig {
        id
        image {
          id
          src
        }
        alignment
        size
      }
      letterheadConfig {
        id
        image {
          id
          src
        }
        alignment
      }
      fontConfig {
        header
        body
      }
      marginsConfig {
        top
        horizontal
      }
      footerConfig {
        content
        alignment
      }
      showTableBorders
    }
  }
`;
