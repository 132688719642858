export const UPSERT_LETTERHEAD_IMAGE_MUTATION = gql`
  mutation UpsertLetterheadImage($input: UpsertImageInput!) {
    upsertLetterheadImage(input: $input) {
      id
      image {
        id
        src
      }
      alignment
    }
  }
`;
