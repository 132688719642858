import { ChevronDownIcon, DocumentTextIcon, PlusIcon, UserIcon } from '@heroicons/react/24/outline';
import { NetworkStatus } from '@apollo/client';
import { navigate, routes } from '@redwoodjs/router';
import { MetaTags, useMutation, useQuery } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/dist/toast';
import { FC, useState } from 'react';
import {
  Candidate,
  DuplicateCandidateMutation,
  DuplicateCandidateMutationVariables,
  GetCandidates,
  GetCandidatesVariables,
  UpdateCandidate,
  UpdateCandidateVariables,
} from 'types/graphql';
import { Checkbox, Pagination, SearchInput } from '../../components';
import { Button } from '../../components/Button';
import { DropdownButton } from '../../components/DropdownButton';
import { PageTitle } from '../../components/PageTitle';
import { Spinner } from '../../components/Spinner';
import { GET_CANDIDATES_QUERY } from '../../graphql/queries';
import { useDebounce, usePageClasses, useTrackPageView } from '../../hooks';
import { useCreateSpecEmail } from '../../hooks/useCreateSpecEmail';
import { CandidateTableRow } from './CandidateTableRow';
import { DUPLICATE_CANDIDATE_MUTATION, UPDATE_CANDIDATE_MUTATION } from '../../graphql/mutations';
import {
  useQueryParamSyncedState,
  useQueryParamSyncedStateObject,
} from 'src/hooks/useQueryParamSyncedState';

type FilterValuesType = {
  mineOnly: boolean;
  includeArchived: boolean;
};
import { canSelectCandidate } from './util';

const PAGE_LIMIT = 10;

const CandidatesPage = () => {
  useTrackPageView();
  usePageClasses('bg-white');

  const { value: filterValues, setValue: setFilterValues } =
    useQueryParamSyncedStateObject<FilterValuesType>({
      mineOnly: true,
      includeArchived: false,
    });
  const { value: page, setValue: setPage } = useQueryParamSyncedState('page', 1);
  const [searchTerm, setSearchTerm] = useState<string | null>('');
  const [selected, setSelected] = useState<string[]>([]);

  const debouncedFilterValues = useDebounce({ ...filterValues, searchTerm });

  const queryVariables: GetCandidatesVariables = {
    input: {
      connection: { page, take: PAGE_LIMIT },
      searchTerm: debouncedFilterValues.searchTerm,
      statuses: debouncedFilterValues.includeArchived ? ['ACTIVE', 'ARCHIVED'] : ['ACTIVE'],
      accessLevel: debouncedFilterValues.mineOnly ? 'OWNER' : 'READ',
    },
  };

  const {
    error,
    networkStatus,
    data: queryData,
    previousData,
  } = useQuery<GetCandidates, GetCandidatesVariables>(GET_CANDIDATES_QUERY, {
    variables: queryVariables,
  });

  const [updateCandidate] = useMutation<UpdateCandidate, UpdateCandidateVariables>(
    UPDATE_CANDIDATE_MUTATION,
    {
      optimisticResponse: ({ input: { status }, id }) => {
        return {
          __typename: 'Mutation',
          updateCandidate: {
            __typename: 'Candidate',
            id,
            status,
          },
        } as unknown as UpdateCandidate;
      },
      onCompleted: ({ updateCandidate: { status } }) => {
        if (status === 'ARCHIVED') {
          toast.success('Candidate Archived');
        } else {
          toast.success('Candidate restored');
        }
      },
      update: (cache, { data }) => {
        if (debouncedFilterValues.includeArchived === false) {
          cache.updateQuery<GetCandidates, GetCandidatesVariables>(
            {
              query: GET_CANDIDATES_QUERY,
              variables: queryVariables,
            },
            (cacheData) => {
              return {
                __typename: 'Query',
                candidates: {
                  __typename: 'CandidatesConnection',
                  nodes:
                    cacheData?.candidates?.nodes.filter(
                      (j) => j.id !== data?.updateCandidate?.id
                    ) ?? [],
                  pageInfo: {
                    // Make typescript happy
                    isLast: cacheData?.candidates?.pageInfo?.isLast ?? true,
                    ...cacheData?.candidates?.pageInfo,
                    __typename: 'PageInfo',
                    count: (cacheData?.candidates?.pageInfo?.count ?? 1) - 1,
                  },
                },
              };
            }
          );
        }
      },
      refetchQueries: [GET_CANDIDATES_QUERY],
    }
  );

  const [duplicateCandidate] = useMutation<
    DuplicateCandidateMutation,
    DuplicateCandidateMutationVariables
  >(DUPLICATE_CANDIDATE_MUTATION, {
    onCompleted: (data) => {
      toast.success('Candidate created!');
      navigate(routes.candidate({ candidateId: data.duplicateCandidate.id }));
    },
  });

  const { createSpecEmail, loading: specEmailLoading } = useCreateSpecEmail({
    candidateIds: selected,
  });

  const data = queryData ?? previousData;
  const loading = networkStatus === NetworkStatus.loading && !queryData;
  const paginationPending = networkStatus === NetworkStatus.setVariables && !queryData;
  const pageInfo = data?.candidates.pageInfo;

  const handleSearchInputChange = (newValue: string | null) => {
    setSearchTerm(newValue);
  };

  const handleIncludeArchivedChange = (newValue: boolean) => {
    setFilterValues({ ...filterValues, includeArchived: newValue });
  };

  const handleMineOnlyChange = (newValue: boolean) => {
    setFilterValues({ ...filterValues, mineOnly: newValue });
  };

  const handleSetPage = (newPage: number) => {
    setPage(newPage);
  };

  const onToggleSelected = (id: string) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((s) => s !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const onExportSpecEmail = () => {
    toast.promise(createSpecEmail(), {
      loading: 'Generating Combined Spec Email...',
      success: 'Success',
      error: 'Something went wrong, please try again.',
    });
  };

  const onArchiveCandidate = (candidate: Pick<Candidate, 'id'>) => {
    updateCandidate({
      variables: {
        id: candidate.id,
        input: {
          status: 'ARCHIVED',
        },
      },
    });
  };

  const onUnArchiveCandidate = (candidate: Pick<Candidate, 'id'>) => {
    updateCandidate({
      variables: {
        id: candidate.id,
        input: {
          status: 'ACTIVE',
        },
      },
    });
  };

  const onDuplicateCandidate = (candidate: Pick<Candidate, 'id'>) => {
    duplicateCandidate({
      variables: {
        id: candidate.id,
      },
    });
  };

  if (error) {
    throw error;
  }

  if (loading) {
    return <Spinner />;
  }

  const hasSetFilterValues = debouncedFilterValues.searchTerm !== '';
  const isEmpty = !hasSetFilterValues && data?.candidates?.nodes.length === 0;
  const noResults = hasSetFilterValues && data?.candidates?.nodes.length === 0;

  const selectableCandidates = data?.candidates?.nodes.filter(canSelectCandidate) ?? [];

  const onSelectAll = () => {
    if (selected.length === selectableCandidates.length) {
      setSelected([]);
    } else {
      setSelected(selectableCandidates.map((j) => j.id));
    }
  };

  return (
    <div className="flex h-full w-full flex-col items-center px-16">
      <div className="flex w-full max-w-6xl flex-grow flex-col">
        <MetaTags title="Candidates" description="Candidates page" />
        <div className="flex flex-row items-center justify-between pb-4 pt-12">
          <PageTitle text="Candidates" Icon={UserIcon} size="lg" />
          <Button
            text="New Candidate"
            LeftIcon={PlusIcon}
            onClick={() => navigate(routes.newCandidate())}
          />
        </div>
        <div className="flex w-full flex-row items-center gap-x-4 pb-4 pt-6">
          <div className="mx-2">
            {!!data?.candidates?.nodes.length && (
              <Checkbox
                onChange={onSelectAll}
                value={selected.length === data?.candidates?.nodes.length}
              />
            )}
          </div>
          <div className="max-w-[600px] flex-grow">
            <SearchInput
              placeholder="Search by name and job title"
              value={searchTerm ?? ''}
              onChange={handleSearchInputChange}
            />
          </div>
          <Checkbox
            value={filterValues.mineOnly}
            label="Mine only"
            onChange={handleMineOnlyChange}
          />
          <Checkbox
            value={filterValues.includeArchived}
            label="Include archived"
            onChange={handleIncludeArchivedChange}
          />
          {!!selected.length && (
            <div className="flex flex-1 items-center justify-end">
              <p className="px-2 text-sm text-text-medium">{selected.length} selected</p>
              <DropdownButton
                buttonText="Batch Actions"
                Icon={ChevronDownIcon}
                options={[
                  {
                    Icon: DocumentTextIcon,
                    onClick: () => onExportSpecEmail(),
                    text: 'Combined Spec Email',
                    disabled: specEmailLoading,
                  },
                ]}
              />
            </div>
          )}
        </div>
        {loading ? (
          <Spinner />
        ) : isEmpty ? (
          <Empty />
        ) : (
          <>
            {noResults ? (
              <NoResults />
            ) : (
              <div className="flex-grow">
                <div className="relative">
                  {data?.candidates.nodes.map((candidate) => (
                    <CandidateTableRow
                      candidate={candidate}
                      key={candidate.id}
                      onToggleSelected={onToggleSelected}
                      onDuplicateCandidate={onDuplicateCandidate}
                      selected={selected.includes(candidate.id)}
                      onArchiveCandidate={onArchiveCandidate}
                      onUnArchiveCandidate={onUnArchiveCandidate}
                    />
                  ))}
                </div>
                {pageInfo && (
                  <Pagination
                    loading={paginationPending}
                    count={pageInfo.count}
                    limit={PAGE_LIMIT}
                    isLast={pageInfo.isLast}
                    page={pageInfo.page}
                    thisPageCount={data?.candidates.nodes.length}
                    onSetPage={handleSetPage}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export const Empty: FC = () => (
  <div className="flex flex-grow flex-col">
    <div className="flex flex-grow flex-col items-center justify-center gap-y-2">
      <p className="font-medium text-text-medium">You haven&apos;t created any Candidates yet.</p>
      <p className="text-text-medium">
        Click the button below to create a Candidate and auto-generate a Candidate Introduction and
        Summary.
      </p>
      <div className="pb-6 pt-4">
        <Button
          onClick={() => navigate(routes.newCandidate())}
          variant="outline"
          size="medium"
          LeftIcon={PlusIcon}
          text="Create a Candidate"
        />
      </div>
    </div>
  </div>
);

export const NoResults: FC = () => (
  <div className="flex flex-grow flex-col">
    <div className="flex flex-grow flex-col items-center justify-center gap-y-2">
      <p className="font-medium text-text-medium">
        No Candidates found with the search criteria above.
      </p>
      <p className="text-text-medium">
        Click the button below to create a Candidate and auto-generate a Candidate Introduction and
        Summary.
      </p>
      <div className="pb-6 pt-4">
        <Button
          onClick={() => navigate(routes.newCandidate())}
          variant="outline"
          size="medium"
          LeftIcon={PlusIcon}
          text="Create a Candidate"
        />
      </div>
    </div>
  </div>
);

export default CandidatesPage;
