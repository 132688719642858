import { DOCUMENT_FIELDS_FRAGMENT } from '../fragments';

export const CREATE_TEMPLATE_DOC_MUTATION = gql`
  ${DOCUMENT_FIELDS_FRAGMENT}
  mutation CreateTemplateDocMutation($input: CreateTemplateDocumentInput!) {
    createTemplateDocument(input: $input) {
      ...DocumentFields
    }
  }
`;
