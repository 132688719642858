import { FC, useEffect, useState } from 'react';
import { SettingsItem } from '../SettingsComponents';
import { Select } from '../../../components';
import { useDebounce } from '../../../hooks';

type Props = {
  label: string;
  initialValue?: string;
  onChange: (value: string) => void;
  loading?: boolean;
  options: { label: string; value: string }[];
};

export const DebouncedSelect: FC<Props> = ({ initialValue, onChange, label, loading, options }) => {
  const [value, setValue] = useState<string>(initialValue ?? '');
  const debouncedValue = useDebounce(value, 1000);

  useEffect(() => {
    if (debouncedValue && debouncedValue !== initialValue) {
      onChange(debouncedValue);
    }
  }, [debouncedValue]);

  return (
    <SettingsItem
      label={label}
      value=""
      labelClassName="text-base font-medium"
      className="max-w-[578px]"
    >
      <Select options={options} onChange={setValue} loading={loading} value={value} />
    </SettingsItem>
  );
};
