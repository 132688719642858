import { gql } from '@apollo/client';
import { DOCUMENT_FIELDS_FRAGMENT, PERMISSION_FIELDS_FRAGMENT } from '../fragments';

// TODO: FIX FRAGMENT INTERPOLATION https://github.com/redwoodjs/redwood/issues/7491
export const GET_JOB_AND_DOCUMENTS = gql`
  ${DOCUMENT_FIELDS_FRAGMENT}
  ${PERMISSION_FIELDS_FRAGMENT}

  query GetJobAndDocuments($jobId: String!) {
    job(id: $jobId) {
      id
      title
      salary
      status
      campaignStatus
      contractType
      roleAndResponsibilities
      mustHaves
      company {
        id
        name
        location
        overview
        culture
        benefits
      }
      documents {
        ...DocumentFields
      }
      permissions {
        ...PermissionFields
      }
      myPermission
    }
  }
`;
