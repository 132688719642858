import { useMutation, useQuery } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/dist/toast';
import { useAuth } from 'src/auth';
import { DropdownSelect } from 'src/components/DropdownSelect';
import { Spinner } from 'src/components/Spinner';
import { SET_DEFAULT_ORGANISATION_PERMISSION_MUTATION } from 'src/graphql/mutations';
import { GET_ORGANISATION_QUERY } from 'src/graphql/queries';
import { USER_ORGANISATION_DEFAULT_ROLE_DROPDOWN_OPTIONS } from 'src/lib/sharedConstants/userRoleDropdownOptions';
import { hasRole } from 'src/lib/user';
import {
  ACCESS_LEVEL_SETTING,
  GetOrganisationQuery,
  GetOrganisationQueryVariables,
  SetDefaultOrganisationPermissionInput,
  SetDefaultOrganisationPermissionMutation,
  SetDefaultOrganisationPermissionMutationVariables,
} from 'types/graphql';

export const PermissionsSection = () => {
  const { currentUser } = useAuth();
  const isAdmin = currentUser ? hasRole(currentUser, 'ADMIN') : false;

  const {
    data: organisationData,
    loading,
    error,
  } = useQuery<GetOrganisationQuery, GetOrganisationQueryVariables>(GET_ORGANISATION_QUERY, {
    variables: {
      isAdmin,
    },
  });

  const [setDefaultOrganisationPermission] = useMutation<
    SetDefaultOrganisationPermissionMutation,
    SetDefaultOrganisationPermissionMutationVariables
  >(SET_DEFAULT_ORGANISATION_PERMISSION_MUTATION, {
    onCompleted: () => toast.success('Permissions updated successfully'),
    refetchQueries: [GET_ORGANISATION_QUERY],
  });

  const jobsPermission = organisationData?.organisation?.defaultJobPermissions;

  const candidatesPermission = organisationData?.organisation?.defaultCandidatePermissions;

  const companiesPermission = organisationData?.organisation?.defaultCompanyPermissions;

  const handlePermissionChange = async (input: SetDefaultOrganisationPermissionInput) => {
    await setDefaultOrganisationPermission({ variables: { input } });
  };

  if (loading) return <Spinner />;
  if (error) return <p>Error loading permissions: {error.message}</p>;

  const renderPermissionControl = (
    permission: ACCESS_LEVEL_SETTING | null | undefined,
    onChangeCallback: (newPermission: ACCESS_LEVEL_SETTING) => void
  ) => {
    return isAdmin ? (
      <DropdownSelect
        items={USER_ORGANISATION_DEFAULT_ROLE_DROPDOWN_OPTIONS}
        value={permission as ACCESS_LEVEL_SETTING}
        onChange={onChangeCallback}
      />
    ) : (
      <span className=" text-text-medium">{permission || 'Private'}</span>
    );
  };

  return (
    <div className="w-full max-w-[740px]">
      <div className="flex flex-col justify-center gap-2">
        <h3 className="text-xl font-bold text-text-dark">Permissions</h3>
        <p className="mb-6 mt-2 text-sm font-medium text-text-medium">
          Set default workspace permissions for Jobs, Candidates and Companies created by your team.
          Users can override these in their personal settings.
        </p>
      </div>

      <div className="max-w-[355px] space-y-8 pt-2">
        <div>
          <div className="flex items-center justify-between">
            <span className="font-medium text-text-medium">Jobs</span>
            {renderPermissionControl(jobsPermission, (newPermission) =>
              handlePermissionChange({ jobs: newPermission })
            )}
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between">
            <span className="font-medium text-text-medium">Candidates</span>
            {renderPermissionControl(candidatesPermission, (newPermission) =>
              handlePermissionChange({ candidates: newPermission })
            )}
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between">
            <span className="font-medium text-text-medium">Companies</span>
            {renderPermissionControl(companiesPermission, (newPermission) =>
              handlePermissionChange({ companies: newPermission })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
