export const EXTRACT_JOB_URL_MUTATION = gql`
  mutation ExtractJobUrlMutation($url: String!) {
    extractJobUrl(url: $url) {
      __typename
      jobTitle
      jobResponsibilities
      jobRequirements
      companyName
      companyOverview
      location
      companyCulture
      companyBenefits
      contract
      salary
    }
  }
`;
