import { FC, useEffect, useRef, useState } from 'react';
import { useForm, useFieldArray } from '@redwoodjs/forms';
import { zodResolver } from '@hookform/resolvers/zod';
import { Link, SelectField, TextAreaField, TextField } from 'src/components';
import { DatePickerField } from 'src/components/DatePicker';
import { RichTextField } from '../RichTextField';
import { ArrowLeftIcon, PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Form } from 'src/components/Form/Form';
import { Reorder } from 'framer-motion';
import { DraggableItem } from 'src/components/DraggableItem';
import { useMutation } from '@redwoodjs/web';
import { UPDATE_STRUCTURED_DOCUMENT_MUTATION } from 'src/graphql/mutations';
import {
  CandidateCvDocumentMarkupFormValues,
  candidateCvDocumentMarkupFormSchema,
} from './structuredDocumentFormSchema';
import { Accordion } from '../Accordion';
import { useBoolean, useDebounce } from 'src/hooks';
import {
  CandidateCv,
  CvInput,
  UpdateStructuredDocument,
  UpdateStructuredDocumentVariables,
} from 'types/graphql';
import { formatStartEndDateSnippet } from 'src/lib/candidateUtil';
import { htmlToText } from 'html-to-text';
import { getLanguageLevelLabel } from 'src/lib/sharedConstants';
import { serializeDate } from 'src/lib/dateTime';
import { PageTitle } from '../PageTitle';
import { navigate, routes } from '@redwoodjs/router';

type Props = {
  document: Pick<CandidateCv, 'id' | 'structuredMarkup' | 'title'>;
  candidateId?: string;
};

export const StructuredDocumentEditor: FC<Props> = ({ document, candidateId }) => {
  const { __typename, ...documentMarkup } = document.structuredMarkup;
  const markup = {
    ...documentMarkup,
    certifications: documentMarkup.certifications?.map(({ __typename, ...c }) => c),
    experience: documentMarkup.experience?.map(({ __typename, ...e }) => e),
    education: documentMarkup.education?.map(({ __typename, ...ed }) => ed),
    skills: documentMarkup.skills?.map(({ __typename, ...s }) => s),
    achievements: documentMarkup.achievements?.map(({ __typename, ...a }) => a),
    interests: documentMarkup.interests?.map(({ __typename, ...i }) => i),
    languages: documentMarkup.languages?.map(({ __typename, ...l }) => l),
  };

  const formMethods = useForm<CandidateCvDocumentMarkupFormValues>({
    resolver: zodResolver(candidateCvDocumentMarkupFormSchema),
    defaultValues: {
      name: markup?.name,
      email: markup?.email,
      phone: markup?.phone,
      jobTitle: markup?.jobTitle,
      profileSummary: markup?.profileSummary,
      experience:
        markup?.experience?.map((exp) => ({
          startDate: exp.startDate ? new Date(exp.startDate) : null,
          endDate: exp.endDate ? new Date(exp.endDate) : null,
          location: exp.location,
          description: exp.description,
          company: exp.company,
          title: exp.title,
        })) ?? [],
      education:
        markup?.education?.map((edu) => ({
          title: edu.title,
          institution: edu.institution,
          location: edu.location,
          description: edu.description,
          startDate: edu.startDate ? new Date(edu.startDate) : null,
          endDate: edu.endDate ? new Date(edu.endDate) : null,
        })) ?? [],
      skills:
        markup?.skills?.map((skill) => ({
          title: skill.title,
          description: skill.description,
        })) ?? [],
      achievements:
        markup?.achievements?.map((ach) => ({
          description: ach.description,
        })) ?? [],
      certifications:
        markup?.certifications?.map((cert) => ({
          title: cert.title,
          description: cert.description,
        })) ?? [],
      languages:
        markup?.languages?.map((lang) => ({
          language: lang.language,
          level: lang.level,
        })) ?? [],
      interests:
        markup?.interests?.map((interest) => ({
          description: interest.description,
        })) ?? [],
    },
  });

  const [openAccordions, setOpenAccordions] = useState<{ [key: string]: boolean }>({});
  const { value: isAccordionOpen, setTrue: openAllAccordions } = useBoolean(false);

  const formErrorRef = useRef<{ [key: string]: HTMLDivElement | null }>({});

  // Open all accordions when there is any form error and scroll into view the first error field
  useEffect(() => {
    const errors = formMethods.formState.errors;
    if (Object.keys(errors).length > 0) {
      openAllAccordions();
      const firstErrorKey = Object.keys(errors)[0];
      const firstErrorRef = formErrorRef.current[firstErrorKey];
      if (firstErrorRef) {
        firstErrorRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [formMethods.formState.errors]);

  const [updateDocument, { loading: updateDocumentLoading }] = useMutation<
    UpdateStructuredDocument,
    UpdateStructuredDocumentVariables
  >(UPDATE_STRUCTURED_DOCUMENT_MUTATION);

  // Managing dynamic fields using useFieldArray
  const {
    fields: experienceFields,
    append: appendExperience,
    remove: removeExperience,
    replace: replaceExperiences,
  } = useFieldArray({ control: formMethods.control, name: 'experience', keyName: 'fieldId' });

  const {
    fields: educationFields,
    append: appendEducation,
    remove: removeEducation,
    replace: replaceEducation,
  } = useFieldArray({ control: formMethods.control, name: 'education', keyName: 'fieldId' });

  const {
    fields: skillFields,
    append: appendSkill,
    remove: removeSkill,
    replace: replaceSkills,
  } = useFieldArray({ control: formMethods.control, name: 'skills', keyName: 'fieldId' });

  const {
    fields: achievementFields,
    append: appendAchievement,
    remove: removeAchievement,
    replace: replaceAchievements,
  } = useFieldArray({ control: formMethods.control, name: 'achievements', keyName: 'fieldId' });

  const {
    fields: certificationFields,
    append: appendCertification,
    remove: removeCertification,
    replace: replaceCertifications,
  } = useFieldArray({ control: formMethods.control, name: 'certifications', keyName: 'fieldId' });

  const {
    fields: languageFields,
    append: appendLanguage,
    remove: removeLanguage,
    replace: replaceLanguages,
  } = useFieldArray({ control: formMethods.control, name: 'languages', keyName: 'fieldId' });

  const {
    fields: interestFields,
    append: appendInterest,
    remove: removeInterest,
    replace: replaceInterests,
  } = useFieldArray({ control: formMethods.control, name: 'interests', keyName: 'fieldId' });

  const liveExperiences = formMethods.watch('experience') ?? [];
  const mergedExperienceFields = experienceFields.map((f, i) => ({
    ...f,
    ...liveExperiences?.[i],
  }));

  const liveEducation = formMethods.watch('education') ?? [];
  const mergedEducationFields = educationFields.map((f, i) => ({
    ...f,
    ...liveEducation?.[i],
  }));

  const liveSkills = formMethods.watch('skills') ?? [];
  const mergedSkillFields = skillFields.map((f, i) => ({
    ...f,
    ...liveSkills?.[i],
  }));

  const liveAchievements = formMethods.watch('achievements') ?? [];
  const mergedAchievementFields = achievementFields.map((f, i) => ({
    ...f,
    ...liveAchievements?.[i],
  }));

  const liveLanguages = formMethods.watch('languages') ?? [];
  const mergedLanguageFields = languageFields.map((f, i) => ({
    ...f,
    ...liveLanguages?.[i],
  }));

  const liveInterests = formMethods.watch('interests') ?? [];
  const mergedInterestFields = interestFields.map((f, i) => ({
    ...f,
    ...liveInterests?.[i],
  }));

  const liveCertifications = formMethods.watch('certifications') ?? [];
  const mergedCertificationFields = certificationFields.map((f, i) => ({
    ...f,
    ...liveCertifications?.[i],
  }));

  const watchedFormValues: CandidateCvDocumentMarkupFormValues = {
    ...formMethods.watch(),
    experience: mergedExperienceFields,
    education: mergedEducationFields,
    skills: mergedSkillFields,
    achievements: mergedAchievementFields,
    languages: mergedLanguageFields,
    interests: mergedInterestFields,
    certifications: mergedCertificationFields,
  };

  const debouncedValues = useDebounce(watchedFormValues, 500);
  const prevValuesRef = useRef<CandidateCvDocumentMarkupFormValues | null>(null);

  useEffect(() => {
    const prevValues = prevValuesRef.current;

    // Check if there are any actual changes before triggering the mutation
    const { email, phone, ...cleanedDebouncedValues } = debouncedValues;
    const hasChanged = JSON.stringify(debouncedValues) !== JSON.stringify(prevValues);
    const saveChanges = async () => {
      try {
        // Transform form values to match the GraphQL input types
        const transformedValues: CvInput = {
          ...cleanedDebouncedValues,
          jobTitle: cleanedDebouncedValues.jobTitle,
          experience: cleanedDebouncedValues.experience?.map((exp) => ({
            startDate: exp.startDate ? serializeDate(exp.startDate) : null,
            endDate: exp.endDate ? serializeDate(exp.endDate) : null,
            company: exp.company,
            description: exp.description,
            location: exp.location,
            title: exp.title,
          })),
          education: cleanedDebouncedValues.education?.map((edu) => ({
            startDate: edu.startDate ? serializeDate(edu.startDate) : null,
            endDate: edu.endDate ? serializeDate(edu.endDate) : null,
            title: edu.title,
            description: edu.description,
            institution: edu.institution,
            location: edu.location,
          })),
          certifications: cleanedDebouncedValues.certifications?.map((cert) => ({
            title: cert.title,
            description: cert.description,
          })),
          achievements: cleanedDebouncedValues.achievements?.map((a) => ({
            description: a.description,
          })),
          languages: cleanedDebouncedValues.languages?.map((l) => ({
            language: l.language,
            level: l.level,
          })),
          interests: cleanedDebouncedValues.interests?.map((i) => ({
            description: i.description,
          })),
          skills: cleanedDebouncedValues.skills?.map((s) => ({
            title: s.title,
            description: s.description,
          })),
        };

        await updateDocument({
          variables: {
            id: document.id,
            input: {
              candidateCvInput: transformedValues,
            },
          },
        });

        // Only update the ref if the mutation was successful
        prevValuesRef.current = debouncedValues;
      } catch (error) {
        console.error('Failed to save document:', error);
      }
    };

    if (hasChanged) {
      saveChanges();
    }
  }, [debouncedValues, updateDocument, document.id]);

  return (
    <div className="flex flex-1 flex-col overflow-hidden pt-2 ">
      <div className="flex flex-col px-4 md:px-16">
        <Link
          size="medium"
          LeftIcon={ArrowLeftIcon}
          onClick={() => navigate(routes.candidate({ candidateId: candidateId ?? '' }))}
        >
          Back to Candidate
        </Link>
        <PageTitle className="pt-3" size="sm" Icon={PencilIcon} text={document.title ?? ''} />
        <p className="pt-1 text-text-medium">
          You are editing a candidate CV. Changes you make will be auto-saved.
        </p>
      </div>
      <Form<CandidateCvDocumentMarkupFormValues>
        className="flex min-h-0 flex-1 flex-col"
        formMethods={formMethods}
      >
        <div className="flex flex-1 flex-col gap-y-3 overflow-auto px-4 py-4 md:px-16">
          <div className="grid grid-cols-1">
            <TextField
              label="Name"
              name="name"
              placeholder="John Doe"
              ref={(el) => (formErrorRef.current['name'] = el)}
            />
            <TextField
              label="Job Title"
              name="jobTitle"
              placeholder="Senior Software Engineer"
              ref={(el) => (formErrorRef.current['jobTitle'] = el)}
            />
          </div>
          <div
            className="flex flex-col space-y-2 pt-2"
            ref={(el) => (formErrorRef.current['profileSummary'] = el)}
          >
            <RichTextField
              label="Profile Summary"
              name="profileSummary"
              placeholder="Experienced software engineer with a strong background in full-stack development..."
              className="h-72"
            />
          </div>

          <div className="flex flex-col space-y-2">
            <h2 className="text-lg font-semibold">Work Experience</h2>
            <Reorder.Group
              values={mergedExperienceFields}
              onReorder={replaceExperiences}
              axis="y"
              className="flex flex-col gap-y-4"
            >
              {mergedExperienceFields.map((field, index) => {
                const liveField = liveExperiences?.[index];
                const startDateName = `experience.${index}.startDate`;
                const endDateName = `experience.${index}.endDate`;
                const fieldKey = `experience.${index}`;

                return (
                  <DraggableItem
                    key={field.fieldId}
                    field={field}
                    index={index}
                    removeHandler={removeExperience}
                  >
                    <Accordion
                      headline={`${liveField?.title || 'Untitled'}${
                        liveField?.company ? ` - ${liveField.company}` : ''
                      }`}
                      subtext={formatStartEndDateSnippet(liveField.startDate, liveField.endDate)}
                      isOpen={openAccordions[fieldKey] || isAccordionOpen}
                      onToggle={() =>
                        setOpenAccordions((prev) => ({
                          ...prev,
                          [fieldKey]: !prev[fieldKey],
                        }))
                      }
                    >
                      <TextField
                        label="Job Title"
                        name={`experience.${index}.title`}
                        required
                        className="h-[42px]"
                      />
                      <TextField
                        label="Company"
                        name={`experience.${index}.company`}
                        required
                        className="h-[42px]"
                      />
                      <DatePickerField label="Start Date" name={startDateName} />
                      <DatePickerField
                        label="End Date"
                        setPlaceholderPresentIfNull={!!liveField.startDate}
                        name={endDateName}
                        startDateName={startDateName}
                      />
                      <TextField
                        label="Location"
                        name={`experience.${index}.location`}
                        className="h-[42px]"
                      />
                      <RichTextField
                        label="Description"
                        name={`experience.${index}.description`}
                        placeholder="Developed and maintained web applications using React and Node.js."
                        className="h-72"
                      />
                    </Accordion>
                  </DraggableItem>
                );
              })}
            </Reorder.Group>
            <Link
              size="medium"
              className="text-generate-medium"
              iconClassName="text-generate-medium"
              LeftIcon={PlusIcon}
              onClick={() => {
                const newIndex = experienceFields.length;
                const newFieldKey = `experience.${newIndex}`;
                appendExperience({
                  title: '',
                  company: '',
                  startDate: null,
                  endDate: null,
                  location: '',
                  description: '',
                });
                setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
              }}
            >
              Add Experience
            </Link>
          </div>

          <div className="flex flex-col space-y-2">
            <h2 className="text-lg font-semibold">Education</h2>
            <Reorder.Group
              values={mergedEducationFields}
              onReorder={replaceEducation}
              axis="y"
              className="flex flex-col gap-y-4"
            >
              {mergedEducationFields.map((field, index) => {
                const startDateName = `education.${index}.startDate`;
                const endDateName = `education.${index}.endDate`;
                const liveField = liveEducation?.[index];
                const fieldKey = `education.${index}`;
                const titleValue = formMethods.watch(`education.${index}.title`);
                const institutionValue = formMethods.watch(`education.${index}.institution`);
                const separator = titleValue && institutionValue ? ' - ' : '';

                return (
                  <DraggableItem
                    key={field.fieldId}
                    field={field}
                    index={index}
                    removeHandler={removeEducation}
                  >
                    <Accordion
                      headline={
                        titleValue || institutionValue
                          ? `${institutionValue}${separator}${titleValue}`
                          : 'Untitled'
                      }
                      subtext={formatStartEndDateSnippet(liveField.startDate, liveField.endDate)}
                      isOpen={openAccordions[fieldKey] || isAccordionOpen}
                      onToggle={() =>
                        setOpenAccordions((prev) => ({
                          ...prev,
                          [fieldKey]: !prev[fieldKey],
                        }))
                      }
                    >
                      <TextField
                        label="Degree"
                        name={`education.${index}.title`}
                        required
                        className="h-[42px]"
                      />
                      <TextField
                        label="Institution"
                        name={`education.${index}.institution`}
                        required
                        className="h-[42px]"
                      />
                      <DatePickerField label="Start Date" name={startDateName} />
                      <DatePickerField
                        label="End Date"
                        setPlaceholderPresentIfNull={!!liveField.startDate}
                        name={endDateName}
                        startDateName={startDateName}
                      />
                      <TextField
                        label="Location"
                        name={`education.${index}.location`}
                        className="h-[42px]"
                      />
                      <RichTextField
                        label="Description"
                        name={`education.${index}.description`}
                        placeholder="Focused on software engineering and data structures."
                        className="h-72"
                      />
                    </Accordion>
                  </DraggableItem>
                );
              })}
            </Reorder.Group>
            <Link
              size="medium"
              LeftIcon={PlusIcon}
              className="text-generate-medium"
              iconClassName="text-generate-medium"
              onClick={() => {
                const newIndex = educationFields.length;
                const newFieldKey = `education.${newIndex}`;
                appendEducation({
                  title: '',
                  institution: '',
                  startDate: null,
                  endDate: null,
                  location: '',
                  description: '',
                });
                setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
              }}
            >
              Add Education
            </Link>
          </div>

          <div className="flex flex-col space-y-2">
            <h2 className="text-lg font-semibold">Skills</h2>
            <Reorder.Group
              values={mergedSkillFields}
              onReorder={replaceSkills}
              axis="y"
              className="flex flex-col gap-y-4"
            >
              {mergedSkillFields.map((field, index) => {
                const liveField = formMethods.watch(`skills.${index}`);
                const fieldKey = `skills.${index}`;

                return (
                  <DraggableItem
                    key={field.fieldId}
                    field={field}
                    index={index}
                    removeHandler={removeSkill}
                  >
                    <Accordion
                      headline={liveField?.title || 'Untitled'}
                      isOpen={openAccordions[fieldKey] || isAccordionOpen}
                      onToggle={() =>
                        setOpenAccordions((prev) => ({
                          ...prev,
                          [fieldKey]: !prev[fieldKey],
                        }))
                      }
                    >
                      <TextField
                        label="Skill"
                        name={`skills.${index}.title`}
                        required
                        className="h-[42px]"
                      />
                      <TextAreaField
                        label="Description"
                        name={`skills.${index}.description`}
                        placeholder="Proficient in modern JavaScript, including ES6+ features."
                        className="h-[105px] pt-2"
                      />
                    </Accordion>
                  </DraggableItem>
                );
              })}
            </Reorder.Group>
            <Link
              size="medium"
              LeftIcon={PlusIcon}
              className="text-generate-medium"
              iconClassName="text-generate-medium"
              onClick={() => {
                const newIndex = skillFields.length;
                const newFieldKey = `skills.${newIndex}`;
                appendSkill({
                  title: '',
                  description: '',
                });
                setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
              }}
            >
              Add Skill
            </Link>
          </div>

          <div className="flex flex-col space-y-2">
            <h2 className="text-lg font-semibold">Achievements</h2>
            <Reorder.Group
              values={mergedAchievementFields}
              onReorder={replaceAchievements}
              axis="y"
              className="flex flex-col gap-y-4"
            >
              {mergedAchievementFields.map((field, index) => {
                const liveField = liveAchievements?.[index];
                const fieldKey = `achievements.${index}`;

                return (
                  <DraggableItem
                    key={field.fieldId}
                    field={field}
                    index={index}
                    removeHandler={removeAchievement}
                  >
                    <Accordion
                      headline={htmlToText(liveField?.description) || 'Untitled'}
                      isOpen={openAccordions[fieldKey] || isAccordionOpen}
                      onToggle={() =>
                        setOpenAccordions((prev) => ({
                          ...prev,
                          [fieldKey]: !prev[fieldKey],
                        }))
                      }
                    >
                      <RichTextField
                        label="Description"
                        name={`achievements.${index}.description`}
                        placeholder="Awarded Employee of the Year for outstanding performance in 2022."
                        className="h-64"
                        required
                      />
                    </Accordion>
                  </DraggableItem>
                );
              })}
            </Reorder.Group>
            <Link
              size="medium"
              LeftIcon={PlusIcon}
              className="text-generate-medium"
              iconClassName="text-generate-medium"
              onClick={() => {
                const newIndex = achievementFields.length;
                const newFieldKey = `achievements.${newIndex}`;
                appendAchievement({
                  description: '',
                });
                setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
              }}
            >
              Add Achievement
            </Link>
          </div>

          <div className="flex flex-col space-y-2">
            <h2 className="text-lg font-semibold">Certifications</h2>
            <Reorder.Group
              values={mergedCertificationFields}
              onReorder={replaceCertifications}
              axis="y"
              className="flex flex-col gap-y-4"
            >
              {mergedCertificationFields.map((field, index) => {
                const fieldKey = `certifications.${index}`;

                return (
                  <DraggableItem
                    key={field.fieldId}
                    field={field}
                    index={index}
                    removeHandler={removeCertification}
                  >
                    <Accordion
                      headline={field.title || 'Untitled'}
                      isOpen={openAccordions[fieldKey] || isAccordionOpen}
                      onToggle={() =>
                        setOpenAccordions((prev) => ({
                          ...prev,
                          [fieldKey]: !prev[fieldKey],
                        }))
                      }
                    >
                      <TextField
                        label="Certification Title"
                        name={`certifications.${index}.title`}
                        required
                        className="h-[42px]"
                      />
                      <RichTextField
                        label="Description"
                        name={`certifications.${index}.description`}
                        placeholder="Certification demonstrating advanced knowledge of JavaScript."
                        className="h-72"
                      />
                    </Accordion>
                  </DraggableItem>
                );
              })}
            </Reorder.Group>
            <Link
              size="medium"
              LeftIcon={PlusIcon}
              className="text-generate-medium"
              iconClassName="text-generate-medium"
              onClick={() => {
                const newIndex = certificationFields.length;
                const newFieldKey = `certifications.${newIndex}`;
                appendCertification({
                  title: '',
                  description: '',
                });
                setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
              }}
            >
              Add Certification
            </Link>
          </div>

          <div className="flex flex-col space-y-2">
            <h2 className="text-lg font-semibold">Languages</h2>
            <Reorder.Group
              values={mergedLanguageFields}
              onReorder={replaceLanguages}
              axis="y"
              className="flex flex-col gap-y-4"
            >
              {mergedLanguageFields.map((field, index) => {
                const liveField = liveLanguages?.[index];
                const fieldKey = `languages.${index}`;

                return (
                  <DraggableItem
                    key={field.fieldId}
                    field={field}
                    index={index}
                    removeHandler={removeLanguage}
                  >
                    <Accordion
                      headline={liveField?.language || 'Untitled'}
                      subtext={
                        liveField?.level !== null
                          ? getLanguageLevelLabel(liveField?.level ?? 1)
                          : ''
                      }
                      isOpen={openAccordions[fieldKey] || isAccordionOpen}
                      onToggle={() =>
                        setOpenAccordions((prev) => ({
                          ...prev,
                          [fieldKey]: !prev[fieldKey],
                        }))
                      }
                    >
                      <TextField
                        label="Language"
                        name={`languages.${index}.language`}
                        required
                        className="h-[42px]"
                      />
                      <SelectField
                        label="Proficiency Level"
                        name={`languages.${index}.level`}
                        options={[
                          { value: 1, label: 'Novice' },
                          { value: 2, label: 'Intermediate' },
                          { value: 3, label: 'Skilled' },
                          { value: 4, label: 'Advanced' },
                          { value: 5, label: 'Expert' },
                        ]}
                      />
                    </Accordion>
                  </DraggableItem>
                );
              })}
            </Reorder.Group>
            <Link
              size="medium"
              LeftIcon={PlusIcon}
              className="text-generate-medium"
              iconClassName="text-generate-medium"
              onClick={() => {
                const newIndex = languageFields.length;
                const newFieldKey = `languages.${newIndex}`;
                appendLanguage({
                  language: '',
                  level: 3,
                });
                setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
              }}
            >
              Add Language
            </Link>
          </div>

          <div className="flex flex-col space-y-2">
            <h2 className="text-lg font-semibold">Interests</h2>
            <Reorder.Group
              values={mergedInterestFields}
              onReorder={replaceInterests}
              axis="y"
              className="flex flex-col gap-y-4"
            >
              {mergedInterestFields.map((field, index) => {
                const liveField = liveInterests?.[index];
                const fieldKey = `interests.${index}`;

                return (
                  <DraggableItem
                    key={field.fieldId}
                    field={field}
                    index={index}
                    removeHandler={removeInterest}
                  >
                    <Accordion
                      headline={liveField?.description || 'Untitled'}
                      isOpen={openAccordions[fieldKey] || isAccordionOpen}
                      onToggle={() =>
                        setOpenAccordions((prev) => ({
                          ...prev,
                          [fieldKey]: !prev[fieldKey],
                        }))
                      }
                    >
                      <TextAreaField
                        label="Interest"
                        name={`interests.${index}.description`}
                        placeholder="Hiking, photography, and open-source contributions."
                        className="min-h-24 flex-grow"
                        required
                      />
                    </Accordion>
                  </DraggableItem>
                );
              })}
            </Reorder.Group>
            <Link
              size="medium"
              LeftIcon={PlusIcon}
              className="text-generate-medium"
              iconClassName="text-generate-medium"
              onClick={() => {
                const newIndex = interestFields.length;
                const newFieldKey = `interests.${newIndex}`;
                appendInterest({
                  description: '',
                });
                setOpenAccordions((prev) => ({ ...prev, [newFieldKey]: true }));
              }}
            >
              Add Interest
            </Link>
          </div>
        </div>
      </Form>
    </div>
  );
};
