import { z } from 'zod';

export const candidateFormSchema = z.object({
  name: z.string().trim().min(1, { message: 'Required' }),
  jobTitle: z.string().trim().min(1, { message: 'Required' }),
  notes: z.string().trim().max(10000, "Can't be longer than 10000 characters").nullish(),
  availability: z.string().trim().nullish(),
  currentSalary: z.string().trim().nullish(),
  desiredJobTitle: z.string().trim().nullish(),
  desiredSalary: z.string().trim().nullish(),
  location: z.string().trim().nullish(),
  refId: z.string().trim().nullish(),
  profileSummary: z.string().trim().max(10000, "Can't be longer than 10000 characters").nullish(),
  rightToWork: z.string().trim().nullish(),
  experience: z
    .array(
      z.object({
        id: z.string().optional().nullable(),
        title: z.string().trim().min(1, { message: 'Required' }),
        company: z.string().trim().min(1, { message: 'Required' }),
        startDate: z.date().nullable(),
        endDate: z.date().nullable(),
        location: z.string().trim().nullable(),
        description: z.string().trim().max(4000, "Can't be longer than 4000 characters").nullish(),
      })
    )
    .optional(),
  education: z
    .array(
      z.object({
        id: z.string().optional().nullable(),
        title: z.string().trim().min(1, { message: 'Required' }),
        institution: z.string().nullish(),
        startDate: z.date().nullable(),
        endDate: z.date().nullable(),
        location: z.string().trim().nullable(),
        description: z.string().trim().max(1600, "Can't be longer than 1600 characters").nullish(),
      })
    )
    .optional(),
  skills: z
    .array(
      z.object({
        id: z.string().optional().nullable(),
        title: z.string().trim().min(1, { message: 'Required' }),
        description: z
          .string()
          .max(255, "Description can't be longer than 255 characters.")
          .trim()
          .nullable(),
      })
    )
    .optional(),
  achievements: z
    .array(
      z.object({
        id: z.string().optional().nullable(),
        description: z
          .string()
          .trim()
          .min(1, { message: 'Required' })
          .max(2000, "Can't be longer than 2000 characters"),
      })
    )
    .optional(),
  languages: z
    .array(
      z.object({
        id: z.string().optional().nullable(),
        language: z.string().trim().min(1, { message: 'Required' }),
        level: z.number().max(5, { message: 'Level must be between 1 and 5' }).nullable(),
      })
    )
    .optional(),
  interests: z
    .array(
      z.object({
        id: z.string().optional().nullable(),
        description: z
          .string()
          .trim()
          .min(1, { message: 'Required' })
          .max(255, "Can't be longer than 255 characters"),
      })
    )
    .optional(),
  certifications: z
    .array(
      z.object({
        id: z.string().optional().nullable(),
        title: z.string().trim().min(1, { message: 'Required' }),
        description: z
          .string()
          .max(1200, "Description can't be longer than 1200 characters.")
          .trim()
          .nullable(),
      })
    )
    .optional(),
});

export type CandidateFormValues = z.infer<typeof candidateFormSchema>;
