export const UPDATE_SNIPPET_MUTATION = gql`
  mutation UpdateSnippetMutation($id: String!, $input: UpdateSnippetInput!) {
    updateSnippet(id: $id, input: $input) {
      id
      name
      text
      updatedAt
      createdAt
    }
  }
`;
