import { FC, useEffect, useRef, useState } from 'react';
import { useForm } from '@redwoodjs/forms';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/dist/toast';
import {
  DELETE_LOGO_IMAGE_MUTATION,
  DELETE_LETTERHEAD_IMAGE_MUTATION,
  UPSERT_BRAND_ASSETS_MUTATION,
  UPSERT_BRAND_ASSET_LOGO_MUTATION,
  UPSERT_BRAND_ASSET_LETTERHEAD_MUTATION,
} from 'src/graphql/mutations';
import { GET_BRAND_ASSETS_QUERY } from 'src/graphql/queries';
import {
  DeleteLogoImage,
  DeleteLogoImageVariables,
  DeleteLetterheadImage,
  DeleteLetterheadImageVariables,
  GetBrandAssets,
  UpsertBrandAssets,
  UpsertBrandAssetsVariables,
  UpsertBrandAssetLetterhead,
  UpsertBrandAssetLetterheadVariables,
  UpsertBrandAssetLogo,
  UpsertBrandAssetLogoVariables,
  UpsertBrandAssetInput,
} from 'types/graphql';
import { AlignmentSelectionGroup } from './AlignmentSelectionGroup';
import { FileInput } from './FileInput';
import { useBoolean, useDebounce, useFileUpload } from 'src/hooks';
import { BrandingFormValues, brandingFormSchema } from './brandingFormSchema';
import { alignmentOptions, footerFontOptions, logoSizeOptions } from './constants';
import { SelectField, Tabs, TextField } from 'src/components';
import { Form } from 'src/components/Form';
import { RichTextField } from 'src/components/RichTextField';
import { BottomMarginIcon } from 'src/assets/BottomMarginIcon';
import { LeftMarginIcon } from 'src/assets/LeftMarginIcon';
import { RightMarginIcon } from 'src/assets/RightMarginIcon';
import { TopMarginIcon } from 'src/assets/TopMarginIcon';

const DEFAULT_FORM_VALUES: BrandingFormValues = {
  footerFont: 'Roboto',
  footerContent: '',
  logoAlignment: 'left',
  logoUrl: '',
  letterheadUrl: '',
  logoSize: '124',
  letterheadTopMargin: 24,
  letterheadBottomMargin: 24,
  letterheadLeftMargin: 24,
  letterheadRightMargin: 24,
  leftFooter: '',
  middleFooter: '',
  rightFooter: '',
};

enum FOOTER_TABS {
  LEFT = 'Left',
  MIDDLE = 'Middle',
  RIGHT = 'Right',
}

const FOOTER_TABS_OPTIONS: FOOTER_TABS[] = [
  FOOTER_TABS.LEFT,
  FOOTER_TABS.MIDDLE,
  FOOTER_TABS.RIGHT,
];

export const BrandAssets: FC<{
  brandAssets: GetBrandAssets['getBrandAssets'];
}> = ({ brandAssets }) => {
  const [selectedFooterTab, setSelectedFooterTab] = useState<FOOTER_TABS>(FOOTER_TABS.LEFT);

  const { getFileUploadDestination, uploadFile } = useFileUpload();
  const prevFormValuesRef = useRef<BrandingFormValues | null>(null);
  const { setTrue: showLogoConfigs, setFalse: hideLogoConfigs } = useBoolean(false);

  const CONFIG_FORM_VALUES: BrandingFormValues = {
    footerFont: (brandAssets?.footerFont || DEFAULT_FORM_VALUES.footerFont) as
      | 'Roboto'
      | 'serif'
      | 'mono',
    logoAlignment: (brandAssets?.logoAlignment || DEFAULT_FORM_VALUES.logoAlignment) as
      | 'left'
      | 'center'
      | 'right',
    logoSize: (brandAssets?.logoSize || DEFAULT_FORM_VALUES.logoSize) as '64' | '124' | '224',
    logoUrl: brandAssets?.logo?.src,
    letterheadUrl: brandAssets?.letterhead?.src,
    letterheadTopMargin:
      brandAssets?.letterheadTopMargin || DEFAULT_FORM_VALUES.letterheadTopMargin,
    letterheadBottomMargin:
      brandAssets?.letterheadBottomMargin || DEFAULT_FORM_VALUES.letterheadBottomMargin,
    letterheadLeftMargin:
      brandAssets?.letterheadLeftMargin || DEFAULT_FORM_VALUES.letterheadLeftMargin,
    letterheadRightMargin:
      brandAssets?.letterheadRightMargin || DEFAULT_FORM_VALUES.letterheadRightMargin,
    leftFooter: brandAssets?.leftFooter || DEFAULT_FORM_VALUES.leftFooter,
    middleFooter: brandAssets?.middleFooter || DEFAULT_FORM_VALUES.middleFooter,
    rightFooter: brandAssets?.rightFooter || DEFAULT_FORM_VALUES.rightFooter,
  };

  const formMethods = useForm<BrandingFormValues>({
    resolver: zodResolver(brandingFormSchema),
    defaultValues: CONFIG_FORM_VALUES ?? DEFAULT_FORM_VALUES,
  });

  const hasLogo = Boolean(brandAssets?.logo?.src);

  const hasLetterHead = Boolean(brandAssets?.letterhead?.src);
  const footerFields: Array<keyof BrandingFormValues> = [
    'leftFooter',
    'middleFooter',
    'rightFooter',
  ];

  const handleFooterTabChange = (tab: FOOTER_TABS) => {
    setSelectedFooterTab(tab);
  };

  const [upsertLogoImage] = useMutation<UpsertBrandAssetLogo, UpsertBrandAssetLogoVariables>(
    UPSERT_BRAND_ASSET_LOGO_MUTATION,
    {
      refetchQueries: [GET_BRAND_ASSETS_QUERY],
    }
  );

  const [upsertLetterheadImage] = useMutation<
    UpsertBrandAssetLetterhead,
    UpsertBrandAssetLetterheadVariables
  >(UPSERT_BRAND_ASSET_LETTERHEAD_MUTATION, {
    refetchQueries: [GET_BRAND_ASSETS_QUERY],
  });

  const [upsertBrandAssets] = useMutation<UpsertBrandAssets, UpsertBrandAssetsVariables>(
    UPSERT_BRAND_ASSETS_MUTATION
  );

  const [deleteLogoImage] = useMutation<DeleteLogoImage, DeleteLogoImageVariables>(
    DELETE_LOGO_IMAGE_MUTATION,
    {
      refetchQueries: [GET_BRAND_ASSETS_QUERY],
    }
  );

  const [deleteLetterheadImage] = useMutation<
    DeleteLetterheadImage,
    DeleteLetterheadImageVariables
  >(DELETE_LETTERHEAD_IMAGE_MUTATION, {
    refetchQueries: [GET_BRAND_ASSETS_QUERY],
  });

  const handleFileUpload = async (file: File, type: 'logo' | 'letterhead') => {
    const maxSizeInMB = type === 'letterhead' ? 3 : 10;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

    if (file.size > maxSizeInBytes) {
      toast.error(`File size should not exceed ${maxSizeInMB} MB.`);
      return;
    }

    const fileRecord = await getFileUploadDestination();
    try {
      const { url, blobName } = await uploadFile(
        file,
        fileRecord?.data?.getFileUploadLocation?.url
      );

      if (type === 'letterhead') {
        await upsertLetterheadImage({
          variables: {
            input: {
              fileUrl: url,
              mimeType: file.type,
              name: file.name,
              blobName,
            },
          },
        });
      } else if (type === 'logo') {
        await upsertLogoImage({
          variables: {
            input: {
              fileUrl: url,
              mimeType: file.type,
              name: file.name,
              blobName,
            },
          },
        });
        showLogoConfigs();
      }
    } catch (uploadError) {
      console.error('Upload error:', uploadError);
      toast.error('Failed to upload file. Please try again.');
    }
  };

  const handleFileRemove = async (type: 'logo' | 'letterhead') => {
    if (type === 'logo') {
      await deleteLogoImage({
        variables: { fileId: brandAssets?.logo?.id ?? '' },
      });
      hideLogoConfigs();
    } else if (type === 'letterhead') {
      await deleteLetterheadImage({
        variables: { fileId: brandAssets?.letterhead?.id ?? '' },
      });
    }
  };

  const formValues = formMethods.watch();
  const debouncedFormValues = useDebounce(formValues, 500);

  useEffect(() => {
    const formValuesChanged =
      JSON.stringify(debouncedFormValues) !== JSON.stringify(prevFormValuesRef.current);

    if (formValuesChanged) {
      const {
        logoAlignment,
        leftFooter,
        middleFooter,
        rightFooter,
        logoSize,
        footerFont,
        letterheadLeftMargin,
        letterheadRightMargin,
        letterheadTopMargin,
        letterheadBottomMargin,
      } = debouncedFormValues;

      const upsertBrandAssetsInput: UpsertBrandAssetInput = {
        leftFooter,
        middleFooter,
        rightFooter,
        footerFont,
        logoSize,
        letterheadLeftMargin,
        letterheadRightMargin,
        letterheadTopMargin,
        letterheadBottomMargin,
        logoAlignment,
      };

      const updateData = async () => {
        try {
          await upsertBrandAssets({
            variables: {
              input: upsertBrandAssetsInput,
            },
          });
          prevFormValuesRef.current = debouncedFormValues;
        } catch (error) {
          console.error('Error updating brand assets:', error);
        }
      };

      updateData();
    }
  }, [debouncedFormValues, upsertBrandAssets]);

  return (
    <Form<BrandingFormValues> formMethods={formMethods}>
      <div>
        <>
          <div className="flex max-w-2xl flex-col justify-center gap-y-1">
            <h1 className="text-lg font-semibold text-text-veryDark">Brand Assets</h1>
            <p className="text-sm font-normal text-text-medium">
              Manage your brand assets here. You will be able to use these in formatted CVs, PDF
              exports and cover sheets.
            </p>
          </div>

          {/* Logo Section */}
          <div className="flex flex-col justify-between space-y-6 pt-5">
            <h2 className="font-semibold text-text-veryDark">Logo</h2>
            <div className="flex items-center gap-[124px]">
              <p className="font-medium text-text-dark">Logo:</p>
              <FileInput
                name="logoUrl"
                onChange={(file) => handleFileUpload(file, 'logo')}
                onRemove={() => handleFileRemove('logo')}
                imageUrl={brandAssets?.logo?.src ?? ''}
                className="h-16 min-w-16"
              />
            </div>
            {hasLogo && (
              <div className="flex max-w-2xl items-center justify-between pt-5">
                <p className="font-medium text-text-dark">Size:</p>
                <div className="max-w-[263px]">
                  <SelectField name="logoSize" options={logoSizeOptions} />
                </div>
                <AlignmentSelectionGroup
                  name="logoAlignment"
                  options={alignmentOptions}
                  label="Alignment:"
                />
              </div>
            )}
          </div>

          {/* Letterhead Section */}
          <div className="flex flex-col justify-between space-y-6 pt-8">
            <h2 className="font-semibold text-text-veryDark">Letterhead</h2>
            <div className="flex items-center gap-20">
              <p className="font-medium text-text-dark">Letterhead:</p>
              <FileInput
                name="letterheadUrl"
                onChange={(file) => handleFileUpload(file, 'letterhead')}
                onRemove={() => handleFileRemove('letterhead')}
                imageUrl={brandAssets?.letterhead?.src ?? ''}
                className="h-24 min-w-20"
              />
            </div>

            {hasLetterHead && (
              <div className="flex max-w-[630px] items-center justify-between space-x-3 pt-5">
                <h2 className="font-medium text-text-dark">Margins:</h2>
                <div className="flex items-center gap-x-6">
                  <div className="w-22">
                    <TextField
                      name="letterheadTopMargin"
                      type="number"
                      placeholder="24"
                      LeftIcon={TopMarginIcon}
                      required
                    />
                  </div>
                  <div className="w-22">
                    <TextField
                      name="letterheadBottomMargin"
                      type="number"
                      placeholder="24"
                      LeftIcon={BottomMarginIcon}
                      required
                    />
                  </div>
                  <div className="w-22">
                    <TextField
                      name="letterheadLeftMargin"
                      type="number"
                      placeholder="24"
                      LeftIcon={LeftMarginIcon}
                      required
                    />
                  </div>
                  <div className="w-22">
                    <TextField
                      name="letterheadRightMargin"
                      placeholder="24"
                      type="number"
                      LeftIcon={RightMarginIcon}
                      required
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Footer Section */}
          <div className="flex flex-col justify-center space-y-3 pt-5">
            <h2 className="text-lg font-bold text-text-dark">Footer</h2>
            <div className="flex items-center gap-x-12 pt-3">
              <h2 className="font-semibold text-text-dark">Font:</h2>
              <div className="max-w-[236px]">
                <SelectField name="footerFont" options={footerFontOptions} />
              </div>
            </div>
            <div className="flex justify-between border-b border-text-light">
              <Tabs
                options={FOOTER_TABS_OPTIONS}
                setSelected={handleFooterTabChange}
                selected={selectedFooterTab}
              />
            </div>
            <div className="pt-3">
              {selectedFooterTab === FOOTER_TABS.LEFT && (
                <RichTextField
                  name="leftFooter"
                  label="Content"
                  placeholder="Enter left footer content..."
                />
              )}
              {selectedFooterTab === FOOTER_TABS.MIDDLE && (
                <RichTextField
                  name="middleFooter"
                  label="Content"
                  placeholder="Enter center footer content..."
                />
              )}
              {selectedFooterTab === FOOTER_TABS.RIGHT && (
                <RichTextField
                  name="rightFooter"
                  label="Content"
                  placeholder="Enter right footer content..."
                />
              )}
            </div>
          </div>
        </>
      </div>
    </Form>
  );
};
