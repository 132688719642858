import { Tooltip, UserIcon } from 'src/components';
import { classNames } from 'src/lib';
import { formatRelativeDateTime } from 'src/lib/dateTime';
import { DocumentVersion, DocumentVersionActions, User } from 'types/graphql';

export type ActionKey = 'EDIT' | 'CREATE' | 'REGENERATE' | 'CHAT' | 'RESTORE';

type ListItemProps = {
  content: Omit<DocumentVersion, 'editedBy'> & {
    editedBy: Pick<User, 'id' | 'email' | 'name'>;
  };
  onClick: (id: string) => void;
  selected: boolean;
};

export const ListItem = ({ content, onClick, selected }: ListItemProps) => {
  const actionFormat: Record<DocumentVersionActions['__typename'], string> = {
    EditDocumentAction: 'Edited',
    CreateDocumentAction: 'Created',
    RegenerateDocumentAction: 'Regenerated',
    ChatDocumentAction: 'Edited with Chat',
    RestoreDocumentAction: 'Restored',
  };

  const onClickItem = () => {
    if (selected) return;
    onClick(content.id);
  };

  const updatedAtDate = new Date(content.updatedAt);

  return (
    <li
      className={`flex items-center  space-x-3 p-3 ${
        selected ? 'rounded-lg bg-gray-50' : 'rounded-lg bg-white hover:bg-gray-50'
      } cursor-pointer`}
      onClick={onClickItem}
    >
      <Tooltip innerBody={content.editedBy.name || content.editedBy.email}>
        <UserIcon className="shrink-0" user={content.editedBy} />
      </Tooltip>

      <div className="flex-grow">
        <p className={classNames('text-sm  text-text-dark', selected && 'font-medium')}>
          {actionFormat[content.__typename]}
        </p>
        <p className="text-xs text-text-dark">{formatRelativeDateTime(updatedAtDate)}</p>
      </div>
    </li>
  );
};
