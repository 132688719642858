import { DOCUMENT_FIELDS_FRAGMENT } from '../fragments';

export const OVERRIDE_DOCUMENT_WITH_CHAT_RESPONSE_MUTATION = gql`
  ${DOCUMENT_FIELDS_FRAGMENT}
  mutation OverrideDocumentWithChatResponseMutation(
    $documentId: String!
    $chatResponseId: String!
  ) {
    overrideDocumentWithChatResponse(documentId: $documentId, chatResponseId: $chatResponseId) {
      response {
        id
        text
        createdAt
        document {
          ...DocumentFields
        }
      }
      document {
        ...DocumentFields
      }
    }
  }
`;
