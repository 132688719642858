export const GET_BRAND_VOICES_QUERY = gql`
  query GetBrandVoices {
    brandVoices {
      __typename
      ... on OrganisationBrandVoice {
        id
        title
        value
        summary
        inputs {
          questionnaireInput {
            companyName
            industry
            whatSetsYouApart
            industries
            locations
            clients
            candidates
            importantValues
            languageStyles
            perceived
            archetypes {
              title
              description
            }
            example {
              title
            }
          }
          examplesInput {
            examples {
              title
            }
          }
        }
      }

      ... on UserBrandVoice {
        id
        title
        value
        summary
        inputs {
          __typename
          questionnaireInput {
            jobTitle
            recruitmentType
            recruitmentStyle
            industries
            locations
            clients
            candidates
            communicationTone
            clientsPrimaryGoal
            candidatesPrimaryGoal
            challengingSituations
            archetypes {
              __typename
              title
              description
            }
            example {
              title
            }
          }
          examplesInput {
            examples {
              title
            }
          }
        }
      }
    }
  }
`;
