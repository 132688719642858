import { FC } from 'react';
import { Switch } from '@headlessui/react';
import { classNames } from 'src/lib';

type Props = {
  enabled: boolean;
  onChange: (enabled: boolean) => void;
  className?: string;
  disabled?: boolean;
};

export const Toggle: FC<Props> = ({ enabled, onChange, className, disabled = false }) => {
  const toggleClassNames = classNames(
    'relative inline-flex h-[12px] w-[24px] shrink-0 cursor-pointer rounded-full border-2 border-transparent  transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75',
    enabled ? 'bg-generate-medium' : 'bg-generate-light',
    disabled && 'cursor-not-allowed',
    className
  );

  return (
    <Switch checked={enabled} onChange={onChange} className={toggleClassNames} disabled={disabled}>
      <span className="sr-only ">Use setting</span>
      <span
        aria-hidden="true"
        className={`${enabled ? 'translate-x-[10px]' : 'translate-x-[-6px]'}
      pointer-events-none inline-block h-4 w-4 -translate-y-[5px] transform rounded-full border border-gray-50 bg-white shadow-md ring-0 transition duration-200 ease-in-out hover:bg-gray-50`}
      />
    </Switch>
  );
};
