import { FC } from 'react';
import { classNames } from '../../lib';

export type ToggleButtonProps = {
  value: boolean | null | undefined;
  onToggle: (value: boolean) => void;
  text: string;
  className?: string;
  LeftIcon?: FC<React.ComponentProps<'svg'>>;
  RightIcon?: FC<React.ComponentProps<'svg'>>;
};

export const ToggleButton: FC<ToggleButtonProps> = ({
  value,
  onToggle,
  text,
  className,
  LeftIcon,
  RightIcon,
}) => {
  const handleClick = () => {
    onToggle(!value);
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      className={classNames(
        'flex cursor-pointer items-center justify-center rounded-lg bg-sky-100 px-4 py-2 text-lg font-medium text-text-dark',
        value ? 'border-2 border-generate-medium font-semibold' : 'border border-transparent',
        'hover:bg-sky-200',
        className
      )}
    >
      <div className="flex items-center justify-center gap-2">
        {LeftIcon && (
          <span>
            <LeftIcon className="h-6 w-6" />
          </span>
        )}
        <span>{text}</span>
        {RightIcon && (
          <span>
            <RightIcon className="h-6 w-6" />
          </span>
        )}
      </div>
    </button>
  );
};
