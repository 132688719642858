import { Document, Image, Page, View, StyleSheet, Font } from '@react-pdf/renderer';
import { FC } from 'react';
import { DocumentBrandingConfig, GetBrandAssets } from 'types/graphql';
import { convertHtmlToReactPdfContent } from 'src/components/PdfDocument/pdf';
import { PdfNode as TPdfNode } from './PdfNodes/types';
import { PdfNode } from './PdfNodes';

type BrandedDocumentProps = {
  content: TPdfNode[];
  config: DocumentBrandingConfig;
  brandAssets: GetBrandAssets['getBrandAssets'];
};

Font.register({
  family: 'Libre Baskerville',
  fonts: [
    { src: '/fonts/LibreBaskerville-Regular.ttf' },
    { src: '/fonts/LibreBaskerville-Bold.ttf', fontWeight: 'bold' },
    { src: '/fonts/LibreBaskerville-Italic.ttf', fontStyle: 'italic' },
  ],
});

Font.register({
  family: 'Roboto',
  fonts: [
    { src: '/fonts/Roboto-Regular.ttf' },
    { src: '/fonts/Roboto-Bold.ttf', fontWeight: 'bold' },
    { src: '/fonts/Roboto-Black.ttf', fontWeight: 900 },
    { src: '/fonts/Roboto-Italic.ttf', fontStyle: 'italic' },
  ],
});

Font.register({
  family: 'Roboto Mono',
  fonts: [
    { src: '/fonts/RobotoMono-Regular.ttf' },
    { src: '/fonts/RobotoMono-Bold.ttf', fontWeight: 'bold' },
    { src: '/fonts/RobotoMono-Italic.ttf', fontStyle: 'italic' },
  ],
});

const getAlignment = (alignment: string): 'flex-start' | 'center' | 'flex-end' => {
  switch (alignment) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    case 'center':
    default:
      return 'center';
  }
};

const getDefaultPageBodyMargins = (
  config: DocumentBrandingConfig,
  brandAssets?: GetBrandAssets['getBrandAssets']
) => {
  if (config?.headerStyle === 'LETTERHEAD' && brandAssets) {
    return {
      marginTop: brandAssets?.letterheadTopMargin
        ? Math.min(brandAssets.letterheadTopMargin, 248)
        : undefined,
      marginBottom: brandAssets?.letterheadBottomMargin
        ? Math.min(brandAssets.letterheadBottomMargin, 248)
        : undefined,
      marginLeft: brandAssets?.letterheadLeftMargin
        ? Math.min(brandAssets.letterheadLeftMargin, 248)
        : undefined,
      marginRight: brandAssets?.letterheadRightMargin
        ? Math.min(brandAssets.letterheadRightMargin, 248)
        : undefined,
    };
  } else {
    return {
      marginTop: 24,
      marginBottom: 48,
      marginLeft: 48,
      marginRight: 48,
    };
  }
};

export const BrandedPreview: FC<BrandedDocumentProps> = ({ content, config, brandAssets }) => {
  const defaultPageBodyMargins = getDefaultPageBodyMargins(config, brandAssets);

  const styles = StyleSheet.create({
    page: {
      fontFamily: config?.fontConfig?.body ?? 'sans-serif',
      flexDirection: 'column',
      fontSize: 12,
      position: 'relative',
      height: '100vh',
    },
    letterheadWrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: '100vw',
      height: '100vh',
    },
    letterheadBackground: {
      width: '100vw',
      height: '100vh',
      objectFit: 'cover',
    },
    contentWrapper: {
      flex: 1,
      ...defaultPageBodyMargins,
    },
    headerWrapper:
      config?.headerStyle === 'LOGO'
        ? {
            marginBottom: 20,
          }
        : {
            marginBottom: 0,
          },
    pageBody: {
      flex: 1,
      border: config?.headerStyle === 'LOGO' ? '1pt dashed #d3d3d3' : '2pt dashed #d3d3d3',
    },
    logo: {
      width: `${brandAssets?.logoSize ?? 124}pt`,
      alignSelf: getAlignment(brandAssets?.logoAlignment ?? 'left'),
    },
    footer: {
      position: 'absolute',
      bottom: defaultPageBodyMargins.marginBottom ?? undefined, // Respecting the bottom margin
      left: defaultPageBodyMargins.marginLeft ?? undefined,
      right: defaultPageBodyMargins.marginRight ?? undefined,
      fontSize: 9,
      fontFamily: brandAssets?.footerFont ?? config?.fontConfig?.body ?? 'sans-serif',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    footerLeft: {
      textAlign: 'left',
      flex: 1,
    },
    footerCenter: {
      textAlign: 'center',
      flex: 1,
    },
    footerRight: {
      textAlign: 'right',
      flex: 1,
    },
  });

  const renderFooterContent = (footerContent: string) => {
    const nodes = convertHtmlToReactPdfContent(footerContent);

    if (!nodes) return null;

    const renderNode = (node: TPdfNode, index: number) => (
      <PdfNode key={index} node={node} documentConfig={config} />
    );

    return Array.isArray(nodes) ? nodes.map(renderNode) : renderNode(nodes, 0);
  };

  const letterheadSrc = brandAssets?.letterhead?.src;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {config?.headerStyle === 'LETTERHEAD' && letterheadSrc && (
          <View style={styles.letterheadWrapper} fixed>
            <Image src={letterheadSrc} style={styles.letterheadBackground} />
          </View>
        )}

        <View style={styles.contentWrapper}>
          <View style={styles.headerWrapper} fixed>
            {config?.headerStyle === 'LOGO' && brandAssets?.logo?.src && (
              <Image src={brandAssets?.logo?.src ?? ''} style={styles.logo} />
            )}
          </View>

          <View style={styles.pageBody} />

          <View style={styles.footer} fixed>
            <View style={styles.footerLeft}>
              {renderFooterContent(brandAssets?.leftFooter ?? '')}
            </View>
            <View style={styles.footerCenter}>
              {renderFooterContent(brandAssets?.middleFooter ?? '')}
            </View>
            <View style={styles.footerRight}>
              {renderFooterContent(brandAssets?.rightFooter ?? '')}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
