import { ExclamationTriangleIcon, XMarkIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useMutation } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/dist/toast';
import { FC } from 'react';
import { DELETE_DOCUMENT_MUTATION } from '../../graphql/mutations';
import { GET_DOCUMENTS_QUERY } from '../../graphql/queries';
import { Button } from '../Button';
import { DialogLayout } from '../DialogLayout';
import { DeleteDocumentMutation, DeleteDocumentMutationVariables, Document } from 'types/graphql';
import { navigate } from '@redwoodjs/router';

export const DeleteDocumentDialog: FC<{
  document: Pick<Document, 'id' | '__typename'>;
  onDismiss: () => void;
  navigateTo?: string;
}> = ({ onDismiss, document, navigateTo }) => {
  const [deleteDocument, { loading }] = useMutation<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
  >(DELETE_DOCUMENT_MUTATION, {
    variables: {
      id: document.id,
    },
    onCompleted: () => {
      toast.success('Document deleted');
      navigateTo && navigate(navigateTo);
      onDismiss();
    },
    update: (cache) => {
      cache.evict({ id: cache.identify(document) });
      cache.gc();
    },
    refetchQueries: [GET_DOCUMENTS_QUERY],
  });

  return (
    <DialogLayout
      title="Are you sure you want to delete this document?"
      className="max-w-[700px]"
      Icon={ExclamationTriangleIcon}
      onClose={onDismiss}
    >
      <p className="pt-3 text-text-dark">This will delete the document permanently.</p>
      <div className="mt-4 flex justify-end gap-x-2">
        <Button onClick={onDismiss} text="Cancel" LeftIcon={XMarkIcon} variant="outline" />
        <Button
          onClick={deleteDocument}
          text="Delete"
          loading={loading}
          LeftIcon={TrashIcon}
          variant="danger"
        />
      </div>
    </DialogLayout>
  );
};
