import { gql } from '@apollo/client';

export const PERMISSION_FIELDS_FRAGMENT = gql`
  fragment PermissionFields on Permission {
    __typename
    ... on UserPermission {
      id
      user {
        id
        name
        email
      }
      permission
    }
    ... on OrganisationPermission {
      id
      organisationId
      orgPermission: permission
      organisation {
        id
        name
      }
    }
  }
`;
