// @ts-check
// eslint-disable-next-line no-undef
module.exports = {
  jobAd: {
    light: '#daacec',
    medium: '#a738d2',
    dark: '#8226a6',
  },
  social: {
    light: '#fe9ab0',
    medium: '#fd3460',
    dark: '#de0232',
  },
  inMail: {
    light: '#89bcf0',
    medium: '#0B70D5',
    dark: '#074788',
  },
  email: {
    light: '#fee09a',
    medium: '#fec339',
    dark: '#f4ab01',
  },
  tips: {
    1: '#FF3056',
    2: '#ffbe0b',
    3: '#ff1f80',
    4: '#8338ec',
    5: '#2352fb',
    6: '#c11cad',
    7: '#6552f4',
    8: '#4f5af8',
    9: '#009381',
    10: '#179d8c',
  },
  tags: {
    1: '#EDE0D9',
    2: '#F9DDC8',
    3: '#FDEBC7',
    4: '#DBEDDB',
    5: '#D3E5EF',
    6: '#E8DDED',
    7: '#F4DFE9',
    8: '#FEE2DC',
    9: '#009381',
    10: '#F1F0F0',
  },
  chatResponse: '#FEFEFE',
  generate: {
    light: '#E3EDFF',
    medium: '#4D84F0',
    dark: '#1E62EB',
  },
  coscribe: '#F84F4F',
  pageGray: '#F7F7F7',
  text: {
    light: '#D1D5DB',
    medium: '#6B7280',
    dark: '#374151',
    veryDark: '#111827',
  },
  primary: {
    dark: '#101012',
    medium: '#1C1C20',
    light: '#323238',
  },
  error: {
    light: '#FCA5A5',
    medium: '#DC2626',
    dark: '#7F1D1D',
  },
};
