export const SET_DEFAULT_ORGANISATION_PERMISSION_MUTATION = gql`
  mutation SetDefaultOrganisationPermissionMutation(
    $input: SetDefaultOrganisationPermissionInput!
  ) {
    setDefaultOrganisationPermission(input: $input) {
      id
      defaultJobPermissions
      defaultCompanyPermissions
      defaultCandidatePermissions
    }
  }
`;
