import { PageTitle } from '../PageTitle';
import ExclamationCircleIcon from '@heroicons/react/24/outline/ExclamationCircleIcon';
import MailIcon from '@heroicons/react/24/outline/EnvelopeIcon';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { Moon } from './Moon';
import { Button } from '../Button';
import { FC, PropsWithChildren } from 'react';

export class ReloadErrorBoundary extends React.Component<PropsWithChildren, { hasError: boolean }> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ hasError: true });
    // Errors logged to sentry
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="flex h-full w-full justify-center">
          <div className="max-w-5xl flex-grow ">
            <PageTitle className="pb-6 pt-24" text="Error" Icon={ExclamationCircleIcon} />
            <div className="flex flex-grow flex-col items-center justify-center">
              <div className="flex flex-col items-center">
                <p className="pb-6 text-error-dark">Something went wrong.</p>
                <Moon className="h-[400px]" />
                <div className="flex justify-center gap-x-3 pt-6">
                  <Button
                    variant="outline"
                    LeftIcon={MailIcon}
                    text="Report a Bug"
                    onClick={() => {
                      window.open('mailto:help@adscribe.co?subject=Bug%20Report');
                    }}
                  />
                  <Button
                    text="Reload"
                    LeftIcon={ArrowPathIcon}
                    onClick={() => {
                      this.setState({ hasError: false });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export function withReloadErrorBoundary<T extends JSX.IntrinsicAttributes>(Component: FC<T>) {
  // eslint-disable-next-line react/display-name
  return (props: T) => (
    <ReloadErrorBoundary>
      <Component {...props} />
    </ReloadErrorBoundary>
  );
}
