import { DOCUMENT_FIELDS_FRAGMENT } from '../fragments';

export const CREATE_DOCUMENT_MUTATION = gql`
  ${DOCUMENT_FIELDS_FRAGMENT}
  mutation CreateDocumentMutation($input: CreateDocumentInput!) {
    createDocument(input: $input) {
      ...DocumentFields
    }
  }
`;
