import { FC } from 'react';
import { useAuth } from 'src/auth';
import { classNames } from 'src/lib';

type UserAvatarProps = {
  name?: string;
  className?: string;
};

export const UserAvatar: FC<UserAvatarProps> = ({ name, className }) => {
  const { currentUser } = useAuth();

  const initial =
    name?.toUpperCase()[0] ??
    currentUser?.name?.toUpperCase()[0] ??
    currentUser?.email?.toUpperCase()[0];

  return (
    <div
      className={classNames(
        'flex h-6 w-6 items-center justify-center rounded-full bg-generate-light text-sm font-light text-text-dark shadow-sm',
        className
      )}
    >
      {initial}
    </div>
  );
};
