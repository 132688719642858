import { ACCESS_LEVEL } from 'types/graphql';

export const USER_PERMISSION_LABELS: Record<ACCESS_LEVEL, string> = {
  OWNER: 'Owner',
  WRITE: 'Editor',
  READ: 'Viewer',
};

export const ORGANISATION_PERMISSION_LABELS: Record<ACCESS_LEVEL, string> = {
  OWNER: 'Owner',
  WRITE: 'Can Edit',
  READ: 'Can View',
};

export type PermissionLabel = (typeof USER_PERMISSION_LABELS)[ACCESS_LEVEL];
