export const UPDATE_COMPANY_MUTATION = gql`
  mutation UpdateCompanyMutation($id: String!, $input: UpdateCompanyInput!) {
    updateCompany(id: $id, input: $input) {
      id
      status
      campaignStatus
      name
      website
      location
      overview
      culture
      benefits
      notes
    }
  }
`;
