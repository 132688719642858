import { useMutation } from '@redwoodjs/web';
import { EditorEvents } from '@tiptap/react';
import { UPDATE_DOCUMENT_MUTATION } from '../graphql/mutations';
import { useCallback } from 'react';
import { UpdateDocumentMutation, UpdateDocumentMutationVariables } from '../../types/graphql';
import { debounce } from '../lib';
import { useState } from 'react';

/**
 * Hook to mount with RichTextEditor for autosave invocation and state tracking
 * Autosave function using a debounced call to mutate
 *
 * @param documentId Id of the content to edit
 */

export function useEditDocument(documentId: string) {
  const [mutate, { loading }] = useMutation<
    UpdateDocumentMutation,
    UpdateDocumentMutationVariables
  >(UPDATE_DOCUMENT_MUTATION, {
    variables: { updateDocumentInput: { id: documentId, markup: '' } },
  });

  // isPending tracks whether an update operation is in progress, used to
  // optimize UI interactions and prevent race conditions during data fetching
  // and submission. It helps ensure that user edits aren't overwritten by
  // incoming data until the current edit is fully processed.
  const [isPending, setPending] = useState(false);

  const updateDocument = useCallback(
    debounce(
      ({ editor }: EditorEvents['update']) => {
        const value = editor?.getHTML();
        mutate({
          variables: { updateDocumentInput: { id: documentId, markup: value } },
        });
        setPending(false);
      },
      500,
      () => setPending(true)
    ),
    [mutate, documentId]
  );

  return { loading, isPending, updateDocument };
}
