import { FC } from 'react';
import { ApolloError } from '@apollo/client';
import { FormError as RWFormError } from '@redwoodjs/forms';

export const FormError: FC<{ error?: ApolloError }> = ({ error }) => {
  const gqlError = error?.graphQLErrors?.[0];

  if (gqlError?.extensions?.code === 'BAD_USER_INPUT') {
    return (
      <RWFormError
        wrapperClassName="text-error-dark bg-red-50 mb-3 p-3 rounded-lg "
        listItemClassName="list-disc list-inside"
        error={error}
      />
    );
  } else {
    return null;
  }
};
