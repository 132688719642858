import { PropsWithChildren } from 'react';
import { Banner } from '../Banner';
import { WEB_VERSION } from 'src/services/env';
import { Link } from '../Link';
import { GET_API_VERSION } from 'src/graphql/queries';
import { GetApiVersion } from 'types/graphql';
import { useQuery } from '@redwoodjs/web';
import { useAuth } from '../../auth';

export const AppVersionChecker = ({ children }: PropsWithChildren) => {
  const { isAuthenticated } = useAuth();

  const { data: apiVersionData } = useQuery<GetApiVersion>(GET_API_VERSION, {
    // Only check for app banner if a user is already logged in
    skip: !isAuthenticated,
  });

  const apiVersion = apiVersionData?.apiVersion;
  const isLoading = !apiVersionData;
  // Show update banner if API and Client versions are out of sync
  const showBanner = !isLoading && apiVersion !== WEB_VERSION;
  return (
    <>
      {showBanner && (
        <Banner>
          <p>
            We&apos;ve made some important updates to improve your experience. Please{' '}
            <Link size="medium" onClick={() => window.location.reload()}>
              Reload
            </Link>{' '}
            your browser to ensure compatibility and access the latest features.
          </p>
        </Banner>
      )}
      {children}
    </>
  );
};
