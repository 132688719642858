/**
 * Registers a custom Handlebars helper with a manually injected Handlebars instance.
 * This method enhances flexibility in Node.js backend environments, where direct imports
 * might lead to different behavior due to module scoping. It ensures the use of the
 * appropriate Handlebars instance, improving modularity and testing by not depending
 * on global instances.
 *
 * @param {typeof Handlebars} handlebarsInstance - The Handlebars instance to register the helper with.
 * @param {string} name - The name of the helper to register.
 * @param {Function} helperFunction - The helper function.
 */
export const registerHandlebarsHelper = (
  handlebarsInstance: typeof Handlebars,
  name: string,
  helperFunction: Handlebars.HelperDelegate
) => {
  handlebarsInstance.registerHelper(name, helperFunction);
};
