import { BoltIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from '@redwoodjs/forms';
import { FC, useEffect } from 'react';
import { BlogPostFormValues, blogPostFormSchema } from '../../lib/formSchemas';
import { PageTitle } from '../PageTitle';
import { TextAreaField } from '../TextArea';
import { TextField } from '../TextField';
import { BaseForm } from './BaseForm';
import { BaseFormProps } from './types';
import { BlogPost } from '../../../types/graphql';
import { SelectField } from '../SelectField';
import { Link } from '../Link';
import { routes } from '@redwoodjs/router';
import { useTemplateInputsStore } from '../../services/store';

export const BlogPostForm: FC<BaseFormProps> = ({
  onSubmit,
  operation,
  document,
  documentLoading,
  mutationError,
  mutationLoading,
}) => {
  const cachedInput = useTemplateInputsStore((state) => state.blogPost);
  const formMethods = useForm<BlogPostFormValues>({
    resolver: zodResolver(blogPostFormSchema),
    defaultValues: {
      type: 'Educational & Informative',
    },
  });

  if (document && document?.__typename !== 'BlogPost') {
    throw new Error('Document is not a BlogPost');
  }

  const handleSubmit: SubmitHandler<BlogPostFormValues> = (data) => {
    useTemplateInputsStore.setState({ blogPost: data });
    onSubmit({
      inputData: {
        blogPost: {
          industry: data.industry,
          targetAudience: data.targetAudience,
          topic: data.topic,
          type: data.type,
          informationToInclude: data.informationToInclude,
        },
      },
    });
  };

  useEffect(() => {
    if (document?.__typename === 'BlogPost') {
      const documentData = document as BlogPost;
      formMethods.reset({
        industry: documentData.input.industry,
        targetAudience: documentData.input.targetAudience,
        topic: documentData.input.topic,
        type: documentData.input.type,
        informationToInclude: documentData.input.informationToInclude,
      });
    } else if (cachedInput) {
      formMethods.reset(cachedInput);
    }
  }, [document, formMethods]);

  const selectOptions = [
    'Educational & Informative',
    'Industry Insights & News',
    'Tips & Guidance',
    'Case Study, Storytelling & Anecdotal',
    'Other',
  ].map((option) => ({
    label: option,
    value: option,
  }));

  return (
    <BaseForm<BlogPostFormValues>
      formProps={{ onSubmit: handleSubmit, formMethods }}
      operation={operation}
      documentLoading={documentLoading}
      mutationError={mutationError}
      mutationLoading={mutationLoading}
      document={document}
    >
      <PageTitle Icon={BoltIcon} text="Blog Post" />
      <SelectField options={selectOptions} name="type" label="Type" />
      <TextField
        name="targetAudience"
        label="Target Audience"
        placeholder="e.g Architecture Directors"
        schema={blogPostFormSchema}
      />
      <TextField
        name="industry"
        label="Target Industry"
        placeholder="e.g Architecture"
        schema={blogPostFormSchema}
      />
      <TextField name="topic" label="Topic" schema={blogPostFormSchema} />
      <p className="text-sm text-text-medium">
        Not sure what to write?{' '}
        <Link
          size="medium"
          onClick={() =>
            window.open(routes.createDocument({ templateType: 'BlogPostIdeas' }), '_blank')
          }
        >
          Click here
        </Link>{' '}
        to generate a list of blog post topics!
      </p>
      <TextAreaField
        name="informationToInclude"
        label="Description"
        className="min-h-[200px]"
        placeholder="Describe what you would like to see in the blog post and any particular themes, keywords or information you would like to include."
        schema={blogPostFormSchema}
      />
    </BaseForm>
  );
};
