import { MetaTags, useMutation, useQuery } from '@redwoodjs/web';
import { PageTitle } from '../../components/PageTitle';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import { SubmitHandler, useForm } from '@redwoodjs/forms';
import { TextAreaField, TextField } from 'src/components';
import { Button } from '../../components/Button';
import { SparklesIcon } from '@heroicons/react/24/outline';
import {
  AutoFillCompanyMutation,
  AutoFillCompanyMutationVariables,
  CreateCompanyMutation,
  CreateCompanyMutationVariables,
  ExtractCompanyUrlMutation,
  ExtractCompanyUrlMutationVariables,
  GetMeQuery,
  GetMeQueryVariables,
} from 'types/graphql';
import { toast } from '@redwoodjs/web//toast';
import { navigate, routes } from '@redwoodjs/router';
import { usePageClasses, useTrackPageView } from '../../hooks';
import { Spinner } from '../../components/Spinner';
import { CREATE_COMPANY_MUTATION, EXTRACT_COMPANY_URL_MUTATION } from '../../graphql/mutations';
import { CompanyIllustration } from '../../assets/172-Diversity-&-Inclusion-Revision';
import { zodResolver } from '@hookform/resolvers/zod';
import { companyFormSchema, CompanyFormValues } from '../../lib/formSchemas';
import { CampaignOnboardingWizard } from '../../components/CampaignOnboardingWizard';
import { GET_ME_QUERY } from '../../graphql/queries';
import { COMPANY_CAMPAIGN_CONFIGURABLE_DOCUMENTS } from '../../lib/document';
import { Form } from 'src/components/Form/Form';
import { useTextFromFile } from 'src/hooks/useTextFromFile';
import { classNames } from 'src/lib';
import { AUTOFILL_COMPANY_MUTATION } from 'src/graphql/mutations/autoFillCompanyMutation';
import { SmartDataUploader } from 'src/components';

const NewCompanyPage = () => {
  useTrackPageView();
  usePageClasses('bg-pageGray');
  const formMethods = useForm<CompanyFormValues>({
    resolver: zodResolver(companyFormSchema),
  });
  const { data: getMeData, loading: getMeLoading } = useQuery<GetMeQuery, GetMeQueryVariables>(
    GET_ME_QUERY,
    {
      variables: {
        isAdmin: false,
      },
    }
  );
  const [mutate, { loading: mutationLoading, error: mutationError }] = useMutation<
    CreateCompanyMutation,
    CreateCompanyMutationVariables
  >(CREATE_COMPANY_MUTATION, {
    onError: (error) => {
      const gqlError = error?.graphQLErrors?.[0];
      if (gqlError.extensions?.code === 'BAD_USER_INPUT') {
        toast.error(gqlError.message);
      }
    },
  });

  const [autoFillCompany, { loading: autoFillCompanyLoading }] = useMutation<
    AutoFillCompanyMutation,
    AutoFillCompanyMutationVariables
  >(AUTOFILL_COMPANY_MUTATION, {
    onCompleted: (data) => {
      formMethods.reset({
        ...formMethods.getValues(),
        //here we are filtering out the empty values from the response
        ...Object.fromEntries(Object.entries(data.autoFillCompany).filter(([, value]) => value)),
      });
    },
  });

  const [extractCompany, { loading: extractCompanyLoading }] = useMutation<
    ExtractCompanyUrlMutation,
    ExtractCompanyUrlMutationVariables
  >(EXTRACT_COMPANY_URL_MUTATION, {
    onCompleted(data) {
      formMethods.reset({
        ...formMethods.getValues(),
        name: data.extractCompanyUrl.name ?? '',
        overview: data.extractCompanyUrl.overview ?? '',
        benefits: data.extractCompanyUrl.benefits ?? '',
        culture: data.extractCompanyUrl.culture ?? '',
        location: data.extractCompanyUrl.location ?? '',
        notes: data.extractCompanyUrl.notes ?? '',
        website: data.extractCompanyUrl.website ?? '',
      });
    },
  });

  const onSubmit: SubmitHandler<CompanyFormValues> = async (values) => {
    const result = await mutate({
      variables: {
        input: {
          benefits: values.benefits,
          culture: values.culture,
          location: values.location,
          name: values.name,
          overview: values.overview,
          notes: values.notes,
          website: values.website,
        },
      },
    });
    if (result.data) {
      toast.success('Company created!');
      navigate(
        routes.company({
          companyId: result.data.createCompany.id,
          initial: true,
        })
      );
    }
  };

  const { getRootProps, isDragActive, onUploadFile } = useTextFromFile((text) => {
    toast.promise(
      autoFillCompany({
        variables: { body: text },
      }),
      {
        error: 'Unable to scan this file, please try another one',
        loading: 'Loading',
        success: 'Success',
      }
    );
  });

  const onUrlScrape = (url: string) => {
    toast.promise(
      extractCompany({
        variables: { url },
      }),
      {
        error: 'Unable to scan URL, please try a different link',
        loading: 'Loading',
        success: 'Success',
      }
    );
  };

  const inputsLoading = autoFillCompanyLoading || extractCompanyLoading;

  if (mutationLoading || getMeLoading) {
    return <Spinner />;
  }

  if (getMeData?.me?.companyCampaignOnboardingStatus === 'PENDING') {
    return (
      <CampaignOnboardingWizard
        configurableDocuments={COMPANY_CAMPAIGN_CONFIGURABLE_DOCUMENTS}
        onboardingType={'COMPANY'}
      />
    );
  }

  return (
    <div className="flex min-h-0 flex-grow">
      <MetaTags title="New company" description="Create a Company" />
      <div
        className={classNames(
          'flex h-full min-h-0 w-full max-w-2xl flex-col border border-dashed border-transparent py-8',
          isDragActive && 'border-generate-light bg-blue-50'
        )}
        {...getRootProps()}
      >
        <div className="mb-6 flex items-center justify-between px-16">
          <div>
            <PageTitle
              size="sm"
              className="pb-1"
              Icon={BuildingOfficeIcon}
              text="Company Introduction"
              iconClassName="h-[24px] w-[24px]"
            />
            <p className="text-sm text-text-dark">Generate a powerful company introduction.</p>
          </div>
          <SmartDataUploader onFileUpload={onUploadFile} onUrlScrape={onUrlScrape} />
        </div>
        <Form<CompanyFormValues>
          className={classNames(
            'flex h-full min-h-0 flex-1 flex-col gap-y-6 transition-all duration-75 ease-in',
            isDragActive && 'scale-[0.97]'
          )}
          formMethods={formMethods}
          onSubmit={onSubmit}
          error={mutationError}
        >
          <div className="flex flex-1 flex-col gap-y-4 overflow-auto px-16">
            <TextField
              label="Company Name"
              name="name"
              schema={companyFormSchema}
              loading={inputsLoading}
            />
            <div className="flex flex-row gap-x-4">
              <TextField
                label="Location"
                name="location"
                placeholder="e.g North London | Hybrid"
                loading={inputsLoading}
              />
              <TextField label="Website" name="website" loading={inputsLoading} />
            </div>
            <TextAreaField
              label="Overview"
              name="overview"
              className="min-h-[200px]"
              placeholder="e.g. global leading design and communications agency, 2,000 employees across 29 studios…"
              schema={companyFormSchema}
              loading={inputsLoading}
            />
            <TextAreaField
              label="Culture"
              name="culture"
              className="min-h-[200px]"
              placeholder="e.g. growth-minded and ambitious, engaging and supportive environment..."
              schema={companyFormSchema}
              loading={inputsLoading}
            />

            <TextAreaField
              label="Benefits"
              name="benefits"
              className="min-h-[100px]"
              placeholder="e.g. up to 2 days remote working, health insurance…"
              schema={companyFormSchema}
              loading={inputsLoading}
            />
            <TextAreaField label="Notes" name="notes" loading={inputsLoading} />
          </div>
          <div className="flex px-16">
            <Button
              type="submit"
              className="flex flex-1"
              loading={mutationLoading}
              LeftIcon={SparklesIcon}
              text={mutationLoading ? 'Loading' : 'Generate'}
              size="mega"
              disabled={inputsLoading || mutationLoading}
            />
          </div>
        </Form>
      </div>

      <div className="flex h-full w-full items-center justify-center bg-white px-16">
        <div className="flex flex-col items-center">
          <CompanyIllustration className="h-64 w-64" />
          <p className="max-w-xs text-center font-medium text-text-dark">
            Enter the company details, click &apos;Generate&apos; and watch your company
            introduction and summary appear.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NewCompanyPage;
