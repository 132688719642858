import { navigate, routes } from '@redwoodjs/router';
import { useMutation } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/dist/toast';
import { useEffect } from 'react';
import {
  AcceptUserInviteMutation,
  AcceptUserInviteMutationVariables,
} from '../../../types/graphql';
import { Spinner } from '../../components/Spinner';
import { ACCEPT_USER_INVITE_MUTATION } from '../../graphql/mutations';

const AcceptInvitePage = ({ code }: { code: string }) => {
  const [acceptInvite] = useMutation<AcceptUserInviteMutation, AcceptUserInviteMutationVariables>(
    ACCEPT_USER_INVITE_MUTATION,
    {
      variables: {
        code: code,
      },
      onCompleted: (data) => {
        navigate(
          routes.resetPassword({
            resetToken: data.acceptUserInvite.resetPasswordToken,
            initial: true,
          })
        );
      },
      onError: (error) => {
        const gqlError = error?.graphQLErrors?.[0];
        if (gqlError?.extensions?.code === 'BAD_USER_INPUT') {
          toast.error(gqlError.message);
        }
        navigate(routes.login());
      },
    }
  );

  useEffect(() => {
    if (!code) {
      toast.error('Invalid invite code');
      navigate(routes.login());
    } else {
      acceptInvite();
    }
  }, [acceptInvite, code]);
  return <Spinner />;
};

export default AcceptInvitePage;
