import { ACCESS_LEVEL, ACCESS_LEVEL_SETTING, USER_ROLE } from '../../../types/graphql';

export const INVITE_USER_DROPDOWN_OPTIONS: {
  label: string;
  value: Exclude<ACCESS_LEVEL, 'OWNER'>;
}[] = [
  { label: 'Viewer', value: 'READ' },
  { label: 'Editor', value: 'WRITE' },
];

export const USER_PERMISSION_DROPDOWN_OPTIONS = [
  { label: 'Viewer', value: 'READ' },
  { label: 'Editor', value: 'WRITE' },
  { label: 'Remove', value: 'Remove' },
];

export const ORGANISATION_PERMISSION_DROPDOWN_OPTIONS: {
  label: string;
  value: Exclude<ACCESS_LEVEL, 'OWNER'> | null;
}[] = [
  { label: 'Can View', value: 'READ' },
  { label: 'Can Edit', value: 'WRITE' },
  { label: 'No Access', value: null },
];

export const USER_ORGANISATION_DEFAULT_ROLE_DROPDOWN_OPTIONS: {
  label: string;
  value: ACCESS_LEVEL_SETTING;
}[] = [
  {
    label: 'Public (read-only)',
    value: 'READ',
  },
  {
    label: 'Private',
    value: 'NONE',
  },
  {
    label: 'Collaborative',
    value: 'WRITE',
  },
];

export const USER_ROLE_DROPDOWN_OPTIONS: {
  label: string;
  value: USER_ROLE;
}[] = [
  { label: 'Admin', value: 'ADMIN' },
  { label: 'Member', value: 'USER' },
];
