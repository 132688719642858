import { FC } from 'react';
import { classNames } from '../../lib';

export type Props = {
  text: string;
  Icon?: FC<React.ComponentProps<'svg'>>;
  /**
   * Optional styling, mainly for spacing
   */
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  iconClassName?: string;
};

export const PageTitle: FC<Props> = ({ text, Icon, className, size = 'md', iconClassName }) => {
  return (
    <h2
      className={classNames(
        'flex flex-wrap items-center gap-2 font-bold',
        size === 'sm' ? 'text-lg' : size === 'md' ? 'text-2xl' : 'text-3xl',
        className
      )}
    >
      {Icon && (
        <span>
          <Icon
            className={classNames(
              size === 'sm' ? 'h-4 w-4' : size === 'md' ? 'h-6 w-6' : 'h-9 w-9',
              iconClassName ?? 'text-text-veryDark'
            )}
          />
        </span>
      )}
      {text}
    </h2>
  );
};
