import { CheckIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { classNames } from '../../lib';

export const Checkbox: FC<{
  label?: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
}> = ({ label, value, onChange, disabled }) => {
  const onClickCheck = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (disabled) return;
    onChange?.(!value);
  };

  return (
    <div className="flex items-center space-x-2">
      <div
        onClick={onClickCheck}
        onMouseOver={(e) => e.stopPropagation()}
        className={classNames(
          'flex h-5 w-5 items-center justify-center rounded-md border border-text-medium hover:cursor-pointer',
          value ? 'bg-primary-medium' : disabled ? '' : 'bg-white hover:bg-gray-50'
        )}
      >
        {value && (
          <CheckIcon className="h-full w-full text-white" aria-hidden="true" strokeWidth={'2'} />
        )}
      </div>
      <span className=" text-sm font-medium text-text-medium">{label}</span>
    </div>
  );
};
