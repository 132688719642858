import { PropsWithChildren } from 'react';
import { ExclamationTriangleIcon, CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useBoolean } from 'src/hooks';

export type BannerProps = {
  variant?: 'warning' | 'success';
};

const iconMap = {
  warning: ExclamationTriangleIcon,
  success: CheckCircleIcon,
};

export const Banner = ({ children, variant = 'warning' }: PropsWithChildren<BannerProps>) => {
  const { value: isBannerVisible, setFalse: hideBanner } = useBoolean(true);
  const Icon = iconMap[variant];

  if (!isBannerVisible) {
    return null;
  }

  return (
    <div
      className={`flex w-full items-center justify-center border px-4 py-2 text-sm
      ${
        variant === 'warning'
          ? 'text-primary border-yellow-300 bg-yellow-200'
          : 'text-primary border-emerald-800 bg-emerald-700'
      }`}
    >
      <Icon className="mr-2 h-5 w-5" />
      <span>{children}</span>
      <XMarkIcon
        className="text-primary absolute right-8 top-2 h-5 w-5 cursor-pointer"
        onClick={hideBanner}
      />
    </div>
  );
};
