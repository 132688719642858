export const UPSERT_BRAND_ASSETS_MUTATION = gql`
  mutation UpsertBrandAssets($input: UpsertBrandAssetInput!) {
    upsertBrandAssets(input: $input) {
      id
      brandColor
      logoSize
      logoAlignment
      letterheadLeftMargin
      letterheadRightMargin
      letterheadTopMargin
      letterheadBottomMargin
      leftFooter
      middleFooter
      rightFooter
      footerFont
      logo {
        id
        src
      }
      letterhead {
        id
        src
      }
    }
  }
`;
