import { FC, useEffect, useRef, useState } from 'react';
import { navigate, routes } from '@redwoodjs/router';
import { useMutation, useQuery } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/dist/toast';
import { NetworkStatus } from '@apollo/client';
import { AnimatePresence } from 'framer-motion';
import {
  ArrowLeftOnRectangleIcon,
  ChatBubbleLeftRightIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ExclamationTriangleIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import {
  ChatSession,
  GetChatSessionsQuery,
  GetChatSessionsQueryVariables,
  Document,
  DeleteChatSessionMutation,
  DeleteChatSessionMutationVariables,
  UpdateChatSessionMutation,
  UpdateChatSessionMutationVariables,
} from 'types/graphql';
import { GET_CHAT_SESSIONS_QUERY } from '../../graphql/queries';
import {
  DELETE_CHAT_SESSION_MUTATION,
  UPDATE_CHAT_SESSION_MUTATION,
} from '../../graphql/mutations';

import { useDialog } from '../../hooks';
import { DialogLayout, Link } from '../../components';
import { Button } from '../../components/Button';
import { PageTitle } from '../../components/PageTitle';
import { Spinner } from '../../components/Spinner';
import { IconButton } from '../../components/IconButton';
import { FadeIn } from 'src/components/FadeIn';
import { classNames } from '../../lib';
import { documentPresentationalProperties } from '../../lib/document';
import { groupChatSessionsByDate } from './groupChatSessionsByDate';

type Props = {
  selectedSessionId: string;
  onSelectSession: (id: string) => void;
  document?: Pick<Document, 'id' | 'title' | '__typename'> & {
    jobId?: string;
    candidateId?: string;
    companyId?: string;
  };
  IS_EDITING_DOCUMENT?: boolean;
  onShowSuggestedPrompts: () => void;
  isChatSidebarExpanded?: boolean;
  onToggleChatSidebar?: () => void;
};

export const ChatSidebar: FC<Props> = ({
  selectedSessionId,
  onSelectSession,
  document,
  IS_EDITING_DOCUMENT,
  onShowSuggestedPrompts,
  isChatSidebarExpanded,
  onToggleChatSidebar,
}) => {
  const { data, networkStatus, error } = useQuery<
    GetChatSessionsQuery,
    GetChatSessionsQueryVariables
  >(GET_CHAT_SESSIONS_QUERY, {
    skip: IS_EDITING_DOCUMENT,
  });

  const groupedChatSessionsByDate = data?.chatSessions
    ? groupChatSessionsByDate(data.chatSessions)
    : {};

  const hasChatSessions = Boolean(data?.chatSessions?.length);

  if (!hasChatSessions && !IS_EDITING_DOCUMENT) {
    return null;
  }

  /**
   * @description It returns user to the entity-document page when the user exits the chat page, based on the document user was editing.
   * @returns {void}
   */
  const onExitAndReturnToDocument = () => {
    const { __typename: documentType, id, jobId, candidateId, companyId } = document || {};

    if (!id || !documentType) return;

    if (jobId) {
      navigate(routes.job({ jobId, documentType }));
    } else if (candidateId) {
      navigate(routes.candidate({ candidateId, documentType }));
    } else if (companyId) {
      navigate(routes.company({ companyId, documentType }));
    } else {
      navigate(routes.document({ documentId: id }));
    }
  };

  const loading = networkStatus === NetworkStatus.loading && !data;

  if (error) {
    throw error;
  }

  if (loading) {
    return (
      <div className="flex w-full max-w-xs flex-col gap-y-3 border-r border-text-light">
        <Spinner />
      </div>
    );
  }

  if (document) {
    if (!document.__typename) return null;

    const { title } = documentPresentationalProperties[document.__typename];
    return (
      <div className="flex w-full max-w-xs flex-col gap-y-3 border-r border-text-light px-6">
        <div className="pt-6">
          <PageTitle size="md" text="Edit with Chat" Icon={ChatBubbleLeftRightIcon} />
        </div>
        <p className="py-8 text-text-medium">
          You are editing a <b>{title}</b> with AdScribe Chat.
        </p>
        <p className="pb-4 text-text-medium">
          1. Instruct AdScribe chat to tweak the document to your liking.
        </p>
        <p className="pb-8 text-text-medium">
          2.Click <b>Save</b> to update the original document with AdScribe Chat
          {"'"}s response.
        </p>
        <p className="pb-8 text-sm text-text-medium">
          Don{"'"}t worry about losing the original after saving. It can still be accessed via the
          document history.
        </p>
        <Link size="large" LeftIcon={ArrowLeftOnRectangleIcon} onClick={onExitAndReturnToDocument}>
          Exit and return to document
        </Link>
      </div>
    );
  }

  return (
    <div className="flex w-full max-w-[277px] flex-col gap-y-3">
      <div className="max-w-[80px] px-6 pt-6">
        <FadeIn visible={hasChatSessions}>
          <IconButton
            onClick={onToggleChatSidebar}
            Icon={isChatSidebarExpanded ? ChevronLeftIcon : ChevronRightIcon}
            tooltipText={isChatSidebarExpanded ? 'Close' : 'Expand'}
            tooltipPosition="right"
          />
        </FadeIn>
      </div>
      {selectedSessionId && (
        <>
          <FadeIn
            visible={isChatSidebarExpanded}
            className="flex flex-col px-6 py-2"
            hasExitAnimation={false}
          >
            <Button
              onClick={() => {
                onShowSuggestedPrompts();
                navigate(routes.chat());
              }}
              text="New Chat"
              size="medium"
              LeftIcon={PlusIcon}
            />
          </FadeIn>
          <FadeIn
            visible={!isChatSidebarExpanded}
            className="max-w-[80px] px-6"
            hasExitAnimation={false}
          >
            <IconButton
              onClick={() => {
                onShowSuggestedPrompts();
                navigate(routes.chat());
              }}
              Icon={PlusIcon}
              className="text-generate-medium"
              tooltipText="New Chat"
              tooltipPosition="right"
            />
          </FadeIn>
        </>
      )}

      <div className="flex flex-col overflow-auto">
        <FadeIn
          visible={isChatSidebarExpanded}
          className="flex flex-col overflow-auto p-2"
          hasExitAnimation={false}
        >
          {Object.entries(groupedChatSessionsByDate).map(([group, sessions]) => (
            <div key={group}>
              <div className="px-6 py-4 text-sm font-medium text-text-medium">{group}</div>
              {sessions.map((session) => (
                <ChatSessionItem
                  key={session.id}
                  session={session}
                  selected={selectedSessionId === session.id}
                  onClick={onSelectSession}
                />
              ))}
            </div>
          ))}
        </FadeIn>
      </div>
    </div>
  );
};

const ChatSessionItem: FC<{
  session: Pick<ChatSession, 'title' | 'id'>;
  selected?: boolean;
  onClick: (id: string) => void;
}> = ({ session, selected, onClick }) => {
  const { show, close } = useDialog();
  const [editMode, setEditMode] = useState(false);

  const [updateChatSession] = useMutation<
    UpdateChatSessionMutation,
    UpdateChatSessionMutationVariables
  >(UPDATE_CHAT_SESSION_MUTATION, {
    optimisticResponse: ({ input }) => ({
      __typename: 'Mutation',
      updateChatSession: {
        __typename: 'ChatSession',
        id: session.id,
        title: input.title,
      },
    }),
  });

  const [deleteChatSession] = useMutation<
    DeleteChatSessionMutation,
    DeleteChatSessionMutationVariables
  >(DELETE_CHAT_SESSION_MUTATION, {
    update: (cache, { data }) => {
      const normalizedId = cache.identify({
        id: data?.deleteChatSession.id,
        __typename: 'ChatSession',
      });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  useEffect(() => {
    if (!selected) {
      setEditMode(false);
    }
  }, [selected]);

  const handleClick = () => {
    if (!selected) {
      onClick(session.id);
    }
  };

  const onUpdateSession = () => {
    if (!ref.current) return;
    const value = ref.current.value;
    if (!value) {
      toast.error('Chat session title cannot be empty');
      return;
    }

    updateChatSession({
      variables: {
        id: session.id,
        input: {
          title: ref.current.value,
        },
      },
    });
    setEditMode(false);
  };

  const onDeleteSession = () => {
    deleteChatSession({
      variables: {
        id: session.id,
      },
    });
    close();
    toast.success('Chat session deleted');
    navigate(routes.chat());
  };

  const ref = useRef<HTMLInputElement>(null);

  return (
    <div
      onClick={handleClick}
      className={classNames(
        'flex h-[44px] items-center justify-between text-ellipsis rounded-lg px-4 py-3 text-sm hover:cursor-pointer hover:bg-gray-100 hover:font-medium hover:text-text-medium',
        selected ? 'bg-gray-100 font-medium text-text-dark' : 'font-normal text-text-dark'
      )}
    >
      {editMode ? (
        <input
          autoFocus
          ref={ref}
          className="flex-1 bg-none px-2 py-1"
          defaultValue={session.title ?? ''}
        />
      ) : (
        <p
          className="line-clamp-1 flex-1 overflow-hidden px-2 font-normal"
          title={session.title ?? ''}
        >
          {session.title}
        </p>
      )}

      <AnimatePresence exitBeforeEnter>
        <div className="flex gap-x-2 pl-1">
          {editMode ? (
            <>
              <IconButton
                size="small"
                tooltipText="Submit"
                onClick={onUpdateSession}
                Icon={CheckIcon}
                className="text-text-medium"
              />
              <IconButton
                size="small"
                tooltipText="Cancel"
                onClick={() => setEditMode(false)}
                Icon={XMarkIcon}
                className="text-text-medium"
              />
            </>
          ) : selected ? (
            <>
              <IconButton
                size="small"
                tooltipText="Edit Title"
                onClick={() => setEditMode(true)}
                Icon={PencilIcon}
                className="text-text-medium"
              />
              <IconButton
                size="small"
                tooltipText="Delete Session"
                onClick={() =>
                  show(<DeleteChatSessionDialog onDismiss={close} onDelete={onDeleteSession} />)
                }
                Icon={TrashIcon}
                className="text-text-medium"
              />
            </>
          ) : null}
        </div>
      </AnimatePresence>
    </div>
  );
};

const DeleteChatSessionDialog: FC<{
  onDismiss: () => void;
  onDelete: () => void;
}> = ({ onDismiss, onDelete }) => {
  const onNavigate = () => {
    navigate(routes.chat());
    onDismiss();
  };

  return (
    <DialogLayout
      title="Are you sure you want to delete this chat session?"
      className="max-w-[700px]"
      Icon={ExclamationTriangleIcon}
      onClose={onDismiss}
    >
      <p className="pt-3 text-text-dark">
        This will delete the chat session and all of its messages. Documents saved as chat responses
        will stil be available via the{' '}
        <Link size="medium" onClick={onNavigate}>
          Docs
        </Link>{' '}
        page.
      </p>
      <div className="mt-4 flex justify-end gap-x-2">
        <Button onClick={onDismiss} text="Cancel" LeftIcon={XMarkIcon} variant="outline" />
        <Button onClick={onDelete} text="Delete" LeftIcon={TrashIcon} variant="danger" />
      </div>
    </DialogLayout>
  );
};
