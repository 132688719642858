import { stringify } from 'csv-stringify/browser/esm';

/**
 * Converts an object to a CSV string with headings (keys)
 */
export function objectToCSVString(
  obj: Record<string, string | number | boolean | undefined | null>
) {
  return new Promise<string>((resolve, reject) => {
    stringify([obj], { header: true }, (err, output) => {
      if (err) {
        reject(err);
      } else {
        resolve(output);
      }
    });
  });
}
