import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { PropsWithChildren, ReactNode } from 'react';

type AlertProps = {
  variant: 'success' | 'warn' | 'destructive' | 'informative';
  icon?: ReactNode;
};

const variants = {
  success: {
    bgColor: 'bg-green-50',
    borderColor: 'border-green-700',
    defaultIcon: <CheckCircleIcon className="h-9 w-9 text-green-700" aria-hidden="true" />,
  },
  warn: {
    bgColor: 'bg-yellow-50',
    borderColor: 'border-yellow-700',
    defaultIcon: <ExclamationTriangleIcon className="h-9 w-9 text-yellow-700" aria-hidden="true" />,
  },
  destructive: {
    bgColor: 'bg-red-50',
    borderColor: 'border-red-700',
    defaultIcon: <XCircleIcon className="h-9 w-9 text-red-700" aria-hidden="true" />,
  },
  informative: {
    bgColor: 'bg-sky-50',
    borderColor: 'border-sky-700',
    defaultIcon: <InformationCircleIcon className="h-9 w-9 text-sky-700" aria-hidden="true" />,
  },
};

export const Alert = ({ variant, icon, children }: PropsWithChildren<AlertProps>) => {
  const { bgColor, borderColor, defaultIcon } = variants[variant];

  return (
    <div className={`${bgColor} ${borderColor} w-fit rounded-xl border p-3`} role="alert">
      <div className="flex items-center justify-center">
        <div className="flex-shrink-0">{icon || defaultIcon}</div>
        <div className="ml-3">
          <p className=" font-normal text-text-dark">{children}</p>
        </div>
      </div>
    </div>
  );
};
