import { USER_PERMISSION_LABELS } from 'src/lib';
import { DialogLayout } from '../DialogLayout';
import { UserIcon } from '../UserIcon';
import { PermissionFields } from 'types/graphql';
import { useAuth } from 'src/auth';
import { UserGroupIcon } from '@heroicons/react/24/outline';
import { sortUserPermissions } from 'src/lib/sortUserPermissions';
import { notNullish } from '../../lib/guards';
import { ORGANISATION_PERMISSION_LABELS } from '../../lib/sharedConstants/userPermissionLabels';

export type NonOwnerViewDialogProps = {
  onClose: () => void;
  permissionsData: PermissionFields[];
};

export const NonOwnerViewDialog = ({ onClose, permissionsData }: NonOwnerViewDialogProps) => {
  const { currentUser } = useAuth();

  const organisationPermission = permissionsData.find(
    (perm) => perm.__typename === 'OrganisationPermission'
  );

  const userPermissions = permissionsData.filter(
    ({ __typename }) => __typename === 'UserPermission'
  );

  const sortedUserPermissions = sortUserPermissions(userPermissions, currentUser);

  const combinedPermissions = [organisationPermission, ...sortedUserPermissions].filter(notNullish);

  const userList = combinedPermissions.map((permission) => {
    if (permission.__typename === 'UserPermission') {
      const isCurrentUser = permission?.user?.email === currentUser?.email;
      const userInfo = isCurrentUser ? 'You' : permission?.user?.email || permission?.user?.name;
      const permissionLabel =
        USER_PERMISSION_LABELS[permission?.permission as keyof typeof USER_PERMISSION_LABELS] || '';

      return (
        <div key={permission?.user?.id} className="mb-2 flex items-center justify-between">
          <div className="flex items-center justify-center gap-4">
            <UserIcon user={permission.user} size="medium" />
            <div className={`text-sm ${isCurrentUser ? 'text-text-verydark' : 'text-text-dark'}`}>
              {userInfo}
            </div>
          </div>
          <div className={`text-sm ${isCurrentUser ? 'text-text-verydark' : 'text-text-dark'}`}>
            {permissionLabel}
          </div>
        </div>
      );
    } // Handling OrganisationPermission
    else if (permission.__typename === 'OrganisationPermission') {
      const organisationName = permission?.organisation?.name;
      const permissionLabel =
        ORGANISATION_PERMISSION_LABELS[
          permission?.orgPermission as keyof typeof USER_PERMISSION_LABELS
        ] ?? 'No Access';
      return (
        <div key={permission.id} className="mb-2 flex justify-between">
          <div className="flex items-center gap-4">
            <UserGroupIcon className="h-6 w-6 text-gray-400" />
            <div className="text-sm">{organisationName} members</div>
          </div>
          <div className="text-sm text-text-dark">{permissionLabel}</div>
        </div>
      );
    }
  });

  return (
    <DialogLayout onClose={onClose}>
      <div className="mb-2 space-y-1 text-sm text-text-dark">
        <p>Users With Access</p>
        <hr />
      </div>

      <div className="pt-2">{userList}</div>
    </DialogLayout>
  );
};
