/**
 * Represents a schema structure for recursive field definitions. This type is designed
 * to capture the structure and constraints of fields within a schema, potentially including
 * nested schemas (innerType) or schemas modified by methods (schema), such as those wrapped
 * by Zod's refine method or similar transformations.
 *
 * The `_def` property is an optional object that may contain validation checks (such as
 * maximum length constraints), a reference to an inner schema (`innerType`), or a wrapped
 * schema (`schema`). This flexible structure allows for recursive inspection of complex
 * schema definitions.
 *
 * @typedef {Object} RecursiveFieldSchema
 * @property {Object} [_def] - Definition object containing schema details.
 * @property {Array<{kind: string, value: any}>} [_def.checks] - An array of validation checks.
 * @property {RecursiveFieldSchema} [_def.innerType] - Nested schema definition for recursive types.
 * @property {RecursiveFieldSchema} [_def.schema] - Wrapped schema, used for schemas modified by methods like refine.
 */

/**
 * Recursively finds the maximum length constraint defined in a given field schema.
 * This function is designed to navigate through complex schema structures, including
 * those wrapped by methods like Zod's refine, to extract the `max` length constraint
 * if it exists. This is particularly useful for dynamically determining validation
 * constraints directly from schema definitions in applications.
 *
 * @param {RecursiveFieldSchema | undefined} fieldSchema - The schema to inspect for a maximum length constraint.
 * @returns {number | undefined} - The maximum length constraint if found, otherwise undefined.
 *
 * Example usage:
 *
 * const schema = {
 *   _def: {
 *     checks: [{ kind: 'max', value: 255 }],
 *   },
 * };
 * const maxLength = findMaxLengthOfFieldSchema(schema);
 * console.log(maxLength); // Outputs: 255
 */

export type RecursiveFieldSchema = {
  _def?: {
    checks?: Array<{ kind: string; value: any }>;
    innerType?: RecursiveFieldSchema;
    schema?: RecursiveFieldSchema;
  };
};

export const findMaxLengthOfFieldSchema = (
  fieldSchema: RecursiveFieldSchema | undefined
): number | undefined => {
  if (fieldSchema?._def?.schema) {
    return findMaxLengthOfFieldSchema(fieldSchema._def.schema);
  }

  if (fieldSchema?._def?.innerType) {
    return findMaxLengthOfFieldSchema(fieldSchema._def.innerType);
  }

  if (fieldSchema?._def?.checks) {
    const maxCheck = fieldSchema._def.checks.find((check) => check.kind === 'max');
    return maxCheck ? maxCheck.value : undefined;
  }

  return undefined;
};
