import { z } from 'zod';

export const clientProposalFormSchema = z.object({
  name: z.string().trim().min(1, {
    message: 'Required',
  }),
  about: z
    .string()
    .trim()
    .min(1, {
      message: 'Required',
    })
    .max(2000, {
      message: "Can't be longer than 2000 characters",
    }),
  clientCompanyName: z.string().trim().min(1, {
    message: 'Required',
  }),
  clientCompanyAbout: z
    .string()
    .trim()
    .min(1, {
      message: 'Required',
    })
    .max(2000, {
      message: "Can't be longer than 2000 characters",
    }),
});

export type ClientProposalFormValues = z.infer<typeof clientProposalFormSchema>;
