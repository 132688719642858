import { FC } from 'react';
import { classNames } from 'src/lib';

export type LabelProps = {
  name: string;
  label: string;
  largeLabel?: boolean;
  readOnly?: boolean;
  required?: boolean;
  error?: boolean;
};

const ERROR_CLS = 'text-error-dark text-sm font-medium';
const BASE_CLS = 'text-sm font-medium text-text-dark';

export const Label: FC<LabelProps> = ({
  name,
  label,
  largeLabel = false,
  readOnly = false,
  required,
  error,
}) => {
  return (
    <label
      htmlFor={name}
      className={classNames(
        BASE_CLS,
        error && ERROR_CLS,
        largeLabel && 'pb-1 text-lg font-bold',
        readOnly && 'font-bold'
      )}
    >
      {label}
      {!required && <span className="pl-1.5 text-sm font-light text-text-light">- optional</span>}
    </label>
  );
};
