import Handlebars from 'handlebars';
import { registerHandlebarsHelper } from '../../../../api/src/lib/handlebars/registerHandlebarshelper';
import { eqHelper } from '../../../../api/src/lib/handlebars/eqHelper';
import { structuredHelper } from '../../../../api/src/lib/handlebars/structuredHelper.browser';

/**
 * This file should be kept in sync with the handlebars.ts file in the api/src/lib/handlebars directory.
 * It is used to compile handlebars templates in the browser.
 *
 * In the future we should consider moving this to a shared package.
 */

registerHandlebarsHelper(Handlebars, 'eq', eqHelper);
registerHandlebarsHelper(Handlebars, 'structured', function (this: void, config, options) {
  return structuredHelper.call(this, config, options, Handlebars);
});

export function compilePromptTemplate(
  prompt: string,
  config: Record<string, unknown> | null,
  input: Record<string, unknown>,
  userPersona?: Record<string, unknown> | null,
  user?: { language?: string | null } | null
): string {
  try {
    const generatePrompt = Handlebars.compile(prompt);
    return generatePrompt({ config, input, userPersona, user });
  } catch (e) {
    console.log(e);
    throw new Error('Error compiling prompt template');
  }
}
