import { useQuery } from '@redwoodjs/web';
import { FC } from 'react';
import { GetMeQuery, GetMeQueryVariables } from 'types/graphql';
import { GET_ME_QUERY } from 'src/graphql/queries';
import { hasRole } from 'src/lib/user';
import { useAuth } from 'src/auth';
import { Spinner } from 'src/components/Spinner';
import { DebouncedInput } from './DebouncedInput';
import { LanguageSelect } from './LanguageSelect';
import { RecruitmentTypeSelect } from './RecruitmentTypeSelect';
import { usePageClasses } from '../../../hooks';

const ProfilePage: FC = () => {
  usePageClasses('bg-white');
  const { currentUser } = useAuth();
  const isAdmin = currentUser ? hasRole(currentUser, 'ADMIN') : false;
  const { data, loading, error } = useQuery<GetMeQuery, GetMeQueryVariables>(GET_ME_QUERY, {
    variables: { isAdmin },
  });

  if (loading) return <Spinner />;
  if (error) {
    throw error;
  }

  // Make TypeScript happy
  if (!data?.me) {
    console.error('No data returned from GET_ME_QUERY');
    throw new Error('Something went wrong');
  }

  return (
    <div className="flex w-full flex-col gap-y-6 p-6">
      <h3 className="text-xl font-bold text-text-dark">Profile</h3>
      <DebouncedInput label="Name" name="name" initialValue={data?.me?.name ?? ''} />
      <DebouncedInput
        label="Your job title"
        name="jobTitle"
        placeholder="Recruitment consultant"
        initialValue={data?.me?.userPersona?.jobTitle ?? ''}
      />
      <LanguageSelect initialValue={data?.me?.language ?? ''} label="Language" />
      <RecruitmentTypeSelect
        label="Recruitment type"
        initialValue={data?.me?.userPersona?.recruitmentType ?? ''}
      />
      <DebouncedInput
        label="Clients"
        name="clients"
        placeholder="Start-ups, SMEs, Large Corporations, Fin-tech Startups"
        initialValue={data?.me?.userPersona?.clients ?? ''}
      />
      <DebouncedInput
        label="Industry(s)"
        name="industries"
        placeholder="Finance, Technology, Healthcare, etc."
        initialValue={data?.me?.userPersona?.industries ?? ''}
      />
      <DebouncedInput
        label="Candidates"
        name="candidates"
        placeholder="Software Engineers, Marketing Managers"
        initialValue={data?.me?.userPersona?.candidates ?? ''}
      />
      <DebouncedInput
        label="Client Locations"
        name="clientLocations"
        placeholder="London, UK"
        initialValue={data?.me?.userPersona?.clientLocations ?? ''}
      />
    </div>
  );
};

export default ProfilePage;
