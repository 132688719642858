import { useContext, useEffect } from 'react';
import { DialogContext } from '../providers/DialogProvider';

export function useDialog(
  config:
    | undefined
    | {
        onClose?: () => void;
      } = undefined
) {
  const { onClose } = config || {};
  const dialogContext = useContext(DialogContext);
  useEffect(() => {
    if (onClose) {
      dialogContext?.setOnClose?.(onClose);
    }
  });
  if (!dialogContext) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return dialogContext;
}
