import { DOCUMENT_FIELDS_FRAGMENT } from '../fragments';

export const REGENERATE_CHAT_RESPONSE_MUTATION = gql`
  ${DOCUMENT_FIELDS_FRAGMENT}
  mutation RegenerateChatResponseMutation($sessionId: String!, $responseId: String!) {
    regenerateChatResponse(sessionId: $sessionId, responseId: $responseId) {
      id
      title
      messages {
        ... on ChatRequest {
          id
          text
          createdAt
        }
        ... on ChatResponse {
          id
          text
          createdAt
          document {
            ...DocumentFields
          }
        }
      }
    }
  }
`;
