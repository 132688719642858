import { ACCESS_LEVEL_SETTING, SetDefaultUserPermissionInput } from 'types/graphql';
import { PermissionDropdown } from './PermissionDropdown';
import { FC } from 'react';

type PermissionOption = {
  label: string;
  value: ACCESS_LEVEL_SETTING;
};

type DefaultPermissionsProps = {
  permissionOptions: PermissionOption[];
  jobsPermission?: ACCESS_LEVEL_SETTING | null;
  candidatesPermission?: ACCESS_LEVEL_SETTING | null;
  companiesPermission?: ACCESS_LEVEL_SETTING | null;
  onDefaultPermissionChange: (input: SetDefaultUserPermissionInput) => void;
};

export const DefaultPermissions: FC<DefaultPermissionsProps> = ({
  permissionOptions,
  jobsPermission,
  candidatesPermission,
  companiesPermission,
  onDefaultPermissionChange,
}) => (
  <div className="mt-4 max-w-[355px]">
    <div className="space-y-6 pt-4">
      <PermissionDropdown
        label="Jobs"
        options={permissionOptions}
        value={jobsPermission}
        onChange={(newPermission) =>
          onDefaultPermissionChange({
            jobs: newPermission as ACCESS_LEVEL_SETTING,
          })
        }
      />
      <PermissionDropdown
        label="Candidates"
        options={permissionOptions}
        value={candidatesPermission}
        onChange={(newPermission) =>
          onDefaultPermissionChange({
            candidates: newPermission as ACCESS_LEVEL_SETTING,
          })
        }
      />
      <PermissionDropdown
        label="Companies"
        options={permissionOptions}
        value={companiesPermission}
        onChange={(newPermission) =>
          onDefaultPermissionChange({
            companies: newPermission as ACCESS_LEVEL_SETTING,
          })
        }
      />
    </div>
  </div>
);
