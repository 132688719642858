import { User } from '../../../types/graphql';
import { classNames } from '../../lib';

export type UserType = Pick<User, 'id' | 'name' | 'email'>;

export type UserIconProps = {
  size?: 'medium' | 'large';
  variant?: 'primary';
  user?: UserType | null;
  onClick?: () => void;
  hasShadow?: boolean;
  className?: string;
};

const userIconSizes: Record<string, string> = {
  medium: 'w-5 h-5 text-xs',
  large: 'w-6 h-6 text-sm',
};

const variantClasses: Record<string, string> = {
  primary: 'bg-generate-light',
};

export const UserIcon = ({
  size = 'large',
  variant = 'primary',
  user,
  hasShadow = false,
  onClick,
  className,
}: UserIconProps) => {
  const name = user?.name ?? user?.email ?? '';
  const initial = name.toUpperCase()[0];

  return (
    <div
      className={classNames(
        'flex items-center justify-center rounded-full',
        userIconSizes[size],
        variantClasses[variant],
        hasShadow && 'shadow-md',
        className
      )}
      onClick={onClick}
    >
      <div className={classNames('flex items-center justify-center  font-light text-text-dark ')}>
        {initial}
      </div>
    </div>
  );
};
