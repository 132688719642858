import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import {
  BlogPostIdeasInput,
  BlogPostInput,
  MarketingStrategyInput,
  Prompt,
  TestimonialRequestInput,
} from '../../types/graphql';

export const useOnboardingStore = create(
  persist(
    () => ({
      showWelcomeDialog: true,
    }),
    {
      name: 'onboarding-storage',
    }
  )
);

export const useSelectedPromptStore = create<{
  selectedPrompt: Pick<Prompt, 'id' | 'prompt' | 'description'> | null;
}>(() => ({
  selectedPrompt: null,
}));

type TTemplateInputsStore = {
  blogPost: BlogPostInput | undefined;
  blogPostIdeas: BlogPostIdeasInput | undefined;
  testimonialRequest: TestimonialRequestInput | undefined;
  marketingStrategy: MarketingStrategyInput | undefined;
};

export const useTemplateInputsStore = create(
  persist(
    () =>
      ({
        blogPost: undefined,
        blogPostIdeas: undefined,
        testimonialRequest: undefined,
        marketingStrategy: undefined,
      } as TTemplateInputsStore),
    { name: 'template-inputs-storage' }
  )
);

type BrandVoiceExample = {
  title: string;
  content?: string;
};

export type BrandVoiceWizardFormData = {
  company: {
    companyName: string;
    industry: string;
    whatSetsYouApart: string;
  };
  targetAudience: {
    industries: string;
    locations: string;
    clients: string;
    candidates: string;
  };
  personality: {
    importantValues: string;
    languageStyles: string;
    percieved: string;
  };
  archetype: {
    archetypes: {
      name: string;
      description: string;
    }[];
  };
  onBrandExample?: BrandVoiceExample;
  brandExamples?: BrandVoiceExample[];
  aboutYourWork: {
    jobTitle: string;
    recruitmentType: string;
    keyPersonalityTraits: string;
  };

  personalityStyleAims: {
    communicationTone: string;
    clientPrimaryGoal: string;
    candidatePrimaryGoal: string;
    challengingSituations: string;
  };
};

export type FormWizardType = 'COMPANY' | 'USER';

export type FormWizardMethod = 'QUESTIONNAIRE' | 'EXAMPLES';

type UpdateFieldFunction<T> = <K extends keyof T>(
  field: K,
  value: T[K] extends object ? T[K] : T[K]
) => void;

type BrandVoiceWizardStore = {
  currentStep: number;
  totalSteps: number;
  formData: BrandVoiceWizardFormData;
  method: FormWizardMethod | null;
  type: FormWizardType | null;
  generatedBrandVoice: string | null;
  updateFormData: UpdateFieldFunction<BrandVoiceWizardFormData>;
  setCurrentStep: (step: number) => void;
  resetFormData: () => void;
  setFormType: (type: FormWizardType) => void;
  setFormMethod: (method: FormWizardMethod) => void;
  nextStep: () => void;
  previousStep: () => void;
  resetStore: () => void;
  setTotalSteps: (steps: number) => void;
  addBrandExample: (data: BrandVoiceExample) => void;
  saveGeneratedBrandVoice: (brandVoice: string) => void;
  onBrandVoiceChange: (value: string) => void;
};

const brandVoiceWizardInitialData: BrandVoiceWizardFormData = {
  company: {
    companyName: '',
    industry: '',
    whatSetsYouApart: '',
  },
  targetAudience: {
    industries: '',
    locations: '',
    clients: '',
    candidates: '',
  },
  personality: {
    importantValues: '',
    languageStyles: '',
    percieved: '',
  },
  archetype: {
    archetypes: [],
  },
  onBrandExample: {
    title: '',
    content: '',
  },
  brandExamples: [],
  aboutYourWork: {
    jobTitle: '',
    recruitmentType: '',
    keyPersonalityTraits: '',
  },
  personalityStyleAims: {
    communicationTone: '',
    clientPrimaryGoal: '',
    candidatePrimaryGoal: '',
    challengingSituations: '',
  },
};

export const useBrandVoiceWizard = create<BrandVoiceWizardStore>()(
  persist(
    (set) => ({
      currentStep: 0,
      totalSteps: 0,
      formData: { ...brandVoiceWizardInitialData },
      method: null,
      type: null,
      generatedBrandVoice: null,
      setCurrentStep: (step) => set(() => ({ currentStep: step })),
      resetFormData: () => set(() => ({ formData: { ...brandVoiceWizardInitialData } })),
      setFormType: (type: FormWizardType) =>
        set(() => ({
          type,
        })),
      setFormMethod: (method: FormWizardMethod) =>
        set(() => ({
          method,
        })),
      updateFormData: ((field, value) => {
        set((state) => {
          if (typeof value === 'object' && value !== null) {
            return {
              formData: {
                ...state.formData,
                [field]: {
                  ...state.formData[field],
                  ...value,
                },
              },
            };
          } else {
            return { formData: { ...state.formData, [field]: value } };
          }
        });
      }) as UpdateFieldFunction<BrandVoiceWizardFormData>,
      nextStep: () => {
        set((state) => {
          if (state.currentStep + 1 > state.totalSteps) {
            throw new Error('Cannot go to next step');
          }

          return {
            currentStep: state.currentStep + 1,
          };
        });
      },
      previousStep: () =>
        set((state) => {
          if (state.currentStep - 1 < 0) {
            return {
              currentStep: state.currentStep,
            };
          }

          return {
            currentStep: state.currentStep - 1,
          };
        }),
      resetStore: () =>
        set(() => ({
          currentStep: 0,
          totalSteps: 0,
          formData: { ...brandVoiceWizardInitialData },
          method: null,
          type: null,
          generatedBrandVoice: null,
        })),
      setTotalSteps: (steps) => set(() => ({ totalSteps: steps })),
      addBrandExample: (data) => {
        return set((state) => {
          let brandExamples = state.formData.brandExamples || [];
          const existingExampleIndex = brandExamples.findIndex(
            (example) => example.title === data.title
          );

          if (existingExampleIndex !== -1) {
            brandExamples[existingExampleIndex] = data;
          } else if (brandExamples.length < 3) {
            brandExamples = [...brandExamples, data];
          }

          return {
            formData: {
              ...state.formData,
              brandExamples,
            },
          };
        });
      },
      saveGeneratedBrandVoice: (brandVoice) =>
        set(() => ({
          generatedBrandVoice: brandVoice,
        })),
      onBrandVoiceChange: (value) => {
        set(() => {
          return {
            generatedBrandVoice: value,
          };
        });
      },
    }),
    {
      name: 'brand-voice-wizard-storage',
    }
  )
);

type FeatureStoreType = {
  features: {
    brandVoice: boolean;
    userPersona: boolean;
  };
  setFeature: (feature: keyof FeatureStoreType['features'], value: boolean) => void;
};

export const useFeatureStore = create<FeatureStoreType>((set) => ({
  features: {
    brandVoice: false,
    userPersona: true,
  },
  setFeature: (feature, value) => {
    set((state) => ({
      features: {
        ...state.features,
        [feature]: value,
      },
    }));
  },
}));

type UserPersonaWizardFormData = {
  aboutYourWork: {
    name: string;
    language: string;
    recruitmentType: string;
  };
  targetAudience: {
    industries: string;
    clients: string;
    candidates: string;
    clientLocations: string;
  };
};

const userPersonaWizardInitialData: UserPersonaWizardFormData = {
  aboutYourWork: {
    name: '',
    language: 'en-gb',
    recruitmentType: 'Contingency',
  },
  targetAudience: {
    industries: '',
    clients: '',
    candidates: '',
    clientLocations: 'United Kingdom',
  },
};

type UserPersonaWizardStore = {
  currentStep: number;
  totalSteps: number;
  formData: UserPersonaWizardFormData;
  updateFormData: UpdateFieldFunction<UserPersonaWizardFormData>;
  setCurrentStep: (step: number) => void;
  resetFormData: () => void;
  nextStep: () => void;
  previousStep: () => void;
  resetStore: () => void;
  setTotalSteps: (steps: number) => void;
};

export const useUserPersonaWizard = create<UserPersonaWizardStore>()((set) => ({
  currentStep: 0,
  totalSteps: 4,
  formData: { ...userPersonaWizardInitialData },
  method: null,
  type: null,
  setCurrentStep: (step) => set(() => ({ currentStep: step })),
  resetFormData: () => set(() => ({ formData: { ...userPersonaWizardInitialData } })),

  updateFormData: ((field, value) => {
    set((state) => {
      if (typeof value === 'object' && value !== null) {
        return {
          formData: {
            ...state.formData,
            [field]: {
              ...state.formData[field],
              ...value,
            },
          },
        };
      } else {
        return { formData: { ...state.formData, [field]: value } };
      }
    });
  }) as UpdateFieldFunction<UserPersonaWizardFormData>,
  nextStep: () => {
    set((state) => {
      if (state.currentStep + 1 > state.totalSteps) {
        throw new Error('Cannot go to next step');
      }

      return {
        currentStep: state.currentStep + 1,
      };
    });
  },
  previousStep: () =>
    set((state) => {
      if (state.currentStep - 1 < 0) {
        return {
          currentStep: state.currentStep,
        };
      }

      return {
        currentStep: state.currentStep - 1,
      };
    }),
  resetStore: () =>
    set(() => ({
      currentStep: 0,
      totalSteps: 0,
      formData: { ...userPersonaWizardInitialData },
      method: null,
      type: null,
    })),
  setTotalSteps: (steps) => set(() => ({ totalSteps: steps })),
}));

type PersonaNotifierStore = {
  display: boolean;
  show: () => void;
  close: () => void;
};

export const usePersonaNotifierStore = create<PersonaNotifierStore>()(
  persist(
    (set) => ({
      display: true,
      show: () => set(() => ({ display: true })),
      close: () => set(() => ({ display: false })),
    }),
    {
      name: 'persona-notifier-storage',
    }
  )
);
