import { useMutation, useQuery } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/dist/toast';
import {
  ACCESS_LEVEL,
  AddAccessDocumentMutation,
  AddAccessDocumentMutationVariables,
  GetDocumentQuery,
  GetDocumentQueryVariables,
  RevokeAccessDocumentMutation,
  RevokeAccessDocumentMutationVariables,
  SetAccessDocumentMutation,
  SetAccessDocumentMutationVariables,
  SetAccessOrganisation,
  Document,
  PermissionFields,
} from 'types/graphql';
import {
  ADD_ACCESS_DOCUMENT_MUTATION,
  REVOKE_ACCESS_DOCUMENT_MUTATION,
  SET_ACCESS_DOCUMENT_MUTATION,
} from 'src/graphql/mutations';
import { ShareDialog } from './ShareDialog';
import { InviteFormValues } from './inviteFormSchema';
import { GET_DOCUMENT_QUERY } from 'src/graphql/queries';
import { getErrorMessage, isBadUserInputError } from '../../lib/errors';

export type ShareDocumentDialogProps = {
  document: Pick<Document, 'id' | 'title' | '__typename' | 'myPermission'> & {
    permissions?: PermissionFields[] | null;
  };
  onClose: () => void;
  onCopy?: () => void;
};

export const ShareDocumentDialog = ({ document, onClose, onCopy }: ShareDocumentDialogProps) => {
  const { data: documentdata, previousData: documentPreviousData } = useQuery<
    GetDocumentQuery,
    GetDocumentQueryVariables
  >(GET_DOCUMENT_QUERY, {
    variables: { id: document.id },
    fetchPolicy: 'cache-only',
    returnPartialData: true,
  });

  const isOwner = document.myPermission === 'OWNER';
  const documentPermissionData =
    (documentdata ?? documentPreviousData)?.document?.permissions ?? [];

  const [addDocumentAccess] = useMutation<
    AddAccessDocumentMutation,
    AddAccessDocumentMutationVariables
  >(ADD_ACCESS_DOCUMENT_MUTATION);

  const [setDocumentAccess] = useMutation<
    SetAccessDocumentMutation,
    SetAccessDocumentMutationVariables
  >(SET_ACCESS_DOCUMENT_MUTATION);

  const [revokeDocumentAccess] = useMutation<
    RevokeAccessDocumentMutation,
    RevokeAccessDocumentMutationVariables
  >(REVOKE_ACCESS_DOCUMENT_MUTATION);

  const handleAddDocumentAccess = async (values: InviteFormValues) => {
    const userAlreadyExists = documentPermissionData.some(
      (permission) =>
        permission.__typename === 'UserPermission' && permission.user.email === values.email
    );

    if (userAlreadyExists) {
      toast.error(`User with email ${values.email} already has access.`);
      return;
    }

    await addDocumentAccess({
      variables: {
        input: {
          id: document.id,
          email: values.email,
          permission: values.permission,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        addAccessDocument: {
          __typename: documentdata?.document?.__typename ?? 'General',
          id: document.id,
          permissions: [
            ...documentPermissionData,
            {
              __typename: 'UserPermission',
              id: 'new',
              user: {
                __typename: 'User',
                id: 'new',
                email: values.email,
              },
              permission: values.permission,
            },
          ],
        },
      },
      onCompleted: () => {
        toast.success(`An invite has been sent to ${values.email}`);
      },
      onError: (error) => {
        if (isBadUserInputError(error)) {
          toast.error(getErrorMessage(error));
        }
      },
    });
  };

  const handleSetDocumentAccessToUser = async (
    userEmail: string,
    newRole: string,
    userName: string
  ) => {
    const permissionRecord = documentPermissionData.find((p) =>
      p.__typename === 'UserPermission'
        ? p.user.email === userEmail
        : p.organisation.name === userName
    );

    if (!permissionRecord) {
      toast.error('User Permission not found');
      return;
    }

    const permissionId = permissionRecord.id;
    const isRevokingAccess = newRole === 'Remove' || newRole === 'NONE';

    if (isRevokingAccess) {
      await revokeDocumentAccess({
        variables: {
          input: { user: { entityId: document.id, permissionId } },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          revokeAccessDocument: {
            __typename: documentdata?.document?.__typename ?? 'General',
            id: document.id,
            permissions: [...documentPermissionData.filter((p) => p.id !== permissionId)],
          },
        },
        onCompleted: () => {
          toast.success(`Access for ${userName} has been revoked.`);
        },
      });
    } else {
      await setDocumentAccess({
        variables: {
          input: {
            user: {
              entityId: document.id,
              permission: newRole as ACCESS_LEVEL,
              permissionId,
            },
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          setAccessDocument: {
            __typename: documentdata?.document?.__typename ?? 'General',
            id: document.id,
            permissions: documentPermissionData.map((permission) =>
              permission.id === permissionId
                ? {
                    ...permission,
                    __typename: 'UserPermission',
                    id: 'new',
                    user: {
                      __typename: 'User',
                      id: 'new',
                      email: userEmail,
                    },
                    permission: newRole as ACCESS_LEVEL,
                  }
                : permission
            ),
          },
        },
        onCompleted: () => {
          toast.success(`Permission of ${userName} has been updated successfully`);
        },
      });
    }
  };

  const handleSetDocumentAccessToOrganisation = async (newRole: ACCESS_LEVEL | null) => {
    const permissionRecord = documentPermissionData.find(
      (p) => p.__typename === 'OrganisationPermission'
    );

    if (!permissionRecord) {
      toast.error('Organisation Permission not found');
      return;
    }

    const permissionId = permissionRecord.id;
    const setAccessInput: SetAccessOrganisation = {
      entityId: document.id,
      permissionId,
      permission: newRole,
    };

    await setDocumentAccess({
      variables: { input: { organisation: setAccessInput } },
      optimisticResponse: {
        __typename: 'Mutation',
        setAccessDocument: {
          __typename: documentdata?.document?.__typename ?? 'General',
          id: document.id,
          permissions: documentPermissionData.map((permission) =>
            permission.id === permissionId
              ? { ...permission, permission: newRole as ACCESS_LEVEL }
              : permission
          ),
        },
      },
      onCompleted: () => {
        toast.success(`Organisation permission updated.`);
      },
    });
  };

  return (
    <ShareDialog
      title={document.title ?? ''}
      description={`Members with edit access are only authorised to make manual edits. This means they cannot regenerate your ${document.__typename}`}
      onClose={onClose}
      isOwner={isOwner}
      permissionsData={documentPermissionData}
      onAddAccess={handleAddDocumentAccess}
      onSetAccessToUser={handleSetDocumentAccessToUser}
      onSetAccessToOrganisation={handleSetDocumentAccessToOrganisation}
      onCopy={onCopy}
    />
  );
};
