import { FC } from 'react';
import { DropdownSelect } from 'src/components/DropdownSelect';
import { ACCESS_LEVEL_SETTING } from 'types/graphql';

type OptionType = {
  label: string;
  value: ACCESS_LEVEL_SETTING;
};

type PermissionDropdownProps = {
  label: string;
  options: OptionType[];
  value?: ACCESS_LEVEL_SETTING | null;
  onChange: (value: ACCESS_LEVEL_SETTING | null) => void;
};

export const PermissionDropdown: FC<PermissionDropdownProps> = ({
  label,
  options,
  value,
  onChange,
}) => (
  <div className="flex items-center justify-between">
    <label className="pb-2 text-text-medium">{label}</label>
    <DropdownSelect<ACCESS_LEVEL_SETTING | null>
      items={options}
      value={value}
      onChange={onChange}
      emptyText="Private"
    />
  </div>
);
