import { z } from 'zod';

export const cvTemplateFormSchema = z.object({
  templateId: z.string().uuid().nullish(),
  accentColor: z.string().optional(),
  headerType: z.enum(['LOGO', 'LETTERHEAD']).optional(),
  logoAlignment: z.string().optional(),
  font: z.string().optional(),
  fontColor: z.string().optional(),
  headingFont: z.string().optional(),
  headingColor: z.string().optional(),
  fontScale: z.string().optional(),
  marginSize: z.string().optional(),
  capitaliseHeading: z.boolean().optional(),
  boldHeading: z.boolean().optional(),
  showBorder: z.boolean().optional(),
  borderColor: z.string().optional(),
});

export type CvTemplateFormValues = z.infer<typeof cvTemplateFormSchema>;
