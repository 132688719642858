import { JsonSchema } from './schema';

export function sortSchemaFields(schema: JsonSchema) {
  if (!schema || !schema.properties) {
    return [];
  }

  return Object.keys(schema.properties)
    .sort((a, b) => {
      return (schema.properties[a]?.order ?? 0) - (schema.properties[b]?.order ?? 0);
    })
    .map((key) => ({
      key,
      properties: schema.properties[key],
    }));
}
