import { Reorder, useDragControls } from 'framer-motion';
import { FC, PropsWithChildren, MouseEvent, useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import GrabIcon from 'src/assets/GrabIcon';
import { Tooltip } from '../Tooltip';
import { IconButton } from '../IconButton';
import { HideIfReadOnly } from '../HideIfReadOnly';

export type DraggableItemProps = {
  field: any;
  index: number;
  removeHandler: (index: number) => void;
};

export const DraggableItem: FC<PropsWithChildren<DraggableItemProps>> = ({
  field,
  index,
  removeHandler,
  children,
}) => {
  const controls = useDragControls();
  const [isDragging, setIsDragging] = useState(false);

  const handlePointerDown = (e: MouseEvent) => {
    setIsDragging(true);
    controls.start(e);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  return (
    <Reorder.Item
      value={field}
      id={field.fieldId}
      dragListener={false}
      dragControls={controls}
      onDragEnd={handleDragEnd}
      className="relative"
    >
      <div className="group relative flex flex-col gap-3">
        <HideIfReadOnly>
          <div
            className="absolute left-[-30px] top-4 flex cursor-grab items-center opacity-0 transition-opacity hover:text-generate-medium group-hover:opacity-100"
            onPointerDown={handlePointerDown}
          >
            <Tooltip innerBody="Click and drag to move" minWidth="150px" disabled={isDragging}>
              <GrabIcon className="h-5 w-5 text-text-medium " />
            </Tooltip>
          </div>
          <div className="absolute right-[-35px] top-3 flex items-center opacity-0 transition-opacity group-hover:opacity-100">
            <IconButton
              Icon={TrashIcon}
              onClick={() => removeHandler(index)}
              tooltipText="Delete"
              className="hover:text-generate-medium"
            />
          </div>
        </HideIfReadOnly>
        {children}
      </div>
    </Reorder.Item>
  );
};
