/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  ArchiveBoxIcon,
  ArchiveBoxXMarkIcon,
  ArrowDownTrayIcon,
  ArrowPathIcon,
  BriefcaseIcon,
  ClipboardIcon,
  DocumentDuplicateIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import {
  CreateJobCampaign,
  CreateJobCampaignVariables,
  DOCUMENT_TYPE,
  Document,
  DuplicateJobMutation,
  DuplicateJobMutationVariables,
  Permission,
  RegenerateJobCampaign,
  RegenerateJobCampaignVariables,
} from 'types/graphql';
import { useApolloClient } from '@apollo/client';
import { MetaTags, useMutation, useQuery } from '@redwoodjs/web';
import { PageTitle } from '../../components/PageTitle';
import {
  GetDocumentQuery,
  GetDocumentQueryVariables,
  GetJobAndDocuments,
  UpdateJobMutation,
  UpdateJobMutationVariables,
} from 'types/graphql';
import { Spinner } from '../../components/Spinner';
import {
  Button,
  DetailSnippet,
  DocumentEditor,
  EntityStatusPill,
  Moonwalk,
  Tabs,
} from '../../components';
import { BuildingOfficeIcon, MapPinIcon, BanknotesIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { RegenerateForm } from './RegenerateForm';
import {
  useBoolean,
  useDialog,
  usePageClasses,
  useQueryParamSyncedState,
  useSyncQueryParams,
  useTimeout,
  useTrackPageView,
} from '../../hooks';
import { classNames } from '../../lib';
import { toast } from '@redwoodjs/web/dist/toast';
import { SubmitHandler } from '@redwoodjs/forms';
import { navigate, routes, useParams } from '@redwoodjs/router';
import {
  CREATE_JOB_CAMPAIGN_MUTATION,
  DUPLICATE_JOB_MUTATION,
  REGENERATE_JOB_CAMPAIGN_MUTATION,
  UPDATE_JOB_MUTATION,
} from '../../graphql/mutations';
import { GET_JOB_AND_DOCUMENTS } from '../../graphql/queries/getJobAndDocumentsQuery';
import { DocumentListItem } from '../../components';
import { GET_DOCUMENT_QUERY } from '../../graphql/queries/getDocumentQuery';
import { JobFormValues } from '../../lib/formSchemas';
import { DocumentType } from '../../lib/document';
import { DropdownButton } from '../../components/DropdownButton';
import { JobExportDialog } from 'src/components/ExportDialogs/JobExportDialog';
import CampaignLogo from '../../assets/Campaign.svg';
import WandIcon from '../../assets/Wand.svg';
import { notNullish } from 'src/lib/guards';
import { NonOwnerViewDialog, ShareJobDialog } from 'src/components/ShareDialogs';
import { hasRequiredAccess } from 'src/lib/accessControl';
import { OtherPermissions } from 'src/components/OtherPermissions';
import { FadeIn } from 'src/components/FadeIn';

type JobPageProps = {
  jobId: string;
  initial?: string;
  documentType?: Document['__typename'];
};

const TABS = ['Campaign', 'Job Details'] as const;
type Tabs = (typeof TABS)[number];

const JobPage = ({ jobId, initial, documentType }: JobPageProps) => {
  const client = useApolloClient();
  useTrackPageView();
  usePageClasses('bg-pageGray');
  const { show, close } = useDialog();

  const { setQueryParams } = useSyncQueryParams();
  const params = useParams();

  const initialTab = params.tab || 'Campaign';
  const initialDocType = params.docType || 'JobAdvert';

  const { value: tab, setValue: setTab } = useQueryParamSyncedState<Tabs>(
    'tab',
    initialTab as Tabs
  );

  const { value: selectedDocType, setValue: setSelectedDocType } =
    useQueryParamSyncedState<DOCUMENT_TYPE>('docType', initialDocType as DOCUMENT_TYPE);

  const [fetchPolicy, setFetchPolicy] = useState<'cache-and-network' | 'network-only'>(
    'network-only'
  );

  const {
    value: isRegenerateCampaignButtonVisible,
    setTrue: showRegenerateCampaignButton,
    setFalse: hideRegenerateCampaignButton,
  } = useBoolean(false);

  // Only show the regenerate campaign button for 10 seconds
  useTimeout(
    () => {
      if (isRegenerateCampaignButtonVisible) {
        hideRegenerateCampaignButton();
      }
    },
    isRegenerateCampaignButtonVisible ? 10000 : null
  );

  const {
    error,
    data,
    loading: queryLoading,
    stopPolling,
    startPolling,
    previousData,
  } = useQuery<GetJobAndDocuments>(GET_JOB_AND_DOCUMENTS, {
    variables: { jobId },
    fetchPolicy,
    pollInterval: 5000,
    onCompleted: (data) => {
      if (data?.job?.campaignStatus === 'COMPLETED') {
        stopPolling();
        setFetchPolicy('cache-and-network');
        // Populate doc cache
        data?.job?.documents?.forEach((doc) => {
          client.writeQuery<GetDocumentQuery, GetDocumentQueryVariables>({
            query: GET_DOCUMENT_QUERY,
            variables: { id: doc.id },
            data: {
              __typename: 'Query',
              document: { ...doc, history: [] },
            },
          });
        });
        if (initial === 'true') {
          toast.success('Your campaign is ready!');
          /**
           * Get rid of 'initial' param to avoid further toasts when cache updates
           */
          navigate(routes.job({ jobId }));
        }
      }
    },
  });

  const documents =
    (data ?? previousData)?.job?.documents
      ?.filter((i) => i.isActive)
      .reduce<
        Partial<
          Record<
            DocumentType,
            Exclude<
              Exclude<GetJobAndDocuments['job'], null | undefined>['documents'],
              null | undefined
            >[0]
          >
        >
      >((prev, curr) => {
        prev[curr.__typename] = curr;
        return prev;
      }, {}) ?? {};
  const selectedDocument = documents?.[selectedDocType];
  const hasDocuments = documents && Object.keys(documents).length > 0;

  const myPermission = data?.job?.myPermission;
  const isOwner = hasRequiredAccess(myPermission, 'OWNER');

  const TABS: readonly ('Campaign' | 'Job Details')[] = hasDocuments
    ? ['Campaign', 'Job Details']
    : ['Job Details'];

  const isJobDetailsTabSelected = tab === 'Job Details';

  useEffect(() => {
    const params: Record<string, string> = { tab };
    if (tab === 'Campaign') {
      params.docType = selectedDocType;
    }
    setQueryParams(params);
  }, [tab, selectedDocType, setQueryParams]);

  /* This useEffect determines the initial tab to display based on the presence of a Job Campaign.
     If active documents exist and no specific tab is defined in the URL parameters, it sets the tab to 'Campaign'.
     Otherwise, it defaults to 'Job Details'. This ensures users are directed to relevant content upon page load. */
  useEffect(() => {
    const hasJobCampaign = data?.job?.campaignStatus;
    if (!data) return;

    if (hasJobCampaign) {
      if (!params.tab) {
        setTab('Campaign');
      }
    } else {
      setTab('Job Details');
    }
  }, [queryLoading, data, params.tab]);

  const [createJobCampaign, { loading: createJobCampaignLoading }] = useMutation<
    CreateJobCampaign,
    CreateJobCampaignVariables
  >(CREATE_JOB_CAMPAIGN_MUTATION);

  const [regenerateJobCampaign, { loading: regenerateCampaignLoading }] = useMutation<
    RegenerateJobCampaign,
    RegenerateJobCampaignVariables
  >(REGENERATE_JOB_CAMPAIGN_MUTATION);

  const handleCreateJobCampaign = async () => {
    const response = await createJobCampaign({
      variables: {
        id: jobId,
      },
    });
    if (response?.data?.createJobCampaign.campaignStatus === 'PENDING') {
      startPolling(5000);
    }
  };

  const handleRegenerateCampaign = async () => {
    const response = await regenerateJobCampaign({
      variables: { id: jobId },
    });

    if (response?.data?.regenerateJobCampaign.campaignStatus === 'PENDING') {
      startPolling(5000);
    }
  };

  const campaignCreationSection = (myPermission === 'READ' || myPermission === 'WRITE') && (
    <div className="flex flex-1 flex-col items-center justify-center gap-6 p-4">
      <CampaignLogo />
      <p className="max-w-xl text-center text-2xl font-medium text-text-medium">
        Click below to generate a personalised campaign for this job.
      </p>
      <div className="flex px-16 py-2">
        <Button
          className="flex-grow "
          LeftIcon={WandIcon}
          text="Generate"
          size="mega"
          disabled={createJobCampaignLoading}
          onClick={handleCreateJobCampaign}
        />
      </div>
    </div>
  );

  const [duplicateJob] = useMutation<DuplicateJobMutation, DuplicateJobMutationVariables>(
    DUPLICATE_JOB_MUTATION,
    {
      onCompleted: (data) => {
        toast.success('Job created');
        navigate(routes.job({ jobId: data.duplicateJob.id }));
      },
    }
  );

  const [updateJob, { error: updateJobError }] = useMutation<
    UpdateJobMutation,
    UpdateJobMutationVariables
  >(UPDATE_JOB_MUTATION, {
    onCompleted: ({ updateJob }) => {
      if (updateJob?.campaignStatus === 'PENDING') {
        setTab('Campaign');
        setFetchPolicy('network-only');
      }
    },
    optimisticResponse: ({ input, id }) => {
      return {
        __typename: 'Mutation',
        updateJob: {
          id,
          ...data?.job,
          ...input,
          campaignStatus: data?.job?.campaignStatus,
          __typename: 'Job',
          documents: [],
        },
      } as UpdateJobMutation;
    },
  });

  /**
   * Prevent spinner showing when refetching after cache update
   */
  const loading = queryLoading && !previousData;

  const onRegenerate: SubmitHandler<JobFormValues> = (values) => {
    updateJob({
      variables: {
        id: jobId,
        input: {
          contractType: values.contract,
          mustHaves: values.mustHaves,
          roleAndResponsibilities: values.responsibilities,
          salary: values.salary,
          title: values.title,
          company: {
            benefits: values.benefits,
            culture: values.culture,
            location: values.location,
            overview: values.overview,
          },
        },
      },
    });
    showRegenerateCampaignButton();
    toast.success('Changes has been saved');
  };

  const onDuplicate = () => {
    duplicateJob({
      variables: {
        id: jobId,
      },
    });
  };

  const onArchiveJob = () => {
    updateJob({
      variables: {
        id: jobId,
        input: {
          status: 'ARCHIVED',
        },
      },
    });
    toast.success('Job archived');
  };
  const onUnArchiveJob = () => {
    updateJob({
      variables: {
        id: jobId,
        input: {
          status: 'ACTIVE',
        },
      },
    });
    toast.success('Job restored');
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    throw error;
  }

  if (data?.job?.campaignStatus === 'PENDING') {
    return <Moonwalk showEmailInfo />;
  }

  const dropdownOptions = [
    isOwner
      ? {
          text: 'Share Job',
          Icon: UserPlusIcon,
          onClick: () => {
            show(
              <ShareJobDialog
                isOwner={isOwner}
                jobId={data?.job?.id ?? ''}
                onClose={close}
                title="Share Job Details"
                description="Users with Edit access to the job details can also edit the company details within the job."
              />
            );
          },
        }
      : null,
    isOwner
      ? {
          text: 'Regenerate campaign',
          Icon: ArrowPathIcon,
          onClick: handleRegenerateCampaign,
        }
      : null,
    {
      text: 'Create a copy',
      Icon: DocumentDuplicateIcon,
      onClick: () => onDuplicate(),
    },
    {
      text: 'Export',
      Icon: ArrowDownTrayIcon,
      onClick: () => show(<JobExportDialog id={jobId} />),
    },
    isOwner
      ? data?.job?.status === 'ACTIVE'
        ? {
            text: 'Archive',
            onClick: onArchiveJob,
            Icon: ArchiveBoxIcon,
          }
        : {
            text: 'Restore',
            onClick: onUnArchiveJob,
            Icon: ArchiveBoxXMarkIcon,
          }
      : null,
  ].filter(notNullish);

  if (!data?.job) {
    console.error('No job found');
    return null;
  }

  return (
    <div className="flex min-h-screen">
      <MetaTags title="Job" description={data?.job?.title ?? ''} />

      <div className={classNames('flex flex-1 flex-col overflow-auto pt-4 ')}>
        <div className="flex items-center justify-between px-16 pt-1">
          <div className="flex flex-col flex-wrap">
            <div className="flex flex-row gap-x-3">
              <PageTitle size="sm" Icon={BriefcaseIcon} text={data.job.title} />
            </div>
            <div className="flex flex-row flex-wrap gap-x-3">
              <DetailSnippet Icon={BuildingOfficeIcon} text={data.job.company.name} />
              {data.job.company.location && (
                <DetailSnippet Icon={MapPinIcon} text={data.job.company.location} />
              )}
              <DetailSnippet Icon={ClipboardIcon} text={data.job.contractType} />
              {data.job.salary && <DetailSnippet Icon={BanknotesIcon} text={data.job.salary} />}
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-3">
            <EntityStatusPill status={data.job.status} campaignStatus={data.job.campaignStatus} />
            <DropdownButton options={dropdownOptions} />
          </div>
        </div>

        <div className="mx-16 flex justify-between border-b border-text-light pt-4 ">
          <div className="flex flex-grow items-center ">
            <Tabs<Tabs> selected={tab} setSelected={setTab} options={TABS} />
          </div>
          <div className="flex items-center">
            <FadeIn visible={isJobDetailsTabSelected}>
              <OtherPermissions
                permissionsData={data?.job?.permissions as Permission[]}
                onClick={() => {
                  isOwner
                    ? show(
                        <ShareJobDialog
                          isOwner={isOwner}
                          jobId={data?.job?.id ?? ''}
                          onClose={close}
                          title="Share Job Details"
                          description="Users with Edit access to the job details can also edit the company details within the job."
                        />
                      )
                    : show(
                        <NonOwnerViewDialog
                          onClose={close}
                          permissionsData={data?.job?.permissions ?? []}
                        />
                      );
                }}
              />
            </FadeIn>
          </div>
        </div>

        <div className="flex flex-1 basis-5/12 flex-col overflow-hidden">
          {tab === 'Job Details' ? (
            <RegenerateForm
              hasCampaign={!!data.job.campaignStatus}
              job={data.job}
              error={updateJobError}
              onClickRegenerate={onRegenerate}
              myPermission={myPermission}
              isRegenerateCampaignButtonVisible={isRegenerateCampaignButtonVisible}
              onHandleRegenerateCampaign={handleRegenerateCampaign}
              regenerateCampaignLoading={regenerateCampaignLoading}
            />
          ) : (
            <div className="flex flex-1 flex-col overflow-auto px-16">
              <p className="pb-3 pt-6 text-sm font-semibold text-text-dark">Source & Attract</p>

              <div className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3">
                <DocumentListItem
                  onClick={() => setSelectedDocType('JobAdvert')}
                  selected={selectedDocType === 'JobAdvert'}
                  type="JobAdvert"
                />
                <DocumentListItem
                  onClick={() => setSelectedDocType('JobSocialPost')}
                  selected={selectedDocType === 'JobSocialPost'}
                  type="JobSocialPost"
                />

                <DocumentListItem
                  onClick={() => setSelectedDocType('JobInMail')}
                  selected={selectedDocType === 'JobInMail'}
                  type="JobInMail"
                />
                <DocumentListItem
                  onClick={() => setSelectedDocType('JobSnapshot')}
                  selected={selectedDocType === 'JobSnapshot'}
                  type="JobSnapshot"
                />

                {'JobBooleanSearch' in documents && (
                  <DocumentListItem
                    onClick={() => setSelectedDocType('JobBooleanSearch')}
                    selected={selectedDocType === 'JobBooleanSearch'}
                    type="JobBooleanSearch"
                  />
                )}
                {'JobEmail' in documents && (
                  <DocumentListItem
                    onClick={() => setSelectedDocType('JobEmail')}
                    selected={selectedDocType === 'JobEmail'}
                    type="JobEmail"
                  />
                )}
              </div>

              {('JobInterviewQuestions' in documents || 'JobReferenceCheck' in documents) && (
                <>
                  <p className="pb-3 pt-6 text-sm font-semibold text-text-dark">Qualify</p>
                  <div className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3">
                    <DocumentListItem
                      onClick={() => setSelectedDocType('JobInterviewQuestions')}
                      selected={selectedDocType === 'JobInterviewQuestions'}
                      display="card"
                      type="JobInterviewQuestions"
                    />
                    <DocumentListItem
                      onClick={() => setSelectedDocType('JobReferenceCheck')}
                      selected={selectedDocType === 'JobReferenceCheck'}
                      display="card"
                      type="JobReferenceCheck"
                    />
                  </div>
                </>
              )}
              {'JobInterviewPreparation' in documents && (
                <>
                  <p className="pb-3 pt-6 text-sm font-semibold text-text-dark">Interview</p>
                  <div className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3">
                    <DocumentListItem
                      onClick={() => setSelectedDocType('JobInterviewPreparation')}
                      selected={selectedDocType === 'JobInterviewPreparation'}
                      display="card"
                      type="JobInterviewPreparation"
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <div className={'flex basis-7/12 flex-row overflow-y-hidden bg-white'}>
        {!hasDocuments || !selectedDocument
          ? campaignCreationSection
          : selectedDocument && (
              <DocumentEditor key={selectedDocument?.id} id={selectedDocument?.id} />
            )}
      </div>
    </div>
  );
};

export default JobPage;
