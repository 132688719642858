import { useQuery } from '@redwoodjs/web';
import { GET_ME_QUERY } from 'src/graphql/queries';
import { useIsCurrentUserAdmin } from 'src/hooks';
import { GetMeQuery, GetMeQueryVariables } from 'types/graphql';

/**
 *
 * @returns {loading: boolean, isAdmin: boolean, brandVoice: BrandVoice | null}
 * @description This hook is used to get the default brand voice for the current user.
 *
 */
export function useDefaultBrandVoice() {
  const isAdmin = useIsCurrentUserAdmin();

  const { data, loading } = useQuery<GetMeQuery, GetMeQueryVariables>(GET_ME_QUERY, {
    variables: { isAdmin },
  });

  return {
    loading,
    isAdmin,
    brandVoice: data?.me?.defaultBrandVoice ?? data?.me?.organisation?.defaultBrandVoice ?? null,
  };
}
