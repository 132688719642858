import { toast } from '@redwoodjs/web/dist/toast';
import { convertHtmlToPlainText } from '../convertHtmlToPlainText';

// Check if Clipboard API is available
const isClipboardApiAvailable = () => {
  return Boolean(navigator.clipboard);
};

/**
 * Detects if the provided string contains HTML tags.
 *
 * @param {string} string - The string to check.
 * @return {boolean} True if the string contains HTML tags, false otherwise.
 */
const containsHtml = (content: string) => {
  const doc = new DOMParser().parseFromString(content, 'text/html');
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
};

/**
 * Copies content to the clipboard. Automatically detects if the content
 * is HTML or plain text and handles it accordingly.
 *
 * @param {string} content - The content to be copied. Can be HTML or plain text.
 */
export const copyToClipboard = async (content: string) => {
  if (!isClipboardApiAvailable()) {
    toast.error('Clipboard feature is not available in your browser.');
    return;
  }

  try {
    if (containsHtml(content)) {
      // Handle HTML content
      const plainText = convertHtmlToPlainText(content);
      const clipboardItems = {
        'text/html': new Blob([content], { type: 'text/html' }),
        'text/plain': new Blob([plainText], { type: 'text/plain' }),
      };
      await navigator.clipboard.write([new ClipboardItem(clipboardItems)]);
    } else {
      // Directly write the plain text to the clipboard for plain text content
      await navigator.clipboard.writeText(content);
    }
    toast.success('Copied to clipboard');
  } catch (error) {
    toast.error('Error copying to clipboard. Please try again.');
    console.error(error);
  }
};
