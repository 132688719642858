export const GET_ORGANISATION_QUERY = gql`
  query GetOrganisationQuery($isAdmin: Boolean!) {
    organisation {
      id
      name
      users {
        id
        email
        role
      }
      userInvites @include(if: $isAdmin) {
        id
        email
        role
        status
        createdAt
      }
      Subscription @include(if: $isAdmin) {
        id
        status
      }
      userLimit @include(if: $isAdmin)
      stripeCustomerId @include(if: $isAdmin)
      defaultCandidatePermissions
      defaultCompanyPermissions
      defaultJobPermissions
    }
  }
`;
