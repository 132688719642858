import { useLazyQuery } from '@apollo/client';
import { navigate, routes } from '@redwoodjs/router';
import { useMutation } from '@redwoodjs/web';
import { useState } from 'react';
import {
  CreateDocumentMutation,
  CreateDocumentMutationVariables,
  Document,
  GetDocumentQuery,
  GetDocumentQueryVariables,
  GetDocuments,
  GetDocumentsVariables,
} from '../../types/graphql';
import { CREATE_DOCUMENT_MUTATION } from '../graphql/mutations';
import { GET_DOCUMENTS_QUERY } from '../graphql/queries';
import { GET_DOCUMENT_QUERY } from '../graphql/queries/getDocumentQuery';

type CreateSpecEmailInput =
  | {
      jobIds: string[];
      candidateIds?: undefined;
      companyIds?: undefined;
    }
  | {
      candidateIds: string[];
      jobIds?: undefined;
      companyIds?: undefined;
    }
  | {
      companyIds: string[];
      jobIds?: undefined;
      candidateIds?: undefined;
    };

export function useCreateSpecEmail({ jobIds, candidateIds, companyIds }: CreateSpecEmailInput) {
  const [loading, setLoading] = useState(false);
  const [getSpecEmail, { error }] = useLazyQuery<GetDocuments, GetDocumentsVariables>(
    GET_DOCUMENTS_QUERY,
    {
      variables: {
        input: {
          jobIds,
          candidateIds,
          companyIds,
          isActive: true,
          includeDocumentTypes: ['JobSnapshot', 'CompanySnapshot', 'CandidateSnapshot'],
          connection: {
            take: 20,
          },
        },
      },
    }
  );
  const [createDocument] = useMutation<CreateDocumentMutation, CreateDocumentMutationVariables>(
    CREATE_DOCUMENT_MUTATION,
    {
      update: (cache, { data }) => {
        if (!data?.createDocument) {
          return;
        }

        cache.updateQuery<GetDocumentQuery, GetDocumentQueryVariables>(
          {
            query: GET_DOCUMENT_QUERY,
            variables: {
              id: data?.createDocument.id,
            },
          },
          (data) => {
            if (!data) return;
            return {
              __typename: 'Query',
              document: {
                ...data.document,
              } as Document,
            };
          }
        );
      },
    }
  );

  const entityTypes = jobIds ? 'jobs' : candidateIds ? 'candidates' : 'companies';

  const createSpecEmail = async () => {
    setLoading(true);
    try {
      const specEmail = await getSpecEmail();
      const specEmailMarkup = specEmail.data?.documents?.nodes
        .map((i) => i.markup)
        .join('<br/><br/>');
      const count = specEmail.data?.documents?.nodes?.length;

      const { data } = await createDocument({
        variables: {
          input: {
            markup: specEmailMarkup ?? '',
            title: `Spec Email: ${count} ${entityTypes}`,
          },
        },
      });
      if (!data?.createDocument?.id) {
        throw new Error('No document id');
      }
      navigate(routes.document({ documentId: data.createDocument.id }));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return { createSpecEmail, loading };
}
