import { useMutation } from '@redwoodjs/web';
import { DebouncedSelect } from './DebouncedSelect';
import { UPDATE_USER_MUTATION } from '../../../graphql/mutations';
import { UpdateUser, UpdateUserVariables } from '../../../../types/graphql';

const SELECT_OPTIONS = [
  {
    label: 'Contingency',
    value: 'Contingency',
  },
  {
    label: 'Executive Search',
    value: 'Executive Search',
  },
  {
    label: 'Temp staffing',
    value: 'Temp staffing',
  },
  {
    label: 'Specialist/niche',
    value: 'Specialist/niche',
  },
];

type Props = {
  initialValue?: string;
  label: string;
};

export const RecruitmentTypeSelect = ({ initialValue, label }: Props) => {
  const [updateUser, { loading }] = useMutation<UpdateUser, UpdateUserVariables>(
    UPDATE_USER_MUTATION
  );

  const onChange = (value: string) => {
    updateUser({
      variables: {
        input: {
          recruitmentType: value,
        },
      },
    });
  };

  return (
    <DebouncedSelect
      loading={loading}
      label={label}
      initialValue={initialValue}
      onChange={onChange}
      options={SELECT_OPTIONS}
    />
  );
};
