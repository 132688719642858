import { FC } from 'react';
import { DocumentType } from '../../lib/document';
import { ClientProposalForm } from './ClientProposalForm';
import { BaseFormProps } from './types';
import { BlogPostForm } from './BlogPostForm';
import { BlogPostIdeasForm } from './BlogPostIdeasForm';
import { TestimonialRequestForm } from './TestimonialRequestForm';
import { MarketingStrategyForm } from './MarketingStrategyForm';

type Props = { templateType: DocumentType } & BaseFormProps;

export const TemplateForm: FC<Props> = ({ templateType, ...props }) => {
  switch (templateType) {
    case 'ClientProposal':
      return <ClientProposalForm {...props} />;
    case 'BlogPost':
      return <BlogPostForm {...props} />;
    case 'BlogPostIdeas':
      return <BlogPostIdeasForm {...props} />;
    case 'TestimonialRequest':
      return <TestimonialRequestForm {...props} />;
    case 'MarketingStrategy':
      return <MarketingStrategyForm {...props} />;
    default:
      return null;
  }
};
