import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { FC } from 'react';
import { useIsCurrentUserAdmin, useQueryParamSyncedState } from 'src/hooks';
import { GeneralSection } from './GeneralSection';
import { PermissionsSection } from './PermissionsSection';
import { CogIcon, ShieldCheckIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import { MembersSection } from './MembersSection';
import { classNames } from 'src/lib';
import { SectionListItem } from '../shared';
import { BrandedDocumentSettings } from './BrandedDocumentSettings';
import { PaintBrush } from 'src/assets/PaintBrush';
import { notNullish } from 'src/lib/guards';

type Section = {
  name: string;
  component: FC;
  icon: React.FC<React.ComponentProps<'svg'>>;
};

const OrganisationPage: FC = () => {
  const isAdminUser = useIsCurrentUserAdmin();

  const SECTION_NAMES: Section[] = [
    {
      name: 'General',
      component: GeneralSection,
      icon: CogIcon,
    },
    {
      name: 'Members',
      component: MembersSection,
      icon: UserGroupIcon,
    },
    isAdminUser
      ? {
          name: 'Branding',
          component: BrandedDocumentSettings,
          icon: PaintBrush,
        }
      : null,
    {
      name: 'Permissions',
      component: PermissionsSection,
      icon: ShieldCheckIcon,
    },
  ].filter(notNullish);

  const { value, setValue } = useQueryParamSyncedState('section', SECTION_NAMES[0].name);

  const activeSectionIndex = SECTION_NAMES.findIndex((section) => section.name === value);

  const onSectionChange = (index: number) => {
    setValue(SECTION_NAMES[index].name);
  };

  const isBrandingSection = value === 'Branding';

  return (
    <div className="flex h-screen flex-grow overflow-hidden">
      {isBrandingSection ? (
        <div className="h-full w-full">
          <BrandedDocumentSettings />
        </div>
      ) : (
        <TabGroup
          selectedIndex={activeSectionIndex}
          onChange={onSectionChange}
          className="flex flex-grow flex-row "
        >
          <div className="flex w-[190px] flex-col space-y-2 border-r border-text-light p-6">
            <TabList className="flex flex-col gap-y-4">
              {SECTION_NAMES.map((section) => (
                <Tab
                  key={section.name}
                  as="div"
                  className="flex cursor-pointer flex-col justify-center whitespace-nowrap text-left"
                >
                  {({ selected }) => (
                    <SectionListItem onClick={() => setValue(section.name)} selected={selected}>
                      <section.icon
                        className={classNames(
                          'h-6 w-6 min-w-6',
                          selected ? 'text-text-dark' : 'text-text-medium hover:text-text-dark'
                        )}
                        strokeWidth={2}
                      />
                      <p>{section.name}</p>
                    </SectionListItem>
                  )}
                </Tab>
              ))}
            </TabList>
          </div>

          <TabPanels className="flex-1 p-6">
            {SECTION_NAMES.map((section) => (
              <TabPanel key={section.name} className="flex flex-1">
                <section.component />
              </TabPanel>
            ))}
          </TabPanels>
        </TabGroup>
      )}
    </div>
  );
};

export default OrganisationPage;
