import { useEffect, useLayoutEffect, useRef } from 'react';

/**
 * Custom hook to handle execution of a callback function after a specified delay.
 *
 * @param callback The callback function to be executed after the delay.
 * @param delay The time (in milliseconds) to wait before executing the callback. If null, the timeout is disabled.
 * @description
 * - `useEffect`: React's effect hook used for side effects in function components.
 * - `savedCallback`: A ref object to hold the latest version of the callback function.
 * - `setTimeout`: JavaScript function to execute a function after a delay.
 * - `clearTimeout`: JavaScript function to cancel a timeout set with `setTimeout`.
 *
 * The hook sets up a timeout to execute the provided callback after the specified delay.
 * If the `delay` is null, the timeout is not set. The callback is kept up-to-date using
 * `useLayoutEffect` to ensure the latest version of the callback is used.
 */

export const useTimeout = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback);

  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!delay && delay !== 0) {
      return;
    }

    const id = setTimeout(() => savedCallback.current(), delay);

    return () => clearTimeout(id);
  }, [delay]);
};
