import { PermissionFields, User } from 'types/graphql';

/**
 * Sorts an array of permission objects based on predefined criteria.
 * The sorting order prioritizes:
 * 1. Current user's permission
 * 2. Owner's permission
 * 3. All other permissions
 *
 * @param {PermissionFields[]} permissionsData - Array of permission objects to sort.
 * @param {User} currentUser - The current user's information for identifying personal and owner permissions.
 * @returns {PermissionFields[]} The sorted array of permission objects.
 */

export type UserType = Pick<User, 'id' | 'name' | 'email'>;

export const sortUserPermissions = (
  permissionsData: PermissionFields[],
  currentUser: UserType | null
): PermissionFields[] => {
  // Find the current user's permission to place it after organization permissions
  const currentUserPermission = permissionsData.find(
    (perm) => perm.__typename === 'UserPermission' && perm.user?.id === currentUser?.id
  );

  // Find the owner's permission to ensure it follows the current user's permission
  const ownerPermission = permissionsData.find(
    (perm) => perm.__typename === 'UserPermission' && perm.permission === 'OWNER'
  );

  // Filter out the rest of the permissions, excluding organization, current user, and owner permissions
  const restPermissions = permissionsData.filter(
    (perm) =>
      perm !== currentUserPermission &&
      perm !== ownerPermission &&
      perm.__typename !== 'OrganisationPermission'
  );

  // Concatenate the sorted groups to form the final sorted permissions list
  return [
    ...(currentUserPermission ? [currentUserPermission] : []), //  current user's permission first
    ...(ownerPermission && ownerPermission !== currentUserPermission ? [ownerPermission] : []), // Then owner's permission if it's not the current user
    ...restPermissions, // Finally, all other permissions
  ];
};
