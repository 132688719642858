export const AUTOFILL_COMPANY_MUTATION = gql`
  mutation AutoFillCompanyMutation($body: String!) {
    autoFillCompany(body: $body) {
      name
      benefits
      culture
      location
      notes
      overview
      website
    }
  }
`;
