import { CheckIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { Spinner } from '../Spinner';

type Props = {
  loading?: boolean;
};

export const EditLoadingState: FC<Props> = ({ loading }) => {
  return (
    <div className="w-18 flex flex-row items-center justify-center">
      {loading ? (
        <Spinner fillContainer={false} className="h-6 w-6" />
      ) : (
        <span className="flex items-center gap-x-1 pr-0.5 text-sm text-text-dark">
          <CheckIcon className="h-5 w-5 text-text-dark" />
          Saved
        </span>
      )}
    </div>
  );
};
