import { DOCUMENT_FIELDS_WITH_HISTORY_FRAGMENT } from '../fragments/documentFieldsWithHistoryFragment';

export const GET_DOCUMENT_QUERY = gql`
  ${DOCUMENT_FIELDS_WITH_HISTORY_FRAGMENT}
  query GetDocumentQuery($id: String!) {
    document(id: $id) {
      ...DocumentFieldsWithHistory
    }
  }
`;
