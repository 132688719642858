import { useMutation, useQuery } from '@redwoodjs/web';
import { FC, useEffect, useRef } from 'react';
import { CVTemplateCarousel } from 'src/components/Carousel';
import { CvTemplateFormValues, cvTemplateFormSchema } from './cvTemplateFormSchema';
import { useForm } from '@redwoodjs/forms';
import { zodResolver } from '@hookform/resolvers/zod';
import { UPDATE_CV_CONFIG_MUTATION } from 'src/graphql/mutations';
import {
  GetBrandAssets,
  GetCvConfig,
  GetCvTemplatesQuery,
  GetCvTemplatesQueryVariables,
  UpdateCvConfig,
  UpdateCvConfigVariables,
} from 'types/graphql';
import { Form, SelectField, Tooltip } from 'src/components';
import { AlignmentSelectionGroup } from './AlignmentSelectionGroup';
import { ColorPickerField } from 'src/components/ColorPicker/ColorPickerField';
import { Toggle } from 'src/components/Toggle';
import { alignmentOptions } from './constants';
import { useDebounce } from 'src/hooks';
import { GET_CV_TEMPLATES_QUERY } from 'src/graphql/queries';
import { Spinner } from '../../../components/Spinner';

const DEFAULT_CV_TEMPLATE_VALUES: CvTemplateFormValues = {
  templateId: '',
  accentColor: '',
  logoAlignment: 'left',
  font: 'Roboto',
  fontColor: '#000000',
  capitaliseHeading: false,
  boldHeading: false,
  showBorder: true,
  borderColor: '#000000',
  marginSize: 'medium',
  headingFont: 'Roboto',
  headingColor: '#000000',
  fontScale: 'medium',
};

export const CvTemplates: FC<{
  cvConfigData: GetCvConfig['getCvConfig'];
  onTemplateSelect: (templateId: string) => void;
  selectedTemplateId?: string | null;
  brandAssetsData: GetBrandAssets['getBrandAssets'];
}> = ({ cvConfigData, onTemplateSelect, selectedTemplateId, brandAssetsData }) => {
  const [updateCvConfig] = useMutation<UpdateCvConfig, UpdateCvConfigVariables>(
    UPDATE_CV_CONFIG_MUTATION
  );

  const CONFIG_FORM_VALUES: CvTemplateFormValues = {
    templateId: cvConfigData?.CvTemplate?.id ?? selectedTemplateId ?? '',
    headerType: cvConfigData?.headerType || getDefaultHeaderType(brandAssetsData),
    logoAlignment: cvConfigData?.logoAlignment || DEFAULT_CV_TEMPLATE_VALUES.logoAlignment,
    font: cvConfigData?.font || DEFAULT_CV_TEMPLATE_VALUES.font,
    fontColor: cvConfigData?.fontColor || DEFAULT_CV_TEMPLATE_VALUES.fontColor,
    capitaliseHeading:
      cvConfigData?.capitaliseHeading ?? DEFAULT_CV_TEMPLATE_VALUES.capitaliseHeading,
    boldHeading: cvConfigData?.boldHeading ?? DEFAULT_CV_TEMPLATE_VALUES.boldHeading,
    showBorder: cvConfigData?.showBorder ?? DEFAULT_CV_TEMPLATE_VALUES.showBorder,
    borderColor: cvConfigData?.borderColor || DEFAULT_CV_TEMPLATE_VALUES.borderColor,
    marginSize: cvConfigData?.marginSize || DEFAULT_CV_TEMPLATE_VALUES.marginSize,
    headingFont: cvConfigData?.headingFont || DEFAULT_CV_TEMPLATE_VALUES.headingFont,
    headingColor: cvConfigData?.headingColor || DEFAULT_CV_TEMPLATE_VALUES.headingColor,
    fontScale: cvConfigData?.fontScale || DEFAULT_CV_TEMPLATE_VALUES.fontScale,
  };

  const formMethods = useForm<CvTemplateFormValues>({
    resolver: zodResolver(cvTemplateFormSchema),
    defaultValues: CONFIG_FORM_VALUES,
  });

  const formValues = formMethods.watch();
  const debouncedFormValues = useDebounce(formValues, 500);
  const prevFormValuesRef = useRef<CvTemplateFormValues | null>(null);

  const {
    data: templatesData,
    loading: templatesLoading,
    error: templatesError,
  } = useQuery<GetCvTemplatesQuery, GetCvTemplatesQueryVariables>(GET_CV_TEMPLATES_QUERY);

  useEffect(() => {
    const prevFormValues = prevFormValuesRef.current;
    const formValuesChanged =
      JSON.stringify(debouncedFormValues) !== JSON.stringify(prevFormValues);

    if (formValuesChanged && !!selectedTemplateId) {
      updateCvConfig({
        variables: {
          input: {
            ...debouncedFormValues,
            templateId: selectedTemplateId,
          },
        },
      });

      prevFormValuesRef.current = debouncedFormValues;
    }
  }, [debouncedFormValues, updateCvConfig, selectedTemplateId]);

  const handleTemplateSelect = (templateId: string) => {
    onTemplateSelect(templateId);
    formMethods.setValue('templateId', templateId);
  };

  useEffect(() => {
    // Swiper is not a controlled component, so we need to manually set the initial slide
    if (!selectedTemplateId && templatesData?.getCvTemplates.length) {
      handleTemplateSelect(templatesData.getCvTemplates[1].id);
    }
  }, [selectedTemplateId, templatesData]);

  if (templatesLoading) {
    return <Spinner />;
  }

  if (!templatesData || templatesError) {
    throw new Error('Failed to fetch CV templates');
  }

  return (
    <Form<CvTemplateFormValues> formMethods={formMethods} className="space-y-6">
      <div>
        <h2 className="text-lg font-bold text-text-dark">Template</h2>
        <p className="text-sm font-normal text-text-medium">
          Select and customise your CV template. These will apply to your workspace.
        </p>
      </div>

      {/* Carousel Section */}
      <div className="relative -mx-12 border-b border-t border-text-light bg-gray-100 py-6">
        <div className="w-full px-12">
          <CVTemplateCarousel
            templates={templatesData.getCvTemplates ?? []}
            onSelectTemplate={handleTemplateSelect}
          />
        </div>
      </div>

      <div className="flex flex-grow flex-col justify-center gap-8 pt-5">
        {/* Header Type Selection */}
        <div className="flex items-center space-x-4">
          <Label>Header:</Label>
          <div>
            <SelectField
              name="headerType"
              options={getHeaderTypeDropdownOptions(brandAssetsData)}
            />
          </div>
        </div>

        {formValues.headerType === 'LOGO' && (
          <>
            {/* Logo Alignment */}
            <div className="flex items-center space-x-4">
              <Label>Align Logo:</Label>
              <AlignmentSelectionGroup name="logoAlignment" options={alignmentOptions} />
            </div>

            {/* Margin Size Selection */}
            <div className="flex items-center space-x-4">
              <Label>Margin:</Label>
              <div className="max-w-[200px]">
                <SelectField
                  name="marginSize"
                  options={[
                    { label: 'Small', value: 'small' },
                    { label: 'Medium', value: 'medium' },
                    { label: 'Large', value: 'large' },
                  ]}
                />
              </div>
            </div>
          </>
        )}

        {/* Font Selection */}
        <div className="flex items-center space-x-4">
          <Label>Font:</Label>
          <div className="flex max-w-[200px] items-center gap-8">
            <div className="min-w-28">
              <SelectField
                name="font"
                options={[
                  { label: 'Roboto', value: 'Roboto' },
                  { label: 'Libre Baskerville', value: 'Libre Baskerville' },
                  { label: 'Mono', value: 'Roboto Mono' },
                ]}
              />
            </div>
            <ColorPickerField
              name="fontColor"
              colorRecommendations={['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF']}
            />
          </div>
        </div>

        {/* Font Scale Selection */}
        <div className="flex items-center space-x-4">
          <Label>Font scale:</Label>
          <SelectField
            name="fontScale"
            options={[
              { label: 'Small', value: 'small' },
              { label: 'Medium', value: 'medium' },
              { label: 'Large', value: 'large' },
            ]}
          />
        </div>

        {/* Headings Section */}
        <div className="flex items-center space-x-4">
          <div className="flex w-full items-center gap-3">
            <Label>Headings:</Label>
            <div className="flex flex-wrap items-center gap-8">
              <div className="min-w-28">
                <SelectField
                  name="headingFont"
                  options={[
                    { label: 'Roboto', value: 'Roboto' },
                    { label: 'Libre Baskerville', value: 'Libre Baskerville' },
                    { label: 'Mono', value: 'Roboto Mono' },
                  ]}
                />
              </div>
              <div className="flex items-center gap-x-3">
                <Tooltip innerBody="Bold headings" position="top">
                  <button
                    type="button"
                    className={`flex h-10 w-10 items-center justify-center rounded border bg-white transition-colors duration-150 ${
                      formValues.boldHeading
                        ? 'border-primary-medium bg-gray-50 text-primary-medium'
                        : 'border-text-light text-text-medium hover:border-text-medium hover:bg-gray-50'
                    }`}
                    onClick={() => formMethods.setValue('boldHeading', !formValues.boldHeading)}
                  >
                    <p className="text-sm font-medium">B</p>
                  </button>
                </Tooltip>

                <Tooltip innerBody="Uppercase headings" position="top">
                  <button
                    type="button"
                    className={`flex h-10 w-10 items-center justify-center rounded border bg-white transition-colors duration-150 ${
                      formValues.capitaliseHeading
                        ? 'border-primary-medium bg-gray-50 text-primary-medium'
                        : 'border-text-light text-text-medium hover:border-text-medium hover:bg-gray-50'
                    }`}
                    onClick={() =>
                      formMethods.setValue('capitaliseHeading', !formValues.capitaliseHeading)
                    }
                  >
                    <p className="text-sm font-medium">A↑</p>
                  </button>
                </Tooltip>
              </div>
              <ColorPickerField
                name="headingColor"
                colorRecommendations={['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF']}
              />
            </div>
          </div>
        </div>

        {/* Border Section */}
        <div className="flex min-h-10 items-center space-x-4">
          <Label>Border:</Label>
          <div className="flex w-full items-center gap-8">
            <Toggle
              enabled={!!formValues.showBorder}
              onChange={(enabled) => formMethods.setValue('showBorder', enabled)}
            />
            {formValues.showBorder && (
              <ColorPickerField
                name="borderColor"
                colorRecommendations={['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF']}
              />
            )}
          </div>
        </div>
      </div>
    </Form>
  );
};

const Label: FC<{ children: React.ReactNode }> = ({ children }) => (
  <p className="min-w-28 font-medium text-text-dark">{children}</p>
);

function getDefaultHeaderType(brandAssetsData: GetBrandAssets['getBrandAssets']) {
  if (brandAssetsData?.letterhead) {
    return 'LETTERHEAD';
  }
  return 'LOGO';
}

function getHeaderTypeDropdownOptions(brandAssetsData: GetBrandAssets['getBrandAssets']) {
  const options = [
    { label: 'Logo', value: 'LOGO' },
    { label: 'Letterhead', value: 'LETTERHEAD' },
  ];

  if (!brandAssetsData?.letterhead) {
    return options.filter((option) => option.value !== 'LETTERHEAD');
  }

  return options;
}
