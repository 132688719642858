import { PropsWithChildren } from 'react';
import { useReadOnlyForm } from 'src/hooks';

export const HideIfReadOnly = ({ children }: PropsWithChildren) => {
  const { readOnly } = useReadOnlyForm();

  if (readOnly) {
    return null;
  }

  return <>{children}</>;
};
