import { useEffect, RefObject } from 'react';

/**
 * Hook to detect clicks outside of a specified component.
 *
 * @param ref - A RefObject of the component to monitor for outside clicks.
 * @param callback - A callback function that is executed when a click outside the component is detected.
 */
export function useOutsideClick<T extends HTMLElement>(
  ref: RefObject<T>,
  callback: () => void
): void {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ref, callback]);
}
