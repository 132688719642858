import { useState } from 'react';

/**
 * Custom hook for managing character count in a text input or textarea.
 *
 * @param {number} initialCount - The initial character count, usually 0.
 * @param {number} [maxLength] - An optional maximum length for the input.
 * @returns {[number, (value: string) => string]} - Returns the current character count and a function to update it.
 *
 * @description
 * The hook provides character count management with an optional maximum length.
 * It automatically truncates the input value if it exceeds the specified maximum length.
 * This is particularly useful for inputs or textareas where character limits are enforced.
 */
export const useCharCount = (
  initialCount: number,
  maxLength?: number
): [number, (value: string) => string] => {
  const [charCount, setCharCount] = useState(initialCount);

  /**
   * Updates the character count based on the input value.
   * If a maxLength is provided and the input value exceeds it, the value is truncated.
   *
   * @param {string} value - The current value of the input or textarea.
   * @returns {string} - The possibly truncated new value.
   */
  const updateCharCount = (value: string): string => {
    if (maxLength && value.length > maxLength) {
      value = value.substring(0, maxLength);
    }
    setCharCount(value.length);
    return value;
  };

  return [charCount, updateCharCount];
};
