import { FC } from 'react';
import { SubmitHandler, useForm } from '@redwoodjs/forms';
import { useMutation } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/dist/toast';
import { zodResolver } from '@hookform/resolvers/zod';

import { UPDATE_SNIPPET_MUTATION } from 'src/graphql/mutations';
import {
  Snippet,
  UpdateSnippetMutation,
  UpdateSnippetMutationVariables,
} from '../../../types/graphql';
import { Button } from '../Button';
import { DialogLayout } from '../DialogLayout';
import { Spinner } from '../Spinner';
import { TextField } from '../TextField';
import { FormError } from '../FormError';
import { Form } from '../Form/Form';
import { SnippetValues, snippetFormSchema } from './snippetFormSchema';
import { HideIfReadOnly } from '../HideIfReadOnly';
import { RichTextField } from '../RichTextField';

type FormValues = {
  name: string;
  text: string;
};

type Props = {
  onClose: () => void;
  snippet: Pick<Snippet, 'id' | 'name' | 'text'>;
};

export const EditSnippetDialog: FC<Props> = ({ onClose, snippet }) => {
  const [mutate, { loading, error }] = useMutation<
    UpdateSnippetMutation,
    UpdateSnippetMutationVariables
  >(UPDATE_SNIPPET_MUTATION, {
    onCompleted: () => {
      toast.success('Snippet updated.');
      onClose();
    },
  });

  const formMethods = useForm<SnippetValues>({
    resolver: zodResolver(snippetFormSchema),
    defaultValues: {
      name: snippet.name,
      text: snippet.text,
    },
  });

  const onSubmit: SubmitHandler<FormValues> = (values) => {
    mutate({
      variables: {
        id: snippet.id,
        input: { name: values.name, text: values.text },
      },
    });
  };

  return (
    <DialogLayout
      onClose={onClose}
      title="Edit Snippet"
      className="w-full max-w-full overflow-visible rounded-[32px] md:max-w-[802px]"
      titleClassName="text-text-veryDark font-bold text-2xl px-3"
    >
      <div className="relative px-3 py-8">
        <Form
          className="flex min-w-[600px] flex-col gap-y-4"
          onSubmit={onSubmit}
          config={{ defaultValues: snippet }}
          formMethods={formMethods}
        >
          <TextField label="Description" name="name" schema={snippetFormSchema} />

          <RichTextField
            name="text"
            label="Snippet"
            loading={loading}
            disabled={false}
            placeholder="Write your snippet here"
            className="h-96"
            schema={snippetFormSchema}
            visibleButtons={['bold', 'italic', 'heading1', 'heading2', 'bulletList', 'orderedList']}
          />
          <FormError error={error} />
          {loading && (
            <div className="absolute top-0 h-full w-full bg-white opacity-80">
              <Spinner />
            </div>
          )}
          <div className="mt-auto flex justify-end space-x-3 px-3 pt-2">
            <HideIfReadOnly>
              <Button variant="outline" size="medium" text="Cancel" onClick={onClose} />
              <Button type="submit" size="medium" text="Save" loading={loading} />
            </HideIfReadOnly>
          </div>
        </Form>
      </div>
    </DialogLayout>
  );
};
