import { useEffect } from 'react';
import { PageClassesContext } from '../providers';

/**
 *
 * @param mountPageClasses Page classes to set on mount
 * @returns pageClasses and setPageClasses
 */
export function usePageClasses(mountPageClasses?: string) {
  const { pageClasses, setPageClasses } = React.useContext(PageClassesContext);

  useEffect(() => {
    if (mountPageClasses) {
      setPageClasses(mountPageClasses);
    }
  }, [pageClasses, setPageClasses]);

  return { pageClasses, setPageClasses };
}
