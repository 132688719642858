import { useQuery } from '@redwoodjs/web';
import { FC } from 'react';
import { GetDocumentQuery, GetDocumentQueryVariables } from 'types/graphql';
import { GET_DOCUMENT_QUERY } from '../../graphql/queries/getDocumentQuery';
import { Spinner } from '../Spinner';
import { RichTextDocumentEditor } from './RichTextDocumentEditor';
import { PdfDocumentEditor } from './PdfDocumentEditor';

type Props = {
  id: string;
  candidateId?: string;
};

export const DocumentEditor: FC<Props> = ({ id, candidateId }) => {
  const {
    data: documentData,
    loading,
    previousData,
    error,
  } = useQuery<GetDocumentQuery, GetDocumentQueryVariables>(GET_DOCUMENT_QUERY, {
    variables: { id },
    skip: !id,
    // Return base document while history is fetched in background
    returnPartialData: true,
  });

  const document = documentData?.document || previousData?.document;

  if (loading && !document) {
    return <Spinner />;
  }

  if (error) {
    throw error;
  }

  if (!document) {
    return null;
  }

  return (
    <div className="flex flex-1 flex-col bg-white">
      {document?.__typename === 'CandidateCv' ? (
        <PdfDocumentEditor candidateId={candidateId} document={document} />
      ) : (
        <RichTextDocumentEditor document={document} />
      )}
    </div>
  );
};
