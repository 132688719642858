import z from 'zod';

export const blogPostFormSchema = z.object({
  industry: z.string().trim().min(1, {
    message: 'Required',
  }),
  targetAudience: z.string().trim().min(1, {
    message: 'Required',
  }),
  topic: z.string().trim().min(1, {
    message: 'Required',
  }),
  type: z.string().trim().min(1, {
    message: 'Required',
  }),
  informationToInclude: z
    .string()
    .max(4000, {
      message: "Can't be longer than 4000 characters",
    })
    .nullish(),
});

export type BlogPostFormValues = z.infer<typeof blogPostFormSchema>;
