import { FC, useRef } from 'react';
import { useForm } from '@redwoodjs/forms';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { GlobeAltIcon } from '@heroicons/react/24/outline';
import { Form } from '../Form/Form';
import { TextField } from '../TextField';
import { Button } from '../Button';
import { IconButton } from '../IconButton';
import { classNames } from 'src/lib';

interface UrlScrapePopoverProps {
  onSubmit: (data: UrlScrapeFormValues) => void;
  helpText?: string;
  className?: string;
}

const urlScrapeSchema = z.object({
  url: z.string().min(1, { message: 'Required' }),
});

export type UrlScrapeFormValues = z.infer<typeof urlScrapeSchema>;

export const UrlScrapePopover: FC<UrlScrapePopoverProps> = ({
  onSubmit,
  helpText = '💡 Example: LinkedIn profile or a company website',
  className,
}) => {
  const formMethods = useForm<UrlScrapeFormValues>({
    resolver: zodResolver(urlScrapeSchema),
    defaultValues: { url: '' },
  });

  const anchorRef = useRef<HTMLButtonElement>(null);

  const determinePositionClasses = (): string => {
    const anchorBounds = anchorRef.current?.getBoundingClientRect();

    if (!anchorBounds) return '';

    const { left, right } = anchorBounds;
    const spaceToLeft = left;
    const spaceToRight = window.innerWidth - right;
    const threshold = 500;

    const positionClasses = {
      right: 'left-0 origin-top-left',
      left: 'right-0 origin-top-right',
      center: 'top-0 origin-top left-1/2 transform -translate-x-1/2',
    };

    if (spaceToLeft < threshold && spaceToRight < threshold) {
      return positionClasses.center;
    } else if (spaceToLeft < threshold) {
      return positionClasses.right;
    } else if (spaceToRight < threshold) {
      return positionClasses.left;
    }

    // Default to 'center' if none of the conditions above are met
    return positionClasses.center;
  };

  const hasValidationError = formMethods.formState.errors.url;

  return (
    <div className="flex justify-center">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <PopoverButton as="button" ref={anchorRef} className="focus:outline-none">
              <IconButton
                Icon={GlobeAltIcon}
                tooltipText="Scan web page"
                tooltipMinWidth="105px"
                variant="generate"
              />
            </PopoverButton>
            <Transition
              show={open}
              enter="transition ease-out duration-100"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <PopoverPanel
                static
                className={classNames(
                  'absolute z-40 mt-10 min-w-[500px] rounded-lg bg-white p-4 shadow-lg',
                  determinePositionClasses(),
                  className
                )}
              >
                <Form
                  onSubmit={(data) => {
                    onSubmit(data);
                    close();
                  }}
                  formMethods={formMethods}
                  className={classNames(
                    'flex gap-4',
                    hasValidationError ? 'items-center' : 'items-end'
                  )}
                >
                  <TextField
                    name="url"
                    autoFocus
                    placeholder="Enter your URL here"
                    label="URL"
                    schema={urlScrapeSchema}
                  />
                  <Button type="submit" text="Scan" className="mb-[5px]" />
                </Form>
                <p className="pt-2 text-sm font-normal text-text-dark">{helpText}</p>
              </PopoverPanel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};
