import { useEffect, useState } from 'react';
import { Button } from '../../../components';
import { InputField, useForm } from '@redwoodjs/forms';
import { Form } from 'src/components/Form/Form';
import { textInputClasses } from 'src/components/TextField/TextField';
import { classNames } from 'src/lib';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useMutation, useQuery } from '@redwoodjs/web';
import { hasRole } from 'src/lib/user';
import { useAuth } from 'src/auth';
import {
  GetMeQuery,
  GetMeQueryVariables,
  GetOrganisationQuery,
  GetOrganisationQueryVariables,
} from 'types/graphql';
import { GET_ME_QUERY, GET_ORGANISATION_QUERY } from 'src/graphql/queries';
import { Spinner } from 'src/components/Spinner';
import { UPDATE_ORGANISATION_MUTATION } from 'src/graphql/mutations';
import { toast } from '@redwoodjs/web/dist/toast';
import { useDebounce } from 'src/hooks';
import { SettingsItem } from '../SettingsComponents';
import { formatDate } from 'src/lib/dateTime';

export const GeneralSection = () => {
  const { currentUser } = useAuth();

  const formMethods = useForm();
  const isAdmin = currentUser ? hasRole(currentUser, 'ADMIN') : false;

  const {
    data: orgData,
    loading: orgLoading,
    error: orgError,
  } = useQuery<GetOrganisationQuery, GetOrganisationQueryVariables>(GET_ORGANISATION_QUERY, {
    variables: {
      isAdmin,
    },
  });

  const { data: getMeData, loading: getMeLoading } = useQuery<GetMeQuery, GetMeQueryVariables>(
    GET_ME_QUERY,
    {
      variables: {
        isAdmin: currentUser ? hasRole(currentUser, 'ADMIN') : false,
      },
      fetchPolicy: 'cache-first',
    }
  );

  const [organisationName, setOrganisationName] = useState('');
  const debouncedOrganisationName = useDebounce(organisationName, 1000);

  const [updateOrganisation, { loading: updateOrganisationLoading }] = useMutation(
    UPDATE_ORGANISATION_MUTATION,
    {
      onCompleted: () => {
        toast.success('Your organisation name has been changed successfully!');
      },
    }
  );

  useEffect(() => {
    if (orgData && orgData.organisation) {
      setOrganisationName(orgData.organisation.name ?? '');
    }
  }, [orgData]);

  useEffect(() => {
    if (debouncedOrganisationName && debouncedOrganisationName !== orgData?.organisation?.name) {
      updateOrganisation({
        variables: {
          input: {
            name: debouncedOrganisationName,
          },
        },
      });
    }
  }, [debouncedOrganisationName, orgData?.organisation?.name, updateOrganisation]);

  if (orgLoading || getMeLoading) {
    return <Spinner />;
  }

  if (orgError) {
    throw orgError;
  }

  const subscriptionEnds = getMeData?.me?.subscription?.cancelAt
    ? new Date(getMeData?.me?.subscription?.cancelAt)
    : null;
  const showEndsAt = subscriptionEnds && subscriptionEnds > new Date();

  const onUpdate = () => {
    const emailUrl = new URL(`mailto:${process.env.SUPPORT_EMAIL}`);
    emailUrl.searchParams.append('subject', `Update Licenses for ${orgData?.organisation?.name}`);
    emailUrl.searchParams.append('body', `Please update my license limit to `);
    /**
     * Plus encoded spaces to not work on iOS mail app so we must convert to percent encoded spaces
     */
    const urlString = emailUrl.toString().replace(/\+/g, '%20');

    window.open(urlString);
  };

  return (
    <Form formMethods={formMethods} className="flex flex-col space-y-6 ">
      <h3 className="text-xl font-bold text-text-dark">General</h3>
      <div className="flex flex-col space-y-6">
        <div className="flex justify-start">
          <SettingsItem label="Name" value={''}>
            <InputField
              value={organisationName}
              onChange={(e) => setOrganisationName(e.target.value)}
              className={classNames(
                textInputClasses,
                'min-w-[253px] pl-3 disabled:cursor-not-allowed disabled:text-text-medium'
              )}
              errorClassName={classNames(
                textInputClasses,
                'disabled:bg-gray-100 border-error-light text-error-medium'
              )}
              name="name"
              disabled={!isAdmin || updateOrganisationLoading}
            />
          </SettingsItem>
        </div>

        <SettingsItem label="Role" value={isAdmin ? 'Admin' : 'Member'} />
        {isAdmin && (
          <>
            {showEndsAt && <SettingsItem label="Renews" value={formatDate(subscriptionEnds)} />}
            <SettingsItem label="Plan" value={getMeData?.me?.subscription?.product?.name ?? ''} />
            <SettingsItem label="Licenses" value={`${orgData?.organisation?.userLimit}`}>
              <Button
                variant="outline"
                size="medium"
                text="Update"
                LeftIcon={PlusIcon}
                onClick={onUpdate}
              />
            </SettingsItem>
          </>
        )}
      </div>
    </Form>
  );
};
