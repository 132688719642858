export const CREATE_COMPANY_MUTATION = gql`
  mutation CreateCompanyMutation($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      id
      campaignStatus
      name
      location
      overview
      culture
      benefits
      website
      notes
    }
  }
`;
