import { ComponentProps, FC } from 'react';

export const TopMarginIcon: FC<ComponentProps<'svg'>> = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5002 7.5H7.50016C6.57969 7.5 5.8335 8.24619 5.8335 9.16667V10.8333C5.8335 11.7538 6.57969 12.5 7.50016 12.5H12.5002C13.4206 12.5 14.1668 11.7538 14.1668 10.8333V9.16667C14.1668 8.24619 13.4206 7.5 12.5002 7.5Z"
        stroke="#6B7280"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3332 3.33301H1.6665"
        stroke="#6B7280"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
