import { FC } from 'react';
import { Button } from '../Button';

type Props = {
  onCancel: () => void;
  onExport: () => void;
  loading?: boolean;
};

const ActionButtons: FC<Props> = ({ onCancel, onExport, loading }) => {
  return (
    <div className="flex w-full justify-end gap-x-3">
      <Button text="Cancel" variant="outline" onClick={onCancel} disabled={loading} />
      <Button
        text={loading ? 'Exporting...' : 'Export'}
        loading={loading}
        disabled={loading}
        onClick={onExport}
      />
    </div>
  );
};

export default ActionButtons;
