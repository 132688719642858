export const UPDATE_CANDIDATE_MUTATION = gql`
  mutation UpdateCandidate($id: String!, $input: UpdateCandidateInput!) {
    updateCandidate(id: $id, input: $input) {
      id
      campaignStatus
      status
      name
      refId
      rightToWork
      jobTitle
      desiredJobTitle
      currentSalary
      desiredSalary
      notes
      location
      availability
      overview
      experience {
        id
        title
        company
        startDate
        endDate
        location
        description
      }
      education {
        id
        title
        institution
        startDate
        endDate
        location
        description
      }
      skills {
        id
        title
        description
      }
      achievements {
        id
        description
      }
      profileSummary
      languages {
        id
        language
        level
      }
      interests {
        id
        description
      }
      certifications {
        id
        title
        description
      }
    }
  }
`;
