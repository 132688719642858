import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useQuery, useMutation } from '@redwoodjs/web';
import { toast } from '@redwoodjs/web/dist/toast';
import { FC } from 'react';
import { Button, CreateSnippetDialog, EditSnippetDialog } from 'src/components';
import { DropdownButton } from 'src/components/DropdownButton';
import { Spinner } from 'src/components/Spinner';
import { DELETE_SNIPPET_MUTATION } from 'src/graphql/mutations';
import { GET_SNIPPETS_QUERY } from 'src/graphql/queries';
import { useDialog, usePageClasses } from 'src/hooks';
import { convertHtmlToPlainText } from 'src/lib';

import {
  DeleteSnippetMutation,
  DeleteSnippetMutationVariables,
  GetSnippetsQuery,
  GetSnippetsQueryVariables,
  Snippet,
} from 'types/graphql';

const SnippetsPage: FC = () => {
  usePageClasses('bg-white');
  const { show, close } = useDialog();
  const { data, loading, error } = useQuery<GetSnippetsQuery, GetSnippetsQueryVariables>(
    GET_SNIPPETS_QUERY
  );
  const [deleteSnippet, { loading: mutationLoading }] = useMutation<
    DeleteSnippetMutation,
    DeleteSnippetMutationVariables
  >(DELETE_SNIPPET_MUTATION, {
    onCompleted: () => {
      toast.success('Snippet deleted.');
    },
    refetchQueries: [GET_SNIPPETS_QUERY],
    onError: (e) => {
      toast.error("Couldn't delete snippet.");
      console.error(e);
    },
  });

  if (error) {
    throw error;
  }

  if (loading ?? mutationLoading) {
    return <Spinner />;
  }
  const isEmpty = data?.snippets?.length === 0;

  return (
    <div className="flex max-w-[1400px] flex-1 flex-col px-8 pt-6">
      <div className="flex flex-row items-center justify-between">
        <div className="text-xl font-bold text-text-dark">Snippets</div>
        <Button
          size="medium"
          LeftIcon={PlusIcon}
          text="Create New"
          onClick={() => show(<CreateSnippetDialog onClose={close} />)}
        />
      </div>
      <div className="border-b border-gray-200 pt-4" />
      <div className="flex-grow flex-col pt-6">
        {isEmpty ? (
          <EmptyState />
        ) : (
          data?.snippets?.map((snippet) => (
            <SnippetsListItem
              snippet={snippet}
              onEditSnippet={() => show(<EditSnippetDialog onClose={close} snippet={snippet} />)}
              onDeleteSnippet={(id) => deleteSnippet({ variables: { id } })}
              key={snippet.id}
            />
          ))
        )}
      </div>
    </div>
  );
};

const SnippetsListItem: FC<{
  snippet: Pick<Snippet, 'name' | 'id' | 'text'>;
  onEditSnippet: (snippet: Pick<Snippet, 'id' | 'name' | 'text'>) => void;
  onDeleteSnippet: (id: string) => void;
}> = ({ snippet, onDeleteSnippet, onEditSnippet }) => {
  const { show, close } = useDialog();
  return (
    <div
      onClick={() => show(<EditSnippetDialog onClose={close} snippet={snippet} />)}
      className="flex cursor-pointer items-center py-2 hover:bg-gray-50"
    >
      <div className="flex min-w-[350px] items-center text-sm font-medium text-text-veryDark">
        {snippet.name}
      </div>
      <div className="line-clamp-2 flex flex-grow items-center  pr-3 text-sm font-normal text-text-veryDark">
        {convertHtmlToPlainText(snippet.text)}
      </div>
      <div>
        <DropdownButton
          options={[
            {
              Icon: PencilIcon,
              onClick: () => onEditSnippet(snippet),
              text: 'Edit',
            },

            {
              Icon: TrashIcon,
              onClick: () => onDeleteSnippet(snippet.id),
              text: 'Delete',
            },
          ]}
        />
      </div>
    </div>
  );
};

const EmptyState = () => {
  return (
    <div className="flex flex-grow flex-col items-center justify-center gap-y-2 py-4">
      <div className="font-medium text-text-dark">You don&apos;t have any snippets yet.</div>
      <div className="font-normal text-text-medium">
        Create a snippet by clicking the button above.
      </div>
    </div>
  );
};

export default SnippetsPage;
