import { ComponentProps, FC } from 'react';

export const GuitarIcon: FC<ComponentProps<'svg'>> = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.8999 12.0999L16.4139 7.58594"
        stroke="#4D84F0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1001 2.30002C19.9131 2.11679 19.6618 2.01416 19.4001 2.01416C19.1383 2.01416 18.887 2.11679 18.7001 2.30002L17.5861 3.41402C17.211 3.789 17.0002 4.29763 17.0001 4.82802V6.17202C16.9999 6.70241 16.7892 7.21103 16.4141 7.58602C16.789 7.21092 17.2977 7.00013 17.8281 7.00002H19.1721C19.7025 6.9999 20.2111 6.78912 20.5861 6.41402L21.7001 5.30002C21.8833 5.11309 21.9859 4.86177 21.9859 4.60002C21.9859 4.33826 21.8833 4.08695 21.7001 3.90002L20.1001 2.30002Z"
        stroke="#4D84F0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 16L8 18"
        stroke="#4D84F0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.2 9.9C8.7 8.8 9.8 8 11 8C13.8 8 16 10.2 16 13C16 14.2 15.2 15.3 14.1 15.8L13.2 16.2C12.8484 16.3534 12.5484 16.6046 12.3356 16.9237C12.1229 17.2429 12.0064 17.6165 12 18C12 19.0609 11.5786 20.0783 10.8284 20.8284C10.0783 21.5786 9.06087 22 8 22C4.7 22 2 19.3 2 16C2 14.9391 2.42143 13.9217 3.17157 13.1716C3.92172 12.4214 4.93913 12 6 12C6.38351 11.9936 6.75711 11.8771 7.07625 11.6644C7.39539 11.4516 7.64663 11.1516 7.8 10.8L8.2 9.9Z"
        stroke="#4D84F0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 13C11.7761 13 12 12.7761 12 12.5C12 12.2239 11.7761 12 11.5 12C11.2239 12 11 12.2239 11 12.5C11 12.7761 11.2239 13 11.5 13Z"
        fill="black"
        stroke="#4D84F0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
