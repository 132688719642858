export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      email
      name
      language
      userPersona {
        id
        recruitmentType
        clients
        industries
        candidates
        clientLocations
      }
    }
  }
`;
