export const GET_CV_CONFIG_QUERY = gql`
  query GetCvConfig {
    getCvConfig {
      id
      CvTemplate {
        id
      }
      accentColor
      headerType
      logoAlignment
      font
      fontColor
      capitaliseHeading
      boldHeading
      showBorder
      borderColor
      marginSize
      headingFont
      headingColor
      fontScale
    }
  }
`;
