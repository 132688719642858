import { useState, PropsWithChildren, useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { classNames } from 'src/lib';

export type AccordionProps = {
  headline: string;
  subtext?: string;
  isOpen?: boolean;
  onToggle?: (isOpen: boolean) => void;
};

export const Accordion = ({
  headline,
  subtext,
  isOpen = false,
  onToggle,
  children,
}: PropsWithChildren<AccordionProps>) => {
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleToggle = () => {
    const newState = !open;
    setOpen(newState);
    onToggle?.(newState);
  };

  return (
    <div className="w-full rounded-[4px] border border-text-light bg-pageGray">
      <button
        className="group flex w-full select-none items-center justify-between px-4 py-2 text-left text-sm font-medium text-text-veryDark focus:outline-none"
        onClick={handleToggle}
        type="button"
      >
        <div className="flex h-10 flex-col justify-center">
          <div className="line-clamp-1 select-none overflow-ellipsis text-base font-semibold text-text-dark group-hover:text-generate-medium">
            {headline}
          </div>
          <div className="select-none text-xs font-normal text-text-medium">{subtext}</div>
        </div>
        <div className="h-5 w-5">
          <ChevronDownIcon
            className={`h-5 w-5 text-text-dark transition-transform group-hover:text-generate-medium ${
              open ? 'rotate-180' : ''
            } select-none`}
          />
        </div>
      </button>
      <div
        className={classNames(
          'bg-pageGray transition-all duration-300 ease-in-out',
          open ? 'max-h-[1000px] overflow-visible' : 'max-h-0 overflow-hidden'
        )}
      >
        <div className="px-4 pb-2 pt-4">{children}</div>
      </div>
    </div>
  );
};
