import { HtmlToTextOptions, convert } from 'html-to-text';

type SelectorOption = {
  selector: string;
  options: { [key: string]: unknown };
};

/**
 * Converts HTML content to plain text.
 *
 * @param {string} markup - The HTML content to be converted.
 * @param {number} wordWrapLength - Maximum length of a line before it gets wrapped.
 * @param {SelectorOption[]} selectors - Optional selectors to customize the conversion.
 * @returns {string} The converted plain text.
 */
export const convertHtmlToPlainText = (
  markup: string,
  wordWrapLength = 130,
  selectors: SelectorOption[] = []
) => {
  const options: HtmlToTextOptions = {
    wordwrap: wordWrapLength,
  };
  if (selectors.length > 0) {
    options.selectors = selectors;
  }
  return convert(markup, options);
};
