import { DOCUMENT_FIELDS_FRAGMENT } from '../fragments';

// TODO: FIX FRAGMENT INTERPOLATION https://github.com/redwoodjs/redwood/issues/7491
export const UPDATE_DOCUMENT_MUTATION = gql`
  ${DOCUMENT_FIELDS_FRAGMENT}
  mutation UpdateDocumentMutation($updateDocumentInput: UpdateDocumentInput!) {
    updateDocument(input: $updateDocumentInput) {
      ...DocumentFields
    }
  }
`;
