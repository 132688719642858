import { FC } from 'react';
import { useDialog } from '../../hooks';
import { Button, DialogLayout, TextAreaField } from '../../components';
import { IconButton } from '../../components/IconButton';
import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline';
import { State } from './PromptEngineeringPage';
import { Form } from '../../components/Form';
import { useForm } from '@redwoodjs/forms';

type Props = {
  prompts: State['prompts'];
  onChange: (v: State['prompts']) => void;
  templateContext: Record<string, unknown>;
  onSubmit: () => void;
};

/**
 * Allow user to edit raw system and user prompts
 */
export const PromptEditor: FC<Props> = ({ prompts, onChange, onSubmit, templateContext }) => {
  const { show } = useDialog();
  const formMethods = useForm<{ user: string; system: string }>({
    values: prompts,
  });

  formMethods.watch((data) =>
    onChange({
      system: data.system ?? '',
      user: data.user ?? '',
    })
  );

  return (
    <div className="flex min-h-0 flex-col overflow-hidden border border-gray-300  px-6 py-2">
      <div className="flex items-center justify-between">
        <p className="py-3 text-lg font-medium text-text-dark">Prompt Template</p>
        <div>
          <Button size="small" onClick={onSubmit} text="Test Prompt" />
        </div>
        <IconButton
          Icon={MagnifyingGlassCircleIcon}
          tooltipText="Show Available Fields"
          onClick={() => show(<AvailableFieldsPreview context={templateContext} />)}
        />
      </div>
      <Form formMethods={formMethods} className="flex flex-1 flex-col gap-y-4 overflow-auto px-6">
        <TextAreaField
          label="System prompt"
          name="system"
          className="min-h-[600px]"
          placeholder="Type your prompt here..."
          required
          expandable={false}
        />
        <TextAreaField
          label="User prompt"
          name="user"
          className="min-h-[1400px]"
          expandable={false}
          placeholder="Type your prompt here..."
          required
        />
      </Form>
    </div>
  );
};

const AvailableFieldsPreview: FC<{
  context: Record<string, unknown>;
}> = (props) => {
  return (
    <DialogLayout className="" onClose={close}>
      <table className="flex flex-1 overflow-auto">
        <thead>Field</thead>
        <tbody>
          {fieldPaths(props.context).map((field) => (
            <tr key={field}>
              <td>
                <code>{field}</code>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </DialogLayout>
  );
};

/**
 * Find all paths to leaf nodes in an object. E.g `job.company.name` would be a path to a leaf node.
 */
const fieldPaths = (obj: Record<string, unknown>): string[] => {
  const paths: string[] = [];
  const recurse = (obj: Record<string, unknown>, path: string) => {
    Object.entries(obj).forEach(([key, val]) => {
      const newPath = path ? `${path}.${key}` : key;
      if (typeof val === 'object') {
        recurse(val as Record<string, unknown>, newPath);
      } else {
        paths.push(newPath);
      }
    });
  };
  recurse(obj, '');
  return paths;
};
