import { z } from 'zod';

export const companyFormSchema = z.object({
  name: z.string().trim().min(1, { message: 'Required' }),
  location: z.string().trim().nullish(),
  overview: z
    .string({ invalid_type_error: 'Required' })
    .min(1, { message: 'Required' })
    .max(1600, "Can't be longer than 1600 characters"),
  culture: z
    .string()
    .min(1, { message: 'Required' })
    .max(1200, "Can't be longer than 1200 characters"),
  benefits: z.string().max(1000, "Can't be longer than 1000 characters").nullish(),
  website: z.string().max(255, "Can't be longer than 255 characters.").nullish(),
  notes: z.string().nullish(),
});

export type CompanyFormValues = z.infer<typeof companyFormSchema>;
