import { parseSchema } from 'json-schema-to-zod';
import { z } from 'zod';
import { JsonSchema } from '../components/DocumentConfigForm/schema';

/**
 * Converts a JSON schema to a Zod schema.
 * Uses the new Function constructor to ensure zod is available in the scope.
 *
 * @param jsonSchema JSON schema as JSON string
 * @returns zod schema
 */
export function jsonSchemaToZod<T extends z.ZodTypeAny>(
  jsonSchema: JsonSchema
): z.ZodType<unknown> {
  const rawZodSchemaCode = parseSchema(jsonSchema);
  return new Function('z', `return ${rawZodSchemaCode}`)(z);
}
