export const UPDATE_USER_ONBOARDING_STATUS_MUTATION = gql`
  mutation UpdateUserOnboardingStatusMutation($input: UpdateUserOnboardingStatusInput!) {
    updateUserOnboardingStatus(input: $input) {
      id
      jobCampaignOnboardingStatus
      companyCampaignOnboardingStatus
      candidateCampaignOnboardingStatus
    }
  }
`;
