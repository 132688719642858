import { FC, useEffect, useState } from 'react';
import {
  UpdateDocumentMutation,
  UpdateDocumentMutationVariables,
  Document,
} from '../../../types/graphql';
import { classNames } from '../../lib';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { useDebounce } from '../../hooks';
import { useMutation } from '@redwoodjs/web';
import { UPDATE_DOCUMENT_MUTATION } from '../../graphql/mutations';

type Props = {
  document?: Pick<Document, 'id' | 'title'>;
};

export const DocumentTitleEditor: FC<Props> = ({ document }) => {
  const [update] = useMutation<UpdateDocumentMutation, UpdateDocumentMutationVariables>(
    UPDATE_DOCUMENT_MUTATION
  );
  const [text, setText] = useState(document?.title ?? '');

  const onChange = (e: ContentEditableEvent) => {
    setText(e.target.value);
  };

  const debouncedText = useDebounce(text, 500);

  useEffect(() => {
    if (debouncedText && document?.id) {
      update({
        variables: {
          updateDocumentInput: {
            id: document.id,
            title: debouncedText,
          },
        },
      });
    }
  }, [debouncedText]);

  /**
   * Prevents pasting of rich text into the title editor.
   */
  const onPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    e.currentTarget.textContent = text;
  };

  return (
    <div className="flex-1">
      <ContentEditable
        disabled={!document?.id}
        onPaste={onPaste}
        onChange={onChange}
        content="plaintext-only"
        className={classNames(
          'w-full cursor-text whitespace-pre-wrap border border-transparent py-2 pl-1 pr-3 text-4xl font-bold text-text-dark',
          !text &&
            "before:text-4xl before:font-bold before:text-text-light before:content-['Untitled']",
          document && 'hover:border hover:border-blue-300'
        )}
        html={text ?? 'Untitled'}
      />
    </div>
  );
};
