export const GET_DOCUMENT_TEMPLATES_QUERY = gql`
  query GetDocumentTemplatesQuery($documentTemplatesInput: DocumentTemplatesInput!) {
    documentTemplates(input: $documentTemplatesInput) {
      id
      title
      description
      config
      configSchema
      defaultConfig
    }
  }
`;
