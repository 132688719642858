import { Dispatch, SetStateAction, useCallback, useState } from 'react';

/**
 * A custom hook for managing boolean states.
 *
 * @param defaultValue The initial value of the boolean state (optional).
 * @returns An object containing the current value of the boolean state and functions to manipulate it.
 * @description
 * - `value`: The current value of the boolean state.
 * - `setValue`: A function to explicitly set the boolean state.
 * - `setTrue`: A function to set the boolean state to true.
 * - `setFalse`: A function to set the boolean state to false.
 * - `toggle`: A function to toggle the boolean state.
 */

type UseBooleanOutput = {
  value: boolean;
  setValue: Dispatch<SetStateAction<boolean>>;
  setTrue: () => void;
  setFalse: () => void;
  toggle: () => void;
};

export const useBoolean = (defaultValue?: boolean): UseBooleanOutput => {
  const [value, setValue] = useState(!!defaultValue);

  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);
  const toggle = useCallback(() => setValue((x) => !x), []);

  return { value, setValue, setTrue, setFalse, toggle };
};
