import { ComponentProps, FC } from 'react';

export const H1: FC<ComponentProps<'svg'>> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
  >
    <path d="M4 12h8"></path>
    <path d="M4 18V6"></path>
    <path d="M12 18V6"></path>
    <path d="m17 12 3-2v8"></path>
  </svg>
);
