import { Document, Page, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { FC, memo } from 'react';
import { DocumentBrandingConfig, GetBrandAssets } from 'types/graphql';
import { convertHtmlToReactPdfContent } from 'src/components/PdfDocument/pdf';
import { PdfNode as TPdfNode } from './PdfNodes/types';
import { PdfNode } from './PdfNodes';

type BrandedDocumentProps = {
  content: TPdfNode[];
  config: DocumentBrandingConfig;
  brandAssets: GetBrandAssets['getBrandAssets'];
};

Font.register({
  family: 'serif',
  fonts: [
    { src: '/fonts/LibreBaskerville-Regular.ttf' },
    { src: '/fonts/LibreBaskerville-Bold.ttf', fontWeight: 'bold' },
    { src: '/fonts/LibreBaskerville-Italic.ttf', fontStyle: 'italic' },
  ],
});

Font.register({
  family: 'sans-serif',
  fonts: [
    { src: '/fonts/Roboto-Regular.ttf' },
    { src: '/fonts/Roboto-Bold.ttf', fontWeight: 'bold' },
    { src: '/fonts/Roboto-Black.ttf', fontWeight: 900 },
    { src: '/fonts/Roboto-Italic.ttf', fontStyle: 'italic' },
  ],
});

Font.register({
  family: 'mono',
  fonts: [
    { src: '/fonts/RobotoMono-Regular.ttf' },
    { src: '/fonts/RobotoMono-Bold.ttf', fontWeight: 'bold' },
    { src: '/fonts/RobotoMono-Italic.ttf', fontStyle: 'italic' },
  ],
});

const getAlignment = (alignment: string): 'flex-start' | 'center' | 'flex-end' => {
  switch (alignment) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    case 'center':
    default:
      return 'center';
  }
};

const BrandedPdfDocument: FC<BrandedDocumentProps> = ({ content, config, brandAssets }) => {
  const styles = StyleSheet.create({
    page: {
      fontFamily: config?.fontConfig?.body ?? 'sans-serif',
      flexDirection: 'column',
      paddingBottom: 84,
      paddingHorizontal: parseInt(config?.marginsConfig?.horizontal ?? '10', 10),
      fontSize: 12,
      // Keep some extra when no logo is present too (36)
    },
    headerWrapper: {
      paddingTop: 40,
      paddingBottom: 16,
    },
    pageBody: {
      paddingBottom: 10,
    },
    logo: {
      width: `${brandAssets?.logoSize ?? 124}pt`,
      alignSelf: getAlignment(brandAssets?.logoAlignment ?? 'left'),
    },
    letterhead: {
      width: '100%',
      height: 'auto',
      alignSelf: 'flex-start',
    },
    bodyText: {
      fontFamily: config?.fontConfig?.body ?? 'sans-serif',
      fontSize: 12,
      lineHeight: 1.5,
      paddingBottom: 8,
    },
    footer: {
      position: 'absolute',
      bottom: 30,
      left: brandAssets?.letterheadLeftMargin ?? undefined,
      right: brandAssets?.letterheadRightMargin ?? undefined,
      fontSize: 9,
      fontFamily: brandAssets?.footerFont ?? config?.fontConfig?.body ?? 'sans-serif',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    footerLeft: {
      textAlign: 'left',
      width: '33%',
    },
    footerCenter: {
      textAlign: 'center',
      width: '33%',
    },
    footerRight: {
      textAlign: 'right',
      width: '33%',
    },
  });

  const renderFooterContent = (footerContent: string) => {
    const nodes = convertHtmlToReactPdfContent(footerContent);

    if (!nodes) return null;

    const renderNode = (node: TPdfNode, index: number) => (
      <PdfNode key={index} node={node} documentConfig={config} />
    );

    return Array.isArray(nodes) ? nodes.map(renderNode) : renderNode(nodes, 0);
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.headerWrapper} fixed>
          {brandAssets?.logo?.src && (
            <Image src={brandAssets?.logo?.src ?? ''} style={styles.logo} />
          )}
          {brandAssets?.letterhead?.src && (
            <Image src={brandAssets?.letterhead?.src} style={styles.letterhead} />
          )}
        </View>
        <View style={styles.pageBody}>
          {[...(content ?? [])].map((node, index) => (
            <PdfNode key={index} node={node} documentConfig={config} />
          ))}
        </View>
        <View style={styles.footer} fixed>
          <View style={styles.footerLeft}>
            {renderFooterContent(brandAssets?.leftFooter ?? '')}
          </View>
          <View style={styles.footerCenter}>
            {renderFooterContent(brandAssets?.middleFooter ?? '')}
          </View>
          <View style={styles.footerRight}>
            {renderFooterContent(brandAssets?.rightFooter ?? '')}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default memo(BrandedPdfDocument);
