import { useQuery } from '@redwoodjs/web';
import { FC } from 'react';
import {
  GET_BRANDED_DOCUMENT_CONFIG_QUERY,
  GET_BRAND_ASSETS_QUERY,
  GET_JOB_AND_CONTENT,
} from 'src/graphql/queries';
import {
  GetBrandAssets,
  GetBrandAssetsVariables,
  GetBrandedDocumentConfig,
  GetBrandedDocumentConfigVariables,
  GetJobAndDocuments,
  GetJobAndDocumentsVariables,
  Job,
} from 'types/graphql';
import { EntityExportDialog } from './EntityExportDialog';
import { handleExport } from './lib/handleExport';
import { ExportSubmitParams } from './types';
import { DEFAULT_BRANDING_CONFIG, DEFAULT_BRAND_ASSETS } from '../PdfDocument/pdf';

type Props = {
  id: string;
};

export const JobExportDialog: FC<Props> = ({ id }) => {
  const { data } = useQuery<GetJobAndDocuments, GetJobAndDocumentsVariables>(GET_JOB_AND_CONTENT, {
    variables: {
      jobId: id,
    },
  });
  const { data: configData } = useQuery<
    GetBrandedDocumentConfig,
    GetBrandedDocumentConfigVariables
  >(GET_BRANDED_DOCUMENT_CONFIG_QUERY);

  const { data: brandAssetsData } = useQuery<GetBrandAssets, GetBrandAssetsVariables>(
    GET_BRAND_ASSETS_QUERY
  );

  const job = data?.job as Job;

  const handleSubmit = async ({
    exportFormat,
    includeDocuments,
    onComplete,
  }: ExportSubmitParams) => {
    if (!job) return;

    try {
      await handleExport(
        job,
        'Job',
        exportFormat,
        includeDocuments,
        configData?.getBrandedDocumentConfig ?? DEFAULT_BRANDING_CONFIG,
        brandAssetsData?.getBrandAssets ?? DEFAULT_BRAND_ASSETS
      );
    } catch (error) {
      console.error(error);
    } finally {
      onComplete();
    }
  };

  return <EntityExportDialog entityName="Job" onSubmit={handleSubmit} />;
};
