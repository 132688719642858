import { FC } from 'react';
import { Prompt } from 'types/graphql';
import { DialogLayout } from '../DialogLayout';
import { Button } from '../Button';
import { useMutation } from '@redwoodjs/web';

import { useDialog } from 'src/hooks';
import { toast } from '@redwoodjs/web/dist/toast';
import { PromptsDialog } from './PromptsDialog';
import { DELETE_PROMPT_MUTATION } from 'src/graphql/mutations';
import { TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';

type Props = {
  prompt: Pick<Prompt, 'id' | 'description'>;
};

const DeletePromptDialog: FC<Props> = ({ prompt }) => {
  const { id } = prompt;
  const { show } = useDialog();

  const [deletePrompt, { loading }] = useMutation(DELETE_PROMPT_MUTATION, {
    variables: {
      id,
    },
    optimisticResponse: {
      deletePrompt: {
        __typename: 'Prompt',
        id,
      },
    },
    update: (cache) => {
      cache.evict({ id: `Prompt:${id}` });
      cache.gc();
    },
    onCompleted: () => {
      toast.success('Prompt deleted');
      show(<PromptsDialog />);
    },
  });

  const handleSubmit = async () => {
    await deletePrompt();
  };

  const handleOnClose = () => {
    show(<PromptsDialog />);
  };

  return (
    <DialogLayout
      title="Are you sure you want delete this prompt?"
      onClose={handleOnClose}
      className="h-full max-h-48 w-full max-w-[540px] px-6 pb-8 pt-4"
    >
      <p className="mt-2 text-text-dark">
        This will delete
        <b> {prompt.description} </b>
        permanently.
      </p>
      <div className="mt-4 flex w-full justify-end gap-x-3">
        <Button
          text={loading ? 'Canceling...' : 'Cancel'}
          loading={loading}
          variant="outline"
          onClick={handleOnClose}
          LeftIcon={XMarkIcon}
        />
        <Button
          text={loading ? 'Deleting...' : 'Delete'}
          loading={loading}
          variant="danger"
          onClick={handleSubmit}
          LeftIcon={TrashIcon}
        />
      </div>
    </DialogLayout>
  );
};

export default DeletePromptDialog;
