import { ContainerClient } from '@azure/storage-blob';
import { v4 as uuidv4 } from 'uuid';

/**
 * Uploads a file to Azure Blob Storage using the Azure Storage Blob SDK.
 * @param url The SAS URL that allows authenticated access to the blob container.
 * @param file The file to upload.
 * @returns An object containing the URL where the file was uploaded and the blobName.
 */
export async function uploadFileToAzure(
  url: string,
  file: File
): Promise<{ url: string; blobName: string }> {
  const containerClient = new ContainerClient(url);

  const uniqueId = uuidv4();
  const extension = file.name.split('.').pop(); // Extract the extension from the file name
  const baseName = file.name.replace(/\.[^/.]+$/, ''); // Remove the extension from the file name
  const blobName = `${baseName}-${uniqueId}.${extension}`; // Create a new blobName with format {fileName}-{ID}.{extension}

  const blobClient = containerClient.getBlockBlobClient(blobName);

  // Upload the file to Azure Blob Storage
  await blobClient.uploadData(file, {
    blobHTTPHeaders: {
      blobContentType: file.type,
    },
  });

  // Construct the full URL to access the uploaded file
  const uploadedUrl = `${containerClient.url}/${blobName}`;

  return {
    url: uploadedUrl,
    blobName: blobName,
  };
}
