import { useQuery } from '@redwoodjs/web';
import { useParams } from '@redwoodjs/router';
import { Spinner } from 'src/components/Spinner';
import { GetDocumentQuery, GetDocumentQueryVariables } from 'types/graphql';
import { GET_DOCUMENT_QUERY } from 'src/graphql/queries/getDocumentQuery';
import { StructuredDocumentEditor } from 'src/components/StructuredDocument/StructuredDocumentEditor';
import { PdfDocumentEditor } from '../DocumentEditor/PdfDocumentEditor';
import { FC } from 'react';

type Props = {
  candidateId?: string;
};

export const StructuredDocument: FC<Props> = ({ candidateId }) => {
  const { documentId } = useParams();
  const { data, previousData, loading, error } = useQuery<
    GetDocumentQuery,
    GetDocumentQueryVariables
  >(GET_DOCUMENT_QUERY, {
    variables: {
      id: documentId,
    },
  });

  const document = data?.document ?? previousData?.document;
  if (loading && !document) {
    return <Spinner />;
  }

  if (error) {
    throw error;
  }

  if (!document) {
    return null;
  }

  if (document.__typename !== 'CandidateCv') {
    throw new Error('Invalid document type');
  }

  return (
    <div className="flex flex-1 overflow-hidden">
      <div className="flex basis-5/12 flex-col overflow-y-auto">
        <StructuredDocumentEditor candidateId={candidateId} document={document} />
      </div>
      <div className="flex basis-7/12 flex-col bg-white">
        <PdfDocumentEditor document={document} candidateId={candidateId} editMode />
      </div>
    </div>
  );
};
