import { PlusIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { Button, DialogLayout } from '../../components';
import {
  DocumentConfigEditor,
  EnumFieldForm,
} from '../../components/DocumentConfigForm/DocumentConfigEditor';
import { useDialog } from '../../hooks';
import { classNames } from '../../lib';
import { State } from './PromptEngineeringPage';
import { Titlebar } from './Titlebar';

type Props = {
  state: State;
  setState: (state: State) => void;
};

/**
 * THis editor lets a user add fields to the config schema, and set default values for those fields.
 */
export const ConfigEditor: FC<Props> = ({ state, setState }) => {
  const { show } = useDialog();
  return (
    <div className={classNames('flex flex-1 flex-col overflow-auto border border-text-light')}>
      <Titlebar
        button={
          <Button
            variant="outline"
            size="small"
            text="Add Field"
            LeftIcon={PlusIcon}
            onClick={() => show(<EnumFieldEditorDialog state={state} setState={setState} />)}
          />
        }
      >
        Config
      </Titlebar>
      <div className="flex flex-col px-6">
        <DocumentConfigEditor state={state} setState={setState} />
      </div>
    </div>
  );
};

const EnumFieldEditorDialog: FC<{ state: State; setState: (state: State) => void }> = ({
  state,
  setState,
}) => {
  return (
    <DialogLayout onClose={close} title="Add Field">
      <EnumFieldForm
        onChange={(field, key) => {
          const defaultValue = field.default;
          setState({
            ...state,
            configFields: {
              ...state.configFields,
              properties: {
                ...state.configFields.properties,
                [key]: field,
              },
            },
            configValues: {
              ...state.configValues,
              [key]: defaultValue,
            },
          });
          close();
        }}
      />
    </DialogLayout>
  );
};
