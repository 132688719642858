import { toast } from '@redwoodjs/web/dist/toast';
import { Company, Job, CAMPAIGN_STATUS } from 'types/graphql';
import { objectToCSVString } from '../../services';
import { saveAs } from 'file-saver';

export async function exportJobToCSV(
  job: Pick<
    Omit<Job, 'company'>,
    'title' | 'contractType' | 'salary' | 'roleAndResponsibilities' | 'mustHaves'
  > & {
    company: Pick<Company, 'name' | 'benefits' | 'culture' | 'location' | 'overview'>;
  }
) {
  const { title, contractType, salary, roleAndResponsibilities, mustHaves, company } = job;

  const data = {
    'Job Title': title,
    'Company Name': company.name,
    'Contract Type': contractType,
    Salary: salary,
    'Company Benefits': company.benefits,
    'Company Culture': company.culture,
    'Company Location': company.location,
    'Company Overview': company.overview,
    'Role and Responsibilities': roleAndResponsibilities,
    'Must Haves': mustHaves,
  };

  try {
    const csv = await objectToCSVString(data);
    await saveAs(new Blob([csv], { type: 'text/csv' }), `${title}-${company.name}-job-details.csv`);
    return csv;
  } catch (err) {
    console.error(err);
    toast.error('Error exporting job to CSV');
  }
}

/**
 * Only allow entities with a completed campaign to be selected
 */
export const canSelectJob = ({
  campaignStatus,
}: {
  campaignStatus?: CAMPAIGN_STATUS | undefined | null;
}) => campaignStatus === 'COMPLETED';
