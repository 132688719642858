export const DOCUMENT_VERSION_ACTIONS_FRAGMENT = gql`
  fragment DocumentVersionActions on DocumentVersion {
    ... on RestoreDocumentAction {
      id
      markup
      editedBy {
        id
        name
        email
      }
      createdAt
      updatedAt
    }
    ... on EditDocumentAction {
      id
      markup
      editedBy {
        id
        name
        email
      }
      createdAt
      updatedAt
    }
    ... on RegenerateDocumentAction {
      id
      markup
      editedBy {
        id
        name
        email
      }
      createdAt
      updatedAt
    }
    ... on ChatDocumentAction {
      id
      markup
      editedBy {
        id
        name
        email
      }
      createdAt
      updatedAt
    }
    ... on CreateDocumentAction {
      id
      markup
      editedBy {
        id
        name
        email
      }
      createdAt
      updatedAt
    }
  }
`;
