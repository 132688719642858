import { DOCUMENT_FIELDS_FRAGMENT } from '../fragments';

export const GET_CHAT_SESSION_QUERY = gql`
  ${DOCUMENT_FIELDS_FRAGMENT}
  query GetChatSessionQuery($id: String!) {
    chatSession(id: $id) {
      id
      title
      messages {
        ... on ChatRequest {
          __typename
          id
          text
          createdAt
        }
        ... on ChatResponse {
          __typename
          id
          text
          createdAt
          document {
            ...DocumentFields
          }
        }
      }
    }
  }
`;
