import { FC } from 'react';
import { motion } from 'framer-motion';
import { StatusPill } from '../StatusPill';
import { IconButton } from '../IconButton';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { usePersonaNotifierStore, useUserPersonaWizard } from 'src/services/store';
import { navigate, routes } from '@redwoodjs/router';
import { UPDATE_USER_PROFILE_ONBOARDING_STATUS_MUTATION } from '../../graphql/mutations';
import { useMutation } from '@redwoodjs/web';
import { useAuth } from '../../auth';

export const PersonaNotifier: FC = () => {
  const { close } = usePersonaNotifierStore();
  const { setCurrentStep } = useUserPersonaWizard();
  const { currentUser } = useAuth();
  const [updateUserProfileOnboardingStatus] = useMutation(
    UPDATE_USER_PROFILE_ONBOARDING_STATUS_MUTATION,
    {
      optimisticResponse: {
        updateUserProfile: {
          id: currentUser?.id,
          onboardingStatus: 'COMPLETED',
          __typename: 'UserProfile',
        },
      },
    }
  );

  const handleClose = () => {
    updateUserProfileOnboardingStatus();
    close();
  };

  const onClick = () => {
    setCurrentStep(1);
    navigate(routes.newUserPersona());
  };

  return (
    <motion.div
      onClick={onClick}
      initial={{ translateY: 100, opacity: 0 }}
      animate={{ translateY: 0, opacity: 1 }}
      exit={{ translateY: 100, opacity: 0 }}
      className="fixed bottom-8 right-8 w-[300px] cursor-pointer rounded-lg bg-white p-4 shadow-md hover:bg-gray-50"
    >
      <div className="flex items-center gap-x-3">
        <div className="text-base font-semibold text-text-dark">Create Your Persona</div>
        <StatusPill text="New" color="cyan" />
      </div>
      <p className="mt-2 w-full max-w-[265px] text-sm font-light text-text-medium">
        Click here to create your user persona and personalise AdScribe to your goals.
      </p>
      <div className="absolute right-1 top-1">
        <IconButton
          Icon={XMarkIcon}
          tooltipText="Close"
          tooltipPosition="bottom"
          size="xs"
          onClick={handleClose}
        />
      </div>
    </motion.div>
  );
};
