export const SET_USER_DEFAULT_BRAND_VOICE_MUTATION = gql`
  mutation SetUserDefaultBrandVoiceMutation($id: String) {
    setUserDefaultBrandVoice(id: $id) {
      __typename
      ... on BrandVoiceBase {
        id
        title
        value
      }
    }
  }
`;
