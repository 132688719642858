import { classNames } from '../../lib';

type Props<T extends string> = {
  options: readonly T[];
  selected: T;
  setSelected: (selected: T) => void;
};

export function Tabs<T extends string>({ options, selected, setSelected }: Props<T>) {
  return (
    <div className="flex flex-row gap-x-12">
      {options.map((option) => (
        <TabItem
          key={option}
          selected={option === selected}
          onClick={() => setSelected(option)}
          value={option}
        >
          {option}
        </TabItem>
      ))}
    </div>
  );
}

function TabItem<T>({
  selected,
  value,
  onClick,
  children,
}: {
  selected?: boolean;
  value: T;
  onClick: (tab: T) => void;
  children: React.ReactNode;
}) {
  return (
    <div
      onClick={selected ? undefined : () => onClick(value)}
      className={classNames(
        'select-none justify-self-start px-1 py-4 text-sm font-medium',
        selected
          ? 'border-b border-primary-dark text-primary-dark'
          : 'cursor-pointer text-text-medium hover:text-primary-light'
      )}
    >
      {children}
    </div>
  );
}

export default Tabs;
