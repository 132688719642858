export const GET_MY_DOCUMENT_TEMPLATE_QUERY = gql`
  query GetMyDocumentTemplateQuery($myDocumentTemplateInput: MyDocumentTemplateInput!) {
    myDocumentTemplate(input: $myDocumentTemplateInput) {
      id
      title
      description
      config
      configSchema
      defaultConfig
    }
  }
`;
