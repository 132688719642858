import { DOCUMENT_FIELDS_FRAGMENT } from '../fragments';

export const CREATE_CHAT_SESSION_MUTATION = gql`
  ${DOCUMENT_FIELDS_FRAGMENT}
  mutation CreateChatSessionMutation($input: CreateChatSessionInput!) {
    createChatSession(input: $input) {
      id
      title
      messages {
        ... on ChatRequest {
          id
          text
          createdAt
        }
        ... on ChatResponse {
          id
          text
          createdAt
          document {
            ...DocumentFields
          }
        }
      }
    }
  }
`;
