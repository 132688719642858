export const AUTOFILL_JOB_MUTATION = gql`
  mutation AutofillJobMutation($body: String!) {
    autofillJob(body: $body) {
      jobTitle
      companyName
      companyBenefits
      companyCulture
      companyOverview
      jobRequirements
      jobResponsibilities
      location
      contract
      salary
    }
  }
`;
