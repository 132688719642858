import { toast } from '@redwoodjs/web/dist/toast';
import { FC, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDialog } from '../../hooks';
import { classNames } from '../../lib';
import { DialogLayout } from '../DialogLayout';

const supportedFileTypes = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/csv',
];

export const UploadFileDialog: FC<{
  onUploadFile: (file: File) => Promise<unknown>;
  title: string;
  description: string;
}> = ({ onUploadFile, title, description }) => {
  const { close } = useDialog();
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      if (!supportedFileTypes.includes(file.type)) {
        toast.error('Only PDF and DOCX files are supported');
        return;
      }
      if (file.size > 10485760) {
        toast.error('File size must be less than 10MB');
        return;
      }
      onUploadFile(file);
      close();
    },
    [onUploadFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'text/csv': ['.csv'],
    },
    onError: (e) => toast.error(e.message),
  });

  return (
    <DialogLayout onClose={close} className="flex w-full max-w-xl flex-col" title={`📎 ${title}`}>
      <p className="pb-2 text-sm text-text-medium">{description}</p>
      <div
        {...getRootProps()}
        className={classNames(
          'cursor-pointer rounded-lg border-2 border-dashed border-generate-light px-6 py-12 text-center',
          isDragActive && 'bg-gray-50'
        )}
      >
        <input {...getInputProps()} />
        <div className="flex flex-col items-center gap-y-4">
          <p className="text-5xl font-black">📁</p>
          <p className="text-base font-medium text-text-medium">Upload your file</p>
          <p className="text-sm text-text-medium">
            Drag and drop a file here, or click to select a file
          </p>
          <p className="font-xs text-text-medium">Supported file types: .pdf, .docx, .csv</p>
        </div>
      </div>
      <p className="pt-2 text-center text-xs text-text-dark">
        We do not save any files used to auto-fill the form.
      </p>
    </DialogLayout>
  );
};
