import { DOCUMENT_FIELDS_FRAGMENT } from '../fragments';

export const UPDATE_TEMPLATE_DOC_MUTATION = gql`
  ${DOCUMENT_FIELDS_FRAGMENT}
  mutation UpdateTemplateDocMutation($id: String!, $input: CreateTemplateDocumentInput!) {
    updateTemplateDocument(id: $id, input: $input) {
      ...DocumentFields
    }
  }
`;
