import { ComponentProps, FC } from 'react';

export const RightMarginIcon: FC<ComponentProps<'svg'>> = (props) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8333 5.83301H9.16667C8.24619 5.83301 7.5 6.5792 7.5 7.49967V12.4997C7.5 13.4201 8.24619 14.1663 9.16667 14.1663H10.8333C11.7538 14.1663 12.5 13.4201 12.5 12.4997V7.49967C12.5 6.5792 11.7538 5.83301 10.8333 5.83301Z"
        stroke="#6B7280"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6667 18.3337V1.66699"
        stroke="#6B7280"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
