export const CREATE_SNIPPET_MUTATION = gql`
  mutation CreateSnippetMutation($input: CreateSnippetInput!) {
    createSnippet(input: $input) {
      id
      name
      text
      updatedAt
      createdAt
    }
  }
`;
