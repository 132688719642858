export const SEND_USER_INVITE_MUTATION = gql`
  mutation SendUserInviteMutation($input: SendUserInviteInput!) {
    sendUserInvite(input: $input) {
      id
      status
      role
      email
      createdAt
    }
  }
`;
