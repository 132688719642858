import {
  ArchiveBoxIcon,
  ArchiveBoxXMarkIcon,
  ArrowDownTrayIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/24/outline';
import { BanknotesIcon, ClockIcon } from '@heroicons/react/24/solid';
import { FC } from 'react';
import { Checkbox, DetailSnippet, EntityStatusPill, JobTitleTag, UserIcon } from 'src/components';
import { navigate, routes } from '@redwoodjs/router';
import { classNames } from 'src/lib';
import { Candidate, GetCandidates } from '../../../types/graphql';
import { useDialog } from 'src/hooks';
import { CandidateExportDialog } from 'src/components/ExportDialogs/CandidateExportDialog';
import { notNullish } from 'src/lib/guards';
import { canSelectCandidate } from './util';
import { DropdownButton } from 'src/components/DropdownButton';
import { formatRelativeDateTime } from '../../lib/dateTime';

type Props = {
  candidate: GetCandidates['candidates']['nodes'][0];
  selected?: boolean;
  onToggleSelected?: (id: string) => void;
  hideCheckbox?: boolean;
  hideDropdownButton?: boolean;
  onArchiveCandidate?: (candidate: Pick<Candidate, 'id'>) => void;
  onUnArchiveCandidate?: (candidate: Pick<Candidate, 'id'>) => void;
  onDuplicateCandidate?: (candidate: Pick<Candidate, 'id'>) => void;
};

export const CandidateTableRow: FC<Props> = ({
  candidate,
  selected,
  onToggleSelected,
  hideCheckbox,
  hideDropdownButton,
  onArchiveCandidate,
  onUnArchiveCandidate,
  onDuplicateCandidate,
}) => {
  const { show } = useDialog();

  const onClick = () => {
    navigate(routes.candidate({ candidateId: candidate.id }));
  };

  const archiveButton =
    candidate.status === 'ACTIVE'
      ? {
          Icon: ArchiveBoxIcon,
          text: 'Archive',
          onClick: () => onArchiveCandidate?.(candidate),
        }
      : {
          Icon: ArchiveBoxXMarkIcon,
          text: 'Restore',
          onClick: () => onUnArchiveCandidate?.(candidate),
        };

  const isOwner = candidate.myPermission === 'OWNER';

  const dropdownOptions = [
    isOwner ? archiveButton : null,
    {
      Icon: DocumentDuplicateIcon,
      text: 'Create a copy',
      onClick: () => onDuplicateCandidate?.(candidate),
    },
    {
      Icon: ArrowDownTrayIcon,
      text: 'Export candidate details',
      onClick: () => show(<CandidateExportDialog id={candidate.id} />),
    },
  ].filter(notNullish);

  return (
    <li className="relative flex items-center">
      {!hideCheckbox && (
        <div className="mx-2 flex h-6 w-6">
          {canSelectCandidate(candidate) && (
            <Checkbox
              value={!!selected}
              onChange={() => onToggleSelected && onToggleSelected(candidate.id)}
            />
          )}
        </div>
      )}

      <div
        onClick={onClick}
        className={classNames(
          'flex flex-grow items-center border-b border-text-light py-4 pl-3',
          candidate.campaignStatus !== 'PENDING' && 'cursor-pointer hover:bg-gray-50'
        )}
      >
        <div className="flex flex-1 flex-col gap-y-1">
          <div className="text-text-dark">{candidate.name}</div>
          <DetailSnippet Icon={ClockIcon} text={candidate.availability} />
        </div>
        <div className="flex flex-1 flex-col gap-y-1">
          {candidate.jobTitle && <JobTitleTag jobTitle={candidate.jobTitle} />}
          <DetailSnippet Icon={BanknotesIcon} text={candidate.desiredSalary} />
        </div>
        <div className="flex w-32 justify-center">
          <UserIcon user={candidate.owner} />
        </div>
        <div className="flex-1 pl-8 text-sm text-text-medium">
          {formatRelativeDateTime(new Date(candidate.createdAt))}
        </div>
        <div className="flex h-4 flex-1 justify-center">
          <div>
            <EntityStatusPill campaignStatus={candidate.campaignStatus} status={candidate.status} />
          </div>
        </div>

        {!hideDropdownButton && (
          <div className="px-6">
            {dropdownOptions && <DropdownButton options={dropdownOptions} />}
          </div>
        )}
      </div>
    </li>
  );
};
