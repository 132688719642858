import { ComponentProps, FC } from 'react';

export const AdscribeLogo: FC<ComponentProps<'svg'>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 33.59" fill="none" {...props}>
      <path
        fill="#11182708"
        d="M25.75,26.11v.78h-10.31v-.78c.22,0,.44-.1.64-.18.69-.24.7-.95.53-1.55-.12-.43-.34-.82-.54-1.22-.22-.42-.42-.84-.63-1.26-.28-.48-.67-.77-1.23-.77,0,0-3.07,0-3.07,0-.02,0-.03.02-.02.04.05.03.09.06.14.1.75.61,1.19,1.6,1.2,2.69h0c0,1.87-1.39,3.37-3.11,3.37s-3.1-1.5-3.1-3.36c0-.04,0-.08,0-.13.05-1.68,1.25-3.05,2.78-3.21.05,0,.1-.01.16-.01.38-.04.77-.02,1.16-.02h3.53c.62,0,.6-.63.38-1.19-.01-.03-.03-.07-.05-.11l-.06-.12-2.33-5.05c-.05-.11-.05-.23,0-.33l3.16-7.63h.15c7.75,17,8.28,17.97,8.28,17.97,1.11,2.34,1.71,1.89,2.33,1.96Z"
      />
    </svg>
  );
};
