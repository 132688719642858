import { navigate, routes } from '@redwoodjs/router';
import { FC } from 'react';
import { classNames } from '../../lib';
import { DialogLayout } from '../DialogLayout';

export const CreateTemplateDialog: FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <DialogLayout onClose={onClose} title="🛠 Templates">
      <p className="py-2 text-text-medium">
        Craft client proposals, outreach and content with AdScribe templates.
      </p>
      <div className="flex flex-grow flex-wrap justify-center gap-8 overflow-auto py-6">
        <CreateTemplateCard
          emoji="🤝"
          title="Client Proposal"
          description="Introduce your services through a personalised proposal letter to help you win new clients."
          onClick={() => {
            navigate(routes.createDocument({ templateType: 'ClientProposal' }));
            onClose();
          }}
        />
        <CreateTemplateCard
          emoji="💡"
          title="Blog Post Ideas"
          description="Generate a list of over 20 blog post ideas to stand out from your competitors."
          onClick={() => {
            navigate(routes.createDocument({ templateType: 'BlogPostIdeas' }));
            onClose();
          }}
        />
        <CreateTemplateCard
          emoji="📜"
          title="Blog Post"
          description="Craft an outstanding blog post."
          onClick={() => {
            navigate(routes.createDocument({ templateType: 'BlogPost' }));
            onClose();
          }}
        />
        <CreateTemplateCard
          emoji="📁"
          title="Testimonial Request"
          description="Create a testimonial request to help you get more testimonials from your clients."
          onClick={() => {
            navigate(routes.createDocument({ templateType: 'TestimonialRequest' }));
            onClose();
          }}
        />
        <CreateTemplateCard
          emoji="📧"
          title="Marketing Strategy"
          description="Create a marketing strategy for long term success."
          onClick={() => {
            navigate(routes.createDocument({ templateType: 'MarketingStrategy' }));
            onClose();
          }}
        />
      </div>
    </DialogLayout>
  );
};

type Props = {
  title: string;
  description: string;
  onClick?: () => void;
  emoji?: string;
};
const CreateTemplateCard: FC<Props> = ({ title, description, onClick, emoji }) => {
  return (
    <div
      className={classNames(
        'flex w-[260px] flex-col gap-y-4 rounded-3xl border border-text-light p-6 ',
        onClick ? 'cursor-pointer hover:border-generate-medium' : 'opacity-50'
      )}
      onClick={onClick}
    >
      <p className="text-4xl">{emoji}</p>
      <p className="font-medium text-text-dark">{title}</p>
      <p className="text-sm text-text-medium">{description}</p>
    </div>
  );
};
