import { FC } from 'react';

export const TemplatesIllustration: FC<React.ComponentProps<'svg'>> = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="595.279px"
    height="595.281px"
    viewBox="0 0 595.279 595.281"
    enableBackground="new 0 0 595.279 595.281"
    xmlSpace="preserve"
    {...props}
  >
    <path
      id="color_x5F_2"
      fill="#000000"
      d="M508.189,184.889c-108.541,0-217.082,0-325.622,0c-17.199,0-34.398,0-51.599,0
	c0.043-9.156,0.074-18.313,0.077-27.469h377.144C508.189,166.577,508.189,175.732,508.189,184.889z M470.816,222.514
	c0.044-0.117,0.115-0.222,0.198-0.318c-0.187-0.004-0.374-0.006-0.562,0c0.109,0.188-0.079,0.44-0.815,0.711
	c-0.056,0.021-0.104,0.046-0.158,0.067c0.039-0.01,0.077-0.024,0.116-0.032C469.982,222.756,470.395,222.62,470.816,222.514z
	 M441.519,238.212c-9.285,2.031-17.904,9.63-24.989,15.811l-0.274-10.674c0.286-10.314,0.589-20.63,0.627-30.951
	c32.67,0,65.341,0,98.012,0c-0.121,0.118-0.198,0.249-0.198,0.394c0,13.525,0,27.054,0,40.58
	c-6.137-5.915-13.192-9.213-22.167-7.798c-10.003,1.58-20.031,7.816-29.54,1.159c-3.352-2.348-6.005-5.691-9.753-7.479
	C449.428,237.438,445.609,237.316,441.519,238.212z M463.546,232.335c2.117,4.283,7.448,6.672,12.04,5.346
	c4.832-1.396,9.445-5.184,7.258-10.637c-2.091-5.213-8.475-6.879-13.495-5.963c-0.896,0.164-1.802,0.955-1.465,1.238
	C463.68,224.135,461.282,227.759,463.546,232.335z M469.786,235.315c-0.083-0.021-0.159-0.054-0.24-0.076
	c1.909,1.418,4.277,2.039,6.538,1.06c1.465-0.636,2.65-1.604,3.429-2.767c-0.091,0.073-0.171,0.157-0.266,0.228
	c-1.791,2.647-5.168,3.468-8.56,1.74C470.377,235.448,470.072,235.39,469.786,235.315z M447.377,239.888
	c-0.727-0.125-1.476-0.181-2.252-0.157C445.87,239.756,446.625,239.809,447.377,239.888z M523.65,354.213
	c-0.287-0.054-0.466-0.186-0.421-0.414c-1.201-1.185-2.325-2.388-3.342-3.662c0.066,1.717,0.221,3.426,0.49,5.114l1.924,7.049
	c0.507,1.283,1.075,2.514,1.697,3.705c1.856-1.846,2.629-4.418,3.968-6.626C528.764,358.063,524.517,355.019,523.65,354.213z
	 M489.726,415.227c-0.177,0.068-0.367,0.119-0.564,0.138c-13.046,1.235-21.137-3.255-25.836-11.047
	c-0.911-1.387-1.756-3.04-2.456-5.054c-0.047-0.132-0.087-0.283-0.13-0.425c-4.58-12.625-3.271-30.604-0.184-47.543
	c-3.116-1.74-6.447-3.401-9.041-5.866c-2.313-2.197-3.939-5.109-5.574-7.857c-9.889,6.729-19.319,14.015-28.188,22.048
	c7.642,5.871,14.436,12.548,21.696,18.875c0.532,0.464,0.4,0.982,0,1.371c0.607-0.002,1.125,0.242,1.098,0.836
	c-1.026,21.188,22.021,34.369,21.767,55.541c0,0.049-0.013,0.094-0.023,0.141h57.901c-0.122-9.07-1.369-18.074-1.484-27.143
	C509.734,413.508,498.525,414.813,489.726,415.227z M177.111,423.339c-0.152-0.897-0.309-1.796-0.469-2.694
	c-0.302-0.228-0.423-0.579-0.188-1.026c-1.634-8.949-3.72-17.851-6.709-26.28c-2.374-6.696-5.227-13.355-8.629-19.593
	c-3.193-5.852-7.166-13.313-13.633-16.034c-0.192-0.08-0.33-0.179-0.435-0.281c-1.82,0.233-3.641,0.456-5.469,0.629
	c0.125,0.808,0.252,1.617,0.373,2.428c7.694,10.313,16.333,23.586,17.117,36.734c0.054,0.895-1.344,1.479-2.217,1.36
	c-0.118,0.025-0.241,0.045-0.373,0.047c-11.684,0.123-23.178,2.359-34.826,2.987c-0.68,0.136-1.377-0.019-1.509-0.616
	c-2.938-13.269-8.854-28.898-20.19-37.104c-0.005-0.004-0.007-0.007-0.011-0.01c-1.974,0.723-3.947,1.438-5.889,2.194
	c18.025,17.282,24.955,47.463,14.99,70.558h70.091c-0.611-4.378-1.265-8.81-2.015-13.242
	C177.116,423.375,177.112,423.356,177.111,423.339z"
    ></path>
    <path
      id="color_x5F_1"
      d="M129.545,310.15c0.001,1.091,0.124,2.011,0.9,2.831c0.732,0.775-2.02,1.833-2.695,1.119
	c-0.873-0.925-1.101-1.939-1.102-3.173C126.646,309.831,129.545,309.293,129.545,310.15z M143.775,309.427
	c0.001,1.232,0.229,2.249,1.102,3.173c0.676,0.714,3.428-0.345,2.695-1.118c-0.777-0.821-0.898-1.741-0.899-2.832
	C146.672,307.791,143.774,308.329,143.775,309.427z M139.95,322.829c-1.291,1.021,1.612,0.992,2.313,0.438
	c1.85-1.463,3.107-3.104,2.846-5.554c-0.289-2.711-2.747-5.295-4.055-7.563c-0.549-0.952-3.329,0.03-2.897,0.777
	C140.392,314.803,144.685,319.079,139.95,322.829z M124.228,329.04c2.06,2.031,4.296,3.527,7.178,4.063
	c0.654,0.122,1.434-0.107,2.022-0.38c0.207-0.095,1.02-0.594,0.365-0.716c-2.761-0.515-4.866-2.003-6.832-3.939
	C126.44,327.552,123.816,328.634,124.228,329.04z M123.74,305.717c0.999-0.963,2.015-1.752,3.454-1.453
	c0.661,0.138,1.534-0.131,2.049-0.549c0.479-0.389,0.322-0.796-0.245-0.914c-2.766-0.575-5.577,0.646-7.553,2.551
	C120.263,306.493,123.125,306.309,123.74,305.717z M141.091,302.8c0.815-0.254,1.723-0.221,2.567-0.221
	c0.688-0.002,1.454-0.209,2.049-0.551c0.471-0.27,0.396-0.544-0.146-0.544c-1.868,0.002-3.651,0.224-5.439,0.779
	c-0.093,0.029-1.3,0.515-0.885,0.744C139.733,303.284,140.612,302.948,141.091,302.8z M529.302,374.864
	c3.521,5.725,5.838,12.85,3.636,19.508c-2.042,6.174-6.384,10.524-11.808,13.604c0.276,0.023,0.464,0.104,0.466,0.249
	c0.099,9.279,1.41,18.488,1.501,27.771c0.009,0.896-2.892,1.354-2.897,0.777c-0.001-0.131-0.008-0.26-0.01-0.39
	c-0.122-9.069-1.369-18.073-1.484-27.143c-8.971,4.267-20.18,5.573-28.979,5.985c-0.177,0.068-0.367,0.119-0.564,0.139
	c-13.046,1.234-21.137-3.255-25.836-11.047c-0.911-1.388-1.756-3.041-2.456-5.055c-0.047-0.132-0.087-0.283-0.13-0.425
	c-4.58-12.624-3.271-30.604-0.184-47.543c-3.116-1.74-6.447-3.401-9.041-5.866c-2.313-2.197-3.939-5.108-5.574-7.856
	c-9.889,6.728-19.319,14.014-28.188,22.047c7.642,5.872,14.436,12.548,21.696,18.875c0.532,0.464,0.4,0.983,0,1.371
	c0.607-0.002,1.125,0.243,1.098,0.836c-1.026,21.188,22.021,34.37,21.767,55.541c0,0.049-0.013,0.094-0.023,0.142
	c-0.238,1.055-2.912,1.479-2.896,0.141c0.071-5.938-1.706-11.242-4.271-16.277c-0.39,0.244-0.857,0.43-1.217,0.43
	c-79.969,0-159.936,0-239.905,0c-3.845,4.867-8.637,9.155-14.516,10.856c-7.21,2.087-13.443-0.038-18.726-4.448
	c0.472,3.076,0.91,6.137,1.331,9.162c0.205,1.473-2.698,2.21-2.897,0.777c-0.018-0.129-0.037-0.26-0.055-0.389
	c-0.611-4.378-1.265-8.81-2.015-13.242c-0.003-0.02-0.006-0.036-0.009-0.055c-0.152-0.897-0.309-1.796-0.469-2.694
	c-0.302-0.228-0.423-0.579-0.188-1.026c-1.634-8.949-3.72-17.851-6.709-26.28c-2.374-6.696-5.227-13.355-8.629-19.593
	c-3.193-5.852-7.166-13.313-13.633-16.034c-0.192-0.08-0.33-0.179-0.435-0.281c-1.82,0.233-3.641,0.456-5.469,0.629
	c0.125,0.808,0.252,1.617,0.373,2.428c7.694,10.313,16.333,23.586,17.117,36.734c0.054,0.895-1.344,1.479-2.217,1.36
	c-0.118,0.025-0.241,0.045-0.373,0.047c-11.684,0.123-23.178,2.359-34.826,2.987c-0.68,0.136-1.377-0.019-1.509-0.616
	c-2.938-13.269-8.854-28.898-20.19-37.104c-0.005-0.004-0.007-0.007-0.011-0.01c-1.974,0.723-3.947,1.438-5.889,2.194
	c18.025,17.282,24.955,47.463,14.99,70.558c-0.029,0.066-0.054,0.136-0.083,0.202c-0.555,1.271-3.479,1.17-2.791-0.405
	c9.794-22.438,3.03-52.294-14.706-68.977c-0.086-0.081-0.152-0.164-0.205-0.248c-7.755,3.274-14.722,7.567-18.737,16.009
	c-6.822,14.341-10.084,30.428-12.309,46.076c12.391,1.805,25.001,3.371,37.538,3.594c1.288-8.819,1.372-17.697,1.375-26.604
	c0-0.993,2.903-1.602,2.902-0.344c-0.002,9.213-0.08,18.396-1.501,27.521c-0.127,0.818-2.008,1.3-2.675,0.863
	c-11.338-0.115-22.73-1.328-33.988-2.874c0,2.348,0,4.694,0,7.042c0,1.065-2.897,1.589-2.897,0.777c0-2.496,0-4.991,0-7.486
	c0-0.262,0.176-0.49,0.44-0.676c-0.734-0.104-1.469-0.209-2.202-0.314c-0.915,0.373-2.144,0.195-2.004-0.797
	c2.361-16.769,5.542-35.779,14.341-50.537c8.405-14.098,26.271-15.524,39.623-22.626c0.069-4.323,0.27-8.65,0.189-12.973
	c-0.035-1.891,0.626-5.21-1.962-5.186c-1.323,0.014-2.632,0.006-3.952-0.096c-5.252-0.404-6.229-4.65-5.669-9.309
	c0.003-0.025,0.009-0.05,0.012-0.074c-0.463,0.085-0.887,0.068-1.065-0.133c-5.525-6.209-8.387-13.394-9.961-21.447
	c-2.624-0.149-5.342-0.858-6.545-3.116c-1.445-2.711-0.82-5.845,0.538-8.488c3.047-5.931,8.986-11.273,15.809-11.933
	c-1.198-14.639,16.151-12.844,25.37-6.666c0.832-2.002,2.139-3.767,3.727-5.199c0.017-28.621,0.28-57.242,0.417-85.862
	c-0.157-0.118-0.132-0.296,0.001-0.487c0.044-9.352,0.076-18.703,0.078-28.057c0-0.233,0.281-0.433,0.667-0.584
	c-0.013,0-0.026,0-0.039,0c-1.958,0,0.077-1.608,1.137-1.608c17.551,0,35.103,0,52.653,0c109.213,0,218.426,0,327.639,0
	c0.58,0,0.803,0.143,0.813,0.341c0.04,0.021,0.067,0.044,0.067,0.075c0,16.186,0,32.37,0,48.555c5.582,0,11.164,0,16.746,0
	c0.848,0,0.288,0.423-0.451,0.774c0.133,0.043,0.219,0.109,0.219,0.207c0,13.763,0,27.522,0,41.283
	c9.908,33.752,18.102,74.236-1.325,106.209c1.63,1.594,3.341,3.131,4.986,4.742c0.096,0.08,0.122,0.178,0.079,0.295
	c-1.788,2.947-2.849,6.337-5.562,8.6c-0.204,0.17-0.705,0.314-1.209,0.388C526.006,369.684,527.646,372.177,529.302,374.864z
	 M451.834,341.499c-1.949-4.912-1.656-10.992-0.774-16.012c1.407-7.998,4.082-16.896,9.301-23.362
	c-1.63-2.085-2.802-4.475-3.209-7.21c-0.058-0.389,0.3-0.723,0.779-0.946c0.068-0.065,0.136-0.13,0.204-0.195
	c5.491-5.188,11.554-10.454,19.062-12.355c9.07-2.298,18.329-0.35,26.91,2.97c1.394,0.538,2.759,1.066,4.084,1.633
	c0-1.976,0-3.949,0-5.926c-29.104,0-58.208,0-87.311,0c-0.055,0-0.091-0.005-0.133-0.008c-0.688,0.958-1.364,1.924-2.003,2.91
	c-0.132,0.203-0.448,0.353-0.823,0.457c0.053,0.049,0.091,0.104,0.096,0.177c1.521,20.959,6.762,40.463,23.52,54.309
	c1.278-0.889,2.566-1.768,3.861-2.638c0.606-0.673,2.026-0.941,2.518-0.146C449.157,337.18,450.357,339.487,451.834,341.499z
	 M418.793,355.64c6.59-5.768,13.479-11.14,20.622-16.202c-0.069-0.022-0.134-0.055-0.185-0.096
	c-17.152-13.921-22.57-33.672-24.111-54.937c-0.024-0.338,0.322-0.625,0.782-0.824c-0.014-0.014-0.019-0.026-0.009-0.042
	c2.048-3.159,4.554-6.095,6.601-9.285c-0.799-0.011-0.255-0.424,0.473-0.771c0.553-0.91,1.075-1.838,1.521-2.807
	c1.606-3.471,3.145-10.07-0.737-12.951c-0.019-0.01-0.028-0.018-0.044-0.024c-0.147,0.177-0.286,0.361-0.413,0.555
	c-0.38,0.588-0.744,1.181-1.07,1.802c-0.766,1.455-1.463,2.942-2.221,4.401c-1.125,2.164-2.287,4.341-4.266,5.806
	c-0.169,0.484-1.156,0.813-1.928,0.882c0.121,0.131,0.242,0.261,0.371,0.364c0.46,0.369-2.099,1.502-2.729,0.996
	c-3.438-2.771-3.868-10.314-4.75-14.27c-0.927-4.161-1.682-13.313-5.637-15.966c-2.303-1.544-2.966,17.06-2.908,18.761
	c0.213,6.232,0.425,12.744,1.848,18.843c0.008,0.036,0.004,0.073-0.003,0.109c0.521,0.067,0.899,0.358,0.773,0.928
	c-2.862,12.945-2.19,27.113,0.261,40.06c2.327,12.287,6.408,26.883,17.396,34.25C418.626,355.353,418.737,355.495,418.793,355.64z
	 M453.235,239.253c3.748,1.787,6.401,5.132,9.753,7.479c9.509,6.657,19.537,0.42,29.54-1.158c8.975-1.416,16.03,1.882,22.167,7.797
	c0-13.526,0-27.055,0-40.58c0-0.145,0.078-0.274,0.198-0.394c-32.67,0-65.341,0-98.012,0c-0.038,10.321-0.342,20.636-0.627,30.951
	c-0.104,3.721-0.201,7.44-0.283,11.161c0.185-0.161,0.371-0.323,0.558-0.487c7.085-6.181,15.704-13.779,24.989-15.812
	C445.609,237.316,449.428,237.438,453.235,239.253z M415.784,267.482c2.666-4.647,4.048-11.809,9.791-11.194
	c3.695,0.395,3.82,8.196,3.053,10.575c-0.711,2.203-1.748,4.222-2.94,6.15c29.669,0,59.339,0,89.009,0c0-5.229,0-10.456,0-15.685
	c-0.093-0.053-0.18-0.113-0.251-0.193c-4.163-4.631-9.405-9.299-15.95-9.763c-4.229-0.3-8.26,0.688-12.29,1.86
	c-6.432,1.87-14.743,4.607-21.278,1.577c-6.229-2.89-10.885-9.771-17.549-10.925c-0.727-0.125-1.476-0.182-2.252-0.157
	c-0.977,0.028-1.995,0.177-3.069,0.482c-9.283,2.653-17.366,9.728-24.524,15.951c-0.446,0.389-1.089,0.451-1.6,0.307
	C415.856,260.141,415.807,263.812,415.784,267.482z M523.65,354.213c-0.287-0.054-0.466-0.186-0.421-0.414
	c-1.201-1.185-2.325-2.388-3.342-3.662c0.066,1.717,0.221,3.426,0.49,5.114l1.924,7.049c0.507,1.283,1.075,2.514,1.697,3.705
	c1.856-1.846,2.629-4.418,3.968-6.626C528.764,358.063,524.517,355.019,523.65,354.213z M459.462,347.722
	c0.53,0.303,1.063,0.602,1.597,0.896c2.941-15.148,7.146-29.175,9.606-37.382c0.073-0.246,0.335-0.454,0.67-0.612
	c-0.739-0.408-1.939-1.104-3.33-2.043c-2.252-1.363-4.425-2.971-6.242-4.857c-4.063,5.842-6.438,13.434-7.627,20.2
	c-1.374,7.813-1.955,18.38,5.817,23.114C460.199,347.187,459.925,347.47,459.462,347.722z M527.601,257.948
	c0,7.06,0,14.118,0,21.178c0,0.912-2.896,1.365-2.896,0.777c0-0.027,0-0.057,0-0.084c-0.412,0.157-0.811,0.273-0.996,0.273
	c-4.207,0-8.413,0-12.62,0c0,2.43,0,4.857,0,7.288c2.643,1.38,5.042,3.101,7.035,5.646c2.661,3.396,4.104,7.906,4.745,12.121
	c1.666,10.963-0.776,21.108-2.188,31.912c-0.338,2.584-0.617,5.225-0.75,7.875c1.223,2.429,2.861,4.506,4.684,6.438
	C540.698,323.189,535.749,288.486,527.601,257.948z M407.509,205.829c0,13.007,0,26.014,0,39.02c0,0.285-0.285,0.523-0.676,0.701
	c2.823,6.878,3.423,19.152,6.043,24.224c0.068-19.266,1.086-38.51,1.117-57.773c0.001-0.873,2.651-1.321,2.875-0.845
	c33.611,0,67.223,0,100.833,0c0.771,0,0.378,0.35-0.254,0.678c0.091,0.043,0.146,0.102,0.146,0.181c0,20.334,0,40.669,0,61.002
	c0.036,0,0.072,0,0.107,0c0.807,0,0.341,0.383-0.343,0.724c-0.584,0.519-2.103,0.76-2.542,0.52c-29.974,0-59.948,0-89.921,0
	c-1.167,1.766-2.44,3.472-3.689,5.193c0.563-0.268,1.285-0.516,1.56-0.516c28.657,0,57.313,0,85.971,0
	c0.838-0.375,2.353-0.507,2.353-0.246c0,0.082,0,0.164,0,0.246c4.539,0,9.077,0,13.615,0c0-10.436,0-20.869,0-31.306
	c0-0.065,0-0.133,0-0.198c0-13.695,0-27.391,0-41.084c0-0.196,0.14-0.369,0.351-0.519
	C485.872,205.829,446.689,205.829,407.509,205.829z M131.047,157.225c0,0.065,0,0.13,0,0.195
	c-0.003,9.155-0.034,18.313-0.077,27.469c17.2,0,34.399,0,51.599,0c108.541,0,217.082,0,325.622,0c0-9.156,0-18.313,0-27.469
	c0-0.065,0-0.13,0-0.195c-17.258,0-34.515,0-51.772,0C347.96,157.225,239.503,157.225,131.047,157.225z M113.523,337.464
	c0.098,0,0.499,0.027,0.51,0.207c0.388,6.15,0.1,12.299,0.02,18.453c0.251,0.099,0.437,0.276,0.482,0.561
	c1.464,9.229,19.676,12.928,24.573,4.946c-0.795-5.317-2.776-11.729,0.903-16.306c3.893-4.845,12.646-2.599,14.933-9.418
	c2.061-6.151,0.258-13.463-1.22-19.559c-1.378-5.684-3.173-11.253-4.557-16.931c-0.039-0.158-0.031-0.306,0.003-0.445
	c-1.609-0.694-3.029-1.917-4.097-3.785c-4.21,4.751-12.276,4.518-16.929,0.361c-0.284,0.659-0.626,1.279-1.017,1.861
	c-0.448,0.938-1.101,1.817-2.053,2.34c-2.502,2.228-5.867,3.522-9.237,3.787c0.047,0.076,0.096,0.16,0.145,0.244
	c0.252,0.053,0.455,0.165,0.556,0.362c3.614,7.137,1.106,14.022-1.004,21.211c-0.05,0.17-0.187,0.321-0.374,0.451
	c0.133,0.277,0.26,0.562,0.376,0.854c0.221,0.559-2.618,1.481-2.897,0.776c-1.131-2.854-3.903-5.321-6.35-4.45
	c-0.056,0.032-0.109,0.059-0.166,0.092c-0.379,0.223-0.752,0.469-1.116,0.728c-0.444,0.43-0.866,0.99-1.243,1.742
	c-1.167,2.327-1.747,5.258-1.045,7.837C104.083,338.402,109.361,337.491,113.523,337.464z M102.873,362.798
	c10.997,8.393,16.992,23.524,20.002,36.716c11.086-0.68,22.043-2.701,33.164-2.901c-1.188-11.836-8.417-23.516-15.41-33.088
	c-6.941,7.396-25.506,5.324-28.651-4.668C109.065,360.366,105.99,361.622,102.873,362.798z M158.538,356.305
	c-2.375,0.065-4.73,0.289-7.08,0.565c4.853,3.022,8.283,8.397,11.002,13.22c3.554,6.303,6.453,12.903,9.117,19.623
	c3.344,8.433,5.606,17.469,7.34,26.582c2.59-3.446,5.211-6.879,7.999-10.16c0.17-0.201,0.405-0.342,0.666-0.431
	c1.386-5.299,4.121-10.229,7.239-14.905c-6.715-8.031-11.057-17.813-18.028-25.674c-4.386-4.945-9.216-8.201-15.636-8.744
	c-0.539,0.087-1.034,0.071-1.184-0.074C159.503,356.293,159.025,356.292,158.538,356.305z M162.821,354.527
	c1.459,0.168,2.898,0.441,4.312,0.847c0.205-0.073,0.406-0.119,0.581-0.119c15.747,0.007,31.493,0.188,47.24,0.119
	c0.9-2.488,1.796-4.979,2.746-7.447c-2.912-4.795-8.332-9.014-10.101-14.504c-0.159,0.05-0.318,0.079-0.469,0.08
	c-14.769,0.072-29.539,0.021-44.308,0.014C162.821,340.52,162.821,347.524,162.821,354.527z M206.609,298.273
	c0.043-0.004,0.086-0.011,0.126-0.011c9.341,0,18.681,0,28.021,0c0.239,0,0.416,0.023,0.543,0.063
	c1.07-6.566,8.078-11.722,14.558-13.548c-0.165,0-0.331,0.002-0.496,0.002c-0.927,0.001-0.936-0.343-0.585-0.704
	c-10.717-1.285-21.511-1.685-32.301-1.717C210.58,286.122,206.685,291.007,206.609,298.273z M373.42,283.282
	c-39.808,0.041-79.601,1.357-119.407,1.479c-0.092,0.461-0.717,1.025-1.504,1.118c-6.093,0.716-13.56,6.077-14.376,12.485
	c-0.04,0.313-0.33,0.576-0.709,0.763c1.505,2.507,1.531,5.198,0.563,7.624c45.146-0.016,90.287-0.934,135.433-0.973
	C373.42,298.28,373.42,290.78,373.42,283.282z M207.112,299.797c0.005,0.046,0.016,0.088,0.016,0.138
	c-0.071,8.428,10.66,11.318,10.524,19.494c1.86,2.646,1.01,6.229-0.235,9.16c0.413,0.104,0.717,0.368,0.712,0.84
	c-0.057,5.109-1.977,17.699,6.031,18.044c5.049,0.217,4.332-10.395,4.067-13.524c-0.034-0.399,0.208-0.74,0.562-0.99
	c-2.583-2.174-5.225-5.064-4.493-7.898c0.339-1.313,1.365-1.958,2.575-2.21c-0.912-1.068-1.43-2.361-1.089-3.802
	c-2.182-2.139-4.14-5.506-3.588-8.665c0.384-2.194,2.306-3.72,4.584-3.611c2.53,0.119,4.549,1.701,6.25,3.798
	c2.888-2.979,3.67-7.337,1.322-10.955c-0.266,0.11-0.533,0.185-0.75,0.185C224.77,299.797,215.941,299.797,207.112,299.797z
	 M237.902,343.334c0.208,0.196,0.294,0.487,0.161,0.886c-1.201,3.58-2.245,7.203-3.205,10.85
	c46.188-0.282,92.374-0.703,138.562-0.723c0-7.037,0-14.074,0-21.109c-43.454,0.011-86.905,0.426-130.356,0.75
	c-0.171,0-0.298-0.012-0.408-0.029C242.477,337.472,239.943,340.522,237.902,343.334z M239.771,327.941
	c-0.42-2.518-1.87-5.143-3.617-7.245c-0.144-0.041-0.26-0.105-0.326-0.208c-0.123-0.188-0.241-0.385-0.362-0.575
	c-0.793-0.857-1.623-1.604-2.431-2.174c-1.615-1.138-3.859-1.224-4.335,0.456c0.084,0.136,0.047,0.284-0.079,0.431
	c-0.029,0.309-0.01,0.659,0.067,1.062c0.314,1.632,1.274,2.743,2.536,3.55C233.707,324.071,237.279,325.7,239.771,327.941z
	 M232.614,315.191c-1.194-1.925-2.492-3.774-4.146-5.291c-0.377-0.346-2.407-2.298-2.95-1.336c-0.544,0.966-0.58,2.192-0.371,3.259
	c0.349,1.782,1.072,3.369,2.132,4.768c1.156-1.021,2.752-1.491,4.39-1.49C231.984,315.1,232.299,315.141,232.614,315.191z
	 M209.888,327.474c-0.396,5.008,2.379,8.977,5.449,12.773c-0.383-3.159-0.239-6.553-0.165-9.188
	c-0.816,0.152-1.631,0.08-1.446-0.285c1.219-2.409,3.192-6.86,1.654-9.688c-0.415-0.124-0.712-0.418-0.685-0.923
	C212.035,317.632,210.018,325.827,209.888,327.474z M201.249,381.788c0.96-1.317,1.908-2.637,2.829-3.968
	c-4.978-0.03-9.952-0.229-14.929-0.303c2.325,3.704,4.666,7.396,7.368,10.806C198.083,386.096,199.695,383.919,201.249,381.788z
	 M210.974,365.754c1.227-2.893,2.325-5.833,3.399-8.782c-0.061,0.008-0.122,0.016-0.178,0.016
	c-14.396,0.065-28.792-0.073-43.188-0.106c1.934,0.965,3.811,2.246,5.616,3.947c4.684,4.416,8.172,9.771,11.581,15.188
	c5.627,0.033,11.25,0.291,16.878,0.33C207.329,372.989,209.374,369.523,210.974,365.754z M221.627,400.212
	c4.993-18.479,7.476-37.59,13.507-55.793c-0.422-0.252-0.607-0.706-0.178-1.32c1.626-2.328,4.386-5.163,4.741-8.102
	c0.449-3.718-3.136-6.129-5.978-7.786c-0.623-0.363-7.471-4.065-6.491-1.111c1.082,3.261,4.312,5.612,6.943,7.576
	c1.368,1.021-1.128,2.542-2.219,1.729c-0.214-0.159-0.457-0.34-0.711-0.528c0.329,5.771-0.139,14.094-7.113,14.623
	c-1.651,0.127-3.006-0.112-4.116-0.631c-2.036,5.465-3.803,11.055-6.18,16.378c-2.591,5.806-5.978,10.923-9.726,16.034
	c-2.067,2.819-4.243,5.727-6.271,8.742c0.196,0.508-0.227,0.983-0.822,1.244c-4.525,6.959-8.066,14.51-7.423,22.965
	c0.061,0.797-2.856,1.318-2.897,0.777c-0.123-1.612-0.095-3.191,0.059-4.738c-2.473,3.086-4.843,6.262-7.214,9.423
	c0.169,0.958,0.333,1.916,0.493,2.874c3.679,4.037,8.728,8.217,14.594,7.809c5.077-0.354,9.638-2.892,13.226-6.441
	C214.368,417.483,219.232,409.075,221.627,400.212z M454.495,419.066c-6.638-12.351-17.591-23.223-16.871-38.084
	c0.01-0.202,0.11-0.384,0.264-0.54c-0.252-0.019-0.486-0.099-0.668-0.257c-7.523-6.555-14.535-13.494-22.518-19.514
	c-0.591-0.445-0.432-0.979,0.021-1.37c0.042-0.047,0.08-0.094,0.132-0.142c0.759-0.694,1.529-1.375,2.296-2.06
	c-0.348,0.04-0.688-0.006-0.945-0.18c-11.234-7.532-15.821-22.539-18.068-35.175c-2.348-13.206-3.22-27.438-0.302-40.629
	c0.017-0.075,0.044-0.146,0.077-0.215c-0.424,0.027-0.758-0.04-0.808-0.252c-1.903-8.164-2.14-17.226-1.877-25.563
	c0.143-4.507-0.372-12.741,5.216-14.238c1.698-0.455,3.053,0.014,4.167,1.101c0-12.041,0-24.082,0-36.122c-0.078,0-0.155,0-0.233,0
	c-1.451,0,1.219-1.242,1.862-1.242c0.067,0,0.136,0,0.203,0c0.284-0.033,0.543-0.035,0.736,0c33.67,0,67.34,0,101.011,0
	c0-6.028,0-12.059,0-18.088c-17.258,0-34.515,0-51.772,0c-108.485,0-216.969,0-325.453,0c-0.136,27.875-0.385,55.748-0.409,83.625
	c6.128-3.44,14.1-2.61,16.504,6c0.017,0.06,0.019,0.115,0.024,0.173c4.231-2.193,9.564-2.604,13.768-0.068
	c4.452,2.685,8.087,8.508,6.003,13.858c-2.093,5.373-8.77,9.873-14.525,9.599c1.454,5.735,3.307,11.364,4.585,17.146
	c1.193,5.399,2.789,11.956,1.389,17.479c-1.199,4.729-4.432,7.486-8.994,8.992c-3.801,1.256-8.336,2.138-8.436,7.096
	c-0.037,1.847,0.137,3.663,0.382,5.472c6.34-0.55,12.655-1.729,18.668-1.544c0-7.124,0-14.247,0-21.37
	c0-0.178,0.125-0.336,0.317-0.473c0.376-0.443,1.075-0.863,1.662-0.863c15.086,0.002,30.172,0.06,45.258-0.01
	c-0.65-3.968,0.248-9.483,3.47-12.072c0.941-0.756,2.448-1.494,3.88-1.604c-0.796-3.336-3.863-5.623-6.181-8.16
	c-2.577-2.821-4.178-5.749-4.145-9.645v-0.001c-0.282-0.015-0.474-0.08-0.478-0.21c-0.269-8.688,4.687-14.263,11.846-18.449
	c0.005-0.004,0.015-0.008,0.021-0.011c0.401-0.398,1.122-0.78,1.65-0.778c11.817,0.026,23.625,0.504,35.351,2.037
	c0.541,0.07,0.633,0.281,0.485,0.526c40.801-0.078,81.587-1.468,122.388-1.49c0.501,0,0.732,0.102,0.786,0.25
	c0.049,0.034,0.083,0.073,0.083,0.124c0,7.84,0,15.68,0,23.519c0,0.11-0.048,0.215-0.13,0.311c0.354,0.402-1.107,1.343-1.897,1.343
	c-45.688,0.015-91.369,0.957-137.056,0.979c-0.746,1.246-1.748,2.387-2.937,3.342c-0.075,0.061-0.199,0.117-0.35,0.172
	c1.478,2.1,2.71,4.481,3.848,6.412c3.243,3.279,5.466,8.445,4.901,11.901c-0.043,0.263-0.318,0.49-0.68,0.66
	c0.316,0.629,0.538,1.282,0.614,1.969c0.021,0.188,0.025,0.374,0.032,0.56c0.443-0.321,1.188-0.637,1.612-0.64
	c43.193-0.321,86.385-0.73,129.581-0.748c0.808-0.474,2.463-0.626,2.463-0.228c0,7.506,0,15.012,0,22.518
	c0,0.113-0.051,0.219-0.137,0.316c0.374,0.389-1.121,1.315-1.896,1.316c-46.604,0.006-93.207,0.435-139.81,0.723
	c-1.645,6.424-3.026,12.918-4.392,19.41c0.216-0.087,0.437-0.142,0.641-0.142c47.923,0,95.846,0,143.769,0
	c0.825-0.248,1.825-0.272,1.825,0.037c0,7.173,0,14.346,0,21.518c0,0.869-2.896,1.304-2.896,0.777c0-6.826,0-13.651,0-20.478
	c-47.9,0-95.8,0-143.7,0c-0.312,1.482-0.625,2.963-0.942,4.443c-1.118,5.215-2.168,10.536-3.598,15.729
	c50.09,0.014,100.177,0.648,150.268,0.655c1.954,0-0.153,1.525-1.158,1.524c-49.849-0.005-99.693-0.634-149.542-0.653
	c-1.502,5.143-3.424,10.133-6.226,14.735c-1.001,1.646-2.104,3.32-3.304,4.958C294.976,419.066,374.735,419.066,454.495,419.066z
	 M463.546,232.335c-2.264-4.576,0.134-8.2,4.338-10.017c-0.336-0.282,0.569-1.073,1.465-1.237c5.021-0.916,11.404,0.75,13.495,5.963
	c2.188,5.452-2.427,9.24-7.258,10.637C470.994,239.007,465.663,236.618,463.546,232.335z M470.452,222.194
	c0.109,0.188-0.079,0.44-0.815,0.711c-0.056,0.021-0.104,0.046-0.158,0.067c-6.04,2.356-4.075,9.187,0.067,12.266
	c1.909,1.418,4.277,2.04,6.538,1.061c1.465-0.636,2.65-1.604,3.429-2.768c1.282-1.914,1.452-4.352-0.081-6.689
	c-1.902-2.903-5.058-4.573-8.418-4.646C470.827,222.19,470.64,222.188,470.452,222.194z M376.317,235.352c0,7.506,0,15.012,0,22.519
	c0,0.109-0.048,0.214-0.129,0.31c0.351,0.403-1.108,1.342-1.897,1.342c-69.652,0.009-139.3,0.962-208.952,0.979
	c-0.845,0.411-2.315,0.524-2.315,0.149c0-8.174,0-16.347,0-24.52c0-0.869,2.897-1.305,2.897-0.777c0,0.043,0,0.085,0,0.128
	c69.376,0,138.752,0,208.128,0C374.899,235.088,376.317,234.983,376.317,235.352z M165.92,258.974
	c69.168-0.011,138.332-0.949,207.5-0.977c0-6.996,0-13.994,0-20.992c-69.167,0-138.333,0-207.5,0
	C165.92,244.329,165.92,251.65,165.92,258.974z M176.275,223.286c-0.026,0.053-0.052,0.104-0.078,0.155
	c-0.094,0.188,0.086,0.298,0.392,0.336c0.712,0.521,3.042-0.352,2.745-0.944c-0.029-0.06-0.059-0.118-0.088-0.178
	c2.412-4.822,4.823-9.646,7.234-14.469c2.529,5.059,5.058,10.115,7.587,15.173c0.225,0.451,1.114,0.386,1.854,0.129
	c-0.15,0.574,2.491,0.304,2.888-0.487c3.002-6.006,6.005-12.01,9.007-18.015c0.319-0.639-2.469-0.373-2.877,0.441
	c-2.758,5.518-5.517,11.034-8.275,16.551c-2.902-5.803-5.804-11.606-8.706-17.41c-0.425-0.851-3.221,0.132-2.896,0.777
	c0.031,0.062,0.062,0.124,0.093,0.187c-2.411,4.822-4.823,9.646-7.234,14.469c-2.53-5.062-5.062-10.121-7.591-15.182
	c-0.425-0.852-3.221,0.131-2.896,0.776C170.377,211.493,173.326,217.389,176.275,223.286z M219.835,223.288
	c-0.025,0.051-0.051,0.103-0.077,0.153c-0.094,0.188,0.085,0.298,0.392,0.336c0.711,0.521,3.042-0.352,2.745-0.944
	c-0.029-0.06-0.059-0.117-0.088-0.177c2.412-4.823,4.824-9.646,7.235-14.47c2.529,5.059,5.058,10.115,7.587,15.173
	c0.225,0.451,1.114,0.386,1.854,0.129c-0.151,0.574,2.491,0.304,2.887-0.487c3.003-6.006,6.006-12.01,9.008-18.015
	c0.319-0.639-2.469-0.373-2.877,0.441c-2.758,5.518-5.517,11.034-8.275,16.551c-2.902-5.803-5.804-11.606-8.706-17.41
	c-0.425-0.851-3.221,0.132-2.897,0.777c0.031,0.062,0.062,0.123,0.092,0.185c-2.411,4.823-4.823,9.647-7.234,14.471
	c-2.53-5.062-5.061-10.121-7.591-15.182c-0.425-0.852-3.221,0.131-2.897,0.776C213.938,211.493,216.886,217.39,219.835,223.288z
	 M263.396,223.286c-0.026,0.053-0.052,0.104-0.078,0.155c-0.094,0.188,0.086,0.298,0.392,0.336c0.712,0.521,3.042-0.352,2.745-0.944
	c-0.029-0.06-0.059-0.118-0.088-0.178c2.412-4.822,4.823-9.646,7.234-14.469c2.529,5.059,5.058,10.115,7.587,15.173
	c0.225,0.451,1.114,0.386,1.854,0.129c-0.151,0.574,2.491,0.304,2.887-0.487c3.003-6.006,6.005-12.01,9.007-18.015
	c0.319-0.639-2.469-0.374-2.876,0.441c-2.758,5.518-5.517,11.034-8.275,16.551c-2.902-5.803-5.804-11.606-8.706-17.41
	c-0.425-0.851-3.221,0.132-2.897,0.777c0.031,0.062,0.062,0.124,0.093,0.187c-2.411,4.822-4.823,9.646-7.234,14.469
	c-2.53-5.062-5.062-10.121-7.591-15.182c-0.425-0.852-3.221,0.131-2.897,0.776C257.498,211.493,260.447,217.389,263.396,223.286z"
      fill="#000000"
    ></path>
  </svg>
);
