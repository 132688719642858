import { ZodType, ZodRawShape, ZodSchema, ZodObject } from 'zod';

export const isFieldRequired = <T extends ZodRawShape>(
  schema: ZodSchema,
  fieldName: keyof T & string
): boolean => {
  if (!(schema instanceof ZodObject) || !schema.shape[fieldName]) {
    return false;
  }

  const fieldSchema: ZodType<any, any> = (schema as ZodObject<any, any>).shape[fieldName];

  // Check if the field is explicitly marked as optional or nullable.
  const isOptional = fieldSchema.isOptional() || fieldSchema.isNullable();

  // For string fields, check for a 'min' check as an indication of being required.
  // This is a heuristic and might not accurately reflect all cases.
  const hasMinCheck = fieldSchema?._def?.checks?.some(
    (check: any) => check.kind === 'min' && check.value === 1
  );

  return hasMinCheck || !isOptional;
};
