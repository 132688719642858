import { FC } from 'react';
import { InputFieldProps, useFormContext } from '@redwoodjs/forms';
import { ZodSchema } from 'zod';
import { isFieldRequired } from 'src/lib/zod';
import { useReadOnlyForm } from 'src/hooks';
import { classNames } from '../../lib';
import { Spinner } from '../Spinner';
import { ReadOnlyField } from '../ReadOnlyField';
import { Label } from '../Label';
import { FieldError } from '../FieldError';

export const textInputClasses =
  'w-full rounded-xl border disabled:bg-gray-100 border-gray-300 py-2 text-text-veryDark placeholder:text-text-medium focus:border-primary-dark focus:outline-none';

export type TextInputProps = InputFieldProps & {
  label?: string;
  placeholder?: string;
  helpText?: string;
  /**
   * If true, the label will be large and bold.
   */
  largeLabel?: boolean;
  loading?: boolean;
  schema?: ZodSchema;
  LeftIcon?: FC<React.ComponentProps<'svg'>>;
  RightIcon?: FC<React.ComponentProps<'svg'>>;
};

export const TextField: FC<TextInputProps> = ({
  name,
  helpText,
  label,
  largeLabel,
  placeholder,
  loading,
  schema,
  required,
  LeftIcon,
  RightIcon,
  ...inputFieldProps
}) => {
  const formContext = useFormContext() || {};
  const { readOnly } = useReadOnlyForm();
  const { watch } = formContext;

  const isDisabled = inputFieldProps.disabled || loading;
  const readOnlyValue = watch ? watch(name) : '';
  const isRequired = schema ? isFieldRequired(schema, name) : required;
  const hasError = formContext?.formState?.errors?.[name];

  if (readOnly) {
    return <ReadOnlyField name={name} label={label} value={readOnlyValue} />;
  }

  return (
    <div className="flex w-full flex-col gap-1">
      <div className="flex justify-between">
        <Label
          required={isRequired}
          name={name}
          label={label || ' '}
          readOnly={readOnly}
          largeLabel={largeLabel}
        />
      </div>
      <div className="relative flex items-center">
        {LeftIcon && (
          <span className="absolute left-3">
            <LeftIcon className="text-medium h-5 w-5" aria-hidden="true" />
          </span>
        )}
        <input
          {...inputFieldProps}
          placeholder={placeholder}
          className={classNames(
            textInputClasses,
            loading && 'pr-10',
            inputFieldProps.className,
            LeftIcon ? 'pl-10' : 'pl-3',
            RightIcon ? 'pr-10' : 'pr-3',
            hasError && 'border-error-light text-error-medium disabled:bg-gray-100'
          )}
          disabled={isDisabled}
          {...(formContext.register
            ? formContext.register(name, {
                valueAsNumber: inputFieldProps.type === 'number',
                setValueAs: (value) => (value ? value.trim() : value),
              })
            : {})}
        />
        {RightIcon && (
          <span className="absolute right-3">
            <RightIcon className="text-medium h-5 w-5" aria-hidden="true" />
          </span>
        )}
        {loading && (
          <div className="absolute right-3 top-2">
            <Spinner className="h-6 w-6" />
          </div>
        )}
      </div>
      {helpText && <p className="text-sm text-text-medium">{helpText}</p>}
      <FieldError name={name} />
    </div>
  );
};

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(function TextInput(
  {
    name,
    placeholder,
    loading,
    className,
    LeftIcon,
    RightIcon,
    ...inputFieldProps
  }: TextInputProps,
  ref
) {
  const isDisabled = inputFieldProps.disabled || loading;

  return (
    <input
      {...inputFieldProps}
      ref={ref}
      placeholder={placeholder}
      className={classNames(
        textInputClasses,
        loading && 'pr-10',
        LeftIcon ? 'pl-10' : 'pl-3',
        RightIcon ? 'pr-10' : 'pr-3',
        className
      )}
      name={name}
      disabled={isDisabled}
    />
  );
});
