import { z } from 'zod';

export const candidateCvDocumentMarkupFormSchema = z.object({
  name: z.string().max(255).nullish(),
  jobTitle: z.string().max(255).nullish(),
  profileSummary: z.string().max(10000).nullish(),
  email: z.string().email().max(255).nullish(),
  phone: z.string().max(20).nullish(),
  experience: z
    .array(
      z.object({
        startDate: z.date().nullish(),
        endDate: z.date().nullish(),
        title: z.string().max(255),
        company: z.string().max(255).nullish(),
        location: z.string().max(255).nullish(),
        description: z.string().max(4000).nullish(),
      })
    )
    .nullish(),
  education: z
    .array(
      z.object({
        startDate: z.date().nullish(),
        endDate: z.date().nullish(),
        title: z.string().max(255),
        institution: z.string().max(255).nullish(),
        location: z.string().max(255).nullish(),
        description: z.string().max(2000).nullish(),
      })
    )
    .nullish(),
  skills: z
    .array(
      z.object({
        title: z.string().max(255),
        description: z.string().max(255).nullish(),
      })
    )
    .nullish(),
  achievements: z
    .array(
      z.object({
        description: z.string().max(2000),
      })
    )
    .nullish(),
  certifications: z
    .array(
      z.object({
        title: z.string().max(255),
        description: z.string().max(1200).nullish(),
      })
    )
    .nullish(),
  interests: z
    .array(
      z.object({
        description: z.string().max(255),
      })
    )
    .nullish(),
  languages: z
    .array(
      z.object({
        language: z.string().max(255),
        level: z.number().int().min(1).max(5).nullish(),
      })
    )
    .nullish(),
});

export type CandidateCvDocumentMarkupFormValues = z.infer<
  typeof candidateCvDocumentMarkupFormSchema
>;
