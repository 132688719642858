import { DOCUMENT_FIELDS_FRAGMENT } from '../fragments';

export const CHANGE_DOCUMENT_STYLE_MUTATION = gql`
  ${DOCUMENT_FIELDS_FRAGMENT}
  mutation ChangeDocumentStyleMutation($input: ChangeDocumentStyleInput!) {
    changeDocumentStyle(input: $input) {
      ...DocumentFields
    }
  }
`;
