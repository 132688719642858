import { FC, useState } from 'react';
import { State } from './PromptEngineeringPage';
import { Button, DialogLayout } from '../../components';
import { RichTextEditor } from '../../components/RichTextEditor';
import ArrowUturnLeftIcon from '@heroicons/react/24/outline/ArrowUturnLeftIcon';
import { classNames } from '../../lib';
import { formatDateTime } from 'src/lib/dateTime';
import { ReadOnlyField } from '../../components/ReadOnlyField';

type Props = {
  history: { state: State; date: string }[];
  onRestore: (state: State) => void;
  onClose: () => void;
};

export const HistoryDialog: FC<Props> = ({ history, onRestore, onClose }) => {
  const [selected, setSelected] = useState<{
    state: State;
    date: string;
  }>(history[0] || { state: {} as State, date: '' });
  const selectedState = selected.state;
  return (
    <DialogLayout title="History" className="w-full overflow-hidden" onClose={onClose}>
      <div className="flex flex-grow flex-row overflow-hidden">
        <div className="flex flex-1 flex-col overflow-auto">
          {selectedState.prompts?.system && (
            <ReadOnlyField
              name="System Prompt"
              label="System Prompt"
              value={selectedState.prompts?.system}
            />
          )}
          <ReadOnlyField
            name="User Prompt"
            label="User Prompt"
            value={selectedState.prompts?.user ?? selectedState.prompt}
          />
        </div>
        <div className="flex flex-1 flex-col overflow-auto">
          <RichTextEditor
            disableMinWidth
            hideMenuBar
            editable={false}
            content={selectedState.result}
          />
        </div>
        <div className="flex flex-col">
          <ul className="flex-1 list-none overflow-auto">
            {history
              ?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
              .map(({ state, date }) => (
                <ListItem
                  onClick={() => setSelected({ state, date })}
                  date={date}
                  key={date}
                  docType={state.docType}
                  model={state.model}
                />
              ))}
          </ul>
          {
            <div className="flex justify-end">
              <Button
                onClick={() => onRestore(selected.state)}
                text="Restore"
                LeftIcon={ArrowUturnLeftIcon}
              />
            </div>
          }
        </div>
      </div>
    </DialogLayout>
  );
};

type ListItemProps = {
  onClick: () => void;
  date: string;
  docType: State['docType'];
  model: State['model'];
};

const ListItem: FC<ListItemProps> = ({ onClick, date, docType, model }) => {
  return (
    <li
      className={classNames(
        'flex w-full list-none flex-row gap-x-3 rounded-md px-3 py-3',
        'font-medium text-text-medium hover:cursor-pointer hover:bg-gray-50 hover:text-text-dark '
      )}
      onClick={onClick}
      tabIndex={0}
    >
      <div className="flex flex-1 flex-col">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-col">
            <div className="text-sm font-medium">{docType}</div>
            <div className="text-xs text-text-medium">{model}</div>
            <div className="text-xs text-text-medium">{formatDateTime(new Date(date))}</div>
          </div>
        </div>
      </div>
    </li>
  );
};
