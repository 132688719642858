import ReactPDF from '@react-pdf/renderer';
import BrandedPdfDocument from './BrandedPdfDocument';
import { DocumentBrandingConfig, GetBrandAssets } from 'types/graphql';
import { TPdfNode } from './PdfNodes';
import { PDF_NODE_TYPE } from './PdfNodes/types';
import { notNullish } from '../../lib/guards';

export const DEFAULT_BRANDING_CONFIG: DocumentBrandingConfig = {
  __typename: 'DocumentBrandingConfig',
  id: '1',
  fontConfig: {
    __typename: 'FontConfig',
    header: 'sans-serif',
    body: 'sans-serif',
  },
  marginsConfig: {
    __typename: 'MarginConfig',
    top: '16',
    horizontal: '16',
  },
  headerStyle: 'LOGO',
  logoConfig: {
    __typename: 'LogoConfig',
    id: '1',
    alignment: 'left',
    size: '48',
  },
  footerConfig: {
    __typename: 'FooterConfig',
    content: '',
    alignment: 'center',
  },
  showTableBorders: false,
};

export const DEFAULT_BRAND_ASSETS: GetBrandAssets['getBrandAssets'] = {
  __typename: 'BrandAssets',
  id: '1',
  brandColor: '#FFFFFF',
  logo: null,
  logoSize: '124',
  logoAlignment: 'left',
  letterhead: null,
  letterheadTopMargin: 24,
  letterheadBottomMargin: 24,
  letterheadLeftMargin: 24,
  letterheadRightMargin: 24,
  leftFooter: '',
  middleFooter: '',
  rightFooter: '',
  footerFont: 'sans-serif',
};

/**
 * Converts HTML markup into a structured format compatible with React PDF renderer.
 * This function parses HTML and creates a corresponding structure of ReactPDFNode.
 *
 * @param {string} htmlMarkup - The HTML string to be converted.
 * @returns {ReactPDFNode[]} An array of ReactPDFNode objects representing the parsed HTML.
 */

export const convertHtmlToReactPdfContent = (htmlMarkup: string): TPdfNode[] | TPdfNode | null => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlMarkup, 'text/html');
  return processNode(doc.body);
};

/**
 * Generates a PDF blob from given HTML markup by converting the markup to ReactPDFContent
 * and then rendering it using the PdfDocument component.
 *
 * @param {string} htmlMarkup - The HTML content to be converted into a PDF.
 * @returns {Promise<Blob>} A promise that resolves with the PDF blob.
 */

export const createPdfFromHtmlReactPdf = async (
  htmlMarkup: string,
  config?: DocumentBrandingConfig,
  brandAssetsConfig?: GetBrandAssets['getBrandAssets']
): Promise<Blob> => {
  const content = convertHtmlToReactPdfContent(htmlMarkup);
  const serialisedContent = Array.isArray(content) ? content : [content];

  const blob = await ReactPDF.pdf(
    <BrandedPdfDocument
      content={serialisedContent.filter(notNullish)}
      config={config ?? DEFAULT_BRANDING_CONFIG}
      brandAssets={brandAssetsConfig ?? DEFAULT_BRAND_ASSETS}
    />
  ).toBlob();
  return blob;
};

/**
 * Processes an individual HTML node and converts it to a structure compatible with React PDF renderer.
 * This function is designed to be used recursively to handle nested HTML structures such as lists.
 *
 * @param {ChildNode} node - The HTML node to process.
 * @returns {ReactPDFNode | null} - The converted node in ReactPDFContent format, or null if the node type is unsupported.
 */
export const processNode = (node: ChildNode | HTMLElement): TPdfNode => {
  switch (node.nodeName) {
    // Handle root and body
    case 'HTML':
    case 'BODY':
      return {
        type: PDF_NODE_TYPE.FRAGMENT,
        children: [...node.childNodes].map(processNode).filter(notNullish),
        context: {},
      };
    case '#text':
      return {
        type: PDF_NODE_TYPE.TEXT,
        children: node.textContent ?? '',
        context: {},
      };
    case 'P':
      return {
        type: PDF_NODE_TYPE.PARAGRAPH,
        children: [...node.childNodes].map(processNode),
        context: {},
      };
    case 'A': {
      const href = (node as Element).getAttribute('href');
      return {
        type: PDF_NODE_TYPE.LINK,
        children: node.textContent?.trim() ?? '',
        context: {},
        attrs: { href: href ?? '' },
        style: {},
      };
    }
    case 'STRONG':
      return {
        type: PDF_NODE_TYPE.BOLD,
        children: [...node.childNodes].map(processNode).filter(notNullish),
        context: {},
      };
    // Handle italic
    case 'EM':
    case 'I':
      return {
        type: PDF_NODE_TYPE.ITALIC,
        children: [...node.childNodes].map(processNode).filter(notNullish),
        context: {},
      };
    case 'LI':
      return {
        type: PDF_NODE_TYPE.LIST_ITEM,
        children: [...node.childNodes].map(processNode).filter(notNullish),
        context: {},
      };
    case 'OL':
      return {
        type: PDF_NODE_TYPE.ORDERED_LIST,
        context: {},
        style: {},
        children: [...node.childNodes].map(processNode).map((child, index) => ({
          ...child,
          context: { index, parent: PDF_NODE_TYPE.ORDERED_LIST },
        })),
      };
    case 'UL':
      return {
        type: PDF_NODE_TYPE.UNORDERED_LIST,
        context: {},
        style: {},
        children: [...node.childNodes].map(processNode).map((child, index) => ({
          ...child,
          context: { index, parent: PDF_NODE_TYPE.UNORDERED_LIST },
        })),
      };
    case 'TABLE':
      return {
        type: PDF_NODE_TYPE.TABLE,
        children: [...node.childNodes].map(processNode),
        context: {},
      };
    case 'TBODY':
      return {
        type: PDF_NODE_TYPE.TABLE_BODY,
        children: [...node.childNodes].map(processNode),
        context: {},
      };
    case 'TR':
      return {
        type: PDF_NODE_TYPE.TABLE_ROW,
        children: [...node.childNodes].map(processNode),
        context: {},
      };
    case 'TH':
      return {
        type: PDF_NODE_TYPE.TABLE_HEADER,
        children: [...node.childNodes].map(processNode),
        context: {},
      };
    case 'TD':
      return {
        type: PDF_NODE_TYPE.TABLE_CELL,
        children: [...node.childNodes].map(processNode),
        context: {},
      };
    case 'H1':
      return {
        type: PDF_NODE_TYPE.H1,
        children: [
          {
            type: PDF_NODE_TYPE.TEXT,
            children: node.textContent?.trim() ?? '',
            context: {},
          },
        ],
        context: {},
      };
    case 'H2':
      return {
        type: PDF_NODE_TYPE.H2,
        children: [
          {
            type: PDF_NODE_TYPE.TEXT,
            children: node.textContent?.trim() ?? '',
            context: {},
          },
        ],
        context: {},
      };
    case 'H3':
      return {
        type: PDF_NODE_TYPE.H3,
        children: [
          {
            type: PDF_NODE_TYPE.TEXT,
            children: node.textContent?.trim() ?? '',
            context: {},
          },
        ],
        context: {},
      };
    case 'H4':
      return {
        type: PDF_NODE_TYPE.H4,
        children: [
          {
            type: PDF_NODE_TYPE.TEXT,
            children: node.textContent?.trim() ?? '',
            context: {},
          },
        ],
        context: {},
      };
    case 'H5':
      return {
        type: PDF_NODE_TYPE.H5,
        children: [
          {
            type: PDF_NODE_TYPE.TEXT,
            children: node.textContent?.trim() ?? '',
            context: {},
          },
        ],
        context: {},
      };
    case 'H6':
      return {
        type: PDF_NODE_TYPE.H6,
        children: [
          {
            type: PDF_NODE_TYPE.TEXT,
            children: node.textContent?.trim() ?? '',
            context: {},
          },
        ],
        context: {},
      };
    case 'BR':
      return {
        type: PDF_NODE_TYPE.HARD_BREAK,
        children: '',
        context: {},
      };
    default:
      return {
        type: PDF_NODE_TYPE.FRAGMENT,
        children: [...node.childNodes].map(processNode),
        context: {},
      };
  }
};
