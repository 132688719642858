import { FC } from 'react';
import { classNames, getTagColor } from '../../lib';

type Props = {
  jobTitle: string;
};

/**
 *
 * @returns Auto-colored tag for job title
 */
export const JobTitleTag: FC<Props> = ({ jobTitle }) => {
  return (
    <div
      className={classNames(
        'w-fit rounded px-2 py-1 text-sm font-medium text-text-dark',
        getTagColor(jobTitle)
      )}
      title={jobTitle}
    >
      <p className="max-w-[200px] truncate"> {jobTitle}</p>
    </div>
  );
};
