import { Controller, ControllerProps } from '@redwoodjs/forms';
import { useQuery } from '@redwoodjs/web';
import { FC, useState } from 'react';
import { GetCandidates, GetCandidatesVariables } from '../../../types/graphql';
import { GET_CANDIDATES_QUERY } from '../../graphql/queries';
import { useDebounce } from '../../hooks';
import { Autocomplete, AutocompleteProps } from './Autocomplete';
import { UserIcon } from '@heroicons/react/24/solid';

type CandidateAutocompleteValue = { id?: string; name: string };

export const CandidateAutocompleteField: FC<
  Pick<AutocompleteProps<CandidateAutocompleteValue>, 'label' | 'name' | 'placeholder'> &
    Pick<ControllerProps<{ candidate: CandidateAutocompleteValue }, 'candidate'>, 'name'> & {
      required?: boolean;
      onFocus?: () => void;
      onBlur?: () => void;
      disabled?: boolean;
    }
> = ({ name, label, disabled, required, onFocus, onBlur, placeholder }) => {
  const [query, setQuery] = useState('');

  const blurWrapper = (fn: (...args: unknown[]) => void) => {
    return (...args: unknown[]) => {
      onBlur && onBlur();
      fn(...args);
    };
  };

  const debouncedQuery = useDebounce(query, 500);
  const { data, previousData, loading, error } = useQuery<GetCandidates, GetCandidatesVariables>(
    GET_CANDIDATES_QUERY,
    {
      variables: {
        input: {
          searchTerm: debouncedQuery,
          connection: {
            take: 10,
          },
        },
      },
    }
  );

  const options = data?.candidates?.nodes || previousData?.candidates?.nodes || [];

  if (error) {
    throw error;
  }

  return (
    <Controller
      name={name}
      rules={{ required }}
      render={({ field: { name, onBlur, onChange, value, ...rest }, fieldState: { error } }) => (
        <Autocomplete
          {...rest}
          disabled={disabled}
          LeftIcon={UserIcon}
          getOptionKey={(option) => option?.id || ''}
          getOptionLabel={(option) => option?.name || ''}
          queryToOption={(query) => ({ name: query, id: null })}
          placeholder={placeholder}
          label={label}
          name={name}
          onFocus={onFocus}
          onQueryChange={setQuery}
          onBlur={blurWrapper((v) => {
            // If the user has entered a value that is not in the list of options, and tabbed away, we want to save that value.
            onChange({ id: null, name: v });
            onBlur();
          })}
          value={value}
          options={options}
          onChange={onChange}
          loading={loading && !previousData}
          error={!!error}
          required={required}
        />
      )}
    />
  );
};
