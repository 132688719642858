export const CREATE_JOB_MUTATION = gql`
  mutation CreateJob($input: CreateJobInput!) {
    createJob(input: $input) {
      id
      status
      campaignStatus
      title
      contractType
      salary
      roleAndResponsibilities
      mustHaves
      company {
        name
      }
    }
  }
`;
