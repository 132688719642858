import { ACCESS_LEVEL, PermissionFields } from 'types/graphql';

export const PERMISSION_LEVELS = {
  READ: 1,
  WRITE: 2,
  OWNER: 3,
};

/**
 * Determines if the user has the required access level.
 *
 * @param {ACCESS_LEVEL | null} myPermission - The user's current permission level.
 * @param {ACCESS_LEVEL | null} requiredPermission - The minimum required permission level for an action.
 * @returns {boolean} True if the user's permission level is greater than or equal to the required level, false otherwise.
 */

export const hasRequiredAccess = (
  myPermission?: ACCESS_LEVEL | null,
  requiredPermission?: ACCESS_LEVEL | null
): boolean => {
  // Convert permission strings to numeric levels for comparison.
  const myPermissionLevel = PERMISSION_LEVELS[myPermission as keyof typeof PERMISSION_LEVELS];
  const requiredPermissionLevel =
    PERMISSION_LEVELS[requiredPermission as keyof typeof PERMISSION_LEVELS];

  // Check if the user's permission level meets or exceeds the required level.
  return myPermissionLevel >= requiredPermissionLevel;
};

/**
 * Checks if there are multiple users with editing capabilities.
 *
 * @param {PermissionFields[]} permissions - An array of permission objects to evaluate.
 * @returns {boolean} True if more than one user has 'WRITE' or higher permissions, false otherwise.
 */

export const hasMultipleEditors = (permissions: PermissionFields[]): boolean => {
  // Count the number of permissions meeting the 'WRITE' or higher requirement.
  return (
    permissions.reduce(
      (count, p) =>
        // Check each permission for required access level and increment the count accordingly.
        hasRequiredAccess(
          p.__typename === 'UserPermission' ? p.permission : p.orgPermission,
          'WRITE'
        )
          ? count + 1
          : count,
      0 // Initial count is 0.
    ) > 1 // True if more than one editor is found.
  );
};
