import { FC } from 'react';
import { textInputClasses } from '../TextField/TextField';
import { classNames } from 'src/lib';

export type SearchInputProps = {
  placeholder?: string;
  value?: string;
  onChange: (value: string) => void;
  autofocus?: boolean;
  size?: 'small' | 'medium' | 'large';
};

const searchInputSizeClasses: Record<Required<SearchInputProps>['size'], string> = {
  small: 'h-7 py-3.5 px-2 text-sm rounded-lg',
  medium: 'p-3',
  large: 'py-4 px-3 text-lg',
};

export const SearchInput: FC<SearchInputProps> = ({
  placeholder,
  value,
  onChange,
  autofocus,
  size = 'medium',
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (autofocus) {
      inputRef.current?.focus();
    }
  }, [autofocus]);

  return (
    <input
      ref={autofocus ? inputRef : undefined}
      value={value}
      autoComplete="off"
      onChange={(e) => onChange(e.target.value)}
      type="text"
      placeholder={placeholder}
      className={classNames(
        textInputClasses,
        searchInputSizeClasses[size],
        'w-full select-none pl-3'
      )}
    />
  );
};
