import { useController, useFormContext } from '@redwoodjs/forms';
import { classNames } from '../../lib';
import { JsonSchemaEnum } from './schema';

type Props = {
  schema: JsonSchemaEnum;
  name: string;
};

export const EnumField: React.FC<Props> = ({ name, schema }) => {
  const { control } = useFormContext();
  const defaultValue = schema.default;
  const options = schema.enum.map((value) => {
    const details = schema.enumDetails.find((i) => i.value === value);
    return {
      value,
      label: details?.label ?? value,
      description: details?.description,
    };
  });
  const { field, formState } = useController({
    name,
    control,
    defaultValue,
  });

  const error = formState.errors[name];

  return (
    <div className="flex flex-col">
      <div className="pb-2 text-lg font-semibold text-text-dark">{schema.title}</div>
      <div className="flex flex-row flex-wrap gap-x-4 gap-y-2">
        {options.map((option) => (
          <EnumOption
            key={option.value}
            label={option.label}
            description={option.description}
            selected={option.value === field.value}
            onChange={field.onChange}
            value={option.value}
          />
        ))}
      </div>
      {/* TODO: Find out why this type isn't string  */}
      {error && <div>{error.message as unknown as string}</div>}
    </div>
  );
};

const EnumOption: React.FC<{
  label: string;
  description?: string;
  selected?: boolean;
  onChange: (value: string) => void;
  value: string;
}> = ({ label, value, description, selected, onChange }) => {
  const onClick = () => {
    if (selected) return;
    onChange(value);
  };
  return (
    <div
      className={classNames(
        'user-select-none relative flex min-w-[150px] max-w-sm flex-col rounded-3xl border border-text-light bg-white px-6 py-8',
        selected && 'border-generate-medium',
        !selected && 'hover:cursor-pointer hover:border-generate-light',
        description && 'flex-1'
      )}
      onClick={onClick}
    >
      <div
        className={classNames(
          'text-base font-medium text-text-dark',
          description ? '' : 'text-center'
        )}
      >
        {label}
      </div>
      {description && <div className="pt-2 text-sm text-text-medium">{description}</div>}
      <div className="absolute right-4 top-4 flex h-4 w-4 items-center justify-center rounded-full border border-text-light">
        {selected && <div className="h-3 w-3 rounded-full bg-generate-medium" />}
      </div>
    </div>
  );
};
