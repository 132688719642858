import { JobFormValues } from '../../lib/formSchemas';

export const jobCampaignScenarios: {
  name: string;
  input: JobFormValues;
}[] = [
  {
    name: 'Detailed',
    input: {
      company: {
        name: 'JA Projects',
      },
      title: 'Architect',
      salary: '£44k',
      overview: `Up and coming interdisciplinary practice
      fast established a reputation as a thought-provoking, socially and environmentally conscious practice
      mission to create inclusive, sustainable and inspirational environments across diverse sectors.
      The studio's work consists of a number of high profile projects across the arts, housing, community infrastructure and public realm sectors.
      Projects range in scale, from masterplanning and the design of urban blocks, to rethinking cultural institutions and research.
      Headed up by renowned Architect, artist, lecturer and filmmaker, the practice is focused on working on public-facing, cultural projects that strengthen communities and actively reflect on society.
      Recognised by numerous publications as a key voice shaping the life of cities, as well as featuring on the Architects' Journal's prestigious '40 Under 40' list.`,
      benefits: `Discretionary Bonus
      Employer pension contributions
      Flexible working (up to 2 days remote working per week)
      Regular socials, cultural outings, industry events
      Involvement in all stages of projects, from creative direction to technical and on-site delivery
      Thought-provoking, meaningful and values-driven work
      Flexible working arrangements: full-time or part-time 4 days per week.`,
      location: 'Doncaster',
      culture: `dynamic and diverse start-up with a team of ten highly-talented and thought-leading Architects and designers passionate about making a positive difference to communities.
      discretionary bonus, great socials, meaningful and values-driven career opportunities, diverse and challenging projects, flexibility and a great collaborative, supportive and dynamic studio environment.
      At the heart of our multidisciplinary practice, are socially driven people committed to delivering great experiences, buildings, and cities.
      Each member brings a fresh perspective to the fabric of the studio offering a broad diversity of skills, creative thinking, and innovative delivery.`,
      contract: 'Permanent',
      mustHaves: `Minimum 2 years post Part 3 experience in practice
      Excellent design, presentation, and graphics skills.
      Delivery experience (RIBA Stage 1–6) of complex, large-scale projects with a specific focus on technical delivery, and construction documentation.
      Experience working with a variety of sectors including Local Authorities and/or public/third sector and commercial clients.
      Good understanding of construction detailing.
      Strong organisational and communication skills.
      Ability to take initiative in response to a direction.
      Good interpersonal skills and the ability to work well within a team.
      A flexible and open attitude towards new ways of working and commitment to independent learning.
      Calm under pressure.
      Experienced in Vectorworks, Rhino, SketchUp (or other similar CAD software)
      Knowledge and comprehensive experience in BIM methodology and processes
      Experience and interest in decarbonising and or decolonising desirable.`,
      responsibilities: `The successful candidate will be involved across the board helping to drive our design thinking by delivering projects that speak to and for the communities they serve. In the immediacy, they will a new flagship project which is a combination of mixed-use development combining housing and community infrastructure. The role will include managing a team and mentoring younger practitioners. The ideal candidate would have a keen interest in public facing architecture and a will to join a dynamic start-up culture, helping to grow the size and reputation of the practice
      Working arrangement: 4–5 days/week`,
    },
  },
  {
    name: 'Medium',
    input: {
      company: {
        name: 'FAT Recruitment',
      },
      title: 'Project Architect',
      salary: '£50k',
      contract: 'Permanent',
      overview: `A highly acclaimed, award-winning, AJ100 architectural practice with design Studios across the UK.
        Over 40 years in the industry working across 16 sectors from Masterplanning and Mixed-Use to Residential, Commercial, Education
        Dedicated team of 280+ enthusiastic individuals that are passionate about creating memorable places that people are drawn to - while adding commercial, civic, environmental, and social value.
        Believe the underlying value of places is in their ability to foster human connection and support wellbeing.
        Have an innate ability to challenge preconceptions and reimagine spaces with a people-focused, placemaking-led approach.`,
      benefits: `With people at the heart of the practice, they offer a competitive salary and benefits package that promotes a healthy work-life balance and supports your professional development needs. Life assurance, bonus scheme, Cycle to work scheme, Employee Assistance Program, hybrid working opportunities, regular social activities plus more are among some of the benefits on offer.`,
      location: 'Doncaster',
      culture: `opportunity to expand skills and expertise within a friendly, supportive, and sociable office environment.
      Invest and develop in staff to ensure the practice is a happy, rewarding and professionally fulfilling place to be.
      From training and mentoring to wellbeing and CSR – you benefit from a culture that fosters collaboration, creativity and impact.
      Working together with others to share resources and information across the business, seek continuous improvement to develop individual capability and that of the team and being self-motivated and reliable are traits that are highly desirable for this practice.
      This is an equal opportunity employer. They celebrate and support diversity and are committed to creating and maintaining an inclusive environment throughout the employee lifecycle.',
      contract: 'Permanent'`,
      mustHaves: `•	Proven experience as a project architect, with a portfolio showcasing your ability to push architectural boundaries.
      •	Strong team player, self-motivated and reliable.
      •	Excellent understanding of the architectural industry, with the ability to challenge preconceptions and innovate within the sector.
      •	Commitment to expanding skills and expertise, seeking continuous improvement.`,
      responsibilities: `•	Innovating within a variety of project scales, sectors and stages, driving continuous improvement and expansion of skills.
      •	Collaborating with teammates to share resources and information across the business.
      •	Reimagining spaces using a people-focused, placemaking-led approach to add commercial, civic, environmental, and social value.
      •	Contribute to the team's aspirations for growth and development, helping shape the practice's future.`,
    },
  },
  {
    name: 'Brief',
    input: {
      company: {
        name: 'By Others',
      },
      title: 'Architect',
      salary: '£40k',
      overview: `A fast-emerging young and dynamic architecture firm that craft buildings, shape places and challenge the norm.
        Recognised as agents of change, working with the idealists and disruptors who believe in a brighter, open-minded and ambitious future.`,
      benefits: `1 day remote working
24 days holiday
potential to earn equity within the company
regular socials
Autonomy and full-support in progressing your career`,
      location: 'Peckham',
      culture: `Supportive, collaborative, innovative.
      Support career progression and offer a healthy balance of collaboration and autonomy.
      Collectively driven to drive positive change.`,
      contract: 'Permanent',
      mustHaves: `Positive can do attitude
Not afraid to get stuck in
Strong knowledge of Vectorworks is beneficial
Strong presentation skills and ability to communicate effectively
Ability to manage multiple ongoing projects`,
      responsibilities: 'Lead diverse projects of various scales and scopes.',
    },
  },
];
