import { z } from 'zod';
import languageCodes from './language-codes.json';

/**
 * Supported ISO 639-1 language codes.
 */

// Validate the JSON
const schema = z.array(
  z.object({
    code: z.string(),
    name: z.string(),
  })
);

export const LANGUAGE_CODES = schema.parse(languageCodes);
export type LanguageCode = z.infer<typeof schema>[0];
