import { FC } from 'react';

export const CompanyIllustration: FC<React.ComponentProps<'svg'>> = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="595.28px"
    height="595.28px"
    viewBox="0 0 595.28 595.28"
    enableBackground="new 0 0 595.28 595.28"
    xmlSpace="preserve"
    {...props}
  >
    <path
      id="color_x5F_2"
      fill="#1c1c20"
      d="M367.63,176.446c0.986,0.532,1.922,1.146,2.819,1.823L367.63,176.446z M367.238,152.582
	c1.978-0.551,3.988-0.875,6.006-0.995C370.885,151.372,369.091,151.727,367.238,152.582z M401.449,210.991
	c0.173,1.14-2.738,1.831-2.897,0.776c-2.431-16.014-13.548-34.112-28.132-41.859c-1.627,1.19-3.236,2.413-4.819,3.677
	c9.615,2.426,15.74,11.894,19.997,20.207c4.703,9.186,8.062,18.903,11.737,28.516c2.668-2.835,5.632-5.41,8.354-8.168
	c-0.074-0.101-0.125-0.222-0.137-0.373c-1.226-15.125-7.665-32.318-21.166-40.543c-1.41-0.859,1.121-2.365,2.229-1.69
	c13.31,8.109,20.074,24.743,21.664,39.794c2.215-2.6,4.099-5.463,5.257-8.916c3.987-11.89-1.896-25.27-9.94-34.106
	c-3.136-3.445-7.326-7.08-12.02-10.007c-6.842,2.452-13.105,5.958-19.012,10.077C387.468,176.201,398.964,194.619,401.449,210.991z
	 M348.324,184.959c1.609-1.063,3.172-2.192,4.703-3.369c0.066-0.086,0.203-0.188,0.383-0.295c4.731-3.669,9.207-7.711,14.002-11.266
	c7.022-5.204,14.44-9.648,22.647-12.64c-8.14-4.695-17.571-7.07-25.641-2.701C353.671,160.508,344.673,172.549,348.324,184.959z
	 M413.512,208.548c1.687-1.255,2.439-3.219,2.691-5.802c-1.023,2.583-2.499,4.891-4.227,7.044
	C412.557,209.295,413.079,208.871,413.512,208.548z M374.066,181.826c-0.287,0-0.574,0-0.861,0c1.63,1.522,3.33,3.206,4.976,4.998
	c-1.188-1.771-2.479-3.475-3.896-5.028C374.2,181.813,374.123,181.826,374.066,181.826z M313.883,253.33
	c0.292-0.108,0.589-0.202,0.889-0.292c0.085-0.104,0.152-0.233,0.192-0.394c0.49-1.962,0.877-3.917,0.989-5.929
	c-0.662-0.148-1.32-0.308-1.973-0.485c-0.067,2.008-0.458,3.932-0.944,5.883C312.867,252.789,313.353,253.241,313.883,253.33z
	 M276.019,253.212c-2.568-0.462-5.135-0.931-7.709-1.345c0.075-1.209,0.134-2.419,0.165-3.63c-0.674,0.205-1.346,0.415-2.017,0.628
	c-0.033,0.898-0.076,1.795-0.13,2.693c-1.804-0.264-3.615-0.482-5.436-0.64c-0.424,0.184-0.852,0.354-1.27,0.555
	c0.612,0.487,1.236,0.993,1.866,1.505c1.58,0.15,3.154,0.338,4.722,0.568c-0.063,1.004-0.115,2.009-0.155,3.014
	c0.646,0.476,1.298,0.938,1.958,1.373c0.025-1.36,0.096-2.719,0.179-4.076c2.579,0.417,5.15,0.892,7.723,1.355
	c-0.14,1.977-0.399,3.945-0.88,5.901c-0.003,0.014,0.001,0.024-0.001,0.038c0.655,0.145,1.314,0.242,1.978,0.278
	c0.463-1.946,0.726-3.904,0.872-5.869c1.613,0.281,3.229,0.551,4.851,0.783c1.261,0.18,1.804-1.747,0.531-1.929
	c-1.763-0.252-3.518-0.545-5.271-0.852c0.109-2.848,0.036-5.71,0.011-8.578c-0.658,0.282-1.321,0.552-1.991,0.802
	C276.046,248.268,276.102,250.745,276.019,253.212z M276.563,243.509c0.03-0.013,0.059-0.027,0.089-0.041
	C276.62,243.479,276.594,243.496,276.563,243.509z M266.716,246.79c0.369-0.109,0.737-0.219,1.105-0.327
	C267.428,246.35,266.977,246.464,266.716,246.79z M290.734,255.343c0.272,0.083,0.544,0.154,0.817,0.228c0-0.692,0-1.384,0-2.077
	c-0.095-0.027-0.19-0.051-0.286-0.08c-0.004-0.001-0.007,0-0.011-0.001c-0.338,0.536-0.664,1.079-0.971,1.632
	C290.396,255.172,290.534,255.283,290.734,255.343z M294.449,256.219c0-0.679,0-1.358,0-2.037c1.946,0.366,3.906,0.557,5.877,0.651
	c-0.046,0.044-0.098,0.092-0.143,0.135c-0.659,0.636,2.115,0.323,2.634-0.178c0.059-0.057,0.123-0.117,0.183-0.175v0.302
	c2.326,0.028,4.663-0.027,7-0.038c0.284-0.001,0.495,0.105,0.654,0.256c-0.62,0.498-1.193,1.079-1.713,1.753
	c-1.984,0.017-3.966,0.046-5.941,0.022v7.602c1.172-0.115,2.334-0.279,3.484-0.49c0.005,0.677,0.081,1.343,0.221,1.991
	c-1.223,0.218-2.458,0.387-3.705,0.503v11.661c0.058,0.037,0.111,0.081,0.168,0.118c-0.396,1.073-0.715,2.221-0.963,3.438
	c-0.615,3.02-0.291,5.74,0.795,8.006v9.495c1.499,0.073,2.998,0.13,4.5,0.146c0.058,0,0.1,0.024,0.153,0.033
	c-0.314,0.625-0.57,1.274-0.747,1.954c-1.304-0.023-2.605-0.069-3.906-0.132v9.67c2.294-0.231,4.585-0.521,6.873-0.866
	c0.621,0.269,1.297,0.444,2.012,0.549c0.218,0.515,0.082,1.146-0.619,1.257c-2.75,0.435-5.506,0.777-8.266,1.051v14.117
	c3.569-0.272,7.08-0.896,10.5-2.031v-14.245c0.661,0.033,1.333,0.044,2,0.057v4.713c-0.473,1.539-0.564,3.075,0,4.461v4.286
	c0.967-0.384,1.926-0.813,2.877-1.288c0.809,0.44,1.676,0.728,2.579,0.884c-1.791,1.017-3.61,1.862-5.456,2.548v6.258
	c3.174-0.116,6.348-0.087,9.5-0.261v-8.597c0.673-0.116,1.342-0.278,2-0.479v8.933c2.54-0.227,5.06-0.661,7.551-1.455
	c0,0.704,0,1.408,0,2.112c-2.488,0.743-5.01,1.135-7.551,1.35v9.107c-0.341,0.639-0.661,1.293-0.953,1.967
	c-0.121,0.004-0.242-0.005-0.357-0.038c-2.941,1.902-6.528,2.828-10.19,3.282v10.302c0,0.276-0.103,0.48-0.247,0.638
	c-0.457,0.049-0.915,0.091-1.368,0.129c-0.224-0.159-0.386-0.408-0.386-0.767v-10.101c-2.497,0.206-4.979,0.245-7.262,0.272
	c-1.08,0.013-2.159,0.01-3.238-0.002v10.331c0,0.438-0.238,0.716-0.543,0.856c-0.294,0.004-0.587,0.017-0.881,0.019
	c-0.322-0.134-0.576-0.419-0.576-0.875V349.01c-2.185-0.05-4.368-0.137-6.551-0.229c0-0.653,0-1.306,0-1.959
	c2.183,0.099,4.366,0.188,6.551,0.227v-10.705c-2.181,0.237-4.365,0.41-6.551,0.552c0-0.704,0-1.407,0-2.111
	c2.184-0.15,4.366-0.325,6.551-0.551v-5.154c-2.169,0.08-4.355,0.045-6.551-0.059c0-0.644,0-1.287,0-1.931
	c2.197,0.102,4.384,0.13,6.551,0.04v-14.052c-2.182,0.189-4.366,0.329-6.551,0.444c0-0.66,0-1.32,0-1.98
	c2.186-0.115,4.37-0.256,6.551-0.448v-9.973c-2.186-0.134-4.369-0.304-6.551-0.475c0-0.667,0-1.333,0-2
	c2.183,0.171,4.366,0.341,6.551,0.475v-11.24h-6.551c0-0.667,0-1.333,0-2H301v-6.588c-1.862-0.86-4.312-1.004-6.551-0.98
	c0-0.637,0-1.274,0-1.911c0.037,0.001,0.074,0,0.111,0.002c2.069,0.084,4.388,0.168,6.44,0.839V266.66
	c-2.172,0.109-4.364,0.065-6.551-0.097c0-0.667,0-1.335,0-2.002c2.188,0.169,4.38,0.216,6.551,0.102v-7.805
	C298.803,256.778,296.617,256.6,294.449,256.219z M315.5,346.608c3.454-0.462,6.769-1.398,9.5-3.26v-8.952
	c-3.153,0.169-6.329,0.148-9.5,0.269V346.608z M303,334.004c2.054-0.235,4.11-0.495,6.173-0.809c1.439-0.219,2.882-0.351,4.327-0.44
	v-5.671c-3.43,1.064-6.939,1.639-10.5,1.887V334.004z M303,347.064c0.211,0.001,0.421,0.006,0.632,0.005
	c3.055-0.008,6.483,0.055,9.868-0.237v-12.071c-1.75,0.108-3.496,0.283-5.234,0.581c-1.753,0.301-3.509,0.544-5.266,0.763V347.064z
	 M281.779,262.196c-0.093,0.143-0.324,0.312-0.768,0.503c-0.618,0.266-1.24,0.524-1.863,0.773c1.499,0.491,3.033,0.94,4.595,1.334
	c-0.114,1.41-0.2,2.821-0.231,4.236c0.682,0.004,1.352-0.02,2.002-0.075c0.029-1.235,0.097-2.467,0.191-3.697
	c1.922,0.425,3.877,0.764,5.846,1.009c0-0.671,0-1.342,0-2.013c-1.912-0.248-3.811-0.587-5.677-1.008
	c0.184-2.041,0.386-4.081,0.512-6.126c-0.677-0.776-1.31-1.555-1.9-2.342c-0.061,2.674-0.325,5.334-0.568,7.995
	C283.198,262.601,282.486,262.402,281.779,262.196z M302.314,252.963c-0.113-0.033-0.23-0.049-0.349-0.046
	c0.059,0.07,0.122,0.136,0.18,0.207C302.201,253.071,302.258,253.017,302.314,252.963z M291.551,346.692c0,0.656,0,1.313,0,1.969
	c-2.183-0.093-4.367-0.182-6.551-0.234v9.452c0,0.313-0.121,0.545-0.298,0.706c-0.525-1.972-1.088-3.938-1.702-5.89v-4.3
	c-0.333-0.004-0.667-0.014-1-0.016c-0.215-0.001-0.38-0.071-0.524-0.165c-0.134-0.365-0.263-0.732-0.401-1.095
	c0.092-0.401,0.392-0.744,0.925-0.74c0.333,0.002,0.667,0.012,1,0.017v-9.065c-2.238,0.032-4.479,0.041-6.722,0.044
	c-0.462-0.616-0.953-1.184-1.47-1.708c0.161-0.17,0.388-0.288,0.692-0.288c2.514,0,5.011-0.029,7.5-0.083v-7.288
	c-2.808-0.32-5.613-0.658-8.406-0.944c-0.297,2.608-0.515,5.223-0.579,7.854c-0.616-0.543-1.264-1.037-1.949-1.476
	c0.104-2.198,0.313-4.386,0.574-6.568c-2.091-0.193-4.171-0.345-6.237-0.428c0.041,1.537,0.067,3.076,0.082,4.614
	c-0.651-0.173-1.319-0.326-2.008-0.455c-0.018-1.406-0.038-2.812-0.077-4.217c-0.122-0.002-0.244-0.006-0.366-0.008
	c0.07-0.685,0.089-1.352,0.054-1.999c0.083,0.002,0.166,0.004,0.248,0.006c-0.105-3.292-0.231-6.585-0.296-9.879
	c0.385-0.404,0.756-0.825,1.104-1.277c0.335-0.437,0.642-0.89,0.938-1.349c2.838,0.006,5.679,0.022,8.519,0.03
	c0.233-2.335,0.391-4.677,0.397-7.033c0.004-1.324,0.035-2.648,0.052-3.973c0.917-2.647,1.065-5.646,0.136-7.759
	c0.042-1.756,0.082-3.512,0.124-5.268h-4.79c-0.017-0.672-0.036-1.335-0.058-2h4.894c0.067-2.992,0.122-5.984,0.137-8.977
	c0.673-0.131,1.341-0.292,2.005-0.475c-0.011,3.15-0.058,6.301-0.115,9.451H283v-6.733c-0.412,0.065-0.824,0.121-1.234,0.198
	c-1.26,0.236-1.798-1.691-0.531-1.929c0.583-0.109,1.177-0.189,1.766-0.283v-2.813c0.671-0.331,1.331-0.681,1.977-1.053
	c0.004,0.04,0.023,0.069,0.023,0.112v3.458c2.172-0.272,4.367-0.426,6.551-0.462c0,0.673,0,1.346,0,2.019
	c-0.042,0.001-0.091,0.003-0.133,0.005c-2.145,0.072-4.288,0.213-6.418,0.466v7.014h6.551c0,0.667,0,1.333,0,2H285v10.111
	c2.186,0.109,4.368,0.267,6.551,0.434c0,0.667,0,1.333,0,2c-2.183-0.167-4.366-0.325-6.551-0.434v11.872
	c2.185-0.035,4.369-0.1,6.551-0.187c0,0.66,0,1.32,0,1.98c-2.182,0.088-4.366,0.153-6.551,0.19v12.446
	c2.187,0.245,4.373,0.462,6.551,0.623c0,0.643,0,1.287,0,1.929c-2.179-0.16-4.364-0.374-6.551-0.613v7.013
	c2.189-0.063,4.371-0.16,6.551-0.281c0,0.699,0,1.398,0,2.098c-2.182,0.107-4.365,0.187-6.551,0.231v9.141
	C287.185,346.491,289.368,346.591,291.551,346.692z M274.386,313.907c-2.781-0.006-5.562-0.02-8.343-0.025
	c0.062,3.522,0.194,7.042,0.302,10.563c2.163,0.088,4.345,0.254,6.542,0.468C273.37,321.242,273.954,317.581,274.386,313.907z
	 M283,313.874c-2.224,0.026-4.449,0.036-6.674,0.035c-0.444,3.74-1.023,7.466-1.496,11.203c2.711,0.291,5.439,0.63,8.169,0.951
	V313.874z M283,299.912c-1.965-0.068-3.932-0.092-5.902-0.04c-0.034,1.668-0.071,3.338-0.098,5.006
	c-0.038,2.357-0.212,4.701-0.453,7.038c2.151,0.002,4.303-0.007,6.453-0.03V299.912z M283,287.879h-5.653
	c-0.066,3.331-0.14,6.662-0.21,9.993c1.958-0.051,3.912-0.028,5.863,0.04V287.879z M278.778,261.385
	c0.035-0.004,0.07-0.013,0.105-0.017C278.846,261.37,278.813,261.378,278.778,261.385z M154.16,276.739
	c-0.012-0.001-0.024,0-0.036-0.001c-1.638,0.412-3.383,0.57-5.121,0.643c0.035,3.455,0.249,7.272,0.333,11.173l0.039,1.033
	l1.201,0.898c1.611,0.231,2.695,1.167,3.33,2.49l0.23,0.172C154.421,287.787,154.708,282.098,154.16,276.739z M140.926,313.319
	L140.926,313.319c0.864-0.99,1.598-2.082,2.223-3.252C142.444,310.933,141.694,311.994,140.926,313.319z M135.912,318.758
	c0.718,3.098,3.2,10.687,7.579,7.337c3.167-2.423,5.174-6.634,6.642-10.271c1.54-3.815,2.57-7.631,3.167-11.565
	c-0.481,0.296-1.09,0.671-1.883,1.159c-3.584,2.207-3.999,1.126-3.999,1.126c-0.809,2.417-1.926,4.677-3.47,6.685
	C141.853,315.955,139.06,317.806,135.912,318.758z M142.683,328.309c0.589,0.062,1.073-0.098,1.512-0.435
	c-0.423,0.171-0.861,0.308-1.314,0.404C142.812,328.293,142.75,328.296,142.683,328.309z M163.313,242.311
	c0.311,2.723,1.212,5.138,2.592,7.167c0.865,2.022,2.277,3.715,4.556,4.284c2.093,1.254,4.565,2.072,7.351,2.342
	c6.277,0.609,13.253-2.397,15.146-8.458c1.535,0.663,3.391,0.925,4.775,1.218c1.855,0.393,3.729,0.713,5.596,1.056l2.316,0.96
	l4.009,0.461c1.054,0.303,2.098,0.642,3.124,1.055c-3.252,1.939-6.183,4.59-8.583,7.896c-6.683,9.21-6.892,20.909-1.43,30.033
	c-12.436,0-24.872,0-37.308,0c-0.095-15.546-1.617-31.244-9.94-44.752C158.197,244.676,160.935,243.656,163.313,242.311z
	 M191.512,254.442c3.125,5.105,2.914,11.053,2.898,16.825c-0.002,0.876,2.895,0.335,2.897-0.776
	c0.017-5.989,0.126-11.899-3.102-17.175C193.715,252.516,190.894,253.433,191.512,254.442z M202.56,282.106
	c-0.172-2.047-0.382-4.556-0.64-7.628C201.754,277.077,201.968,279.636,202.56,282.106z M148.539,364.385h-0.197
	c0.146,0.636,0.292,1.271,0.446,1.906L148.539,364.385z M355.834,349.796c1.107,0.491,2.522,0.648,4.312,0.333
	c0.06-0.01,0.115-0.029,0.174-0.04C358.86,350.152,357.346,350.045,355.834,349.796z M123.235,325.463
	c-0.15,0.994-0.151,2.073-0.001,3.212c0.376,0.114,0.619,0.377,0.536,0.824c-2.437,13.023-16.299,4.978-21.589-3.841
	c-0.27-0.068-0.436-0.215-0.387-0.462c0.01-0.053,0.021-0.109,0.032-0.163c-0.66-1.198-1.153-2.399-1.431-3.558
	c-2.913,1.588-5.785,3.261-8.614,5.005l0.912,1.071c0,0,4.951,11.139,11.726,14.704c6.775,3.565,18.781,3.364,24.296-2.453
	c3.631-3.83,4.375-8.137,4.463-10.64c-2.843-1.592-5.807-3.062-8.744-3.938L123.235,325.463z M536.359,394.708
	c-0.063-0.178-0.145-0.346-0.211-0.522c-7.362,0.005-14.842,0.091-21.959,1.916h6.141c4.87-0.6,9.851-0.573,14.748-0.575
	c0.417,0,1.168-0.297,1.642-0.611C536.533,394.889,536.401,394.825,536.359,394.708z M84.84,364.385c0,5.655,0,11.311,0,16.966
	h64.769c-1.316-5.671-3.047-11.284-4.324-16.966H84.84z M439.02,396.101h4.818c-3.037-1.508-5.598-3.846-8.931-4.776
	c-0.394-0.11-1.269,0.142-1.915,0.458c0.066,0.361,0.126,0.723,0.191,1.084C435.289,393.607,437.129,394.897,439.02,396.101z
	 M535.523,386.038c0.12,0.214,0.24,0.428,0.358,0.643C536.208,386.381,536.227,386.099,535.523,386.038z M282.765,363.068
	c2.553,10.159,5.022,21.098,5.165,31.633h-12.313c0.006-0.041,0.017-0.08,0.016-0.123c-0.287-14.16-6.922-27.352-7.874-41.428
	c-0.084-1.255-2.989-0.575-2.897,0.776c0.937,13.852,7.518,26.858,7.837,40.775h-66.673c0.003-0.032,0.013-0.062,0.013-0.094
	c0-12.052,0-24.105,0-36.157c0-1.585-2.944-1.197-2.944,0.188c0,12.021,0,24.042,0,36.063h-22.979
	c0.007-0.042,0.02-0.082,0.02-0.125c0.119-10.186,2.172-20.443,3.723-30.481c0.718-4.646,1.465-9.289,2.058-13.952
	c1.123-8.833,7.937-14.036,15.323-18.027c0.012,0.014,0.025,0.029,0.037,0.043c4.692,5.513,10.009,10.868,15.832,15.197
	c0.645,0.48,3.147-0.675,2.731-0.984c-9.02-6.707-18.23-15.956-23.409-25.973c-5.809-11.236,5.297-14.194,15.314-14.961
	c1.301-0.032,2.577-0.021,3.785,0.032c1.037,0.246,2.103,0.36,3.107,0.418c0.04,0.033,0.094,0.064,0.178,0.086
	c6.954,1.854,5.72,11.135,4.576,17.181c-2.101-2.495-3.78-5.327-3.939-8.12c-0.085-1.487-3-0.994-2.931,0.241
	c0.544,9.571,25.212,30.877,28.449,14.222c0.087-0.448-0.157-0.71-0.536-0.823c-0.682-5.17,1.65-9.161,7.66-9.167
	c1.724-0.001,3.39-0.266,4.972-0.739c-0.04,0.165,0.039,0.321,0.309,0.442c8.627,3.883,0.519,17.849-2.841,22.995
	c-0.758,1.16,2.169,0.919,2.707,0.096c1.518-2.325,3.751-6.061,5.257-9.973c7.729,1.276,11.498,5.692,14.517,12.664
	C279.524,350.793,281.228,356.953,282.765,363.068z M224.312,347.23c-3.214,11.183-1.294,22.954-3.974,34.219
	c-0.348,1.462,2.625,1.135,2.942-0.197c2.632-11.064,0.661-22.673,3.814-33.645C227.568,345.959,224.684,345.935,224.312,347.23z
	 M262.195,372.619c-2.512-7.816-6.902-15.579-7.231-23.919c-0.026-0.658-2.934-0.146-2.897,0.776
	c0.329,8.34,4.719,16.103,7.231,23.919C259.555,374.194,262.434,373.362,262.195,372.619z M490.332,395.702
	c-0.39,0.139-0.78,0.268-1.17,0.398h3.906c2.848-1.04,5.674-2.132,8.498-3.14c9.62-3.431,20.959-5.479,31.285-5.483
	c-0.275-0.477-0.553-0.952-0.833-1.427C517.555,386.456,503.864,390.874,490.332,395.702z M375.436,331.887
	c1.053-0.029,2.104-0.035,3.153-0.018c-1.343-0.193-2.485-0.348-3.249-0.449C375.373,331.582,375.405,331.736,375.436,331.887z
	 M465.734,380.586c9.055-0.226,17.209-1.468,25.823-4.418c11.126-3.812,21.616-7.473,33.441-7.889
	c0.019-0.175,0.058-0.347,0.09-0.52c-0.054-0.378-0.106-0.757-0.155-1.136c-7.692,0.347-15.097,1.727-22.474,4.105
	c-8.884,2.863-17.434,6.523-26.752,7.788c-9.029,1.226-20.378,1.437-29.194-0.891c-5.311-1.402-14.513-3.235-17.074-8.819
	c-0.149-0.325-0.537-0.457-0.989-0.459c0.272,1.621,0.56,3.239,0.867,4.854c3.099,2.475,7.542,3.734,11.01,4.833
	C448.729,380.701,456.973,380.805,465.734,380.586z M393.081,336.353c-0.688-0.266-1.38-0.51-2.078-0.734
	c-0.517-0.166-1.039-0.31-1.561-0.454c-0.181-0.05-0.36-0.11-0.542-0.157c-0.34,0.024-0.621-0.054-0.833-0.195
	c-0.002,0-0.003,0-0.005-0.001c-2.001-0.478-4.032-0.809-6.08-1.014c-0.013-0.001-0.026-0.002-0.039-0.003
	c-0.911-0.091-1.826-0.148-2.743-0.191c-0.201-0.009-0.402-0.019-0.604-0.026c-0.94-0.033-1.882-0.05-2.826-0.038
	c0.021,0.227-0.141,0.438-0.402,0.615c-0.359,8.485-6.509,15.811-15.052,17.314c-3.03,0.534-7.115,0.468-9.266-2.15
	c-2.822-3.434-1.793-8.064-0.419-11.895c0.072-0.2,0.274-0.371,0.536-0.511c0.25-0.898,0.479-1.922,0.635-2.981
	c-0.248,0.183-0.571,0.307-0.959,0.311c-2.677,0.031-5.45,0.036-8.112,0.487c-1.427,6.489-2.282,13.018-4.812,19.217
	c-3.14,7.694-6.133,13.874-2.944,22.113c2.314,5.981,5.673,11.859,6.68,18.252c0.138,0.877-0.894,1.491-1.767,1.518v0.27h55.049
	c-4.71-12.146-10.324-25.149-10.054-38.412C385.021,350.869,386.47,340.285,393.081,336.353z M459.419,357.731
	c8.651,0.861,17.1-0.27,25.459-2.522c11.64-3.138,22.496-8.396,34.668-9.243c0.838-0.059,1.535-0.623,1.603-1.077
	c-0.052-0.131-0.104-0.262-0.158-0.392c-0.177-0.136-0.502-0.212-1.022-0.176c-19.483,1.356-36.439,12.863-56.354,11.942
	c-10.843-0.502-28.964-3.069-34.144-14.348c-0.857,0.701-1.48,1.689-1.922,2.862C433.683,354.368,449.262,356.721,459.419,357.731z
	 M426.412,342.667c0.312-0.419,0.664-0.806,1.052-1.162c-0.406,0.268-0.801,0.56-1.187,0.872
	C426.319,342.477,426.368,342.57,426.412,342.667z M526.926,375.926c-12.86-1.096-23.924,5.161-36.063,9.687
	c-10.542,3.931-22.088,4.174-33.196,3.855c-5.465-0.157-10.713-0.528-15.712-2.917c-3.749-1.79-6.62-4.885-10.466-6.541
	c-0.2-0.086-0.501-0.082-0.831-0.022c0.172,0.843,0.344,1.687,0.516,2.529c2.884,1.649,5.439,3.84,8.357,5.467
	c3.415,1.903,7.426,2.48,11.254,2.848c8.912,0.854,18.129,0.595,27.001-0.552c17.141-2.215,32.411-15.161,49.871-12.452
	c-0.165-0.394-0.344-0.779-0.492-1.181C527.078,376.407,527.007,376.166,526.926,375.926z M491.524,366.097
	c10.464-3.457,19.977-10.104,31.255-10.104c0.346,0,0.642-0.09,0.882-0.232c-0.089-0.688-0.187-1.381-0.295-2.081
	c-0.107-0.017-0.223-0.026-0.35-0.026c-8.824,0-15.876,3.589-23.779,7.118c-9.366,4.185-19.686,6.954-29.928,7.54
	c-14.17,0.81-33.035-2.376-42.097-14.435c-0.148-0.196-0.355-0.32-0.588-0.392c0.077,1.675,0.237,3.295,0.4,4.697
	c9.297,9.39,24.382,12.088,37.023,12.518C473.445,371.021,482.647,369.028,491.524,366.097z"
    ></path>
    <path
      id="color_x5F_1"
      d="M451.585,283.404c-0.567,0.302-1.406,0.55-2.04,0.315c-0.637-0.235-0.03-0.788,0.299-0.962
	c3.202-1.703,6.807-2.038,10.198-0.686c1.069,0.426-1.581,1.58-2.34,1.277C455.646,282.529,453.592,282.336,451.585,283.404z
	 M132.713,289.507c0.036,1.413,0.885,14.244-0.56,14.068c-1.371-0.167-2.981,1.787-1.024,2.025
	c7.096,0.865,4.611-12.486,4.513-16.34C135.604,287.784,132.682,288.273,132.713,289.507z M246.777,306.917
	c-1.974-0.697-3.455-2.06-4.542-3.83c-0.526-0.856-3.259,0.188-2.897,0.776c1.234,2.009,2.85,3.534,5.1,4.329
	c0.637,0.225,1.472-0.006,2.04-0.316C246.793,307.704,247.424,307.145,246.777,306.917z M122.336,291.406c0-1.607,0-3.214,0-4.821
	c0-0.729-2.897-0.232-2.897,0.776c0,1.607,0,3.214,0,4.821C119.438,292.911,122.336,292.414,122.336,291.406z M127.248,279.104
	c-3.125-1.662-6.64-1.495-9.868-0.209c-0.249,0.099-1.276,0.603-0.814,1.011c0.456,0.403,1.415,0.134,1.895-0.057
	c2.22-0.884,4.375-0.569,6.447,0.533C125.627,280.763,128.313,279.669,127.248,279.104z M432.949,289.586c0,1.607,0,3.213,0,4.82
	c0,0.729,2.897,0.232,2.897-0.776c0-1.607,0-3.213,0-4.82C435.846,288.082,432.949,288.578,432.949,289.586z M459.743,309.571
	c0.467,0.412,1.401,0.115,1.895-0.059c2.398-0.845,4.288-2.428,5.613-4.586c0.632-1.029-2.239-0.626-2.664,0.065
	c-0.988,1.608-2.215,2.932-4.029,3.57C460.303,308.651,459.286,309.168,459.743,309.571z M437.875,283.01
	c-2.657-0.441-5.178-0.731-7.767,0.182c-0.384,0.136-1.207,0.723-0.762,1.205c0.465,0.504,1.389,0.241,1.924,0.053
	c1.499-0.529,3.333-0.068,4.843,0.182c0.7,0.116,1.515-0.057,2.049-0.549C438.596,283.684,438.499,283.114,437.875,283.01z
	 M445.292,305.797c-5.601,0.907-3.178-11.25-3.126-14.312c0.025-1.47-2.908-0.998-2.929,0.246
	c-0.082,4.803-2.799,17.485,5.774,16.097C446.556,307.578,447.262,305.478,445.292,305.797z M143.844,291.406
	c0-1.607,0-3.214,0-4.821c0-0.729-2.897-0.232-2.897,0.776c0,1.607,0,3.214,0,4.821
	C140.946,292.911,143.844,292.414,143.844,291.406z M224.312,347.23c-3.214,11.183-1.294,22.954-3.974,34.219
	c-0.348,1.462,2.625,1.135,2.942-0.197c2.632-11.064,0.661-22.673,3.814-33.645C227.568,345.959,224.684,345.935,224.312,347.23z
	 M125.579,306.917c-1.974-0.697-3.455-2.06-4.542-3.83c-0.526-0.856-3.259,0.188-2.897,0.776c1.234,2.009,2.849,3.535,5.1,4.329
	c0.637,0.225,1.472-0.006,2.04-0.316C125.595,307.704,126.226,307.145,125.579,306.917z M203.093,358.638
	c0,12.021,0,24.042,0,36.063c0,0.031,0,0.063,0,0.094c0,1.547,2.798,1.211,2.932-0.094c0.003-0.032,0.013-0.062,0.013-0.094
	c0-12.052,0-24.105,0-36.157C206.038,356.865,203.093,357.252,203.093,358.638z M148.341,364.385
	c-0.848-3.705-1.524-7.438-1.781-11.235c-0.084-1.255-2.989-0.575-2.897,0.776c0.239,3.532,0.846,7.008,1.622,10.458
	c1.277,5.682,3.007,11.294,4.324,16.966c1.029,4.434,1.805,8.904,1.897,13.474c0.03,1.476,2.954,0.988,2.929-0.246
	c-0.196-9.685-3.361-18.916-5.647-28.288C148.633,365.656,148.487,365.021,148.341,364.385z M81.896,358.638
	c0,11.063,0,22.127,0,33.19c0,1.585,2.944,1.197,2.944-0.188c0-3.43,0-6.86,0-10.29c0-5.655,0-11.311,0-16.966
	c0-1.979,0-3.957,0-5.935C84.84,356.865,81.896,357.252,81.896,358.638z M254.964,348.7c-0.026-0.658-2.934-0.146-2.897,0.776
	c0.329,8.34,4.719,16.103,7.231,23.919c0.257,0.799,3.136-0.033,2.897-0.776C259.684,364.803,255.293,357.04,254.964,348.7z
	 M101.113,298.899c-1.4,0.132-1.042-2.868-1.023-3.831c0.014-0.765,0.049-1.598-0.563-2.146c-0.856-0.768-2.268-0.139-3.027,0.441
	c-1.076,0.824-0.301,1.544,0.656,1.632c0,0.049,0,0.087,0,0.144c0,0.895-0.058,1.783,0.013,2.677
	c0.171,2.128,1.478,3.398,3.683,3.19c0.635-0.06,1.508-0.437,1.598-1.166C102.539,299.121,101.679,298.846,101.113,298.899z
	 M265.042,291.406c0-1.607,0-3.214,0-4.821c0-0.729-2.897-0.232-2.897,0.776c0,1.607,0,3.214,0,4.821
	C262.144,292.911,265.042,292.414,265.042,291.406z M539.257,393.931c0.152,0.427-1.719,1.094-2.538,0.983
	c-0.186-0.025-0.318-0.089-0.36-0.207c-0.063-0.178-0.145-0.346-0.211-0.522c-0.887-2.344-2.051-4.547-3.297-6.708
	c-0.275-0.477-0.553-0.952-0.833-1.427c-1.58-2.685-3.164-5.362-4.36-8.224c-0.165-0.394-0.344-0.779-0.492-1.181
	c-0.088-0.239-0.159-0.481-0.24-0.721c-0.908-2.667-1.446-5.4-1.838-8.166c-0.054-0.378-0.106-0.757-0.155-1.136
	c-0.221-1.708-0.407-3.426-0.606-5.145c-0.204-1.755-0.403-3.704-0.666-5.718c-0.089-0.688-0.187-1.381-0.295-2.081
	c-0.462-2.992-1.124-6.042-2.216-8.792c-0.052-0.131-0.104-0.262-0.158-0.392c-1.235-2.963-3.009-5.529-5.601-7.249
	c-20.918-2.343-37.776,18.634-59.079,13.311c-5.82-1.454-11.292-3.759-16.839-6.004c-2.226-0.901-4.683-2.254-6.382-4.171
	c-0.28,0.219-0.659,0.4-1.066,0.452c-1.024,0.132-1.861,0.515-2.552,1.079c-0.857,0.701-1.48,1.689-1.922,2.862
	c-0.92,2.44-1.064,5.664-0.925,8.71c0.077,1.675,0.237,3.295,0.4,4.697c0.133,1.14,0.267,2.137,0.358,2.903
	c0.29,2.433,0.661,4.851,1.066,7.262c0.272,1.621,0.56,3.239,0.867,4.854c0.43,2.264,0.881,4.525,1.342,6.785
	c0.172,0.843,0.344,1.687,0.516,2.529c0.63,3.084,1.25,6.169,1.817,9.265c0.066,0.361,0.126,0.723,0.191,1.084
	c0.155,0.869,0.315,1.737,0.459,2.608c0.169,1.021-2.744,1.706-2.897,0.776c-2.273-13.753-6.529-27.487-6.88-41.501
	c-0.076-3.048,0.089-6.329,1.036-9.093c-4.852,3.605-9.803,7.077-14.59,10.77c-0.097,0.075-0.265,0.145-0.468,0.208
	c0.318,1.599,0.587,3.216,0.818,4.839c1.625,11.454,4.701,22.69,5.661,34.239c0.062,0.736-2.858,1.253-2.897,0.776
	c-0.96-11.551-4.054-22.781-5.661-34.239c-1.376-9.81-3.97-19.542-12.722-25.065c-1.765,0.909-3.131,2.395-4.176,4.243
	c-0.051,0.091-0.103,0.181-0.152,0.273c-3.663,6.802-3.185,18.146-2.255,23.62c1.79,10.54,5.689,20.466,9.528,30.393
	c0.377,0.976-2.489,1.833-2.897,0.776c-0.05-0.129-0.101-0.259-0.151-0.388c-4.71-12.146-10.324-25.149-10.054-38.412
	c0.139-6.82,1.588-17.404,8.199-21.336c-0.688-0.266-1.38-0.51-2.078-0.734c-0.517-0.166-1.039-0.31-1.561-0.454
	c-0.181-0.05-0.359-0.11-0.541-0.157c0,0-0.001,0-0.001,0c-0.34,0.024-0.621-0.054-0.833-0.195c0,0,0,0,0,0
	c-0.001,0-0.003,0-0.005-0.001c-2.001-0.478-4.032-0.809-6.08-1.014c-0.013-0.001-0.026-0.002-0.039-0.003
	c-0.911-0.089-1.826-0.15-2.743-0.191c-0.201-0.009-0.402-0.019-0.604-0.026c-0.94-0.032-1.882-0.05-2.826-0.038
	c0.021,0.227-0.141,0.438-0.402,0.615c-0.359,8.485-6.509,15.811-15.052,17.314c-3.03,0.534-7.115,0.468-9.266-2.15
	c-2.822-3.434-1.793-8.064-0.419-11.895c0.072-0.2,0.274-0.371,0.536-0.511c0.25-0.898,0.479-1.922,0.635-2.981
	c-0.248,0.183-0.571,0.307-0.959,0.311c-2.677,0.031-5.45,0.036-8.112,0.487c-1.427,6.489-2.282,13.018-4.812,19.217
	c-3.14,7.694-6.133,13.874-2.944,22.113c2.314,5.981,5.673,11.859,6.68,18.252c0.138,0.877-0.894,1.491-1.767,1.518
	c-0.555,0.018-1.045-0.199-1.131-0.742c-1.088-6.915-4.988-12.994-7.158-19.574c-2.112-6.403-0.911-11.528,1.71-17.61
	c3.244-7.525,4.592-14.599,6.345-22.403c-14.354,2.514-14.379,23.189-13.665,34.172c0.539,8.292,2.336,16.578,2.473,24.881
	c0.018,1.117-2.894,1.671-2.917,0.292c-0.193-11.771-3.275-23.269-2.581-35.137c0.017-0.284,0.037-0.569,0.058-0.855
	c-2.885,1.829-9.082,2.086-11.079,2.297c-8.881,0.94-17.869,0.922-26.695-0.499c2.815,10.936,5.475,22.582,5.606,33.902
	c0.013,1.119-2.901,1.67-2.917,0.292c0-0.048-0.005-0.098-0.005-0.146c-0.143-10.535-2.612-21.474-5.165-31.633
	c-1.537-6.116-3.241-12.275-5.752-18.074c-3.019-6.972-6.788-11.388-14.517-12.664c-1.505,3.912-3.739,7.647-5.257,9.973
	c-0.538,0.823-3.465,1.064-2.707-0.096c3.359-5.146,11.468-19.111,2.841-22.995c-0.27-0.122-0.348-0.278-0.309-0.442
	c-1.583,0.474-3.248,0.738-4.972,0.739c-6.01,0.006-8.342,3.997-7.66,9.167c0.379,0.112,0.623,0.375,0.536,0.823
	c-3.236,16.656-27.904-4.651-28.449-14.222c-0.07-1.235,2.846-1.728,2.931-0.241c0.159,2.793,1.838,5.625,3.939,8.12
	c1.145-6.046,2.378-15.328-4.576-17.181c-0.084-0.022-0.138-0.053-0.178-0.086c-1.004-0.058-2.07-0.173-3.107-0.418
	c-0.243-0.058-0.479-0.136-0.717-0.208c-0.998,0.039-2.027,0.097-3.068,0.177c-10.017,0.767-21.123,3.725-15.314,14.961
	c5.178,10.017,14.389,19.266,23.409,25.973c0.416,0.309-2.086,1.464-2.731,0.984c-5.823-4.33-11.14-9.685-15.832-15.197
	c-0.012-0.014-0.025-0.029-0.037-0.043c-7.387,3.992-14.201,9.195-15.323,18.027c-0.592,4.663-1.34,9.307-2.058,13.952
	c-1.551,10.038-3.604,20.295-3.723,30.481c0,0.043-0.013,0.084-0.02,0.125c-0.206,1.134-2.924,1.535-2.908,0.125
	c0.143-12.223,2.838-24.53,4.662-36.579c0.254-1.681,0.415-3.482,0.622-5.298c-23.737,2.369-17.11-29.592-16.938-44.349
	c0.001-0.102,0.037-0.197,0.088-0.288c0.057-0.544,0.631-1.126,1.499-1.128c9.865-0.019,19.724-0.396,29.589-0.415
	c0.507-0.001,0.84,0.136,1.031,0.338c3.977-2.112,9.395-2.852,14.498-3.083c-0.604-0.456-1.127-1.015-1.522-1.712
	c-0.833-1.468-1.049-3.162-0.82-4.811c-0.46,0.084-0.897,0.065-1.135-0.135c-1.974-1.659-3.656-3.532-5.049-5.551
	c-0.314,0.17-0.655,0.284-0.952,0.284c-12.753,0-25.505,0-38.258,0c-0.554,0.197-1.215,0.196-1.618-0.074
	c-0.217-0.075-0.303-0.203-0.296-0.356c-0.041-0.099-0.069-0.206-0.07-0.333c-0.05-15.539-1.434-31.354-9.866-44.833
	c-0.958,0.311-1.89,0.616-2.766,0.926c-2.453,0.865-4.948,1.807-7.471,2.68c6.058,4.797,10.725,11.177,12.7,18.532
	c0.318,0.066,0.565,0.229,0.649,0.516c1.696,5.8,1.238,12.658,1.219,18.636c-0.022,7.122,0.237,14.675-1.817,21.572
	c-1.656,5.558-4.677,15.955-10.99,18.509c-0.423,0.171-0.861,0.308-1.314,0.404c-0.068,0.015-0.13,0.018-0.198,0.031
	c-5.881,1.133-8.604-3.796-9.702-8.93c-0.686,0.081-1.381,0.13-2.086,0.13c-3.462,0.003-5.694,1.336-6.844,3.446
	c6.104,1.341,12.239,5.25,17.508,8.239c5.829,3.307,12.296,6.475,15.435,12.653c4.659,9.168,7.418,19.337,9.539,29.36
	c1.504,7.107,3.031,14.459,3.123,21.753c0.014,1.119-2.899,1.67-2.917,0.292c-0.094-7.529-1.735-15.236-3.382-22.549
	c-1.989-8.829-4.305-17.869-8.256-26.054c-3.22-6.671-8.478-9.759-14.804-13.348c-2.21-1.254-4.617-2.742-7.117-4.141
	c-2.843-1.592-5.807-3.062-8.744-3.938c-0.371-0.111-0.741-0.21-1.11-0.301c-0.038,0.175-0.061,0.358-0.088,0.54
	c-0.15,0.994-0.151,2.073-0.001,3.212c0.376,0.114,0.619,0.377,0.536,0.824c-2.437,13.023-16.299,4.978-21.589-3.841
	c-0.27-0.068-0.436-0.215-0.387-0.462c0.01-0.053,0.021-0.109,0.032-0.163c-0.66-1.198-1.153-2.399-1.431-3.558
	c-2.913,1.588-5.785,3.261-8.614,5.005c-2.374,1.464-4.727,2.962-7.042,4.504c-6.224,4.146-13.348,8.572-17.979,14.565
	c-2.058,2.663-2.104,5.324-2.605,8.555c-0.639,4.119-1.344,8.227-2.029,12.338c-1.381,8.288-3.08,16.738-3.189,25.167
	c-0.016,1.23-2.947,1.711-2.928,0.251c0.121-9.315,2.097-18.668,3.635-27.826c1.012-6.028,0.609-14.333,4.712-19.26
	c5.051-6.064,11.952-10.713,18.488-15.015c5.687-3.744,11.506-7.34,17.515-10.551c0.545-0.623,1.949-0.812,2.489-0.205
	c0.643-5.475,0.256-11.566-5.232-13.029c-0.084-0.022-0.138-0.053-0.178-0.086c-2.896-0.168-6.354-0.846-7.902-3.575
	c-0.833-1.468-1.049-3.163-0.82-4.811c-0.46,0.084-0.897,0.065-1.135-0.135c-11.198-9.421-13.408-24.88-6.266-37.554
	c5.076-9.007,14.746-16.702,25.537-14.31c11.147-4.376,23.815-3.84,33.811,3.271c0.053,0.038,0.104,0.079,0.157,0.117
	c2.68-0.908,5.326-1.936,7.888-2.851c4.321-1.543,10.544-2.944,14.476-5.825c0-0.004,0-0.007,0-0.011
	c-0.006-0.029-0.005-0.062-0.003-0.094c0-0.038-0.004-0.074-0.004-0.112c0.002-0.386,0.281-0.696,0.665-0.899
	c2.174-2.765,2.484-7.456,2.778-10.742c0.283-3.167,0.423-6.346,0.387-9.526c-3.843,1.267-9.291-1.361-9.869-5.487
	c-0.06-0.426-0.077-0.852-0.06-1.274c-1.309-1.862-2.332-4.091-3.09-6.503c-0.065,0.025-0.134,0.046-0.198,0.073
	c-0.526,0.222-1.482,0.428-1.931-0.076c-0.453-0.509,0.355-1.08,0.75-1.247c0.299-0.126,0.609-0.237,0.921-0.344
	c-0.277-1.075-0.508-2.174-0.684-3.292c-0.152,0.001-0.302-0.007-0.455-0.002c-1.404,0.044-0.571-0.644,0.314-1.035
	c-0.162-1.254-0.261-2.517-0.298-3.776c-1.074,0.23-2.557,0-1.564-0.852c0.482-0.414,1.006-0.689,1.553-0.867
	c0.013-0.823,0.043-1.641,0.109-2.445c-0.993,0.322-2.357,0.232-1.844-0.617c0.466-0.77,1.204-1.124,2.045-1.233
	c0.075-0.544,0.163-1.08,0.263-1.606c-1.057,0.112-2.422-0.206-1.045-0.894c0.445-0.223,0.9-0.398,1.361-0.536
	c0.119-0.486,0.251-0.958,0.394-1.42c-0.298,0.028-0.597,0.057-0.899,0.101c-2.019,0.293-0.866-1.225,0.446-1.415
	c0.316-0.046,0.628-0.076,0.941-0.109c0.12-0.321,0.242-0.641,0.373-0.953c-0.985,0.169-2.317,0.082-0.992-0.617
	c0.523-0.276,1.057-0.515,1.597-0.731c0.272-0.558,0.565-1.097,0.874-1.625c-0.98-0.147-1.735-0.948-0.629-1.721
	c0.897-0.626,1.843-1.098,2.818-1.438c0.421-0.521,0.86-1.025,1.319-1.51c-1.36,0.151-1.605-1.497-0.055-2.048
	c0.637-0.227,1.283-0.38,1.931-0.5c-4.172-0.109-8.562-0.524-12.472,0.943c-4.52,1.696-8.966,3.703-12.568,6.983
	c-1.129,1.028-3.401-0.107-1.95-1.429c3.608-3.286,8.069-5.455,12.609-7.158c4.158-1.561,8.287-1.603,12.537-1.568
	c-7.907-3.335-17.173-2.478-24.866,1.319c-0.9,0.444-3.52,0.083-1.776-0.778c8.708-4.297,19.437-5.805,28.539-2.083
	c-7.215-6.659-19.409-8.527-28.371-6.418c-1.797,0.423-2.353-1.586-0.539-2.013c10.187-2.397,23.349-0.517,31.4,6.973
	c-1.013-2.843-4.725-5.423-6.719-6.882c-5.096-3.728-10.362-6.844-16.892-6.892c-1.946-0.015,0.261-1.413,1.19-1.406
	c6.674,0.05,12.256,2.734,17.562,6.615c1.785,1.306,4.826,3.334,6.613,5.719c0.36-7.457-7.688-15.373-14.778-15.71
	c-1.96-0.093-0.426-2.202,0.996-2.134c6.927,0.33,14.593,6.796,16.381,13.949c1.159-5.508,0.397-11.933-3.351-14.82
	c-1.318-1.016,1.223-2.446,2.235-1.666c6.623,5.101,5.44,18.829,0.236,24.634c-0.056,0.106-0.104,0.214-0.164,0.32
	c-0.08,0.141-0.196,0.263-0.337,0.366c-0.003,0.011-0.003,0.022-0.007,0.033c-0.003,0.011-0.012,0.021-0.017,0.031
	c0.021,0.001,0.041,0.003,0.062,0.005c1.091-0.588,2.222-1.122,3.389-1.601c-0.227-0.02-0.453-0.036-0.678-0.042
	c-1.391-0.037,1.341-1.116,1.901-1.101c0.53,0.014,1.056,0.056,1.579,0.12c1.018-0.322,2.088-0.595,3.202-0.818
	c-0.715-0.163-1.438-0.303-2.176-0.386c-0.715-0.081,1.724-1.161,2.391-1.085c1.376,0.156,2.709,0.464,4.004,0.876
	c1.704-0.145,3.461-0.187,5.243-0.125c-0.872-0.361-1.765-0.654-2.674-0.871c-1.589-0.379,0.993-1.64,1.809-1.445
	c2.338,0.558,4.583,1.554,6.635,2.879c0.792,0.128,1.58,0.273,2.362,0.443c-0.316-0.249-0.632-0.493-0.943-0.733
	c-0.351-0.271,2.129-1.429,2.737-0.961c1.387,1.067,2.844,2.23,4.237,3.495c8.124,3.147,14.602,8.968,15.437,17.774
	c1.751,10.43,7.676,28.936-2.051,37.284c-3.948,3.388-8.386,5.197-13.317,6.642c-7.429,2.175-7.377,7.691-6.618,14.715
	c1.425,2.77,7.961,2.916,10.625,3.404c3.791,0.694,7.591,1.413,11.17,2.875c5.797-2.688,12.411-3.208,18.897-1.081
	c-0.635-3.9-3.25-6.897-6.774-8.956c-1.232-0.719,1.428-1.955,2.293-1.449c3.173,1.853,5.61,4.337,6.78,7.495
	c1.359-2.249,3.255-4.246,5.225-5.826c0.533-0.428,1.268-0.635,1.939-0.691c0.215-0.018,1.216,0.044,0.684,0.471
	c-2.365,1.898-4.572,4.355-5.853,7.171c5.174-3.292,11.082-3.424,16.997,0.189c0.785,0.479,1.592,1.043,2.416,1.656
	c0.018-0.009,0.03-0.019,0.048-0.028c2.828-1.413,5.83-2.367,8.85-3.263c0.369-0.109,0.737-0.219,1.105-0.327
	c2.962-0.87,5.925-1.73,8.742-2.954c0.03-0.013,0.059-0.027,0.089-0.041c0.815-0.356,1.619-0.741,2.405-1.169
	c-0.002-0.011-0.004-0.021-0.006-0.032c-0.074-0.449,0.373-0.828,0.936-1.052c0.439-2.979,0.907-6.76-0.503-8.901
	c-1.028-1.561-2.876-1.641-4.561-2.002c-4.689-1.006-9.723-3.152-12.71-7.089c-7.776-10.248-6.406-26.369-4.539-38.322
	c0.033-0.209,0.133-0.391,0.27-0.549c3.577-13.474,17.105-21.336,31.332-18.161c20.068,4.48,25.931,24.689,21.821,42.829
	c0.573,0.625,0.965,1.411,1.041,2.346c0.362,4.463-3.247,7.004-7.176,8.046c0,4.365,0,8.73,0,13.096
	c1.783,2.861,4.397,6.096,5.134,9.57c0.012,0.007,0.025,0.011,0.037,0.018c4.47,2.878,9.754,3.063,14.744,4.436
	c0.304-2.359,1.867-4.352,5.185-5.273c7.377-2.049,14.631,0.762,20.563,5.163c0.433-3.941,0.884-8.621,5.883-8.208
	c4.489,0.37,8.37,2.314,11.57,5.132c0.419-2.991,0.879-6.554-0.358-9.066c-0.867-1.761-2.677-1.979-4.421-2.331
	c-4.754-0.96-9.938-3.027-12.97-7.023c-7.72-10.174-6.379-26.14-4.527-38.051c-4.526-12.693,4.506-25.92,15.623-31.94
	c1.892-1.024,3.838-1.799,5.817-2.35c1.978-0.551,3.988-0.875,6.006-0.995c8.649-0.516,17.458,2.722,24.624,8.048
	c11.394,8.468,21.113,22.372,19.755,37.184c-0.196,2.144-0.698,4.104-1.421,5.928c-1.023,2.583-2.499,4.891-4.227,7.044
	c-4.146,5.162-9.729,9.441-13.937,14.518c0.001,0.026,0.009,0.048,0.008,0.076c-0.173,4.875-0.821,11.147,2.959,14.907
	c0.257,0.255,0.181,0.534-0.067,0.778c11.849,0.379,26.606,3.461,30.573,14.638c3.981-5.026,9.272-8.946,15.775-11.31
	c13.458-4.891,30.515-2.272,41.665,6.783c12.43,10.095,17,25.699,14.327,41.23c-1.571,9.128-4.581,18.477-9.174,26.841
	c3.698,0.673,5.911,3.663,5.779,6.86c0.134-0.026,0.25-0.038,0.335-0.029c6.466,0.645,14.372,4.772,17.435,10.741
	c0.091,0.057,0.128,0.129,0.119,0.211c7.451,4.573,8.421,15.803,9.33,23.628c0.62,5.337,0.739,11.236,2.96,16.206
	c1.576,3.526,3.591,6.81,5.46,10.168c0.12,0.214,0.24,0.428,0.358,0.643C537.162,389.016,538.355,391.396,539.257,393.931z
	 M149.003,277.38c0.035,3.455,0.249,7.272,0.333,11.173c0.015,0.684,0.035,1.364,0.039,2.051c0.322-0.116,0.62-0.187,0.803-0.175
	c0.139,0.009,0.266,0.036,0.398,0.054c1.611,0.231,2.695,1.167,3.33,2.49c0.076,0.159,0.15,0.319,0.214,0.488
	c0.005-0.104,0.011-0.211,0.017-0.315c0.285-5.36,0.572-11.049,0.023-16.408c-0.012-0.001-0.024,0-0.036-0.001
	C152.486,277.149,150.741,277.308,149.003,277.38z M147.673,305.739c0.182-0.101,0.402-0.194,0.71-0.26
	c3.731-0.793,4.626-11.464,0.992-13.385C149.339,296.789,148.957,301.49,147.673,305.739z M147.417,306.545
	c-0.809,2.417-1.926,4.677-3.47,6.685c-2.095,2.725-4.887,4.575-8.036,5.527c0.718,3.098,3.2,10.687,7.579,7.337
	c3.167-2.423,5.174-6.634,6.642-10.271c1.54-3.815,2.57-7.631,3.167-11.565c0.142-0.936,0.267-1.876,0.36-2.827
	c-1.016,2.588-2.771,4.739-4.964,5.205C148.077,306.769,147.645,306.7,147.417,306.545z M128.916,277.663
	c-5.251-0.063-10.514-0.16-15.755-0.514c-1.495-0.101-3.29-0.08-4.958-0.324c-0.298,5.324-4.147,9.808-8.863,12.206
	c0.271,0.104,0.542,0.233,0.811,0.389c0.359,0.209-2.014,1.399-2.731,0.981c-3.196-1.861-5.771,4.684-5.88,7.093
	c-0.217,4.787,3.438,6.913,7.759,7.222c0.262-0.055,0.496-0.072,0.656-0.03c8.265,2.203,5.979,13.688,4.756,19.918
	c1.51,2.622,3.82,4.972,5.916,6.592c1.64,1.268,3.483,2.589,5.547,3.04c2.714,0.592,3.846-1.207,4.425-3.41
	c-0.017-0.039-0.037-0.076-0.046-0.121c-0.678-3.125-0.564-7.268,1.622-9.806c4.425-5.139,11.541-1.623,16.565-5.508
	c0.81-0.626,1.534-1.322,2.188-2.072c0.864-0.99,1.598-2.082,2.223-3.252c4.271-7.997,3.388-19.733,3.034-28.803
	c-0.407,0.085-0.83,0.079-1.227-0.062c-1.709-0.604-3.614-0.17-5.345,0.117c-0.4,0.066-1.408-0.017-1.262-0.662
	c0.149-0.662,1.083-0.916,1.64-1.008c2.067-0.343,4.108-0.617,6.131-0.191c-0.02-0.685-0.033-1.348-0.039-1.99
	c-0.26,0.007-0.522,0.014-0.778,0.023C139.844,277.69,134.378,277.728,128.916,277.663z M195.217,308.837
	c-8.938,0.043-17.871,0.339-26.809,0.389c-0.546,13.402-6.067,44.004,14.255,42.305c0.418-3.149,1.09-6.263,2.761-8.795
	c3.603-5.46,8.796-9.095,14.474-12.172c-3.633-4.198-8.662-10.171-7.988-15.493C192.247,312.413,193.451,310.386,195.217,308.837z
	 M391.575,158.299c-6.842,2.452-13.105,5.958-19.012,10.077c14.905,7.825,26.401,26.243,28.886,42.615
	c0.173,1.14-2.738,1.831-2.897,0.776c-2.431-16.014-13.548-34.112-28.132-41.859c-1.627,1.19-3.236,2.413-4.819,3.677
	c9.615,2.426,15.74,11.894,19.997,20.207c4.703,9.186,8.062,18.903,11.737,28.516c2.668-2.835,5.632-5.41,8.354-8.168
	c-0.074-0.101-0.125-0.222-0.137-0.373c-1.226-15.125-7.665-32.318-21.166-40.543c-1.41-0.859,1.121-2.365,2.229-1.69
	c13.31,8.109,20.074,24.743,21.664,39.794c2.215-2.6,4.099-5.463,5.257-8.916c3.987-11.89-1.896-25.27-9.94-34.106
	C400.458,164.86,396.269,161.226,391.575,158.299z M367.63,176.446l2.819,1.823C369.552,177.592,368.616,176.979,367.63,176.446z
	 M348.324,184.959c1.609-1.063,3.172-2.192,4.703-3.369c0.066-0.086,0.203-0.188,0.383-0.295c4.731-3.669,9.207-7.711,14.002-11.266
	c7.022-5.204,14.44-9.648,22.647-12.64c-8.14-4.695-17.571-7.07-25.641-2.701C353.671,160.508,344.673,172.549,348.324,184.959z
	 M369.172,231.623c1.188,0.803,1.799,2.161,2.079,3.739c0.007-0.001,0.015-0.003,0.022-0.004c4.461-0.698,8.464,0.617,10.533,4.169
	c0.496-0.792,1.083-1.521,1.793-2.156c3.056-2.731,7.072-4.071,10.89-5.358c0.178-0.06,0.373-0.097,0.568-0.118
	c-0.158-2.373-0.008-4.8,0.074-6.995c-3.798-9.792-7.058-19.835-11.856-29.207c-1.481-2.892-3.148-5.97-5.095-8.87
	c-1.188-1.771-2.479-3.475-3.896-5.028c-0.085,0.018-0.162,0.031-0.219,0.031c-0.287,0-0.574,0-0.861,0c-5.917,0-11.833,0-17.749,0
	c-2.177,1.693-4.411,3.307-6.751,4.763c-1.947,12.53-4.064,33.729,9.04,41.05C361.285,229.616,365.902,229.415,369.172,231.623z
	 M379.068,247.624c0.297-1.381,0.615-2.835,1.052-4.25c-0.034-0.03-0.06-0.064-0.069-0.107c-0.962-4.268-4.246-7.309-8.671-6.937
	c0.286,3.073-0.429,6.685-0.662,8.727c-0.024,0.215-0.158,0.406-0.353,0.566c1.617,1.824,3.007,3.874,4.156,6.025
	c1.499-1.42,3.314-2.871,5.224-3.666C379.32,247.978,379.015,247.872,379.068,247.624z M357.546,240.547
	c0.423,0.089,0.889,0.211,1.379,0.351c-0.115-0.036-0.226-0.08-0.342-0.113C358.209,240.675,357.865,240.598,357.546,240.547z
	 M329.291,243.569c0.499-0.225,1.046-0.446,1.649-0.667C330.341,243.061,329.783,243.283,329.291,243.569z M326.247,309.634
	c0.33,1.915,1.29,3.879,3.377,4.282c0.011,0.002,0.017,0.005,0.027,0.007c-1.166-2.975-1.826-6.188-2.176-9.499
	C326.218,305.521,326.026,308.353,326.247,309.634z M328.703,315.147c0.531-0.034,1.001-0.217,1.3-0.362
	c-0.035-0.081-0.069-0.162-0.103-0.243C329.636,314.771,329.158,315.001,328.703,315.147z M303.967,236.226
	c-0.029,0.01-0.059,0.018-0.088,0.027c-2.186,4.323-5.156,8.18-8.06,12.044c-0.017,0.022-0.043,0.044-0.067,0.066
	c0.037,0.012,0.079,0.01,0.115,0.025c2.373,0.999,4.441,2.572,6.099,4.528c0.059,0.07,0.122,0.136,0.18,0.207
	c0.056-0.053,0.112-0.107,0.168-0.161c2.649-2.521,5.624-5.75,5.075-9.188C306.956,241.064,305.495,238.585,303.967,236.226z
	 M281.01,229.741c1.629,1.43,2.181,3.616,2.265,5.94c0.259,0.002,0.473,0.054,0.584,0.182c3.31,3.808,7.018,7.302,10.695,10.768
	c2.616-3.492,5.169-7.036,7.013-11.012c0.073-0.157,0.247-0.291,0.473-0.403c0-0.669,0-1.339,0-2.008
	c-0.001-0.015-0.004-0.028,0-0.043c0-4.417,0-8.833,0-13.249c-0.568,0.047-1.127,0.071-1.659,0.059
	c-1.956-0.046-1.448-2.232,0.238-2.193c3.191,0.075,9.154-1.151,8.62-5.64c-0.524-4.405-5.251-1.707-7.269-0.07
	c-0.786,0.637-2.105,0.339-2.389-0.307c-1.636-0.819-1.827-6.73-1.837-9.085c-0.947-5.266,0.761-12.83-0.474-16.499
	c-1.829-5.428-6.855-7.011-12.036-3.907c-2.735,1.64-5.33,3.113-8.494,3.72c-5.707,1.094-10.854-0.878-15.014-4.527
	c-0.553,1.401-0.993,2.886-1.293,4.456c-0.115,0.769-0.23,1.568-0.34,2.388c-0.016,0.157-0.039,0.311-0.052,0.47
	c-0.002,0.031-0.015,0.061-0.022,0.091c-1.649,12.612-2.204,30.311,9.563,36.885C272.872,227.594,278.23,227.301,281.01,229.741z
	 M160.464,205.646c-0.307,0.021-0.615-0.007-0.891-0.131c-0.789-0.355-1.667-0.509-2.553-0.516c0.332,1.12,0.733,2.212,1.219,3.262
	C158.82,207.271,159.583,206.38,160.464,205.646z M166.125,240.385c0.211,6.923,4.614,12.887,11.897,13.594
	c5.903,0.573,11.958-2.014,12.466-8.511c0-0.014,0.003-0.028,0.005-0.043c0.001-0.014,0.004-0.025,0.005-0.04
	c-0.521-4.798-1.198-10.904,2.99-14.285c3.237-2.615,7.907-2.798,11.595-4.498c15.698-7.235,9.675-26.241,7.424-39.643
	c-0.551-5.809-3.7-10.39-8.186-13.707c1.293,1.88,2.242,3.938,2.573,6.202c0.1,0.68-2.823,1.287-2.897,0.776
	c-0.629-4.299-3.44-7.816-6.668-10.772c-0.879-0.344-1.776-0.653-2.689-0.93c3.235,2.938,5.678,6.761,6.713,10.925
	c0.227,0.912-2.675,1.67-2.897,0.776c-1.283-5.159-4.898-10.009-9.527-12.976c-1.859-0.274-3.731-0.421-5.579-0.436
	c5.783,2.715,10.529,7.751,13.649,13.229c0.207,0.363-2.508,1.46-2.897,0.776c-3.371-5.919-8.718-11.395-15.231-13.769
	c-1.144,0.129-2.262,0.314-3.348,0.553c6.479,1.699,12.125,6.886,15.54,12.44c0.186,0.302-2.488,1.442-2.897,0.776
	c-1.661-2.701-3.725-5.451-6.226-7.413c-2.616-2.052-6.13-4.094-9.58-4.915c-1.224,0.425-2.389,0.922-3.462,1.51
	c0,0-0.001,0-0.001,0c6.724,1.102,13.3,4.656,16.911,10.43c0.701,1.121-2.104,2.103-2.678,1.185
	c-3.591-5.742-10.149-9.201-16.822-9.998c-0.472,0.329-0.929,0.67-1.373,1.024c6.161,0.47,12.243,4.766,15.914,9.332
	c0.915,1.139-1.846,2.273-2.656,1.266c-3.453-4.296-9.671-8.917-15.572-8.511c-0.486,0.495-0.956,1.001-1.395,1.53
	c5.953-0.473,12.368,3.153,15.856,7.868c0.938,1.269-1.802,2.451-2.643,1.315c-3.028-4.093-9.755-8.319-15.039-6.674
	c-0.408,0.643-0.785,1.305-1.13,1.984c6.334-1.205,12.992,1.325,16.366,7.404c0.253,0.457-2.486,1.518-2.897,0.776
	c-2.686-4.839-8.524-8.305-14.021-7.038c-0.281,0.634-0.526,1.286-0.753,1.947c5.82,0.107,11.025,2.508,15.398,6.734
	c0.671,0.648-2.042,1.724-2.705,1.082c-3.733-3.608-8.154-6.016-13.155-6.323c-0.101,0.368-0.196,0.737-0.28,1.112
	c5.803,0.176,12.114,5.104,16.139,8.281c0.696,0.55-1.954,1.681-2.703,1.09c-1.786-1.41-3.671-2.723-5.608-3.913
	c-1.64-1.008-5.291-3.496-8.104-4.013c-0.124,0.75-0.22,1.51-0.279,2.284c0.758,0.212,1.461,0.474,2,0.686
	c4.577,1.797,9,4.174,13.109,6.871c1.179,0.774-1.499,1.944-2.303,1.416c-2.571-1.688-5.266-3.211-8.021-4.579
	c-1.449-0.719-2.909-1.437-4.417-2.028c-0.15-0.059-0.303-0.117-0.454-0.176c-0.006,0.562-0.007,1.128,0,1.7
	c0.77,0.157,1.532,0.4,2.249,0.691c4.17,1.693,7.986,4.128,11.953,6.229c1.235,0.655-1.422,1.903-2.296,1.44
	c-2.43-1.288-4.796-2.692-7.228-3.977c-0.557-0.294-2.786-1.629-4.62-2.425c0.051,1.132,0.135,2.271,0.269,3.407
	c3.231,0.225,6.288,1.149,9.139,2.85c0.5,0.299-1.964,1.478-2.72,1.027c-1.957-1.168-4.032-1.964-6.208-2.358
	c0.125,0.79,0.272,1.575,0.452,2.35c1.81-0.185,3.653,0.001,5.292,0.739c0.248,0.111,0.329,0.279,0.314,0.457
	c2.504-1.302,5.477-1.365,7.828,0.789c0.363,0.333-2.203,1.447-2.738,0.957c-0.907-0.831-2.557-2.352-3.858-1.21
	c-0.824,0.723-1.537,1.564-2.054,2.536c-0.58,1.089-1.228,2.423-1.367,3.741c0.001,0.002,0.002,0.003,0.003,0.004
	c0.034,0.047,0.022,0.107-0.023,0.173c-0.035,0.506,0.005,1.008,0.166,1.49c0.527,1.579,1.605,2.95,3.009,3.856
	c0.708,0.457,1.484,0.923,2.32,1.096c1.04,0.215,0.989,0.129,1.55-0.811c0.026-0.044,0.062-0.086,0.104-0.125
	c0.526-0.845,2.513-1.19,2.742-0.461c0.003,0.004,0.001,0.008,0.002,0.012c0.011,0.037,0.023,0.072,0.025,0.115
	c0.186,6.434,0.933,16.846-3.249,22.517c0.293,0.169,0.352,0.451-0.096,0.856C166.408,240.156,166.264,240.269,166.125,240.385z
	 M202.765,290.325c-5.461-9.124-5.253-20.823,1.43-30.033c2.399-3.307,5.33-5.957,8.583-7.896c-1.025-0.413-2.07-0.752-3.124-1.055
	c-2.076-0.598-4.195-1.03-6.325-1.421c-1.867-0.343-3.741-0.663-5.596-1.056c-1.385-0.293-3.24-0.556-4.775-1.218
	c-1.893,6.061-8.868,9.067-15.146,8.458c-2.786-0.27-5.258-1.087-7.351-2.342c-1.838-1.102-3.375-2.547-4.556-4.284
	c-1.38-2.029-2.281-4.444-2.592-7.167c-2.377,1.346-5.115,2.366-7.796,3.262c8.324,13.509,9.846,29.206,9.94,44.752
	C177.893,290.325,190.329,290.325,202.765,290.325z M217.087,288.917c0.087-0.205,0.329-0.415,0.786-0.599
	c4.999-2.014,9.157-6.924,8.606-12.545c-0.12-1.217,2.79-1.88,2.897-0.776c0.531,5.409-2.683,10.142-7.062,13.011
	c8.916-0.298,17.167-10.33,17.513-18.843c0.015-0.355,0.407-0.657,0.897-0.843c0.624-0.322,1.495-0.482,1.891-0.222
	c2.362,1.553,4.352,3.816,6.84,5.144c3.223,1.718,6.534,2.398,9.979,1.93c-4.149-1.535-7.997-3.878-10.593-7.064
	c-0.846-1.038,1.931-2.119,2.67-1.211c8.402,10.312,23.599,10.25,34.056,3.631c-6.42,0.727-14.514-0.898-17.898-5.994
	c-0.258-0.098-0.516-0.202-0.775-0.323c-0.165-0.077,0.091-0.305,0.51-0.532c0.435-0.632,2.394-1.156,2.856-0.365
	c0.04,0.068,0.092,0.127,0.134,0.193c2.246,0.789,4.369,0.419,6.478-0.321c-14.463-0.742-26.269-24.794-39.705-11.383
	c0,0.017,0.001,0.032,0,0.049c-0.025,0.986-2.752,1.561-2.891,0.444c-12.342-5.369-25.25,0.799-30.265,13.145
	c-1.221,3.005-1.901,6.044-2.092,9.037c-0.166,2.598,0.048,5.157,0.64,7.628c1.179,4.924,3.83,9.49,7.917,13.251
	c0.078-0.204,0.153-0.409,0.244-0.605C211.834,292.329,214.313,289.692,217.087,288.917z M268.871,305.739
	c0.182-0.101,0.402-0.194,0.71-0.26c3.731-0.793,4.627-11.464,0.992-13.385C270.537,296.789,270.155,301.49,268.871,305.739z
	 M259.935,315.391c9.181-7.099,7.89-22.724,7.445-34.126c-0.407,0.085-0.83,0.079-1.228-0.062c-1.709-0.604-3.614-0.17-5.344,0.117
	c-0.4,0.066-1.408-0.017-1.262-0.662c0.149-0.662,1.083-0.916,1.64-1.008c2.067-0.343,4.108-0.617,6.131-0.191
	c-0.024-0.815-0.04-1.604-0.043-2.359c-1.628-0.208-3.298-0.544-4.956-1c-3.674,1.105-7.417,1.392-11.136,0.186
	c-3.545-1.149-5.947-3.197-8.706-5.249c-0.468,2.622-1.546,5.202-3.07,7.567c2.995-0.998,6.179-1.021,9.04,0.5
	c1.065,0.566-1.621,1.66-2.34,1.277c-2.073-1.102-4.228-1.417-6.448-0.533c-0.32,0.127-0.851,0.285-1.306,0.259
	c-4.147,5.5-10.68,9.515-17.146,9.585c-0.517,0.403-2.04,1.029-2.59,0.708c-3.196-1.861-5.771,4.683-5.881,7.093
	c-0.152,3.355,1.6,5.402,4.147,6.436c2.455,0,4.681,0.063,6.423,0.069c1.087,0.004-0.235,0.691-1.188,1.014
	c7.168,2.852,4.919,13.823,3.755,19.759c-0.04,0.205-0.235,0.388-0.502,0.538c1.277,1.276,2.585,2.409,3.729,3.326
	c3.341,2.679,10.688,8.076,12.668,2.123c-0.005-0.019-0.016-0.033-0.02-0.052c-0.678-3.125-0.564-7.268,1.622-9.806
	C247.795,315.761,254.911,319.276,259.935,315.391z M291.551,252.943c-0.101,0.156-0.198,0.313-0.297,0.47
	c-0.338,0.536-0.664,1.079-0.971,1.632c-0.299,0.54-0.583,1.089-0.833,1.656c-0.347,0.785-2.109,1.52-2.794,0.749
	c-0.094-0.106-0.178-0.212-0.27-0.318c-0.677-0.776-1.31-1.555-1.9-2.342c-2.386-3.181-4.071-6.506-5.013-10.45
	c-0.483,0.232-0.977,0.433-1.467,0.644c-0.658,0.282-1.321,0.552-1.991,0.802c-2.474,0.926-5.008,1.681-7.541,2.451
	c-0.674,0.205-1.346,0.415-2.017,0.628c-1.883,0.599-3.746,1.263-5.565,2.053c-0.424,0.184-0.852,0.354-1.27,0.555
	c0.612,0.487,1.236,0.993,1.866,1.505c1.478,1.2,3.002,2.433,4.566,3.582c0.646,0.476,1.298,0.938,1.958,1.373
	c2.274,1.499,4.623,2.688,7.021,3.218c0.655,0.145,1.314,0.242,1.978,0.278c0.586,0.032,1.175,0.021,1.766-0.044
	c0.035-0.004,0.07-0.013,0.105-0.017c0.368-0.044,0.736-0.094,1.105-0.179c0.931-0.214,1.488,0.155,1.559,0.61
	c0.239,0.078,0.348,0.217,0.231,0.397c-0.093,0.143-0.324,0.312-0.768,0.503c-0.618,0.266-1.24,0.524-1.863,0.773
	c-2.401,0.96-4.841,1.732-7.309,1.774c2.814,2.599,7.454,3.771,11.673,3.796c0.682,0.004,1.352-0.02,2.002-0.075
	c1.01-0.086,1.974-0.238,2.85-0.46c0.032-0.026,0.066-0.051,0.098-0.077c1.003-0.835,3.376-0.147,1.865,1.111
	c-1.639,1.364-3.439,2.625-5.35,3.724c-0.646,0.372-1.306,0.722-1.977,1.053c-1.769,0.872-3.612,1.59-5.5,2.108
	c-0.664,0.183-1.332,0.344-2.005,0.475c-1.747,0.339-3.52,0.5-5.293,0.438c0.025,2.67,0.162,5.564,0.263,8.539
	c0.022,0.665,0.041,1.328,0.058,2c0.023,0.905,0.045,1.813,0.051,2.726c0.322-0.115,0.62-0.187,0.803-0.174
	c1.912,0.126,3.14,1.191,3.811,2.717c0.929,2.113,0.781,5.112-0.136,7.759c-0.977,2.819-2.82,5.234-5.16,5.731
	c-0.617,0.131-1.049,0.063-1.277-0.092c-0.633,1.892-1.463,3.683-2.531,5.336c-0.296,0.459-0.603,0.912-0.938,1.349
	c-0.348,0.452-0.719,0.874-1.104,1.277c-1.292,1.356-2.778,2.446-4.407,3.257c0.011,0.004,0.025,0.006,0.036,0.011
	c3.04,1.369,4.268,3.777,4.419,6.605c0.035,0.647,0.016,1.314-0.054,1.999c-0.133,1.299-0.437,2.651-0.859,4.007
	c0.441,0.064,0.874,0.138,1.301,0.218c0.689,0.129,1.357,0.282,2.008,0.455c2.052,0.544,3.917,1.315,5.581,2.382
	c0.685,0.439,1.333,0.932,1.949,1.476c0.271,0.239,0.535,0.489,0.792,0.75c0.518,0.524,1.008,1.091,1.47,1.708
	c0.655,0.873,1.257,1.834,1.798,2.908c1.12,2.223,2.109,4.51,2.999,6.837c0.139,0.363,0.268,0.729,0.401,1.095
	c0.543,1.48,1.05,2.976,1.524,4.481c0.614,1.952,1.177,3.918,1.702,5.89c0.05,0.188,0.103,0.375,0.152,0.563
	c0.393-0.152,0.789-0.24,1.045-0.195c1.872,0.328,3.758,0.591,5.652,0.795c0-3.695,0-7.391,0-11.086c0-0.656,0-1.313,0-1.969
	c0-3.211,0-6.421,0-9.632c0-0.699,0-1.398,0-2.098c0-2.04,0-4.079,0-6.119c0-0.643,0-1.286,0-1.929c0-4.419,0-8.839,0-13.259
	c0-0.66,0-1.32,0-1.98c0-3.75,0-7.501,0-11.251c0-0.667,0-1.333,0-2c0-3.515,0-7.03,0-10.545c0-0.667,0-1.333,0-2
	c0-2.495,0-4.99,0-7.484c0-0.673,0-1.346,0-2.019c0-3.365,0-6.73,0-10.095c0-0.671,0-1.342,0-2.013c0-2.899,0-5.797,0-8.696
	c0-0.692,0-1.384,0-2.077C291.551,253.311,291.551,253.127,291.551,252.943z M291.964,247.784c-3-2.828-6.008-5.687-8.824-8.704
	c-0.157,1.558-0.396,3.046-0.536,4.277c-0.019,0.168-0.081,0.319-0.17,0.455c1.012,4.053,2.825,7.452,5.427,10.729
	c1.03-1.77,2.202-3.452,3.287-5.202c0.081-0.13,0.226-0.244,0.403-0.343c0-0.243,0-0.486,0-0.729
	C291.551,248.083,291.717,247.92,291.964,247.784z M335.968,334.806c-0.713,0.139-1.417,0.142-1.417-0.039c0-0.62,0-1.239,0-1.858
	c0-0.704,0-1.408,0-2.112c0-3.01,0-6.02,0-9.03c0-0.081,0.039-0.157,0.098-0.229c-0.545-0.457-1.057-0.955-1.517-1.512l-0.224,0.087
	c-0.054,0.18-0.215,0.379-0.518,0.592c-1.493,1.046-3.384,2.001-5.391,2.614c-0.658,0.201-1.327,0.362-2,0.479
	c-1.365,0.236-2.738,0.277-4.044,0.051c-0.903-0.156-1.77-0.444-2.579-0.884c-0.137-0.075-0.279-0.141-0.413-0.225
	c-1.272-0.798-2.046-1.745-2.464-2.773c-0.564-1.387-0.473-2.923,0-4.461c0.49-1.592,1.376-3.184,2.335-4.604
	c-0.75-0.074-1.539-0.094-2.335-0.109c-0.667-0.013-1.339-0.024-2-0.057c-0.55-0.028-1.092-0.072-1.615-0.148
	c-0.715-0.105-1.391-0.281-2.012-0.549c-1.494-0.646-2.65-1.872-3.102-4.267c-0.294-1.563-0.222-3.035,0.135-4.405
	c0.177-0.679,0.433-1.329,0.747-1.954c0.667-1.327,1.609-2.532,2.789-3.578c-3.471-1.087-6.067-3.227-7.441-6.095
	c-1.085-2.266-1.41-4.986-0.795-8.006c0.248-1.216,0.567-2.364,0.963-3.438c1.26-3.417,3.339-6.031,6.721-7.309
	c-1.733-1.271-2.763-3.035-3.184-4.974c-0.141-0.648-0.216-1.314-0.221-1.991c-0.016-2.186,0.657-4.458,1.937-6.427
	c0.164-0.252,0.343-0.477,0.52-0.706c0.52-0.674,1.093-1.256,1.713-1.753c0.978-0.785,2.064-1.374,3.229-1.805
	c0.292-0.108,0.589-0.202,0.889-0.292c3.534-1.057,7.627-0.927,11.314-0.927c-0.563-1.084-0.957-2.196-1.146-3.281
	c-0.104-0.005-0.206-0.019-0.297-0.049c-2.847-0.953-5.802-1.417-8.69-2.066c-0.662-0.148-1.32-0.308-1.973-0.485
	c-1.281-0.347-2.542-0.759-3.763-1.308c-0.575,3.815-4.646,7.234-7.217,9.693c-0.061,0.058-0.125,0.118-0.183,0.175
	c-0.519,0.501-3.292,0.813-2.634,0.178c0.045-0.043,0.096-0.091,0.143-0.135c0.153-0.146,0.307-0.292,0.471-0.446
	c-0.533,0.133-1.039,0.148-1.246-0.119c-1.368-1.762-3.132-3.215-5.103-4.264c0,1.393,0,2.786,0,4.179c0,0.679,0,1.358,0,2.037
	c0,2.78,0,5.561,0,8.342c0,0.667,0,1.335,0,2.002c0,3.279,0,6.558,0,9.837c0,0.637,0,1.274,0,1.911c0,2.522,0,5.045,0,7.568
	c0,0.667,0,1.333,0,2c0,3.588,0,7.177,0,10.766c0,0.667,0,1.333,0,2c0,3.632,0,7.264,0,10.896c0,0.66,0,1.32,0,1.98
	c0,4.523,0,9.045,0,13.568c0,0.644,0,1.287,0,1.931c0,1.921,0,3.843,0,5.764c0,0.704,0,1.408,0,2.111c0,3.309,0,6.618,0,9.927
	c0,0.653,0,1.307,0,1.959c0,3.743,0,7.485,0,11.228c2.371,0.176,4.75,0.255,7.127,0.245c0.294-0.001,0.587-0.014,0.881-0.019
	c1.954-0.027,3.906-0.102,5.848-0.253c0.734-0.057,3.069-0.125,5.581-0.336c0.453-0.038,0.911-0.081,1.368-0.129
	c3.057-0.324,5.997-0.894,6.3-1.947c0.138-0.478,0.941-0.821,1.67-0.919c0.436-3.876,1.305-7.81,2.824-11.317
	c0.292-0.674,0.611-1.328,0.953-1.967C329.003,339.619,331.881,336.529,335.968,334.806z M307.918,274.745
	c0.669-0.614,1.301-1.13,1.816-1.527C309.067,273.633,308.458,274.145,307.918,274.745z M349.552,325.969
	c-3.088-1.757-7.096-0.529-10.436-1.852c-0.566-0.224-1.124-0.473-1.667-0.748c0,3.541,0,7.082,0,10.622
	c0,0.125-0.084,0.239-0.217,0.342c0.889-0.296,1.823-0.539,2.818-0.708c0.033-0.005,0.059-0.005,0.091-0.01
	c0.214-0.387,0.597-0.667,1.047-0.765c3.233-0.702,6.598-0.77,9.89-0.808c0.396-0.004,0.687,0.083,0.892,0.224
	C352.106,329.665,351.599,327.134,349.552,325.969z M372.637,333.521c-0.523-0.19-0.486-0.651-0.129-1.03
	c-1.953-9.517-5.73-29.143,8.609-27.636c0.446,0.047,0.548,0.189,0.457,0.365c0.094-0.003,0.185,0.01,0.279,0.011
	c1.535-1.515,2.146-4.714,2.251-6.625c0.082-1.479,0.065-3.04-0.374-4.47c-0.182-0.591-0.39-1.229-0.784-1.721
	c-0.12-0.149-0.242-0.25-0.352-0.324c-0.811,2.4-1.937,5.776-4.378,6.739c-1.935,0.764-3.459,0.133-4.413-1.664
	c-2.088-3.933-2.705-8.963-3.026-13.52c-2.359,1.214-5.141,1.208-7.409-0.568c-0.321-0.187-0.602-0.451-0.847-0.774
	c-1.91-1.954-3.162-4.528-3.2-7.187c-6.821,4.913-21.389,9.276-22.715,1.271c-1.893,1.668-4.313,3.503-6.834,4.651l-0.219,0.491
	c0.46,0.08,0.815,0.335,0.813,0.83c-0.003,0.96-0.016,1.921-0.032,2.884c2.388-0.685,4.731-0.418,7.19-0.011
	c0.625,0.104,0.722,0.673,0.288,1.073c-0.534,0.492-1.349,0.665-2.049,0.549c-1.51-0.25-3.344-0.711-4.842-0.182
	c-0.174,0.062-0.391,0.128-0.618,0.176c-0.119,5.76-0.304,11.544,0.377,17.263c0.749,6.276,2.378,14.519,8.729,17.565
	c4.545,2.181,12.99-0.289,14.867,5.992c0.994,3.327,0.509,7.048-0.597,10.298c-0.095,0.28-0.346,0.511-0.662,0.684
	c-1.085,4.266-0.824,9.529,2.816,11.144c1.107,0.491,2.522,0.648,4.312,0.333c0.06-0.01,0.115-0.029,0.174-0.04
	c7.565-1.433,12.281-8.811,12.182-16.188C372.5,333.765,372.551,333.638,372.637,333.521z M383.541,309.218
	c-0.293-1.216-1.029-1.952-2.084-2.2c-0.694,0.275-1.45,0.42-2.258,0.434c-2.179,1.184,0.151,6.85,2.975,5.429
	C383.373,312.275,383.833,310.432,383.541,309.218z M373.449,318.527c0.039-0.023,0.082-0.043,0.124-0.064
	c-0.045-0.062-0.092-0.122-0.136-0.185C373.441,318.361,373.444,318.444,373.449,318.527z M409.355,321.154
	c0.108-0.094,0.227-0.163,0.351-0.221c2.489-6.349,2.721-13.144,2.092-19.966c-3.305,5.323-11.74,7.182-17.635,8.541
	c1.703,2.206,4.339,3.68,3.342,6.954c-0.749,2.458-3.379,4.348-5.417,5.655c-6.243,4.007-13.265,2.74-18.523-1.989
	c0.373,4.257,1.205,8.533,1.775,11.292c0.033,0.162,0.065,0.315,0.096,0.466c1.053-0.029,2.104-0.035,3.153-0.018
	c3.338,0.056,6.646,0.371,9.892,1.026c0.074-0.03,0.149-0.06,0.233-0.083C396.547,330.693,403.278,326.446,409.355,321.154z
	 M514.12,335.505c-2.986-4.614-9.115-8.027-14.464-8.957c-0.293,1.106-0.863,2.199-1.779,3.188
	c-0.729,0.788-3.552,0.685-2.358-0.604c2.745-2.963,1.545-8.128-2.433-9.061c-3.064,5.214-6.775,9.994-11.18,14.024
	c-0.662,0.605-3.536,0.702-2.309-0.421c10.826-9.905,17.561-24.887,20.292-39.139c2.546-13.286,1.161-27.531-8.192-38.041
	c-10.201-11.462-27.28-17.011-42.191-12.315c-12.25,3.857-19.843,13.918-22.565,26.156c-0.21,0.947-3.122,1.419-2.892,0.384
	c1.129-5.075,3.096-9.718,5.821-13.765c-0.459-0.041-0.855-0.249-0.986-0.687c-3.938-13.161-24.014-14.597-35.345-13.987
	c-1.976,0.106-1.391-2.046,0.254-2.135c1.289-0.069,2.682-0.111,4.139-0.117c-1.754-1.98-2.508-4.505-2.79-7.162
	c-3.334,1.109-7.273,2.262-9.597,5.034c-2.171,2.59-2.919,6.009-3.614,9.233c-0.023,0.107-0.106,0.208-0.223,0.301
	c1.424-0.195,2.86,0.08,4.222,1.116c5.012,3.813,6.227,9.732,5.58,15.608c4.497-2.265,11.619-1.563,14.998,1.003
	c7.276,5.525-0.932,15.433-6.16,20.26c3.423,0.778,7.349,2.322,9.948,4.741c-0.489-2.902-0.997-5.769-1.411-8.563
	c-0.173-1.168,2.737-1.86,2.897-0.776c1.925,12.971,5.87,27.545,0.672,40.226c-0.102,0.248-0.353,0.454-0.667,0.611
	c0.036,0.282-0.091,0.607-0.476,0.942c-5.599,4.874-11.789,8.87-18.751,11.316c1.364,0.417,2.715,0.899,4.048,1.471
	c0.175,0.075,0.257,0.174,0.293,0.282c7.59,4.337,10.887,11.629,12.652,19.592c5.342-4.028,10.838-7.847,16.137-11.933
	c0.089-0.069,0.242-0.134,0.424-0.193c0.102-0.155,0.187-0.324,0.297-0.472c0.312-0.419,0.664-0.806,1.052-1.162
	c1.194-1.094,2.76-1.866,4.82-2.159c-0.533-0.803-0.934-1.688-1.143-2.666c-1.537-7.184,6.042-10.217,11.98-11.365
	c0.065-0.158,0.137-0.317,0.221-0.481c0.014-0.028,0.038-0.053,0.057-0.079c-0.893-0.346-1.772-0.747-2.632-1.208
	c-17.599-9.458-16.738-33.902-16.731-51.086c0.001-1.176,2.923-1.692,2.923-0.271c0,0.52-0.002,1.047-0.004,1.58
	c12.379-10.35,36.813-6.609,47.221,4.228c0.051,0.053,0.108,0.104,0.159,0.157c0.003,0.003,0.002,0.008,0.005,0.011
	c0.022,0.028,0.057,0.044,0.074,0.076c1.898,3.711,2.082,8.679,2.553,12.74c0.594,5.128,1.063,10.273,1.354,15.428
	c0.043,0.107,0.056,0.233,0.021,0.385c-3.213,14.013-18.994,22.417-32.548,18.74c-1.028,4.363,6.054,6.199,9.46,6.835
	c7.099,1.326,14.436,0.878,20.926-2.497c0.799-0.415,3.451-0.292,1.729,0.604c-7.414,3.854-16.294,5.001-24.487,3.257
	c-3.509-0.747-10.886-2.513-10.674-6.859c-0.008,0.001-0.016,0.004-0.024,0.006c-4.811,0.93-11.518,4.528-8.156,10.355
	c2.696,4.672,7.343,6.041,12.069,7.929c10.266,4.102,19.492,6.148,30.135,2.191C488.942,342.83,500.837,335.246,514.12,335.505z
	 M475.414,307.075c-0.29-5.115-0.726-10.232-1.347-15.317c-0.497-4.064-0.648-9.001-2.545-12.724
	c-9.912-10.428-34.704-15.313-44.58-2.91c-0.074,13.72-0.035,30.627,9.611,40.875C448.624,329.824,471.379,324.639,475.414,307.075z
	 M262.884,301.634c0.555-2.449-0.477-4.519-1.793-6.48c-1.187-1.768-3.91-4.002-4.266-6.158c-0.212-1.283-3.118-0.555-2.897,0.776
	c0.45,2.727,3.782,5.446,5.218,7.733c1.073,1.708,1.427,4.276,0.136,5.979c-1.078,1.421-4.524,0.378-5.93,0.091
	c-1.373-0.281-2.95,1.632-1.024,2.025C256.455,306.444,261.732,306.718,262.884,301.634z M264.861,353.926
	c0.937,13.852,7.518,26.858,7.837,40.775c0.001,0.041,0.005,0.082,0.006,0.123c0.029,1.425,2.748,1.018,2.913-0.123
	c0.006-0.041,0.017-0.08,0.016-0.123c-0.287-14.16-6.922-27.352-7.874-41.428C267.674,351.895,264.77,352.575,264.861,353.926z
	 M454.457,289.586c0,1.607,0,3.213,0,4.82c0,0.729,2.897,0.232,2.897-0.776c0-1.607,0-3.213,0-4.82
	C457.354,288.082,454.457,288.578,454.457,289.586z M359.692,284.295c-3.391-1.351-6.997-1.015-10.198,0.686
	c-0.329,0.175-0.936,0.727-0.299,0.962c0.634,0.235,1.473-0.014,2.04-0.315c2.006-1.066,4.062-0.875,6.117-0.056
	C358.112,285.875,360.762,284.721,359.692,284.295z M222.311,298.899c-1.4,0.132-1.042-2.868-1.023-3.831
	c0.014-0.765,0.049-1.598-0.563-2.146c-0.856-0.768-2.268-0.139-3.027,0.441c-1.076,0.824-0.301,1.544,0.657,1.632
	c-0.001,0.049,0,0.087,0,0.144c0,0.895-0.058,1.783,0.013,2.677c0.171,2.128,1.478,3.398,3.683,3.19
	c0.635-0.06,1.508-0.437,1.598-1.166C223.736,299.121,222.876,298.846,222.311,298.899z M357.005,296.968c0-1.607,0-3.213,0-4.82
	c0-0.729-2.897-0.232-2.897,0.776c0,1.607,0,3.213,0,4.82C354.108,298.473,357.005,297.977,357.005,296.968z M344.942,309.135
	c-2.45,0.422-7.175-0.398-6.016-3.938c0.601-1.832,2.529-2.664,3.382-4.3c0.675-1.294,0.664-2.422,0.62-3.848
	c-0.044-1.477-2.966-0.986-2.929,0.246c0.028,0.934,0.146,1.845-0.156,2.754c-0.437,1.319-2.044,2.35-2.869,3.448
	c-0.963,1.284-1.439,2.833-0.966,4.391c0.985,3.242,5.928,3.749,8.651,3.279C346.199,310.901,346.909,308.796,344.942,309.135z
	 M332.599,292.924c0,1.607,0,3.213,0,4.82c0,0.729,2.897,0.232,2.897-0.776c0-1.607,0-3.213,0-4.82
	C335.497,291.419,332.599,291.916,332.599,292.924z M243.534,291.406c0-1.607,0-3.214,0-4.821c0-0.729-2.897-0.232-2.897,0.776
	c0,1.607,0,3.214,0,4.821C240.637,292.911,243.534,292.414,243.534,291.406z M355.522,309.07c-0.988,1.609-2.215,2.933-4.029,3.572
	c-0.254,0.089-1.271,0.606-0.814,1.009c0.467,0.412,1.401,0.115,1.895-0.059c2.398-0.846,4.287-2.429,5.613-4.588
	C358.818,307.976,355.947,308.378,355.522,309.07z M195.68,212.953c3.276,0.476,7.651-0.661,8.829-4.128
	c0.552-1.625-0.057-3.744-1.295-4.886c-1.315-1.212-3.594-1.112-5.241-1.119c-0.951-0.004-3.13,1.432-1.182,1.44
	c2.465,0.011,4.282,0.361,4.881,3.033c0.622,2.771-1.551,4.632-4.173,4.251c-0.676-0.098-1.51,0.132-2.049,0.549
	C194.983,212.455,195.104,212.87,195.68,212.953z M201.358,217.943c1.88-0.813,0.108-1.296-1.04-0.8
	c-4.839,2.092-10.74,1.962-14.095-2.56c-0.452-0.608-3.232,0.335-2.72,1.026C187.625,221.165,195.688,220.395,201.358,217.943z
	 M204.176,200.137c0,1.122,0,2.243,0,3.364c0,1.536,2.938,1.106,2.938-0.214c0-1.122,0-2.243,0-3.364
	C207.113,198.387,204.176,198.817,204.176,200.137z M185.77,200.137c0,1.122,0,2.243,0,3.364c0,1.536,2.938,1.106,2.938-0.214
	c0-1.122,0-2.243,0-3.364C188.707,198.387,185.77,198.817,185.77,200.137z M184.307,194.805c0.612,0.303,1.509,0.087,2.054-0.263
	c1.017-0.654,2.497-0.179,3.557,0.155c0.942,0.298,3.406-1.087,1.766-1.605c-2.336-0.738-5.003-0.817-7.131,0.552
	C184.161,193.896,183.679,194.494,184.307,194.805z M201.506,194.337c0.635,0.237,1.474-0.012,2.041-0.314
	c0.819-0.437,1.813-0.508,2.709-0.28c0.68,0.173,1.43,0.021,2.041-0.314c0.271-0.148,0.986-0.792,0.298-0.967
	c-2.274-0.579-4.729-0.191-6.791,0.908C201.474,193.546,200.871,194.1,201.506,194.337z M165.996,208.782
	c-1.531-0.278-3.212,0.512-4.459,1.31c-0.5,0.32-0.33,0.732,0.211,0.789c0.71,0.076,1.458-0.17,2.049-0.549
	c0.116-0.074,0.239-0.146,0.367-0.202c-0.021,0.041,0.212,0.06,0.36,0.151c0.577,0.356,0.835,1.038,1.027,1.652
	c0.252,0.813,3.136-0.009,2.897-0.776C168.052,209.881,167.34,209.027,165.996,208.782z M191.512,254.442
	c3.125,5.105,2.914,11.053,2.898,16.825c-0.002,0.876,2.895,0.335,2.897-0.776c0.017-5.989,0.126-11.899-3.102-17.175
	C193.715,252.516,190.894,253.433,191.512,254.442z M354.932,216c1.409,1.623,4.572,0.927,6.346,0.581
	c1.266-0.248,2.465-1.771,0.456-1.378c-1.329,0.26-4.177,1.01-4.29-1.089c-0.083-1.541,1.376-3.297,2.219-4.445
	c1.625-2.212,3.536-4.263,5.521-6.157c0.516-0.492-0.439-0.698-0.742-0.691c-0.704,0.015-1.414,0.262-1.924,0.749
	c-2.548,2.43-5.106,5.134-6.879,8.191C355.012,212.844,353.901,214.812,354.932,216z M357.057,203.288c0-1.122,0-2.243,0-3.364
	c0-1.536-2.938-1.106-2.938,0.214c0,1.122,0,2.243,0,3.364C354.12,205.038,357.057,204.607,357.057,203.288z M372.526,200.137
	c0,1.122,0,2.243,0,3.364c0,1.536,2.938,1.106,2.938-0.214c0-1.122,0-2.243,0-3.364
	C375.463,198.387,372.526,198.817,372.526,200.137z M370.632,194.642c1.357-0.429,2.736-0.646,3.994,0.164
	c0.817,0.526,3.491-0.658,2.3-1.425c-1.954-1.257-4.595-0.896-6.694-0.233c-0.419,0.132-1.417,0.508-1.249,1.135
	C369.145,194.884,370.214,194.774,370.632,194.642z M353.969,193.708c1.138-0.29,2.415,0.094,3.418,0.629
	c0.722,0.385,3.408-0.711,2.339-1.281c-1.85-0.986-4.079-1.068-6.082-0.559c-0.339,0.086-1.432,0.453-1.287,0.994
	C352.505,194.038,353.63,193.795,353.969,193.708z M374.535,223.105c-0.101,0.74,0.788,0.91,1.325,0.896
	c2.566-0.066,5.272-1.889,5.355-4.64c0.044-1.47-2.892-1.005-2.93,0.244c-0.04,1.327-0.806,2.326-2.145,2.36
	C375.522,221.981,374.629,222.421,374.535,223.105z M375.699,212.491c-0.109-0.823-3.025-0.184-2.897,0.776
	c0.96,7.265-7.24,5.001-11.753,4.429c-0.39-0.348-1.09-0.509-1.77-0.184c-3.311,1.581-6.79,0.548-7.06-3.49
	c-0.103-1.538-3.024-1.088-2.938,0.214c0.186,2.787,1.353,5.115,4.19,5.911c2.31,0.647,4.903,0.132,7.035-0.874
	C366.566,220.03,376.848,221.173,375.699,212.491z M287.667,200.709c0.416-0.548-0.352-0.761-0.761-0.761c-1.122,0-2.243,0-3.364,0
	c-0.643,0-1.51,0.228-1.919,0.767c-0.416,0.548,0.352,0.761,0.761,0.761c1.122,0,2.243,0,3.364,0
	C286.39,201.477,287.257,201.249,287.667,200.709z M268.454,199.779c-1.122,0-2.243,0-3.364,0c-0.68,0-1.531,0.193-1.9,0.837
	c-0.337,0.589,0.286,1.03,0.833,1.03c1.122,0,2.243,0,3.364,0c0.68,0,1.531-0.193,1.9-0.837
	C269.625,200.22,269.001,199.779,268.454,199.779z M265.555,193.092c0.708-0.455,2.12,0.041,2.876,0.28
	c0.588,0.186,1.292-0.012,1.855-0.208c0.043-0.015,1.33-0.607,0.884-0.748c-2.553-0.806-5.487-0.819-7.811,0.677
	c-0.44,0.283-0.419,0.548,0.146,0.547C264.169,193.641,264.991,193.455,265.555,193.092z M285.237,192.67
	c0.636,0.162,1.458-0.094,2.026-0.366c0.246-0.117,0.994-0.604,0.352-0.768c-2.214-0.563-4.78-0.104-6.776,0.96
	c-0.226,0.12-1.009,0.611-0.352,0.768c0.624,0.149,1.476-0.072,2.026-0.366C283.381,192.436,284.292,192.43,285.237,192.67z
	 M274.443,213.08c-0.562-0.007-1.071-0.092-1.575-0.328c-0.175-0.082-0.384-0.352-0.395-0.629c-0.058-1.6-2.997-1.217-2.946,0.181
	c0.089,2.473,2.659,3.018,4.697,3.042c0.663,0.008,1.515-0.514,1.583-1.223C275.881,213.343,275.057,213.087,274.443,213.08z
	 M285.607,215.719c-0.63,0.015-1.487,0.243-1.931,0.724c-0.462,0.501,0.399,0.604,0.717,0.596c3.314-0.08,6.237-1.804,7.542-4.892
	c0.271-0.642-2.532-0.339-2.871,0.463C288.435,214.1,287.367,215.676,285.607,215.719z"
      fill="#1c1c20"
    ></path>
  </svg>
);
